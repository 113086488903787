import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { useLoadTopicStats } from '../../ProgressPage/hooksTopic';
import TopicCard from '../../ProgressPage/TopicCard';
import SubTopicsBoard from '../../ProgressPage/SubTopicsBoard';

const topicsItem: any = [
  /*
  {
  topicId: 'Algebra',
  avg: 85,
}, {
  topicId: 'Geometry',
  avg: 33,
}, {
  topicId: 'Data',
  avg: 56,
}, {
  topicId: 'Ratio and proportion',
  avg: 0,
}, {
  topicId: 'Numbers',
  avg: 78,
}
*/
];

export const topicAreas: any = [
 /* 
  {
  topic: 'Expressions',
  avg: 58,
  subTopics: [{
    topic: 'Forming Expressions',
    avg: 46,
  }, {
    topic: 'Intro to Algebraic Notation',
    avg: 86,
  }, {
    topic: 'Brackets in Algebra',
    avg: 26,
  }, {
    topic: 'Algebraic Fractions',
    avg: 51,
  }],
}, {
  topic: 'Equations',
  avg: 78,
  subTopics: [{
    topic: 'Forming Expressions',
    avg: 67,
  }, {
    topic: 'Intro to Algebraic Notation',
    avg: 98,
  }, {
    topic: 'Brackets in Algebra',
    avg: 43,
  }, {
    topic: 'Algebraic Fractions',
    avg: 88,
  }],
}, {
  topic: 'Sequences',
  avg: 13,
  subTopics: [{
    topic: 'Forming Expressions',
    avg: 6,
  }, {
    topic: 'Intro to Algebraic Notation',
    avg: 2,
  }, {
    topic: 'Brackets in Algebra',
    avg: 0,
  }, {
    topic: 'Algebraic Fractions',
    avg: 24,
  }],
}
*/
];

const TopicProgress = () => {
  const [currentTopicNumber, setCurrentTopicNumber] = useState(1);
  const [selectedTopic, setSelectedTopic] = useState('');

  const currentAreas = topicAreas[currentTopicNumber - 1];

  // const avgs: any = useLoadTopicStats();

  // const filterList = (list: any) => list.filter((item: any) => avgs[item.name || item.title] !== undefined);

  // const topicsItem = useSelector((state: RootState) => getTopicsInfo(state, 'Maths'));

  // const topicsAreas = useSelector((state: RootState) => getTopicAreasInfo(state, 'Maths', 'Algebra'));

  const previousAreas = () => {
    if (currentTopicNumber === 1) {
      return setCurrentTopicNumber(topicAreas.length);
    }

    return setCurrentTopicNumber(currentTopicNumber - 1);
  };

  const nextAreas = () => {
    if (topicAreas.length === currentTopicNumber) {
      return setCurrentTopicNumber(1);
    }

    return setCurrentTopicNumber(currentTopicNumber + 1);
  };

  return (
    <Wrapper>
      <Title>Topics</Title>
      <TopicCardWrapper>
        {topicsItem.map((item: any, n: number) => (
          <TopicCardStyled
            key={n}
            value={item.avg}
            widthChart={107}
            heightChart={107}
            title={item.topicId}
            onClick={() => setSelectedTopic(item.topicId)}
            selectedTopic={selectedTopic === item.topicId}
          />
        ))}
      </TopicCardWrapper>
      {selectedTopic !== '' && (
        <SubTopicsBoard
          topicAreas={currentAreas}
          subTopics={currentAreas.subTopics}
          onPreviousAreas={previousAreas}
          onNextAreas={nextAreas}
          currentAreas={currentTopicNumber}
          lengthAreas={topicAreas.length}
          avgs={currentAreas.avg}
        />
      )}
    </Wrapper>
  );
};

export default TopicProgress;

const Wrapper = styled.div`
  max-width: 1280px;
`;

const Title = styled.h2`
  margin-left: 22px;
  margin-bottom: 23px;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;avgs
`;

const TopicCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 20px;
`;

const TopicCardStyled = styled(TopicCard)`
  width: calc(25% - 20px);
  margin: 0 10px 20px;
`;
