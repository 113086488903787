import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useGetPriceList } from '../../Admin/UserEdit/hooks';
import { UserProfileBaseType } from '../../features/user/types';
import { changeTariff, getUserProfile, unsubscribeUser } from '../../features/user/userState';
import InformationPlan from './InformationPlan';
import PaymentInformation from './PaymentInformation';
import { PayPlanStripe, PayPlanType } from './types';
import ModalCancelSubscription from './ModalCancelSubscription';
import ModalChangeSubscription from './ModalChangeSubscripton';
import { tariffNameById } from '../../config/tariffConfig';

interface CurrentPlanProps {
  user: UserProfileBaseType
}

export interface SUBSCRIPTION_STATUS_TYPE {
  ACTIVE: string
  TIME_LEFT: string
  INACTIVE: string
}

export type SUBSCRIPTION_STATUS_KEY = keyof SUBSCRIPTION_STATUS_TYPE;

export const SUBSCRIPTION_STATUS: Record<SUBSCRIPTION_STATUS_KEY, string> = {
  ACTIVE: 'ACTIVE',
  TIME_LEFT: 'TIME_LEFT',
  INACTIVE: 'INACTIVE',
};


const CurrentPlan = ({
  user,
}: CurrentPlanProps) => {
  const [showChangePlan, setShowshowChangePlan] = useState(false);
  const [showCancelSubscription, setShowCancelSubscription] = useState(false);

  const priceList = useGetPriceList();

  const tariffId = user.priceId || '';

  const currentTariffName = tariffNameById[tariffId] || 'Inactive';

  const dispatch = useDispatch();

  const paymentSystem = user.subscription?.system;
  
  const currentTariff = paymentSystem && priceList[paymentSystem].find((item: PayPlanStripe) => item.id === tariffId);

  //const userPaimentId = user.priceId || '';

  const handleChangeTariff = (priceId: string, system: string, ppSubscribeId?: string) => {
    if (system === 'paypal') {
      dispatch(changeTariff(priceId, system, undefined, ppSubscribeId));
    } else if (system === 'stripe') {

      // throw new Error('Stripe not work here');
      dispatch(changeTariff(priceId, system));
    } else {
      throw new Error('Unknown payment system');
    }
    setShowshowChangePlan(false);
  };

  const getSubscriptionStatus = () => {
    if (user.subscription?.status === 'live') {
      return SUBSCRIPTION_STATUS.ACTIVE;
    }
    if (user.subscription?.status === 'canceled') {
      return SUBSCRIPTION_STATUS.TIME_LEFT;
    }

    return SUBSCRIPTION_STATUS.INACTIVE;
  };

  const currentStatusSubscription = getSubscriptionStatus();

  const unsubscribe = (feedbackMessage: string) => {
    dispatch(unsubscribeUser(feedbackMessage));
  };

  return (
    <Container>
      {showChangePlan && (
        <ModalChangeSubscription
          showModal={showChangePlan}
          onChangeTariff={handleChangeTariff}
          onClose={() => setShowshowChangePlan(false)}
          currentPriceName={currentTariff?.name || ''}
          priceList={priceList}
          statusSubscription={currentStatusSubscription}
        />
      )}
      {showCancelSubscription && (
        <ModalCancelSubscription
          showModal={showCancelSubscription}
          onClose={() => setShowCancelSubscription(false)}
          onCancelSubscribe={(reason: string) => {
            unsubscribe(reason);
          }}
        />
      )}
      <Title>Current plan</Title>
      <InformationPlan
        currentTariffName={currentTariffName}
        statusSubscription={currentStatusSubscription}
        onShowModalCancelSubscription={() => setShowCancelSubscription(true)}
        onShowModalChangeSubscribtion={() => setShowshowChangePlan(true)}
        user={user}
      />
      <PaymentInformation onShowModalCancelSubscription={() => setShowshowChangePlan(true)} />
    </Container>
  );
};

export default CurrentPlan;

const Container = styled.div`
  width: 100%;
  background-color: #FFF;
  box-shadow: 0px 2px 8px #F0F2F3;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 40px 56px 40px;
  margin-bottom: 35px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 50px;
  color: #9C9C9C;
`;
