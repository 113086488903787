import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

export interface PaypalDataType {
  billingToken: string
  facilitatorAccessToken: string
  orderID: string
  paymentID: null
  subscriptionID: string
  selectedPlanId?: string
}

interface PaypalActionType {
  subscription: {
    create: Function
  }
}

interface PaypalProps {
  onSubscribePaypal: (data: PaypalDataType) => void
  selectedPlanId?: string
  className?: string
}

const Paypal = ({
  onSubscribePaypal,
  selectedPlanId,
  className,
}: PaypalProps) => {

  useEffect(() => {
    const { paypal } = (window as any);
    console.log(paypal)
    if (!paypal) return;
    const buttonsConf = paypal.Buttons({
      style: {
        shape: 'rect',
        color: 'gold',
        layout: 'vertical',
        label: 'subscribe',
      },
      createSubscription(data: PaypalDataType, actions: PaypalActionType) {
        return actions.subscription.create({
          plan_id: selectedPlanId,
        });
      },
      onApprove(data: PaypalDataType, actions: PaypalActionType) {
        onSubscribePaypal({ ...data, selectedPlanId });
      },
    });
    buttonsConf.render('#paypal-button-container');

    return () => {
      buttonsConf.close();
    }
  }, [selectedPlanId]);

  return (
    <PaypalButtonContainer className={className} id="paypal-button-container" />
  );
};

export default Paypal;

const PaypalButtonContainer = styled.div`
  width: 100%;
`;
