import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import Layout from '../Layout';
import Contacts from './Contacts';
import Footer from '../Layout/Footer/Footer';
import UseLocalization from '../Localization/UseLocalization';

const otterGirlBoy = require('../images/otter-girl-boy.svg');
const otterAndDocument = require('../images/otter-and-document.svg');
const girl = require('../images/interested-girl.svg');
const robotAndWoman = require('../images/robot-and-woman.svg');
const otterProfesor = require('../images/otter-profesor.svg');
const boy = require('../images/interested-boy.svg');
const teacherPresent = require('../images/teacher-present.svg');
const science = require('../images/science.svg');

const subjectMathsImg = require('../images/operators.svg');
const subjectEnglishImg = require('../images/english-book.svg');
const subjectVerbalerbalImg = require('../images/verbal-brain.svg');
const subjectNonverbalImg = require('../images/figures.svg');

const accessIcon = require('../images/icons/access-icon.svg');

const MainPage = () => {
  const [showWarning, setShowWarning] = useState(true);

  return (
    <Layout>
      <Page>
        <Section>
          <OtterAndDocumentImg src={otterAndDocument} />
          <MainGroupElements>
            <MainTitle>
              The first choice for
              {' '}
              <OrangeSpan>11+</OrangeSpan>
              {' '}
              entrance exam preparation
            </MainTitle>
            <WrapperList>
              <ListItem>Unlimited adaptive mock tests</ListItem>
              <ListItem>Expertly written exam questions</ListItem>
              <ListItem>The most realistic ISEB exam simulator available</ListItem>
              <ListItem>Affordably priced and easy to use</ListItem>
              <ListItem>Real-time tutor support chat with Otter Plus</ListItem>
            </WrapperList>
            <StartFreeButton to={`${process.env.PUBLIC_URL}/registration/student`}>
              <UseLocalization translateKey="action.trial">
                Start free 7-day trial
              </UseLocalization>
            </StartFreeButton>
          </MainGroupElements>
        </Section>

        <SubjectsBlock>
          <Subject>
            <SubjectImg src={subjectMathsImg} />
            <SubjectName>Maths</SubjectName>
          </Subject>
          <Subject>
            <SubjectImg src={subjectEnglishImg} />
            <SubjectName>English</SubjectName>
          </Subject>
          <Subject>
            <SubjectImg src={subjectVerbalerbalImg} />
            <SubjectName>Verbal Reasoning</SubjectName>
          </Subject>
          <Subject>
            <SubjectImg src={subjectNonverbalImg} />
            <SubjectName>Non-verbal Reasoning</SubjectName>
          </Subject>
        </SubjectsBlock>

        <Section>
          <GroupElements>
            <Title>
              <UseLocalization translateKey="mainPage.firstSection.title">
                The leading companion for securing a top school place
              </UseLocalization>
            </Title>
            <StrongDescription>
              Check your progress with our school-specific benchmarks and see how you compare with others aiming for your target school.
            </StrongDescription>
            <Description>
              Built with the input of teachers, tutors and parents, Otter is the all-in-one must-have 11+ preparation tool. Our platform is easy to use
              and focused entirely on your exam goals. Simply input your target schools and our AI adaptive learning engine will tailor the learning and tests to your child.
            </Description>
          </GroupElements>
          <OtterGirlBoyImg src={otterGirlBoy} />
        </Section>

        <Section>
          <ScienceImg src={science} />
          <GroupElements>
            <Title>Looks and feels like the real exam</Title>
            <StrongDescription>
              We are seeing more and more schools choosing to use online exams. Exam technique and familiarity with the online test are key to doing well.
            </StrongDescription>
            <Description>
              Learning and practising in an online environment that replicates the ISEB 11+ is crucial to success. We spoke to schools, parents, teachers and tutors who all told us they wanted a simple tool where kids could learn and practice on a platform that looked like the real thing with realistic exam-style adaptive questions.
            </Description>
          </GroupElements>
        </Section>

        <OtherWrapper>
          <FootNoteWrapper>
            <FootNoote>
              Questions written by qualified teachers and tutors
            </FootNoote>
          </FootNoteWrapper>
          <Section>
            <GroupElements>
              <Title>Stay on track with your preparation</Title>
              <Description>
                We’ve got you covered as you work towards the
                exam. Our experts have broken down the whole
                11+ syllabus into topic blocks which are easy to
                work through. Otter then monitors and tracks your
                progress and presents you with easy-to-understand feedback so you can:
              </Description>
              <OtherWrapperList>
                <ListItem>See exactly what you’ve covered and where you need to be</ListItem>
                <ListItem>Identify gaps and weaker areas</ListItem>
                <ListItem>Target topic areas associated with your chosen school test</ListItem>
                <ListItem>See how you compare with others aiming for your target school</ListItem>
              </OtherWrapperList>
            </GroupElements>
            <OtterProfesor src={otterProfesor} />
          </Section>
        </OtherWrapper>

        <Section>
          <TeacherPresentImg src={teacherPresent} />
          <GroupElements>
            <FootNoteWrapperIn>
              <FootNoote>
                Affordably priced and easy-to-use
              </FootNoote>
            </FootNoteWrapperIn>
            <Title>
              Real-time live chat
              <br />
              with a tutor at any time
              <br />
              with Otter Plus
            </Title>
            <Description>
              With our premium package, Otter Plus, you’ll
              get a live chat window where parents and kids
              can ask any question at any time directly to
              one of our in-house tutors. When you sign up
              you’ll be appointed directly to one of the expert
              tutors on the Otter team. They will oversee your
              learning path and will explain any question to you
              whenever you’re stuck right there and then in the
              live chat window – sounds too good to be true, right?
            </Description>
          </GroupElements>
        </Section>

        <Section>
          <GroupElements>
            <FootNoteWrapperIn>
              <FootNoote>
                Free for schools
              </FootNoote>
            </FootNoteWrapperIn>
            <Title>Integrates into the classroom</Title>
            <Description>
              We’ve designed Otter with schools and teachers in mind. Not only is Otter for home preparation but it links into schools and classrooms for teachers to use.
            </Description>
            <OtherWrapperList>
              <ListItem>Run the most realistic mock exams available</ListItem>
              <ListItem>Adaptive AI engine adjusts for all abilities</ListItem>
              <ListItem>Set and mark homework</ListItem>
              <ListItem>Monitor class and individual progress</ListItem>
            </OtherWrapperList>
          </GroupElements>
          <GirlImg src={girl} />
        </Section>

        <Section>
          <RobotAndWomanImg src={robotAndWoman} />
          <GroupElements>
            <Title>
              The future of learning is here
            </Title>
            <Description>
              In recent times we have seen online education become part of our lives and it is undeniably a big part of our future. At Otter we are determined to show how effective online learning can be. We are well-positioned for the future of learning and assessment and 11+ is far from the only area where we will be. At Otter, we are actively expanding into the enormous space where our AI engine can transform and revolutionise education.
            </Description>
          </GroupElements>
        </Section>

        <BottomFreeButton to={`${process.env.PUBLIC_URL}/registration/student`}>
          <UseLocalization translateKey="action.trial">
            Start free 7-day trial
          </UseLocalization>
        </BottomFreeButton>

      </Page>
      <Contacts />
      <Footer />
    </Layout>
  );
};

export default MainPage;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 74px 0px 163px;

  @media (max-width: 800px) {
    padding: 32px 0px 120px;
  }
`;

const Section = styled.section`
  display: flex;
  margin-bottom: 148px;
  justify-content: center;
  align-items: center;
  padding: 0 32px;

  :first-of-type {
    margin-bottom: 109px;
  }

  @media (max-width: 1279px) {
    :first-of-type {
      margin-bottom: 60px;
    }

    flex-direction: column;
    margin-bottom: 100px;
  }

  @media (max-width: 400px) {
    margin-bottom: 32px;
  }
`;

const SubjectsBlock = styled.div`
  padding: 19px 24px 30px;
  background-color: #EEEAFF;
  display: flex;
  justify-content: center;
  margin-bottom: 120px;
  width: 100%;
`;

const Subject = styled.div`
  width: min(225px, 20%);
  :not(:last-child) {
    margin-right: min(42px, 10%);
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubjectImg = styled.img`
  max-width: 143px;
  width: 100%;
  height: auto;
`;

const SubjectName = styled.span`
  display: block;
  text-align: center;
`;

const OtterGirlBoyImg = styled.img`
  max-width: 660px;
  height: auto;
  object-fit: contain;
  margin-left: 62px;

  @media (max-width: 1279px) {
    margin: 0;
    margin-bottom: 32px;
    order: 1;
  }

  @media (max-width: 800px) {
    width: 334px;
    height: 216px;
  }
`;

const MainGroupElements = styled.div`
  flex-shrink: 0;
`;

const MainTitle = styled.h1`
  font-weight: bold;
  font-size: 50px;
  line-height: 55px;
  margin-bottom: 32px;
  max-width: 491px;

  @media (max-width: 1279px) {
    width: auto;
    text-align: center;
  }

  @media (max-width: 1023px) {
    font-size: 37px;
    line-height: 37px;
  }
`;

const OrangeSpan = styled.span`
  color: #F87700;
`;

const MainDescription = styled.p`
  font-weight: normal;
  font-size: 20px;
  line-height: 34px;
  width: 388px;
  margin-bottom: 32px;

  @media (max-width: 1279px) {
    width: auto;
    text-align: left;
  }

  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 23px;
    text-align: center;
  }
`;

const StartFreeButton = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 29px;
  width: fit-content;
  width: -moz-fit-content;
  height: 66px;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: #FFF;
  background-color: #F87700;
  border-radius: 16px;
  white-space: nowrap;

  :hover {
    color: #FFF;
    background-color: #FF912C;
  }

  @media (max-width: 1279px) {
    width: 100%;
    justify-content: center;
  }

  @media (max-width: 400px) {
    font-size: 20px;
    line-height: 24px;
    height: 56px;
  }
`;

const BottomFreeButton = styled(StartFreeButton)`
  max-width: 578px;
  width: 100vw;
  justify-content: center;
  margin: 0 32px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 22px;
  color: #F87700;

  @media (max-width: 1279px) {
    text-align: center;
  }

  @media (max-width: 1023px) {
    font-size: 26px;
    line-height: 33px;
  }

  @media (max-width: 375px) {
    text-align: left;
  }
`;

const Description = styled(MainDescription)`
  width: auto;
  font-size: 18px;
  line-height: 30px;
  text-align: left;

  @media (max-width: 800px) {
    text-align: left;
  }
`;

const StrongDescription = styled(Description)`
  font-weight: bold;
  margin-bottom: 68px;
`;

const GroupElements = styled(MainGroupElements)`
  max-width: 516px;

  @media (max-width: 1279px) {
    order: 2;
  }
`;

const OtterAndDocumentImg = styled.img`
  width: 640px;
  height: 536px;
  object-fit: scale-down;
  object-position: top;
  overflow: hidden;
  margin-right: 100px;

  @media (max-width: 1279px) {
    margin-right: 0;
    margin-bottom: 20px;
  }

  @media (max-width: 800px) {
    width: 334px;
    height: 216px;
  }
`;

const TeacherPresentImg = styled(OtterGirlBoyImg)`
  margin-right: 97px;
`;

const RobotAndWomanImg = styled(OtterGirlBoyImg)`
  max-width: 652px;
  width: 100vw;
  margin-right: 100px;
  margin-bottom: 100px;
`;

const OtterProfesor = styled(OtterGirlBoyImg)`
  max-width: 640px;
  width: 100%;
`;

const ScienceImg = styled(OtterGirlBoyImg)`
  margin-right: 93px;
`;

const GirlImg = styled(OtterGirlBoyImg)`
  max-width: 717px;
  width: 100vw;
`;

const WrapperList = styled.ul`
  padding-left: 0px;
  list-style: none;
  max-width: 365px;
  margin-bottom: 50px;
`;

const OtherWrapperList = styled(WrapperList)`
  max-width: 466px;
`;

const ListItem = styled.li`
  padding-left: 56px;
  font-size: 18px;
  line-height: 22px;
  background: url(${accessIcon}) no-repeat left center;
  :not(:last-child) {
    margin-bottom: 28px;
  }
`;

const FootNoteWrapper = styled.div`
  padding: 0 32px;
  margin-bottom: 46px;
`;

const FootNoteWrapperIn = styled(FootNoteWrapper)`
  padding: 0;
`;

const FootNoote = styled.span`
  font-size: 30px;
  line-height: 46px;
  padding: 0 5px;
  background: linear-gradient(to top, rgba(79, 128, 255, 0.2) 40%, transparent 40%, transparent 100%);
`;

const OtherWrapper = styled.div`
  width: auto;
`;
