import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { RouteType } from '../features/topic/types';
import Modal from '../Form/FieldsEntity/Modal';
import Notification from '../Form/FieldsEntity/Notification';

interface ModalQuitExamProps {
  questionsPassed: number
  questionsNumber: number
  challengeId: string
}

const ModalQuitExam = ({
  questionsPassed,
  questionsNumber,
  challengeId,
}: ModalQuitExamProps) => {
  const { challengeType, currentQuestionNum } = useParams<RouteType>();

  return (
    <Modal unclosed onClose={() => {}}>
      <NotificationStyled>
        You haven’t answered questions
        {' '}
        {questionsPassed}
        -
        {questionsNumber}
      </NotificationStyled>
      <Content>
        <Title>Are you sure you want to quit?</Title>
        <Description>Quit the exam or go back to questions</Description>
        <ButtonsWrapper>
          <ButtonQuit to={
            `${process.env.PUBLIC_URL}/challenge/${challengeType}/current/${currentQuestionNum}/quit-exam/${challengeId}`
          }>
            Quit
          </ButtonQuit>
          <ButtonContinue to={`${process.env.PUBLIC_URL}/challenge/${challengeType}/current/${currentQuestionNum}`}>
            Continue exam
          </ButtonContinue>
        </ButtonsWrapper>
      </Content>
    </Modal>
  );
};

export default ModalQuitExam;

const Content = styled.div`
  padding: 80px 65px 65px;
`;

const Title = styled.h3`
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  margin-bottom: 21px;
`;

const Description = styled.p`
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 49px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const ButtonQuit = styled(Link)`
  box-sizing: border-box;
  padding: 20px 52px;
  background-color: #F55F4B;
  border-radius: 16px;
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  color: #FFF;

  :hover {
    color: #FFF;
  }
`;

const ButtonContinue = styled(ButtonQuit)`
  background-color: #FFF;
  border: 3px solid #9077F5;
  color: #9077F5;
  padding: 17px 49px;
  margin-left: 15px;

  :hover {
    color: #9077F5;
  }
`;

const NotificationStyled = styled(Notification)`
  background-color: #F55F4B;
`;
