import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import FormElements from '..';

const {
  Input,
} = FormElements;

interface InputWrapProps {
  className?: string
  children: ReactNode,
}


export const InputWrap = ({
  className,
  children,
}: InputWrapProps) => (
  <Wrapper className={className}>
    {children}
  </Wrapper>
);

interface InputErrorProps {
  // TODO hack type
  errors?: Array<string> | any
  className?: string
}

export const InputError = ({
  errors,
  className,
}: InputErrorProps) => {
  if (errors && errors.length > 0) {
    return (
      <ErrorsList className={className}>
        {Array.isArray(errors) ? (
          <>
            {errors?.map((item: string, n: number) => (
              <Error key={n}>{item}</Error>
            ))}
          </>
        ) : (
          <Error>{errors}</Error>
        )}
      </ErrorsList>
    );
  }
  return <></>;
};

export const InputPasswordErrors = ({
  errors,
  className,
}: InputErrorProps) => {
  if (errors && errors.length > 0) {
    return (
      <PasswordErrorsWrapper className={className}>
        <ErrorsDescription>For a safe password, please include the elements below:</ErrorsDescription>
        <InputError errors={errors} />
      </PasswordErrorsWrapper>
    );
  }

  return null;
};

const ErrorsDescription = styled.span`
  /* color: #F55F4B; */
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  box-sizing: border-box;
  padding: 3px 0;
`;

const PasswordErrorsWrapper = styled.div`
  padding: 0 10px;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const ErrorsList = styled.ul`
  list-style: none;
  padding: 0 10px;
  margin: 3px 0 0;
`;

const Error = styled.li`
  color: #F55F4B;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  box-sizing: border-box;
  padding: 3px 0;
`;
