import { ChartConfigType, AxisSetType, ChartType, StatType } from './types'

export function drawAxis(chartConfig: ChartConfigType, chart: ChartType, axisSet: AxisSetType, focusesPoint?: StatType) {
  const { x: xAxis, y: yAxis } = axisSet;
  if (chart) {
    const yTciks = chart
      .select(".x-axis")
      .attr("transform", `translate(0, ${chartConfig.size.height})`)
      	.transition().duration(2000)
      .call(xAxis)
    
    yTciks.selectAll('text').style('font-weight', (d: any) => {
      const tmpDate = focusesPoint ? new Date(focusesPoint.endTime) : undefined;
      tmpDate?.setSeconds(0);

      console.log('date', d.toUTCString(), tmpDate?.toUTCString());
      if (focusesPoint && d.toUTCString() === tmpDate?.toUTCString()) {
        return 'bold';
      } else {
        return 'normal';
      }
    });

    chart
      .select(".y-axis")
      .attr("transform", "translate(0, 0)")
      	.transition().duration(2000)
        .call(yAxis)
  }
}