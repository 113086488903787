import React, { ReactNode, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { ProgressIcon, SubjectsIcon, SubtopicsIcon } from '../images/iconSvg';
import Sidebar, { SIDEBAR_MENU } from '../Form/FieldsEntity/Sidebar';
import MenuOfSelectSpecialization from '../Form/FieldsEntity/MenuOfSelectSpecialization';
import TeacherCommentOnPremium from './TeacherCommentOnPremium';

import { getAllSubjects, getSubjectsInfo } from '../features/topic/topicState';
import { RootState } from '../app/store';
import { TopicRouteType } from '../features/topic/types';
import Layout from '../Layout';
import { useGetPriceList } from '../Admin/UserEdit/hooks';
import { getUserProfile } from '../features/user/userState';
import { PayPlanType } from '../ProfilePage/Subscription/types';
import { premiumTariff } from '../database/premiumTariff';

interface ProgressPageTypes {
  children?: ReactNode;
  selectedMenuItem: string
}

const sidebarItems = [
  {
    key: SIDEBAR_MENU.OVERALL,
    name: 'Overall',
    icon: <ProgressIcon />,
    linkTo: 'progress/overall',
  },
  {
    key: SIDEBAR_MENU.SUBJECTS,
    name: 'Subjects',
    icon: <SubjectsIcon />,
    linkTo: 'progress/subjects/Maths',
  },
  {
    key: SIDEBAR_MENU.TOPICS,
    name: 'Topics',
    icon: <SubtopicsIcon />,
    linkTo: 'progress/topics/Maths',
  },
];

const ProgressPage = ({ children, selectedMenuItem }: ProgressPageTypes) => {
  const { subject } = useParams<{ subject?: string }>();

  const history = useHistory();
  const { path } = useRouteMatch();

  const setSubjectNav = (name: string) => {
    //TODO: Fix dirty hack
    if (/subjects/.exec(path)?.[0]) {
      history.push(`/progress/subjects/${name}`);
    } else {
      history.push(`/progress/topics/${name}`);
    }
  };

  // const { headerNav, subject, topic, subTopic } = useParams<TopicRouteType>();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSubjects({ subject }));
  }, []);

  const user = useSelector(getUserProfile);

  const defaultExam: undefined | string = user.defaultExam?.[0];

  const { specializationItems, specializationItemDefault } = useSelector((state: RootState) => getSubjectsInfo(state, subject || ''));

  const userPriceId: string = user.priceId || '';

  return (
    <Layout>
      <Page>
        <Header>
          {subject ? (
            <MenuOfSelectSpecializationStyled
              items={specializationItems}
              onSelect={(name: string) => setSubjectNav(name)}
              defaultValue={specializationItemDefault}
            />
          ) : (
            <>
              {premiumTariff[userPriceId] && (
                <TeacherCommentOnPremium comment={user.teacherComment} />
              )}
            </>
          )}
        </Header>
        <ContentSection>
          <SidebarStyled items={sidebarItems} selectedMenuItem={selectedMenuItem} />
          <Content>{children}</Content>
        </ContentSection>
      </Page>
    </Layout>
  );
};

const Content = styled.div`
  max-width: 800px;
  min-width: 800px;
`;

const MenuOfSelectSpecializationStyled = styled(MenuOfSelectSpecialization)`
  width: fit-content;
  min-width: 800px;
  margin-left: auto;
  margin-bottom: 78px;
  margin-top: auto;
`;

const Page = styled.div`
  width: 100%;
  box-sizing: border-box;
  // padding-top: 184px;
  padding-right: 130px;
  padding-bottom: 224px;

  @media (max-width: 1400px) {
    padding-right: 50px;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 318px;
  display: flex;
`;

const ContentSection = styled.div`
  display: flex;
`;

const SidebarStyled = styled(Sidebar)`
  margin-right: 98px;
`;

export default ProgressPage;
