import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import UseLocalization from '../../Localization/UseLocalization';

const DropdownMobileMenu = () => {
  return (
    <MobileMenuDropdownContainer>
      <MobileMenu>
        <MobileMenuItem>
          <MobileMenuLink to={`${process.env.PUBLIC_URL}/login`}>
            <UseLocalization translateKey="action.logIn">
              Log In
            </UseLocalization>
          </MobileMenuLink>
        </MobileMenuItem>
        <MobileMenuItem>
          <MobileMenuLink to={`${process.env.PUBLIC_URL}/registration`}>
            <UseLocalization translateKey="action.signUp">
              Sign Up
            </UseLocalization>
          </MobileMenuLink>
        </MobileMenuItem>
        <MobileMenuItem>
          <MobileMenuLink to={`${process.env.PUBLIC_URL}/pricing`}>
            <UseLocalization translateKey="navigation.pricing">
              Pricing
            </UseLocalization>
          </MobileMenuLink>
        </MobileMenuItem>
        <MobileMenuItem tabIndex={0}>
          <MobileMenuLink to="#">
            <UseLocalization translateKey="navigation.about">
              About
            </UseLocalization>
          </MobileMenuLink>
          <MobileAboutMenu>
            <MobileAboutItem>
              <MobileAboutLink to={`${process.env.PUBLIC_URL}/about-us`}>
                About Us
              </MobileAboutLink>
            </MobileAboutItem>
            <MobileAboutItem>
              <MobileAboutLink to={`${process.env.PUBLIC_URL}/how-to-use`}>
                How to Use Otter
              </MobileAboutLink>
            </MobileAboutItem>
            <MobileAboutItem>
              <MobileAboutLink to={`${process.env.PUBLIC_URL}/faq`}>
                FAQ
              </MobileAboutLink>
            </MobileAboutItem>
          </MobileAboutMenu>
        </MobileMenuItem>
      </MobileMenu>
    </MobileMenuDropdownContainer>
  );
};

export default DropdownMobileMenu;

const MobileMenuDropdownContainer = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  position: relative;
`;

const MobileMenu = styled.ul`
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #FFF;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
`;

const MobileMenuItem = styled.li`
  border-top: 1px solid #F2F2F2;
  :first-of-type,
  &:nth-of-type(2) {
    font-weight: bold;
  }

  :focus {
    outline: none;
  }
`;

const MobileMenuLink = styled(Link)`
  display: block;
  padding-top: 17px;
  padding-bottom: 18px;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 17px;
  color: #1F1F1F;
  text-align: center;

  :hover {
    color: #1F1F1F;
  }
`;

const MobileAboutMenu = styled(MobileMenu)`
  position: static;
  background-color: #EEEAFF;
  display: none;

  ${MobileMenu}:focus-within & {
    display: block;
  }
`;

const MobileAboutItem = styled(MobileMenuItem)`
  :first-of-type,
  &:nth-of-type(2) {
    font-weight: normal;
  }
`;

const MobileAboutLink = styled(MobileMenuLink)``;
