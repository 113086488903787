export const services = {
  oneSubject: 'One subject',
  allSubjects: 'All subjects',
  teacher: 'Any time live teacher support',
};

const TARIFFS_NAME = {
  OTTER_ONE: 'Otter One',
  OTTER_STANDART: 'Otter Standart',
  OTTER_PLUS: 'Otter Plus',
};

// TODO если изменятся айдишники , поменять
export const tariffServices: Record<string, Array<string>> = {
  price_1IMhEeCS0ftqW8lkx8SdDF5B: [services.allSubjects, services.teacher],
  price_1IMhBkCS0ftqW8lkKIPlDUwO: [services.allSubjects],
  price_1IMh9fCS0ftqW8lkzdQ2zg5s: [services.oneSubject],
};

export const tariffNameById: Record<string, string> = {
  'P-66110546F1612783MMA5KN2Y': TARIFFS_NAME.OTTER_ONE,
  'P-7FE75266GG586473CMBCGPEY': TARIFFS_NAME.OTTER_STANDART,
  'P-1T680671GJ272192LMBCGP2Y': TARIFFS_NAME.OTTER_PLUS,
  price_1IMhEeCS0ftqW8lkx8SdDF5B: TARIFFS_NAME.OTTER_PLUS,
  price_1IMhBkCS0ftqW8lkKIPlDUwO: TARIFFS_NAME.OTTER_STANDART,
  price_1IMh9fCS0ftqW8lkzdQ2zg5s: TARIFFS_NAME.OTTER_ONE,
};
