import React from 'react';
import styled from 'styled-components/macro';
import { UserProfileBaseType } from '../../features/user/types';

const paymentsHistory = [
  {
    date: '26.09.2021',
    tariffPlan: 'Otter One Monthly',
    price: '12.99',
  },
  {
    date: '26.08.2021',
    tariffPlan: 'Otter Plus Monthly',
    price: '44.99',
  },
];

interface BillingHistoryProps {
  user: UserProfileBaseType
}

const BillingHistory = ({
  user,
}: BillingHistoryProps) => {

  console.log(user);

  return (
    <Container>
      <Title>Billing History</Title>
      <Header>
        <DateHeader>Date</DateHeader>
        <DescriptionHeader>Description</DescriptionHeader>
        <PriceHeader>Price</PriceHeader>
      </Header>
      {paymentsHistory.map((item: any, n: number) => (
        <Body key={n}>
          <DateBody>{item.date}</DateBody>
          <DescriptionBody>{item.tariffPlan}</DescriptionBody>
          <PriceBody>
            £
            {item.price}
          </PriceBody>
        </Body>
      ))}
    </Container>
  );
};

export default BillingHistory;

const Container = styled.div`
  width: 100%;
  background-color: #FFF;
  box-shadow: 0px 2px 8px #F0F2F3;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 50px 56px;
  margin-bottom: 35px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 40px;
  color: #9C9C9C;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
`;

const DateHeader = styled.span`
  display: block;
  width: 44%;
  box-sizing: border-box;
  padding-right: 20px;
`;
const DescriptionHeader = styled.span`
  display: block;
  width: 44%;
  box-sizing: border-box;
  padding-right: 20px;
`;
const PriceHeader = styled.span`
  display: block;
  width: 14%;
  box-sizing: border-box;
`;

const Body = styled.div`
  display: flex;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 20px;
`;

const DateBody = styled(DateHeader)`
  
`;

const DescriptionBody = styled(DescriptionHeader)`
  
`;

const PriceBody = styled(PriceHeader)`
  
`;
