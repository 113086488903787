import React, { useState } from 'react';
import styled, { CSSObject } from 'styled-components/macro';
import { useHistory, useRouteMatch } from 'react-router-dom';
import FormElements from '..';
import UseLocalization from '../../Localization/UseLocalization';

const {
  SidebarButton,
} = FormElements;

export const SIDEBAR_MENU = {
  NEW_TEST: 'NEW_TEST',
  PAST_TESTS: 'PAST_TESTS',
  EDIT_PROFILE: 'EDIT_PROFILE',
  SUBSCRIPTION: 'SUBSCRIPTION',
  OVERALL: 'OVERALL',
  SUBJECTS: 'SUBJECTS',
  TOPICS: 'TOPICS',
  MY_GROUPS: 'MY_GROUPS',
  MY_STUDENTS: 'MY_STYDENTS',
};

export interface sidebarItemType {
  key: string
  name: string
  icon?: any
  linkTo: string
}
interface SidebarProps {
  items: sidebarItemType[]
  className?: string
  otterBackground?: CSSObject
  selectedMenuItem?: string
}

const Sidebar = ({ items, className, otterBackground, selectedMenuItem }: SidebarProps) => {
  const { push } = useHistory();

  const pushToItemMenuAndSelectItem = (link: string) => {
    push(`${process.env.PUBLIC_URL}/${link}`);
  };

  return (
    <Container className={className} backgroundImages={otterBackground}>
      {items.map((item) => (
        <SidebarButton
          key={item.key}
          onClick={() => pushToItemMenuAndSelectItem(item.linkTo)}
          icon={item.icon}
          selected={item.key === selectedMenuItem}
        >
          <UseLocalization translateKey={item.name}>
            {item.name}
          </UseLocalization>
        </SidebarButton>
      ))}
    </Container>
  );
};

const Container = styled.div<{backgroundImages?: CSSObject}>`
  max-width: 412px;
  width: 100%;
  /* flex-shrink: 0; */
  background: url(${props => props.backgroundImages}) no-repeat;
  background-size: 100% contain;
  background-position: center 300px;
`;

export default Sidebar;
