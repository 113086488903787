import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { StrictButton } from './StrictChallengeLayout';

interface StrictChallengeSwitchsProps {
  onNext: () => void
  onBack: () => void
  onFinish: () => void
  questionAnswered: boolean,
  show: boolean
  isSelectedAnswer: boolean
}

const StrictChallengeSwitchs = ({
  onNext,
  onBack,
  onFinish,
  questionAnswered,
  show,
  isSelectedAnswer,
}: StrictChallengeSwitchsProps) => {
  const { currentQuestionNum } = useParams<{ currentQuestionNum: string }>();

  return (
    <Wrapper>
      {show && isSelectedAnswer && (
      <>
        {/* {Number(currentQuestionNum) > 1 && (
          <ButtonBack onClick={onBack}>&#5130; Back</ButtonBack>
        )} */}
        {questionAnswered ? (
          <StrictButton onClick={onFinish}>Next</StrictButton>
        ) : (
          <StrictButton onClick={onNext}>Next</StrictButton>
        )}
      </>
      )}
    </Wrapper>
  );
};

export default StrictChallengeSwitchs;

const Wrapper = styled.div`
  height:55px;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`;

const ButtonBack = styled.button`
  font-weight: bold;
  padding: 0px 20px;
  border-radius: 10px;
  border-width: 2px;
  cursor: pointer;
  border-color: 50ac50;
  font-weight: bold;
  font-size: 18px;
  :hover {
    background-color: #abcaab;
  }
  :active {
    background-color: 50ac50;
  }
  :focus {
    outline: none;
  }
`;

const ButtonNext = styled(ButtonBack)`
  font-weight: bold;
  margin-left: auto;
`;
