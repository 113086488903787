import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../Form/FieldsEntity/Modal';
import FormElements from '../../Form';
import { avatars } from '../../database/avatars';

const defaultAvatar = require('../../images/defaultAvatar.svg');

const {
  Button, Radio,
} = FormElements;

interface ModalChangeAvatarProps {
  showModal?: boolean
  onCancel: () => void
  onChangeAvatar: (avatarId: string) => void
  value?: string
}

const ModalChangeAvatar = ({
  showModal,
  onCancel,
  onChangeAvatar,
  value = '',
}: ModalChangeAvatarProps) => {
  const [selectedAvatar, setSelectedAvatar] = useState(value);

  React.useEffect(() => {
    setSelectedAvatar(value);
  }, [value]);

  const giveAwaySelectedAvatar = () => {
    onChangeAvatar(selectedAvatar);
    onCancel();
  };

  if (showModal) {
    return (
      <Modal onClose={onCancel}>
        <Container>
          <Title>Change your avatar</Title>
          <AvatarsList>
            {avatars.map((item: any) => (
              <RadioStyled onChange={() => setSelectedAvatar(item.avatarId)} checked={selectedAvatar === item.avatarId} key={item.avatarId} name="avatar">
                <Image src={item.avatarImg} />
              </RadioStyled>
            ))}
          </AvatarsList>
          <ButtonWrapper>
            <CancelButton onClick={onCancel}>Cancel</CancelButton>
            <SaveButton onClick={() => giveAwaySelectedAvatar()}>Save</SaveButton>
          </ButtonWrapper>
        </Container>
      </Modal>
    );
  }

  return null;
};

export default ModalChangeAvatar;

const Container = styled.div`
  max-width: 740px;
  width: 100vh;
  box-sizing: border-box;
  padding: 50px 76px 44px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 25px;
  line-height: 26px;
  margin-bottom: 36px;
`;

const ButtonWrapper = styled.div`
  width: fit-content;
  display: flex;
  margin-left: auto;
`;

export const SaveButton = styled(Button)`
  min-width: 128px;
  width: auto;
  height: auto;
  padding: 13px 25px;
  border: 3px solid transparent;
  color: #FFF;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  background-color: #9077F5;
  margin-left: 20px;
`;

export const CancelButton = styled(SaveButton)`
  margin-left: 0;
  background-color: #FFF;
  border-color: #9C9C9C;
  color: #9C9C9C;
  :hover:not(:disabled) {
    background-color: #FFF;
    border-color: #F87700;
    color: #F87700;
  }
`;

const AvatarsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7px;
`;

const Image = styled.img`
  width: 72px;
  height: 72px;
`;

const RadioStyled = styled(Radio)`
  margin: 0 7px 15px;
  & span {
    flex-direction: column;
    :before {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  & > input:checked + span::before {
    background-color: #9077F5;
  }
`;
