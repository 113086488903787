import React from 'react';
import styled from 'styled-components/macro';
import ScrollingBar from './ScrollingBar';

const accessIconPurpl = require('../../images/icons/access-icon-purple.svg');

const bigImageSubjects = require('../../images/bigSubjectsImg.svg');
const iseb = require('../../images/iseb11+.svg');
const chemodan = require('../../images/otter-chemodan.svg');
const schoolsa = require('../../images/school-specific-assessment.svg');

const sasScore = [
  {
    score: '<70',
    description: 'bottom 2%',
  },
  {
    score: '70-80',
    description: 'very low',
  },
  {
    score: '80-90',
    description: 'low',
  },
  {
    score: '90-100',
    description: 'below average',
  },
  {
    score: '100-110',
    description: 'above average',
  },
  {
    score: '110-120',
    description: 'well above average',
  },
  {
    score: '120-130',
    description: 'outstanding',
  },
  {
    score: '130+',
    description: 'exceptional - top 2%',
  },
];

const schoolsSasScore = [
  { name: 'Westminster School ', score: '125-132' },
  { name: 'Wycombe Abbey', score: '123-127' },
  { name: 'St Paul’s School', score: '124-130' },
  { name: 'King’s College School ', score: '123-127' },
  { name: 'City of London School', score: '119-123' },
  { name: 'Latymer Upper School ', score: '118-124' },
  { name: 'Eton College', score: '119-127' },
  { name: 'Winchester College', score: '120-125' },
  { name: 'Harrow School ', score: '118-122' },
  { name: 'Marlborough School ', score: '117-122' },
  { name: 'Dulwich College', score: '119-123' },
  { name: 'Charterhouse', score: '115-120' },
  { name: 'Radley', score: '115-117' },
  { name: 'St Edward’s Oxford ', score: '110-115' },
  { name: 'Cranleigh', score: '109-115' },
  { name: 'Bradfield College ', score: '105-114' },
];

const londonConsortium = [
  'Channing School',
  'Francis Holland School, Regent\'s Park',
  'Francis Holland School, Sloane Square',
  'Godolphin and Latymer',
  'More House School',
  'Northwood College for Girls',
  'Notting Hill and Ealing High School',
  'Queen’s Gate',
  'Queens College',
  'South Hampstead High School',
  'St Helen\'s School London',
  'St James Senior Girls\' School',
];

const ElevenPlusContent = () => {
  return (
    <Contetn>
      <Section id="Background">
        <TitleWrapper>
          <TitleInSection>
            Background
          </TitleInSection>
        </TitleWrapper>
        <Description>
          The very first 11 Plus exam was in 1944 and was used as a means to establish whether a child would proceed to a grammar school, a secondary modern school or a technical school. The 11+ quickly spiraled into a fierce competition for grammar schools and arguably this level of competition has only grown since then. Private schools subsequently also began using an 11+ exam either for entry into their school at that level (if they have 11+ admissions) or as a pretest to be taken before the common entrance (13+) so schools could filter their applicants. Historically 11+ has been a paper-based test either taken in the child’s existing school or at the prospective school. It is only recently that we have seen the introduction of the online 11+ exam and, since Covid-19, more and more schools are choosing this option.
        </Description>
        <Description>
          ISEB developed their online 11+ pre-test in 2011, released it in 2012 and within a couple of years major schools had started signing up. This has meant that many children could have three stages of exams for entry into private school; the 11+ pretest, the school specific 11+ (a further exam set by the school) and the 13+ common entrance. The ISEB 11+ Pre-test was presented as tutor-proof and the company stated, “No special preparation is required for the tests, which are designed to identify potential.” – given the size of the tuition market for this exam, the number of published resources available, and the improvements that we see in students using Otter’s unique replica practice platform, this is clearly not true. Each candidate is given a Standard Age Score (SAS) which is based on the number of questions answered correctly. The score is adjusted for age and placed on a scale which makes a comparison with a sample taken from the independent schools sector where the average is 100. Since 2014, ISEB has supplied additional reports forecasting each child’s future GCSE and A-Level results based on their performance in this test.
        </Description>
        <Description>
          While the Common Entrance exams (mostly sat at age 13) are presented as entrance exams, they are effectively little more than a ground for common curriculum coverage and failure to pass 13+ is almost unheard of. The major senior school decision-making process for entry into year 9 takes place from the start of Year 6 so, for some pupils, this means soon after their tenth birthday. ISEB 11+ Pre-tests are taken in year 6, year 7 or both.
        </Description>
      </Section>

      <SectionBackgrounded id="An overview of the ISEB 11+">
        <TitleWrapper>
          <TitleInSectionPurpl>An overview of the ISEB 11+</TitleInSectionPurpl>
        </TitleWrapper>
        <SectionList>
          <SectionListItem>
            <SectionListItemText>
              It is a suite of four tests consisting of: English, Maths, Verbal reasoning and Non-verbal reasoning and created for ISEB by GL Assessment. The suite of tests takes approximately 2.5 hours in total to complete.
            </SectionListItemText>
          </SectionListItem>
          <SectionListItem>
            <SectionListItemImg src={iseb} />
          </SectionListItem>
          <SectionListItem>
            <SectionListItemText>
              They are online and adaptive (meaning they get harder or easier depending on how well you are doing) and the questions are in multiple-choice or simple text entry format.
            </SectionListItemText>
          </SectionListItem>
          <SectionListItem>
            <SectionListItemText>
              The tests are usually taken in Year 6 or 7 when pupils are aged between 10 and 12 years old. The exam takes place at the child’s prep school but if a child is abroad then tests can be taken near their location.
            </SectionListItemText>
          </SectionListItem>
          <SectionListItem>
            <SectionListItemText>
              Some schools choose to make their children sit the tests all in one day while others split them over a week.
            </SectionListItemText>
          </SectionListItem>
          <SectionListItem>
            <SectionListItemText>
              The scores are shared with all senior schools where the child has applied and so only one test sitting is required.
            </SectionListItemText>
          </SectionListItem>
        </SectionList>
      </SectionBackgrounded>

      <Section id="What subjects are covered?">
        <TitleWrapper>
          <TitleInSection>What subjects are covered?</TitleInSection>
        </TitleWrapper>
        <DefaultList>
          <DefaultListItem>
            <Description>
              The four subjects that are usually examined at 11+ are:
            </Description>
            <BigSubjectsImage src={bigImageSubjects} />
          </DefaultListItem>
          <DefaultListItem>
            <Description>
              The ISEB 11+ examines all of these subjects but some school specific tests (tests set by the schools) may not.
            </Description>
            <Description>
              In theory, the tests have been designed to cover only material that has been taught at school by the time the child sits the test. In practice, every school is at a slightly different stage with the material they have covered and so working with the Otter platform ensures there is no gap.
            </Description>
            <Description>
              The English exam tests spelling, punctuation and grammar as well as the ability to to comprehend a piece of written text. The Maths exams tests a very broad range of topics that should have been covered at school by the time of the test. Verbal reasoning tests vocabulary and word-based problem-solving. The Non-verbal reasoning tests are intended to score the child’s capability regardless of what they have been taught.
            </Description>
          </DefaultListItem>
        </DefaultList>
      </Section>

      <Section id="How is the ISEB 11+ scored and what is SAS and CAT?">
        <TitleWrapper>
          <TitleInSectionPurpl>How is the ISEB 11+ scored and what is SAS and CAT?</TitleInSectionPurpl>
        </TitleWrapper>
        <DescriptionIn>
          The ISEB 11+ is not scored like a conventional test. Every child is given a score that lands somewhere around 100. Using data
          from a standardized set of private school pupils, 100 is determined to be the average score and the individual child is placed
          either side of that depending if they are above or below average. In other words, a standardised score is produced using
          a normal distribution with a mean of 100 (and standard deviation of 15) meaning that the average score of the
          whole year is 100 and the lowest 2% score less than 70 and the top 2% score more than 130. The Otter mock
          exam platform is the closest replica system available for children to practise on and every time a mock exam is
          completed, we provide you with the SAS score so you can know exactly where you stand.
        </DescriptionIn>
        <SubTitle>A very rough guide to interpreting an SAS score:</SubTitle>
        <InSectionWrapper>
          <ColoredList>
            {sasScore.map((item) => (
              <ItemInColoredList key={item.score}>
                <LeftSpan>{item.score}</LeftSpan>
                <RightSpan>{item.description}</RightSpan>
              </ItemInColoredList>
            ))}
          </ColoredList>
          <DescriptionSmall>
            CATs (Cognitive Abilities Tests) are examinations used by the school your child is currently at to ascertain your
            child’s ability and potential along with other details such as the type of learner they are. The exams are very
            similar to the ISEB 11+ and they are scored in the same way with each child given a score that lands somewhere
            around 100. Not all schools use CAT assessments and senior schools do not usually require them although the
            grades will be shared to the senior school if they have been taken.
          </DescriptionSmall>
        </InSectionWrapper>
      </Section>

      <Section id="How to prepare?">
        <TitleWrapper>
          <TitleInSection>How to prepare?</TitleInSection>
        </TitleWrapper>
        <Description>
          It is quite possible that the 11+ is the first formal exam that a child has taken and it can be quite a daunting experience. That is why taking mock exams with Otter is invaluable practice in any of the ISEB pre-test, GL, CEM London Consortium and UKISET exams.
        </Description>
        <Description>
          Building a broad vocabulary is critical for doing well in English or Verbal Reasoning. This is something to start early. Otter builds in vocabulary expansion as part of the course and parents can also support the process by exploring complex words in reading and general conversation, as well as encouraging reading books that stretch and challenge while still being enjoyable reads.
        </Description>
        <Description>
          Exam technique is a crucial part of the whole process and is a unique skill particularly with an online exam. Time management skills are a big part and working under pressure to finish a test in a limited amount of time can take a while to get used to. Otter provides the perfect replicated exam environment in which to develop these skills.
        </Description>
      </Section>

      <Section id="Understanding the different boards (CEM, GL, ISEB etc.)">
        <TitleWrapper>
          <TitleInSectionPurpl>
            Understanding the different boards
            <br />
            (CEM, GL, ISEB etc.)
          </TitleInSectionPurpl>
        </TitleWrapper>
        <Description>
          It is quite possible that the 11+ is the first formal exam that a child has taken and it can be quite a daunting experience. That is why taking mock exams with Otter is invaluable practice in any of the ISEB pre-test, GL, CEM London Consortium and UKISET exams.
        </Description>
        <Description>
          Building a broad vocabulary is critical for doing well in English or Verbal Reasoning. This is something to start early. Otter builds in vocabulary expansion as part of the course and parents can also support the process by exploring complex words in reading and general conversation, as well as encouraging reading books that stretch and challenge while still being enjoyable reads.
        </Description>
        <Description>
          Exam technique is a crucial part of the whole process and is a unique skill particularly with an online exam. Time management skills are a big part and working under pressure to finish a test in a limited amount of time can take a while to get used to. Otter provides the perfect replicated exam environment in which to develop these skills.
        </Description>
      </Section>

      <Section id="Which schools use the ISEB 11+?">
        <TitleWrapper>
          <TitleInSection>Which schools use the ISEB 11+?</TitleInSection>
        </TitleWrapper>
        <DescriptionInSmallMargin>
          The following is the latest
          {' '}
          <SpanBoldInDescription>
            lists of schools subscribing to the online ISEB 11+ Pre-test
          </SpanBoldInDescription>
          {' '}
          which has grown every year since it was
          introduced.
        </DescriptionInSmallMargin>
        <ScrollingBar />
      </Section>

      <Section id="What is the procedure after 11+ has been taken?">
        <TitleWrapper>
          <TitleInSectionPurpl>What is the procedure after 11+ has been taken?</TitleInSectionPurpl>
          <Description>
            After your child has completed the ISEB Common Pre-Tests, if they have performed well enough, they may be invited to sit a school-specific assessment and / or interview which will most likely be at the senior school. Each senior school will have a different cut-off point based on the Pre-Test results for inviting children to their school-specific assessment and / or interview and this will vary from year to year.
          </Description>
          <Description>
            Parents will not receive their child’s ISEB Common Pre-Tests results; they will simply be notified if their child has made it to the next stage of the selection process.
          </Description>
        </TitleWrapper>
      </Section>

      <Section id="How to prepare?">
        <TitleWrapper>
          <TitleInSection>How to prepare?</TitleInSection>
        </TitleWrapper>
        <DefaultList>
          <DefaultListItem>
            <DescriptionInSmallMargin>
              There are 4 sections to the test: English, Mathematics, Non-Verbal Reasoning, and Verbal Reasoning. The timings for
              each section are as follows:
            </DescriptionInSmallMargin>
            <ColoredList>
              <ListItemTitle>
                <LeftSpanTitle>Test Section</LeftSpanTitle>
                <RightSpanTitle>Timing</RightSpanTitle>
              </ListItemTitle>
              <ItemInColoredOrange>
                <LeftSpanStyled>English</LeftSpanStyled>
                <RightSpanStyled>25 minutes</RightSpanStyled>
              </ItemInColoredOrange>
              <ItemInColoredOrange>
                <LeftSpanStyled>Maths</LeftSpanStyled>
                <RightSpanStyled>50 minutes</RightSpanStyled>
              </ItemInColoredOrange>
              <ItemInColoredOrange>
                <LeftSpanStyled>Verbal Reasoning</LeftSpanStyled>
                <RightSpanStyled>36 minutes</RightSpanStyled>
              </ItemInColoredOrange>
              <ItemInColoredOrange>
                <LeftSpanStyled>Non-Verbal Reasoning</LeftSpanStyled>
                <RightSpanStyled>32 minutes</RightSpanStyled>
              </ItemInColoredOrange>
            </ColoredList>
          </DefaultListItem>
          <DefaultListItem>
            <DescriptionInSmallMargin>
              Depending on the school, your child will either take all four tests in one day or spread over the course of a week.
            </DescriptionInSmallMargin>
            <DescriptionInSmallMargin>
              At the start of the test are a set of written instructions about the exam procedure and there is no sound component of the test. There is no back button so you cannot return to a previous question and every question requires a response.
            </DescriptionInSmallMargin>
            <DescriptionInSmallMargin>
              In the maths and verbal reasoning tests the child is allowed to use pencil and paper to make as many notes as they need.
            </DescriptionInSmallMargin>
          </DefaultListItem>
        </DefaultList>
      </Section>

      <Section id="Is exam technique important?">
        <TitleWrapper>
          <TitleInSectionPurpl>Is exam technique important?</TitleInSectionPurpl>
        </TitleWrapper>
        <Description>
          It is undeniable that children who have practised using the testing platform and are full comfortable and accustomed to the online test will be better positioned – that is why Otter has created the most realistic exam platform available. Otter’s mock exams are fully adaptive, just like the real thing, meaning that the test gets easier if questions are answered incorrectly and harder if your child is getting the answers right. It is quite unlikely in such an adaptive test that all questions will be answered correctly and an exceptional score, sufficient for entry into even the most academically selective schools, can be achieved even with a surprising number of incorrect answers – this is because if the child has answered enough questions correctly to unlock the most difficult level on the adaptive system then each question is suddenly worth far more marks. In an adaptive test, it is highly unlikely that your child will be able to answer all the questions correctly; if they’re seeing really challenging questions, that is because they are performing well in the test!
        </Description>
        <Description>
          A very common error that children sitting the online exam make is rushing through questions early on and making avoidable mistakes. This causes two problems – the child cannot go back to correct the questions and, more importantly, incorrectly answered questions do not unlock the harder questions on the adaptive system which are worth more marks.
        </Description>
        <Description>
          There is an important balance to be struck with timing and pacing yourself correctly which can only come through experience working on Otter’s replica exam platform. It is important not to rush as children who take time to read the question carefully spot subtle points in the question that if missed would lead to the wrong answer. However, the child must also work quickly through the easier questions in order to unlock the hard questions and allow time to solve them. Otter provides a full breakdown of the timing and pace that your child worked at once a mock exam has been completed and this enables the technique to be refined.
        </Description>
      </Section>

      <Section id="How does Otter help?">
        <TitleWrapper>
          <TitleInSection>
            How does Otter help?
          </TitleInSection>
        </TitleWrapper>
        <Description>
          Otter’s mock exams are taken in the closest replica exam platform available and it mimics what your child will face
          on the day. Our test looks almost exactly like the real thing, is powered by an adaptive engine just like the real thing
          and our questions are written by experts familiar with the real test. In order to support your child’s preparation
          journey, Otter is split into two areas: the practice area and the mock exam area. Your child should begin in the
          practice area and build familiarity with all the topics and question types across all four subject areas – the practice
          area, like the mock exam, is also fully adaptive so building comfort with the online system begins early at Otter! After
          some initial confidence has been built and your child is familiar with the practice area, we recommend letting them
          have a go at a set of mock exams – this will set and record a base score in the Otter system, providing you with an
          initial SAS from which you will be able to track progress. After that keep working through the practice area and sit
          regular Otter mock exams, keeping close track of progress – candidates begin seeing improvements remarkably
          quickly.
        </Description>
        <TextAndImageWrapper>
          <DescriptionWrapper>
            <Description>
              Once a few Otter mock tests have been completed you will have a good idea of your child’s SAS. This will be really helpful to guide you in selecting a school. The team at Otter are more than happy to help advise you at this stage and our in-house team includes education consultants who are familiar with the entry procedure at the major independent schools.
            </Description>
            <Description>
              In the two-month run up to the test we highly recommend increasing the frequency of the mock exams and most of our users complete two sets of mock exams per week at this stage (i.e. two of each of English, maths, VR and NVR).
            </Description>
          </DescriptionWrapper>
          <DefaultImageInOtterHelp src={chemodan} />
        </TextAndImageWrapper>
      </Section>

      <Section id="Is exam technique important?">
        <TitleWrapper>
          <TitleInSectionPurpl>
            Is exam technique important?
          </TitleInSectionPurpl>
        </TitleWrapper>
        <DescriptionIn>
          At Otter we see parents who start the 11+ process firmly set on a specific school all the way through to parents who
          are completely undecided. There are many factors that determine school choice including academic ability, the
          child’s character, preference for boarding/day school or co-ed/single-sex, family connections, location, and many
          more. At Otter, we always recommend that you choose the school that is the best match to your child both
          pastorally and academically – children thrive in the right environment and what suits one child is not necessarily ideal
          for another. However, as well as being crucial to passing the exam, a child’s academic ability is a good indicator that
          you can use to find an appropriate school. The table below is a very rough guide to the SAS scores of typical
          candidates taken by each school – we must emphasise that this is only a guide and every school has their own
          admissions policy – some schools are known to select children outside of this indicated range if they feel they have
          other strengths or there are other factors they want to consider. These scores are not fixed and change each year.
        </DescriptionIn>
        <DescriptionInSmallMargin>
          Shown below is a small selection of top schools using the ISEB 11+:
        </DescriptionInSmallMargin>
        <BoardListInIsExam>
          <HeaderWrapperInIsExam>
            <LeftSpanTitle>School</LeftSpanTitle>
            <RightSpanTitle>SAS score</RightSpanTitle>
          </HeaderWrapperInIsExam>
          {schoolsSasScore.map((item) => (
            <ItemInColoredList key={item.name}>
              <LeftSpanTitle>{item.name}</LeftSpanTitle>
              <RightSpanStyled>{item.score}</RightSpanStyled>
            </ItemInColoredList>
          ))}
        </BoardListInIsExam>
      </Section>

      <Section id="School-Specific Assessments">
        <TitleWrapper>
          <TitleInSection>School-Specific Assessments</TitleInSection>
        </TitleWrapper>
        <TextAndImageWrapper>
          <DescriptionWrapper>
            Once your child has sat the ISEB exam they will be invited to take a test run by the school that they have got into.
            Many school run such tests, particularly the top ranked schools which are always over subscribed. Maths and
            English are commonly examined in these tests alongside non-verbal reasoning and verbal reasoning which is why
            Otter provides good practice for these exams as well. The school-specific exams are sat either on paper or on
            computer and the child is usually required to visit the school where they may also have an interview.
          </DescriptionWrapper>
          <DefaultImageInOtterHelp src={schoolsa} />
        </TextAndImageWrapper>
      </Section>

      <Section id="What is the London Consortium?">
        <TitleWrapper>
          <TitleInSectionPurpl>
            What is the London Consortium?
          </TitleInSectionPurpl>
        </TitleWrapper>
        <DescriptionInSmallMargin>
          A group of twelve independent all-girls schools in London run their own 11+ entry examination procedure for admission into year 7.
        </DescriptionInSmallMargin>
        <DefaultList>
          <DefaultListItem>
            <ColoredList>
              {londonConsortium.map((cons) => (
                <ItemInColoredList key={cons}>
                  <SpanInCons>
                    {cons}
                  </SpanInCons>
                </ItemInColoredList>
              ))}
            </ColoredList>
          </DefaultListItem>
          <DefaultListItemStyled>
            <DescriptionWidth>
              The test set by the London Consortium is a 70-minute paper based test written by the CEM board covering maths, verbal, and non-verbal reasoning, as well as short passages of comprehension. The test is on paper only and answers are written by the students on a separate sheet to the test paper. It is mainly multiple-choice questions with some written questions mixed in. The test is taken in January by all candidates on the same day at one of the schools to which the child has applied and it can only be taken once.
            </DescriptionWidth>
            <DescriptionWidth>
              Using Otter is great preparation for the CEM test as we have both multiple choice and text input answers. Otter helps to build confidence and familiarity with all the question types your child will face in the London Consortium test.
            </DescriptionWidth>
          </DefaultListItemStyled>
        </DefaultList>
      </Section>

      <Section id="Does Otter prepare for Grammar schools?">
        <TitleWrapper>
          <TitleInSection>
            Does Otter prepare for Grammar schools?
          </TitleInSection>
        </TitleWrapper>
        <DescriptionInSmallMargin>
          Otter is the ideal platform to prepare for Grammar school tests. Grammar schools are selective schools funded by the state. In the UK there are 164 Grammar schools across the country and test formats and application procedures vary by region – you must check the application procedure in your area.
        </DescriptionInSmallMargin>
        <DescriptionInSmallMargin>
          Grammar school entrance tests at 11+ test will usually be taken in Year 6 in the Christmas term and results issued in October with places being given out on 1st March. The tests usually cover all of English, Maths, Verbal, and Non-verbal reasoning.
        </DescriptionInSmallMargin>
      </Section>

      <Section id="Understanding the GL and CEM Grammar School 11+">
        <TitleWrapper>
          <TitleInSectionPurpl>
            Understanding the GL and CEM Grammar School 11+
          </TitleInSectionPurpl>
        </TitleWrapper>
        <DescriptionInSmallMargin>
          GL Assessment is one of the two boards that write exam papers for Grammar schools and their papers cover Maths, English, Verbal reasoning and Non-verbal reasoning although it is up to schools to choose which subjects they want to include.
        </DescriptionInSmallMargin>
        <DescriptionInSmallMargin>
          CEM 11+ tests were introduced due to some Grammar schools doubting the integrity of the GL assessment. The CEM test covers an extended version of verbal reasoning which includes vocabulary, comprehension and word logic, as well as numerical reasoning (maths) and non-verbal reasoning.
        </DescriptionInSmallMargin>
        <DescriptionInSmallMargin>
          Using Otter helps to prepare children for both CEM and GL Assessment. Our questions cover the complete range of question types in both examination boards and teach your child all the topics that are not covered in their school curriculum. There are some grammar schools that take into account other factors besides the test score including siblings policies and catchment areas.
        </DescriptionInSmallMargin>
      </Section>

      <Section id="What is the UKISET?">
        <TitleWrapper>
          <TitleInSection>
            What is the UKISET?
          </TitleInSection>
        </TitleWrapper>
        <DescriptionInSmallMargin>
          International students sit the UKiset when seeking a place at a British school. The test is taken online and examines maths, English, verbal reasoning and non-verbal reasoning and it lasts for about 2 hours. A candidate’s UKiset profile is produced from the results of the exam which outlines strengths, weaknesses and academic potential. The profile can be shared with up to five British schools without additional charge. The test can be taken at any age between 9 and 18 provided the candidate has a basic proficiency in English. UKISET results are valid for one year and the test can be re-taken after 6 months have passed.
        </DescriptionInSmallMargin>
        <DescriptionInSmallMargin>
          The UKiset test covers English, maths, verbal and non-verbal reasoning. Students are given standardised scores for their age and their results compared to equivalent British students. Naturally, for students with English as a second language, the verbal reasoning section will be more challenging than for a British student. Therefore, UKiset results will include a separate score which excludes verbal reasoning.
        </DescriptionInSmallMargin>
        <DescriptionInSmallMargin>
          It is essential to practice if taking the UKISET exam and familiarity with the styles of questions will make a dramatic difference to performance. Otter is the ideal platform to practise for UKISET and provides timed tests in the same style, giving you a score that you can work on.
        </DescriptionInSmallMargin>
      </Section>
    </Contetn>
  );
};

export default ElevenPlusContent;

const Contetn = styled.div`
  padding: 128px 0px 126px 31px;
  background-color: #F6F8F9;
  width: 100%;

  [id] {
    scroll-margin: 100px;
  }
`;

const Section = styled.section`
  margin-bottom: 97px;
  padding-right: 24px;
`;

const SectionBackgrounded = styled(Section)`
  margin-bottom: 120px;
  background-color: #EEEAFF;
  padding: 74px 0px 74px 81px;
`;

const TitleWrapper = styled.div`
  margin-bottom: 60px;
`;

const TitleInSection = styled.span`
  width: fit-content;
  font-size: 32px;
  line-height: 44px;
  font-weight: bold;
  padding: 0 5px;
  background: linear-gradient(
  to top, rgba(248, 119, 0, 0.3) 40%,
            transparent 40%,
            transparent 100%
  );
`;

const TitleInSectionPurpl = styled(TitleInSection)`
  background: linear-gradient(
  to top, rgba(144, 119, 245, 0.2) 40%,
            transparent 40%,
            transparent 100%
  );
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 28px;
  max-width: 848px;
  :not(:last-child) {
    margin-bottom: 24px;
  }
`;

const DescriptionIn = styled.p`
  font-size: 16px;
  line-height: 28px;
  max-width: 848px;
  margin-bottom: 78px;
`;

const DescriptionInSmallMargin = styled(DescriptionIn)`
  margin-bottom: 35px;
`;

const DescriptionSmall = styled(Description)`
  max-width: 440px;
`;

const SpanBoldInDescription = styled.span`
  font-weight: bold;
`;

const SectionList = styled.ul`
  padding-left: 0;
  list-style: none;
  /* margin: 0 -24px; */
  display: flex;
  flex-wrap: wrap;
`;

const SectionListItem = styled.li`
  max-width: 370px;
  width: 100%;
  margin: 0 24px 50px;
`;

const SectionListItemText = styled.p`
  font-size: 16px;
  line-height: 28px;
  padding-left: 70px;
  background: url(${accessIconPurpl}) no-repeat left top;
  box-sizing: border-box;
`;

const SectionListItemImg = styled.img`
  display: block;
  width: 326px;
  height: 271px;
  margin: 0 auto;
`;

const DefaultList = styled.ul`
  padding-left: 0;
  list-style: none;
  display: flex;

  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;

const DefaultListItem = styled.li`
  max-width: 427px;

  :first-child {
    margin-right: 65px;
  }

  @media (max-width: 1280px) {
    margin-bottom: 32px;
  }
`;

const BigSubjectsImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

const SubTitle = styled.h3`
  font-size: 16px;
  line-height: 32px;
  font-weight: bold;
  margin-bottom: 30px;
`;

const ColoredList = styled.ul`
  min-width: 400px;
  padding-left: 0;
  list-style: none;
  margin-right: 69px;
  margin-bottom: 32px;
`;

const ItemInColoredList = styled.li`
  display: flex;
  background-color: #FFF;
  :nth-child(even) {
    background-color: #EEEAFF;
  }
`;

const ItemInColoredOrange = styled.li`
  display: flex;
  padding: 0 10px;
  background-color: rgba(246, 209, 174, 0.3);
  :nth-child(even) {
    background-color: #FFF;
  }
`;

const ListItemTitle = styled.li`
  display: flex;
`;

const LeftSpan = styled.span`
  display: block;
  width: 50%;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  padding: 0 10px;
`;

const RightSpan = styled(LeftSpan)`
  font-weight: normal;
  text-align: left;
`;

const InSectionWrapper = styled.div`
  display: flex;

  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;

const LeftSpanStyled = styled(LeftSpan)`
  width: 65%;
  font-weight: normal;
  text-align: left;
`;

const RightSpanStyled = styled(RightSpan)`
  width: 35%;
`;

const RightSpanTitle = styled(RightSpanStyled)`
  font-weight: bold;
`;

const LeftSpanTitle = styled(LeftSpanStyled)`
  font-weight: bold;
  padding: 0 20px;
`;

const TextAndImageWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
  };
`;

const DescriptionWrapper = styled.div`
  max-width: 496px;
`;

const DefaultImageInOtterHelp = styled.img`
  width: 378px;
  height: 297px;
  margin-left: 24px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const BoardListInIsExam = styled.div`
  max-width: 600px;
  width: 100%;
  background-color: #FFF;
`;

const HeaderWrapperInIsExam = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-bottom: 17px;
  background-color: #FFF;
  border-bottom: 4px solid rgba(144, 119, 245, 0.2);
`;

const SpanInCons = styled.span`
  padding: 0 34px;
  font-size: 16px;
  line-height: 32px;
`;

const DescriptionWidth = styled(DescriptionInSmallMargin)`
  max-width: 486px;
`;

const DefaultListItemStyled = styled(DefaultListItem)`
  max-width: 486px;
`;
