import React, { useState } from "react";
import styled from "styled-components/macro";
import { TitleH2, TitleH5 } from "../../styledElements/styledTextElements";
import Elements from "../../Form";
import SelectDropdownInput from "../../Form/SelectDropdownInput";
import moment from "moment";
import { DatePicker, TimePicker } from "antd";
import { ApiChangeAssignment } from "../../project-types/assignments/api-types";
import { UserPublicType } from "../../project-types/users/types";

const { Button, Input } = Elements;

type SelectDropdownListItem = { name: string; _id: string };

interface ModalContentForEditAssignmentProps {
  onCancelClickHandler: () => void;
  onSaveNameClickHandler: (newName: string, assignmentId: string) => void;
  onSaveChangesClickHandler: (
    formData: ApiChangeAssignment['data'],
    assignmentId: string
  ) => void;
  assignmentName: string;
  dueDate: string;
  assignmentId: string;
  groupStudents: Array<UserPublicType>;
  selectedStudents: Array<UserPublicType>;
}

export default function ModalContentForEditAssignment({
  onCancelClickHandler,
  onSaveNameClickHandler,
  onSaveChangesClickHandler,
  dueDate,
  assignmentName,
  assignmentId,
  groupStudents,
  selectedStudents,
}: ModalContentForEditAssignmentProps) {
  const [name, setName] = useState(assignmentName);
  const [dropdownSelectedStudents, setDropdownSelectedStudents] = useState(selectedStudents);
  const [newDueDate, setNewDueDate] = useState(new Date(dueDate));
  const [isDropdownShow, setIsDropdownShow] = useState(false);

  const onListItemClickHandler = (student: UserPublicType) => {
    const selectedIds = dropdownSelectedStudents.map(student => student._id);

    if (selectedIds.includes(student._id)) {
      setDropdownSelectedStudents(selectedStudents => selectedStudents.filter(selectedStudent => selectedStudent._id !== student._id));
    } else {
      setDropdownSelectedStudents(selectedStudents => [...selectedStudents, student])
    }
  };
  const onSelectAllClickHandler = () => {
    if (dropdownSelectedStudents.length === groupStudents.length) {
      setDropdownSelectedStudents([]);
    } else {
      setDropdownSelectedStudents(groupStudents);
    }
  };

  const onSaveChanges = () => {
    const formData = { name, dueDate: newDueDate.toString(), studentsSelected: dropdownSelectedStudents };
    onSaveChangesClickHandler(formData, assignmentId);
  };

  const onChangeDate = (date: Date) => {
    const changedDate = new Date(newDueDate).setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    setNewDueDate(new Date(changedDate));
  };

  const onChangeTime = (date: Date) => {
    const changedTime = new Date(newDueDate).setHours(date.getHours(), date.getMinutes(), date.getSeconds());
    setNewDueDate(new Date(changedTime));
  };

  return (
    <Wrapper>
      <StyledTitleH2>Edit assignment</StyledTitleH2>
      <AssignmentNameContainer>
        <StyledTitleH5>Assignment name</StyledTitleH5>
        <InputNameBlock>
          <InputWithCounter>
            <Counter>{name.length} / 40</Counter>
            <NameInput
              value={name}
              onChange={(e) => setName(e.target.value)}
              maxLength={40}
            />
          </InputWithCounter>
          <SaveButton
            onClick={() => onSaveNameClickHandler(name, assignmentId)}
          >
            Save
          </SaveButton>
        </InputNameBlock>
      </AssignmentNameContainer>
      <SelectStudentsContainer>
        <StyledTitleH5>Select students</StyledTitleH5>
        <SelectDropdownInput
          isOpen={isDropdownShow}
          onBlockClickHandler={() => setIsDropdownShow(!isDropdownShow)}
          listItems={groupStudents}
          selectedListItems={dropdownSelectedStudents}
          onListItemClickHandler={onListItemClickHandler}
          onSelectAllClickHandler={onSelectAllClickHandler}
        />
      </SelectStudentsContainer>
      <EditDueTimeContainer>
        <EditDueTimeBlock>
          <StyledTitleH5>Due date</StyledTitleH5>
          <StyledDatePicker value={moment(newDueDate, "MMMM DD")} format="MMMM DD" onChange={(date) => date && onChangeDate(date.toDate())} allowClear={false} />
        </EditDueTimeBlock>
        <EditDueTimeBlock>
          <StyledTitleH5>Due time</StyledTitleH5>
          <StyledTimePicker value={moment(newDueDate, "h:mm A")} format="h:mm A" onChange={(date) => date && onChangeTime(date.toDate())} allowClear={false} />
        </EditDueTimeBlock>
      </EditDueTimeContainer>
      <ButtonBlock>
        <CancelButton onClick={onCancelClickHandler}>Cancel</CancelButton>
        <SaveChangesButton onClick={onSaveChanges}>
          Save changes
        </SaveChangesButton>
      </ButtonBlock>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 42px;
  max-width: 612px;
  width: 100vw;
`;

const StyledTitleH2 = styled(TitleH2)`
  padding-bottom: 14px;
  border-bottom: 1px solid #e0e0e0;
`;

const StyledInput = styled(Input)`
  &:focus {
    border: 1px solid #9077f5;
  }
`;

const NameInput = styled(StyledInput)`
  width: 370px;
`;

const StyledDatePicker = styled(DatePicker)`
  border-radius: 10px;
  padding: 17px 23px;
`;

const StyledTimePicker = styled(TimePicker)`
  border-radius: 10px;
  padding: 17px 23px;
`;

const AssignmentNameContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
`;

const InputNameBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  padding-right: 60px;
`;

const InputWithCounter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 370px;
`;

const Counter = styled.span`
  align-self: flex-end;
`;

const SaveButton = styled.span`
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  color: #c4c4c4;
  cursor: pointer;
  padding-top: 25px;
  &:hover {
    color: #9077f5;
  }
`;

const StyledTitleH5 = styled(TitleH5)`
  white-space: nowrap;
`;

const SelectStudentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-top: 50px;
`;

const EditDueTimeContainer = styled.div`
  display: flex;
  gap: 35px;
`;

const EditDueTimeBlock = styled.div`
  margin-top: 50px;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
`;

const ButtonBlock = styled.div`
  margin-top: 72px;
  padding-top: 20px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  width: auto;
  font-size: 18px;
  line-height: 22px;
  padding: 17px 50px;
`;

const CancelButton = styled(StyledButton)`
  background-color: #fff;
  border: 3px solid #9c9c9c;
  color: #9c9c9c;
  :hover:not(:disabled) {
    background-color: #e7e7e7;
  }
`;

const SaveChangesButton = styled(StyledButton)`
  background-color: #9077f5;
`;
