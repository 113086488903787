import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface ButtonProps {
  className?: string
  children?: ReactNode
  icon?: any
  backgroundHover?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  href?: string
}

const TopicForDashboard = ({
  icon, children, backgroundHover, className, onClick, href,
}: ButtonProps) => {

  return (
    <Topic className={className} backgroundHover={backgroundHover} onClick={onClick}>
      {icon && (
        <IconTopic src={icon} alt="" />
      )}
      {children}
    </Topic>
  );
};

export default TopicForDashboard;

const Topic = styled.div<{
  backgroundHover?: string
}>`
  display: block;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 2px 8px #F0F2F3;
  background-color: #FFF;
  box-sizing: border-box;
  padding: 10px 40px 10px 10px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  cursor: pointer;
  transition: .2s ease-in-out;
  color: inherit;
  :hover {
    background-color: ${props => props.backgroundHover};
    color: inherit;
  }
`;

const IconTopic = styled.img`
  width: 140px;
  height: 140px;
  flex-shrink: 0;
  object-fit: cover;
`;
