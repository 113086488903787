import {
  ChartType,
  ChartConfigType,
  StatType,
  ScalesType,
  ScalesCallbacksType
} from "../types";

import {
  line as createLine,
  curveCatmullRom,
} from "d3-shape";

const drawLineChart = (
  config: ChartConfigType,
  chart: ChartType,
  data: StatType[],
  scales: ScalesCallbacksType
) => {
  const { dateScale, valueScale } = scales;

  const lineChart = createLine<StatType>();
  lineChart
    .x((d: any) => dateScale(d.endTime))
    .y((d: any) => valueScale(d.result))
    .curve(curveCatmullRom);

  const statPoint: StatType = {
    id: "asdad1212131",
    endTime: data?.[0].endTime,
    result: 0,
  };

  const line = chart
    .selectAll<SVGPathElement, unknown>(".lineChart")
    .data([[...data]]);

  // Updata the line
  line
    .enter()
    .append("path")
    .attr("class", "lineChart")
    .merge(line)
    .transition()
    .duration(100)
    .attr("d", lineChart)
    .attr("fill", "none")
    .attr("stroke", "#C7BBFA")
    .attr("stroke-width", 2.5);

  const lineFill = chart
    .selectAll<SVGPathElement, unknown>(".lineFill")
    .data([[...data]]);

  // Updata the line
  lineFill
    .enter()
    .append("path")
    .attr("class", "lineFill")
    .merge(lineFill)
    .transition()
    .duration(100)
    .attr("d", (d) => {
      const rawLine = lineChart(d);

      if (rawLine && rawLine.length > 0) {
        const startPoint = `0,${config.size.height}`;
        const endPoint = `${
          config.size.width
        },${config.size.height}`;

        return `M${startPoint}L${rawLine.slice(1)}L${endPoint}L${startPoint}`;
      } else {
        return "";
      }
    })
    .attr("fill", "none")
    //.attr("stroke", "steelblue")
    //.attr("stroke-width", 2.5);
};

export default drawLineChart;