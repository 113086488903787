import React from 'react';
import styled from 'styled-components/macro';
import Ellements from '../Form';

const logo = require('../images/logo.svg');

const subjectInstruction: Record<string, Array<{title: string, description: string}>> = {
  English: [
    {title: 'Essential information', description: 'This test is adaptive: questions become easier or harder depending on how you perform during the test.'},
    {title: 'Instructions', description: 'The English mock test assesses comprehension, sentence completion, spelling, and punctuation.'},
    {title: 'No ‘Back’ Button', description: 'You cannot return to a question once an answer has been submitted. Once you have selected an answer, click the Next button to submit your response. '},
    {title: 'Timed Test', description: 'The test is timed. You can see how long you have remaining in the top right-hand corner of the test page. '},
    {title: 'Exam Time', description: 'You will have 25 minutes to complete the test.'},
    {title: 'Fixed Number of Questions', description: 'The test has a fixed number of questions. You can see how many questions you have completed in the progress bar at the bottom of the page. '},
    {title: 'End of Test', description: 'The test will end when you click Next on the final question.'},
    {title: 'Exam Conditions', description: 'The test must be taken under exam conditions.'},
    {title: 'No Additional Resources', description: 'You are not allowed to use additional resources in this test.'},
    {title: 'No Pencil and Paper', description: 'You are not allowed to use a pencil and paper.'},
    {title: 'English Comprehension Navigation', description: 'The comprehension passage is navigated by the numbered buttons above the passage. You will need to click through the buttons to find the relevant part of the passage for the question.'},
  ],
  Maths: [
    {title: 'Essential information', description: 'This test is adaptive: questions become easier or harder depending on how you perform during the test.'},
    {title: 'Instructions', description: 'The mathematics mock test assesses the KS2 mathematics curriculum.'},
    {title: 'No ‘Back’ Button', description: 'You cannot return to a question once an answer has been submitted. Once you have selected an answer, click the Next button to submit your response.'},
    {title: 'Timed Test', description: 'The test is timed. You can see how long you have remaining in the top right-hand corner of the test page.'},
    {title: 'Exam Time', description: 'You will have 50 minutes to complete the test.'},
    {title: 'Fixed Number of Questions', description: 'The test has a fixed number of questions. You can see how many questions you have completed in the progress bar at the bottom of the page.'},
    {title: 'End of Test', description: 'The test will end when you click Next on the final question.'},
    {title: 'Exam Conditions', description: 'The test must be taken under exam conditions.'},
    {title: 'No Additional Resources', description: 'You are not allowed to use additional resources in this test. '},
    {title: 'No Pencil and Paper', description: 'You are not allowed to use a pencil and paper for workings during the test.'},
  ],
  'Verbal reasoning': [
    {title: 'Essential information', description: 'This test is adaptive: questions become easier or harder depending on how you perform during the test.'},
    {title: 'Instructions', description: 'The Verbal reasoning mock test assesses the ability to understand and comprehend written passages.'},
    {title: 'No ‘Back’ Button', description: 'You cannot return to a question once an answer has been submitted. Once you have selected an answer, click the Next button to submit your response.'},
    {title: 'Timed Test', description: 'The test is timed. You can see how long you have remaining in the top right-hand corner of the test page. '},
    {title: 'Exam Time', description: 'You will have 36 minutes to complete the test.'},
    {title: 'Fixed Number of Questions', description: 'The test has a fixed number of questions. You can see how many questions you have completed in the progress bar at the bottom of the page.'},
    {title: 'End of Test', description: 'The test will end when you click Next on the final question.'},
    {title: 'Exam Conditions', description: 'The test must be taken under exam conditions.'},
    {title: 'No Additional Resources', description: 'You are not allowed to use additional resources in this test.'},
    {title: 'No Pencil and Paper', description: 'You are not allowed to use a pencil and paper.'},
  ],
  'Non-verbal reasoning': [
    {title: 'Essential information', description: 'This test is adaptive: questions become easier or harder depending on how you perform during the test.'},
    {title: 'Instructions', description: 'The Non-verbal reasoning mock test assesses reasoning with shapes and space.'},
    {title: 'No ‘Back’ Button', description: 'You cannot return to a question once an answer has been submitted. Once you have selected an answer, click the Next button to submit your response.'},
    {title: 'Timed Test', description: 'The test is timed. You can see how long you have remaining in the top right-hand corner of the test page.'},
    {title: 'Exam Time', description: 'You will have 32 minutes to complete the test.'},
    {title: 'Fixed Number of Questions', description: 'The test has a fixed number of questions. You can see how many questions you have completed in the progress bar at the bottom of the page.'},
    {title: 'End of Test', description: 'The test will end when you click Next on the final question.'},
    {title: 'Exam Conditions', description: 'The test must be taken under exam conditions.'},
    {title: 'No Additional Resources', description: 'You are not allowed to use additional resources in this test.'},
    {title: 'No Pencil and Paper', description: 'You are not allowed to use a pencil and paper.'},
  ],
};

const {
  Button,
} = Ellements;

interface InstructionModalProps {
  onStart: () => void
  subject?: string
  disabledButton?: boolean
}

const InstructionModal = ({
  onStart,
  subject,
  disabledButton,
}: InstructionModalProps) => {

  return (
    <Wrapper>
      <Logo src={logo} />
      {subjectInstruction[subject || ''].map((item) => (
        <>
          <Title>{item.title}</Title>
          <Description>{item.description}</Description>
        </>
      ))}
      <ButtonStyled onClick={onStart} disabled={disabledButton}>Start exam</ButtonStyled>
    </Wrapper>
  );
};

export default InstructionModal;

const Wrapper = styled.div`
  padding: 20px 30px 25px;
  max-width: 810px;
  width: 100%;
`;

const Logo = styled.img`
  width: 123px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 8px;
`;

const Title = styled.h4`
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 4px;

  :nth-of-type(n+3) {
    font-size: 16px;
    text-decoration: underline;
    font-weight: normal
  }
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
`;

const ButtonStyled = styled(Button)`
  background-color: #9077F5;
  max-width: fit-content;
  padding: 16px 65px;
  margin: 100px auto 0;

`;
