import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import RadioSelectPeriod from './RadioSelectPeriod';
import ScrollSelectSchool, { schoolItemsTypes } from './ScrollSelectSchool';

import LineChart from '../Chart/LineChart/LineChart';
import { monthView, weekView, yearView } from '../Chart/LineChart/dateView';
import { request } from '../api';
import ProgressPage from './ProgressPage';
import { SIDEBAR_MENU } from '../Form/FieldsEntity/Sidebar';
import { SchoolType } from '../features/user/types';
import { getUserProfile } from '../features/user/userState';
import { useSelector } from 'react-redux';
import { SchoolsListTypes } from '../ProfilePage/AcademicInformation/InputSelect';

interface ProgressGraphBoardProps {
  subjects?: string;
}

interface StatsExamDataType {
  period: string;
  topic?: string;
}

interface StatItemType {
  id: string;
  rate: number;
  avg: number;
  date: number;
  endTime: string;
}
const useOveralProgressdata = (params: StatsExamDataType) => {
  const [stats, setStats] = useState<StatItemType[]>([]);

  
  useEffect(() => {
    request(
      {
        method: 'POST',
        url: 'challenge/stats/exams',
        data: params,
      },
      [200],
    ).then(({ data }) => {
      const StatItemsList: StatItemType[] = data;
      setStats(StatItemsList);
    });
  }, [params, setStats]);

  return stats;
};

const ProgressGraphBoard = ({
  
}: ProgressGraphBoardProps) => {

  const user = useSelector(getUserProfile);

  const targetSchools: SchoolsListTypes[] = user.targetSchools || [];
 
  const [currentSchool, setCurrentSchool] = useState<SchoolType>({
    _id: '',
    name: '',
    rate: 0
  });

  const { subject } = useParams<{subject?: string}>();

  useEffect(() => {
    setParams({ ...params, topic: subject });
  }, [subject]);


  const [params, setParams] = useState({ period: 'week', topic: subject });

  const data = useOveralProgressdata(params).map(item => ({ ...item, result: item.avg, endTime: new Date(item.date) }));

  return (
    <ProgressPage selectedMenuItem={subject ? SIDEBAR_MENU.SUBJECTS : SIDEBAR_MENU.OVERALL}>
      <Wrapper>
        <Title>{`Study progress (${subject || 'All subjects'})`}</Title>
        <Board>
          <ControlPanel>
            {targetSchools.length > 0 && <ScrollSelectSchool
              targetSchools={targetSchools}
              onChange={(currentSchool: schoolItemsTypes) => setCurrentSchool(currentSchool)}
            />}
            <RadioSelectPeriod
              value={params.period}
              onSelectPeriod={(name: string) => setParams({ ...params, period: name })}
            />
          </ControlPanel>
          {data.length > 0 && (
            <LineChart
              target={currentSchool}
              dateView={monthView}
              onClickPoint={() => {}}
              width={700}
              height={300}
              data={data}
            />
          )}
        </Board>
      </Wrapper>
    </ProgressPage>
  );
};

export default ProgressGraphBoard;

const Wrapper = styled.div`
  width: 100%;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 21px;
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: 22px;
  margin-left: 25px;
`;

const Board = styled.div`
  background-color: #fff;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 35px 35px 25px;
  box-shadow: 0px 1.625px 6.5px #f0f2f3;
`;

const ControlPanel = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
`;
