import React from "react";
import styled from "styled-components/macro";
import { AssignmentFields } from "../../../project-types/assignments/types";
import AssigmentFilterItem, { FilterType } from "./AssigmentFilterItem";
import AssigmentsTable from "./AssigmentsTable";
import ModalInPortal from "../../../utils/ModalPortal";
import ModalContentForEditAssignment from "../ModalContentForEditAssignment";
import { ApiChangeAssignment } from "../../../project-types/assignments/api-types";
import ModalContentForViewReport from "../ModalContentForViewReport";

interface TeacherGroupBodyProps {
  onAddAssignment: () => void;
  assignments: AssignmentFields[];
  currentAssignment: AssignmentFields | null;
  selectedAssignments: Array<string>;
  onSelectAssignments: (id: string) => void;
  onSelectAllAssignments: () => void;
  onDeleteAssignments: () => void;
  onSelectAssignment: (assignmentId: string) => void;
  onChangeAssignmentName: (newName: string, assignmentId: string) => void;
  onSaveChangesClickHandler: (
    formData: ApiChangeAssignment["data"],
    assignmentId: string
  ) => void;
  onChangeFilter: (
    filter: Exclude<FilterType, FilterType.ADD_NEW> | null
  ) => void;
  currentFilter: FilterType | null;
  numOfActiveAssignments: number;
  numOfPastAssignments: number;
}

const TeacherGroupBody = ({
  onAddAssignment,
  assignments,
  onChangeAssignmentName,
  onSaveChangesClickHandler,
  onSelectAssignment,
  currentAssignment,
  onChangeFilter,
  numOfActiveAssignments,
  numOfPastAssignments,
  currentFilter,
  selectedAssignments,
  onSelectAssignments,
  onSelectAllAssignments,
  onDeleteAssignments,
}: TeacherGroupBodyProps) => {
  const { ModalRender, onShow, onClose } = ModalInPortal();
  const {
    ModalRender: ModalRenderReport,
    onShow: onShowReport,
    onClose: onCloseReport,
  } = ModalInPortal();

  const onEditClickHandler = (assignment: AssignmentFields) => {
    onSelectAssignment(assignment._id);
    onShow();
  };
  const onViewReportClickHandler = (assignment: AssignmentFields) => {
    onSelectAssignment(assignment._id);
    onShowReport();
  };

  const onSaveAssignmentChanges = (
    formData: ApiChangeAssignment["data"],
    assignmentId: string
  ) => {
    onSaveChangesClickHandler(formData, assignmentId);
    onClose();
  };

  const onSaveAssignmentName = (newName: string, assignmentId: string) => {
    onChangeAssignmentName(newName, assignmentId);
    onClose();
  };

  return (
    <Body>
      <AssignmentsFiltersList>
        <AssigmentFilterItem
          type={FilterType.ADD_NEW}
          onClick={onAddAssignment}
        />
        <AssigmentFilterItem
          type={FilterType.ACTIVE}
          number={numOfActiveAssignments}
          onClick={() => onChangeFilter(FilterType.ACTIVE)}
          isSelect={currentFilter ? currentFilter === FilterType.ACTIVE : false}
        />
        <AssigmentFilterItem
          type={FilterType.PAST}
          number={numOfPastAssignments}
          onClick={() => onChangeFilter(FilterType.PAST)}
          isSelect={currentFilter ? currentFilter === FilterType.PAST : false}
        />
      </AssignmentsFiltersList>
      {numOfActiveAssignments === 0 && numOfPastAssignments === 0 ? (
        <EmptyAssignmentsMessage>
          You don't have any assignments created yet
        </EmptyAssignmentsMessage>
      ) : (
        <AssigmentsTable
          assignments={assignments}
          onEditClickHandler={onEditClickHandler}
          onViewReportClickHandler={onViewReportClickHandler}
          isDeleteActive={
            selectedAssignments && selectedAssignments.length ? true : false
          }
          onDeleteClickHandler={onDeleteAssignments}
          onSelectAssignments={onSelectAssignments}
          onSelectAllAssignments={onSelectAllAssignments}
          selectedAssignments={selectedAssignments}
        />
      )}
      {ModalRender && currentAssignment && (
        <ModalRender>
          <ModalContentForEditAssignment
            onSaveChangesClickHandler={onSaveAssignmentChanges}
            onCancelClickHandler={onClose}
            onSaveNameClickHandler={onSaveAssignmentName}
            assignmentName={currentAssignment.name}
            assignmentId={currentAssignment._id}
            dueDate={currentAssignment.dueDate}
            groupStudents={currentAssignment.group.students}
            selectedStudents={currentAssignment.studentsSelected}
          />
        </ModalRender>
      )}
      {ModalRenderReport && currentAssignment && (
        <ModalRenderReport>
          <ModalContentForViewReport
            assignmentDueDate={currentAssignment.dueDate}
            assignmentName={currentAssignment.name}
            assignmentStudents={currentAssignment.studentsSelected}
          />
        </ModalRenderReport>
      )}
    </Body>
  );
};

export default TeacherGroupBody;

const AssignmentsFiltersList = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Body = styled.div``;

const EmptyAssignmentsMessage = styled.span`
  font-size: 16px;
  line-height: 22px;
  color: #1f1f1f;
  margin-top: 60px;
  display: block;
`;
