import React, { useEffect, useState }  from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { message } from 'antd';
import SectionWrapper from './SectionWrapper';
import PastExamTests from './PastExamTests';
import PastPracticeTests from './PastPracticeTests';
import { request } from '../../api';
import DashboardPage from '../DashboardPage';
import Layout from '../../Layout';
import { SIDEBAR_MENU } from '../../Form/FieldsEntity/Sidebar';

interface PastTestsBodyProps {

}

// TODO повторение 
interface ChalengeItemType {
      id: string
  key: number
  date: string
  elapsedTime: number
  extraTime: boolean
  result: number
}
interface itemApiTypes {
      id: string
  topicId: string[]
  startTime?: string
  completedTime: string
  timeLength: number
  rate: number
  isExtended: boolean
}

interface ArrayItemTopicType {
  id: string
  key: number
  name: string
  topicName: string
  date: string
  elapsedTime: number
  result: number
}

interface itemPracticeTestTypes {
  key: number
  name: string
  topics: ArrayItemTopicType[]
}

const PastTestsBody = ({

}: PastTestsBodyProps) => {
  const [examStatistic, setExamStatistic] = useState<ChalengeItemType[]>([]);
  const [practiceTests, setPracticeTests] = useState<itemPracticeTestTypes[]>([]);

  const { subject }: any = useParams();

  useEffect(() => {
    request({
      method: 'POST',
      url: 'challenge/history',
      data: {
        type: 'exam',
        topicId: subject
      }

    }, [200]).then((response) => {
      const newExamStatistic: ChalengeItemType[] = [];
      if (response.data) {
        response.data.map((item: itemApiTypes, i: number) => {
          newExamStatistic.push({
            id: item.id,
            key: i + 1,
            date: item.completedTime,
            elapsedTime: item.timeLength,
            extraTime: item.isExtended,
            result: item.rate
          });
        });
      }
      setExamStatistic(newExamStatistic);
    }).catch(error => {
      if (error.response.data.message) {
        console.log(error.response.data.message);
        // message.error(error.response.data.message);
      } else {
        message.error('Not searched challenges');
      }
      setExamStatistic([]);
    });
  }, [subject]);

  useEffect(() => {
    request({
      method: 'POST',
      url: 'challenge/history',
      data: {
        type: 'test',
        topicId: subject,
      }

    }, [200]).then((response) => {
      let newPracticeTests: itemPracticeTestTypes[] = [];
      const newPracticeTestsTopics: ArrayItemTopicType[] = [];
      if (response.data) {
        response.data.map((item: itemApiTypes, i: number) => {
          newPracticeTestsTopics.push({
            id: item.id,
            key: i + 1,
            name: item.topicId[1] || item.topicId[0],
            topicName: item.topicId[item.topicId.length - 1],
            date: item.completedTime,
            elapsedTime: item.timeLength,
            result: item.rate
          });
        });

        // combine
        newPracticeTests = Object.values(newPracticeTestsTopics.reduce((a: any, item: ArrayItemTopicType) => {
          (a[item.name]
            || (a[item.name] = {
              key: item.key,
              name: item.name,
              topics: []
            })
          ).topics.push(item);

          return a;
        }, {}));
      }
      setPracticeTests(newPracticeTests);
    }).catch(error => {
      if (error.response.data.message) {
        console.log(error.response.data.message);
        // message.error(error.response.data.message);
      } else {
        message.error('Not searched challenges');
      }
      setPracticeTests([]);
    });
  }, [subject]);


  const history = useHistory();

  const showPastExamResult = (chalengeId: string) => {
    history.push(`/chalenge/detailed-result/exam/${chalengeId}`);
  }

  const showPastTestResult = (chalengeId: string) => {
    history.push(`/chalenge/detailed-result/test/${chalengeId}`);
  }

  return (
    <Layout>
      <DashboardPage selectedMenuItem={SIDEBAR_MENU.PAST_TESTS}>
        <SectionPastTests>
          <SectionWrapper title="Past exams">
            <PastExamTests examStatistic={examStatistic} onShowDetailResult={showPastExamResult} />
          </SectionWrapper>
          <SectionWrapper title="Practice tests">
            <PastPracticeTests onShowDetailResult={showPastTestResult} practiceTests={practiceTests} />
          </SectionWrapper>
        </SectionPastTests>
      </DashboardPage>
    </Layout>
  );
};

export default PastTestsBody;

const SectionPastTests = styled.div`
  max-width: 800px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 30px;
`;
