import React from 'react';
import styled from 'styled-components/macro';
import FormElements from '../../Form';
import { InputError } from '../../Form/FieldsEntity/FormInput';

const {
  Input,
} = FormElements;

interface ChangeEmailProps {
  onChangeEmail: (email: string) => void
  email?: string
  errors?: string | string[]
  className?: string
}

const ChangeEmail = ({
  onChangeEmail,
  email,
  errors = [],
  className,
}: ChangeEmailProps) => {
  return (
    <Wrapper className={className}>
      <StyledInput
        type="email"
        value={email}
        placeholder="Enter your email"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeEmail(e.target.value)}
        error={errors?.length > 0}
        required
      />
      <InputError errors={errors} />
    </Wrapper>
  );
};

export default ChangeEmail;

const Wrapper = styled.div`
  margin-bottom: 40px;
`;

const StyledInput = styled(Input)`
  flex-grow: 1;
  height: 56px;
  font-weight: normal;
  font-size: 16px;
  width: max(294px);
  :focus {
    border: 2px solid ${({ error }) => error ? '#F55F4B' : '#9077F5'};
    padding: 16px 14px;
  }
`;
