export const examsConf: Record<string, {time: number, questions: number}> = {
  Maths: {
    time: 50,
    questions: 36,
  },
  English: {
    time: 25,
    questions: 32,
  },
  'Verbal reasoning': {
    time: 36,
    questions: 36,
  },
  'Non-verbal reasoning': {
    time: 32,
    questions: 36,
  },
};
