import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Link,
  RouteComponentProps,
  Switch
} from "react-router-dom";

import LoginPage from './LoginPage';
import UsersTable from './UsersTable';
import UserEdit from './UserEdit';
import SchoolsList from "./SchoolsTable/SchoolsList";
import SchoolEdit from './SchoolEdit/SchoolEdit';
import AdminEdit from "./AdminEdit/AdminEdit";
import Acl from "../Acl";



type TParams = { id: number };

export default function Routes() {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact>
        <LoginPage />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/login`} exact>
        <LoginPage />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/users`} exact>
        <Acl>
          <UsersTable />
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/users/:id`} exact>
        <Acl>
          <UserEdit />
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/schools`} exact>
        <Acl>
          <SchoolsList />
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/schools/:action`} exact>
        <Acl>
          <SchoolEdit />
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/admin`} exact>
        <Acl>
          <AdminEdit />
        </Acl>
      </Route>
    </Switch>
  );
}
