import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { DatePicker } from 'antd';
import moment from 'moment';
import { Formik } from 'formik';
import { InputError } from '../../Form/FieldsEntity/FormInput';
import LabelForInput from '../../Form/FieldsEntity/LabelForInput';
import InputSelect, { SchoolsListTypes } from '../../ProfilePage/AcademicInformation/InputSelect';
import TargetSchoolsList from '../../ProfilePage/AcademicInformation/TargetSchoolsList';
import ChangeDate from '../../ProfilePage/PersonalInformation/ChangeDate';
import ChangeGender from '../../ProfilePage/PersonalInformation/ChangeGender';
import ChangePassword from '../../ProfilePage/PersonalInformation/ChangePassword';
import FirstAndLastNameInput from '../../ProfilePage/PersonalInformation/FirstAndLastNameInput';
import { SchoolType, UserProfileBaseType, UserProfileType } from '../../features/user/types';
import FormElements from '../../Form';
import { removeNoChangedValue } from '../../ProfilePage/PersonalInformation/PersonalInformation';
import { passwordVerification } from '../../hooks/passwordValidator';
import { ErrorsType } from '../../utils/createForm';

const {
  Button, Input,
} = FormElements;

interface UserProfileEditProps {
  userId?: string
  schoolsList: SchoolType[],
  user?: UserProfileBaseType,
  onSubmit: (profile: UserProfileBaseType) => void
}

const UserProfileEdit = ({
  userId,
  schoolsList,
  user,
  onSubmit,
}: UserProfileEditProps) => {
  useEffect(() => {

  });

  const formatDate = (dateInput: any) => {

  };

  const profileValidator = (values: UserProfileType) => {
    const errors: ErrorsType = {};
  
    if (values.password) {
      if (values.password === undefined || passwordVerification(values.password).length > 0 || !values.confirmPassword) {
        errors.password = passwordVerification(values.password);
      }
    }
  
    return errors;
  };

  if (!user) {
    return (<div />);
  }
  return (
    <Formik
      initialValues={{
        _id: user._id,
        fullName: user.fullName,
        gender: user.gender,
        currentSchool: user.currentSchool,
        targetSchools: user.targetSchools,
        nextExamDate: user.nextExamDate,
        dateOfBirth: user.dateOfBirth,
        email: user.email,
      }}
      validate={profileValidator}
      onSubmit={
          (values, { setSubmitting }) => {
            const valuesHasChanges = removeNoChangedValue(user, values);
            onSubmit(valuesHasChanges);
          }
        }
    >
      {({
        values,
        handleSubmit,
        handleReset,
        setFieldValue,
        handleChange,
        errors,
      }) => (
        <form onSubmit={handleSubmit} onReset={handleReset}>
          <Container>
            <Title>User name</Title>
            <FirstAndLastNameInput
              onChangeFullName={(name: string) => setFieldValue('fullName', name)}
              nameValue={values.fullName}
            />

            <Title>Birthday and gender</Title>
            <Wrapper>
              {/* <ChangeDate
                    dateValue={values.dateOfBirth}
                    onChange={(date) => {
                      console.log(date);
                    }}
                  /> */}
              <Label name="Date of birth">
                <DatePicker
                  onChange={(date) => setFieldValue('dateOfBirth', date)}
                  name="dateOfBirth"
                  defaultValue={moment(values.dateOfBirth)}
                  size="large"
                  style={{height: 56, borderRadius: 10}}
                  placeholder="Birth day"
                />
              </Label>
              {/* <ChangeGenderStyled
                onChangeGender={(gender: string) => {
                  setFieldValue('gender', gender);
                  console.log(gender);
                }}
                value={values.gender}
              /> */}
            </Wrapper>

            <ChangePasswordStyled
              onChangePassword={(password: string) => {
                setFieldValue('password', password);
              }}
              onConfirm={(confirm) => setFieldValue('confirmPassword', confirm)}
              title="Password"
            />

            {user.role === 'USER' && (
              <>
                <Title>Email</Title>
                <Label name="">
                  <InputStyled type="email" name="email" onChange={handleChange} placeholder="Enter email" value={values.email} />
                </Label>

                <AcademicTitle>Academic Information</AcademicTitle>
                <LabelForInput name="Current school">
                  <InputSelectStyled
                    name="currentSchool"
                    targetSchool={user.currentSchool?.name}
                    onChangeSchool={(target: string) => setFieldValue('currentSchool', target)}
                  />
                  {/* <InputError errors={errors.currentSchool} /> */}
                </LabelForInput>

                {/* <Title>Exam date</Title> */}
                <Label name="Exam date">
                  <DatePicker
                    onChange={(date) => setFieldValue('nextExamDate', date)}
                    name="nextExamDate"
                    defaultValue={moment(values.nextExamDate)}
                    placeholder="Enter date of exam"
                    size="large"
                    style={{height: 56, borderRadius: 10}}
                  />
                </Label>
                {/* <ExamDate
                      dateValue={values.examDate}
                      onChange={(date) => {
                        console.log(date);
                      }}
                    /> */}

                <TargetSchoolsList
                  schoolsList={schoolsList}
                  targetSchoolsList={user.targetSchools || []}
                  onChangeTargetSchools={(targetSchools: string[]) => setFieldValue('targetSchools', targetSchools)}
                  errors={errors.targetSchools}
                />
              </>
            )}


            <ButtonWrapper>
              <CancelButton type="reset">Cancel</CancelButton>
              <SaveButton type="submit">Save changes</SaveButton>
            </ButtonWrapper>
          </Container>
        </form>
      )}
    </Formik>

  );
};

export default UserProfileEdit;

const Container = styled.form`
  padding-bottom: 220px;
`;

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  align-items: center;
`;

const InputSelectStyled = styled(InputSelect)`
  max-width: 570px;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 12px;
`;

const AcademicTitle = styled(Title)`
  margin-top: 48px;
`;

const ExamDate = styled(ChangeDate)`
  margin-bottom: 48px;
`;

const ButtonWrapper = styled.div`
  width: fit-content;
  display: flex;
  margin-right: auto;
  margin-top: 100px;
`;

export const SaveButton = styled(Button)`
  min-width: 128px;
  width: auto;
  height: auto;
  padding: 13px 25px;
  border: 3px solid transparent;
  color: #FFF;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  background-color: #9077F5;
  margin-left: 20px;
`;

const CancelButton = styled(SaveButton)`
  margin-left: 0;
  background-color: #FFF;
  border-color: #9C9C9C;
  color: #9C9C9C;
  :hover:not(:disabled) {
    background-color: #FFF;
    border-color: #F87700;
    color: #F87700;
  }
`;

const InputStyled = styled(Input)`
  max-width: 220px;
  :focus {
    border: 2px solid #9077F5;
    padding: 16px 14px;
  }
`;

const ChangeGenderStyled = styled(ChangeGender)`
  padding-top: 0;
`;

const Label = styled(LabelForInput)`
  margin-bottom: 24px;
  width: auto;
`;

const ChangePasswordStyled = styled(ChangePassword)`
  margin-bottom: 24px;
`;
