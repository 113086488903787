interface BackgroundType {
  color: string
  img: string
}

interface BackgroundChallengeType {
  color: string
  img: string
  opacity: number,
}

interface SybjectStylesType {
  background: BackgroundType
  backgroundChallenge: BackgroundChallengeType
  icon: string
  shadowColor: string
}

export const subjectsStyles: Record<string, SybjectStylesType> = {
  Maths: {
    background: {
      color: '#9077F5',
      img: require('../images/topicsBackground/maths.svg'),
    },
    backgroundChallenge: {
      color: '#EEEAFF',
      img: require('../images/topicsBackground/mathsInChallenge.svg'),
      opacity: 0.2,
    },
    icon: require('../images/TopicsIcon/maths.svg'),
    shadowColor: '#CFC9EC',
  },
  English: {
    background: {
      color: '#90E1D3',
      img: require('../images/topicsBackground/english.svg'),
    },
    backgroundChallenge: {
      color: '#D0EDE8',
      img: require('../images/topicsBackground/englishChallenge.svg'),
      opacity: 0.5,
    },
    icon: require('../images/TopicsIcon/english.svg'),
    shadowColor: '#93C0B8',
  },
  'Verbal reasoning': {
    background: {
      color: '#F372C7',
      img: require('../images/topicsBackground/verbal.svg'),
    },
    backgroundChallenge: {
      color: '#FFE1F5',
      img: require('../images/topicsBackground/verbalChallenge.svg'),
      opacity: 0.2,
    },
    icon: require('../images/TopicsIcon/verbal.svg'),
    shadowColor: '#D6AEC8',
  },
  'Non-verbal reasoning': {
    background: {
      color: '#50C4EE',
      img: require('../images/topicsBackground/nonVerbal.svg'),
    },
    backgroundChallenge: {
      color: '##D8EFF8',
      img: require('../images/topicsBackground/nonVerbalChallenge.svg'),
      opacity: 0.2,
    },
    icon: require('../images/TopicsIcon/nonverbal.svg'),
    shadowColor: '#B4D2DD',
  },
};
