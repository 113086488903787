import React from 'react';
import styled from 'styled-components/macro';
import Modal from '../Form/FieldsEntity/Modal';
import {
  CorrectIcon,
  IncorrectIcon,
} from '../images/iconSvg';

const otterCorrect = require('../images/otter-correct-answer.svg');
const otterIncorrect = require('../images/otter-incorrect-answer.svg');

interface ResultAnswerModalContentProps {
  right?: boolean
  showModal?: boolean
}

const ResultAnswerModalContent = ({
  showModal,
  right
}: ResultAnswerModalContentProps) => {
  const backgroundImg = right ? otterCorrect : otterIncorrect;
  const resultIcon = right ? <CorrectIcon /> : <IncorrectIcon />;
  const description = right ? 'That is correct!' : 'Sorry, that\' s not correct :(';

  if (showModal) {
    return (
      <Modal unclosed onClose={() => {}}>
        {typeof right === 'boolean' && <Wrapper backgroundImg={backgroundImg}>
          <IconBox>
            {resultIcon}
            <Description>
              {description}
            </Description>
          </IconBox>
        </Wrapper>}
      </Modal>
    );
  }

  return null;
};

export default ResultAnswerModalContent;

const Wrapper = styled.div<{backgroundImg?: any}>`
  width: 740px;
  min-height: 380px;
  box-sizing: border-box;
  padding: 85px 0 22px 22px;
  background: url(${(props) => props.backgroundImg}) no-repeat;
  background-position: right 60px bottom 15px;
  background-size: auto 95%;
`;

const IconBox = styled.div`
  width: 420px;
  margin-right: 320px;
  font-size: 126px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Description = styled.span`
  max-width: 230px;  
  display: block;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  margin-top: 35px;
`;
