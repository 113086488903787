import { omit } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Checkbox, CheckboxNormal } from '../../../Form/Form';
import { EditIconCircle } from '../../../images/iconSvg';
import { GroupOutType } from '../../../project-types/groups/types';
import { UserPublicType } from '../../../project-types/users/types';

const checkMark = require('../../../images/check-mark.svg');

interface AssigmentTableProps {
  students: GroupOutType['students']
  onDelete: (userIds: UserPublicType[]) => void
}

type SelectedUsers = Record<string, UserPublicType>;

export default function AssigmentsTable({ students, onDelete }: AssigmentTableProps) {

  const [checkedUsers, setCheckedUsers] = useState<SelectedUsers>({});

  const setIsChecked = (student: UserPublicType) => () => {
    if (student._id) {
      const id = student._id;
      // Hack worng type String in user type
      if (checkedUsers[id as string]) {
        setCheckedUsers(omit(checkedUsers, id as string));
      } else {
        setCheckedUsers({ ...checkedUsers, [id as string]: student });
      }
    }
  };

  const checkAll = (e: any) => {
    if (!e.target.checked) {
      setCheckedUsers({});
    } else {
      const res: SelectedUsers = {};
      const studnetsObject = students.reduce((acc: SelectedUsers, student: UserPublicType) => {
        acc[student._id as string] = student;
        return acc;
      }, res);
      setCheckedUsers(studnetsObject);
    }
  };

  const onClickDelete = () => {
    onDelete(Object.values(checkedUsers));
  };

  return (
    <TableStyled>
      <TableHeadStyled>
        <tr>
          <ThStyled scope="col">Student Name</ThStyled>
          <ThStyled scope="col">Email</ThStyled>
          <ThStyled scope="col">
            <DeleteWrap>
              <Delete onClick={onClickDelete} isActive={Object.keys(checkedUsers).length > 0}>Delete</Delete>
              <CheckboxNormal onChange={checkAll} />
            </DeleteWrap>
          </ThStyled>
        </tr>
      </TableHeadStyled>
      <tbody>
        {students.map((student) => (
          <TrStyled>
            <TdStyled>
              <AssigmentNodeTitle>{student.fullName}</AssigmentNodeTitle>
            </TdStyled>
            <TdStyled>{student.email}</TdStyled>
            <TdStyled>
              <DeleteWrap>
                <CheckboxNormal checked={!!checkedUsers[student['_id'] as string]} onChange={setIsChecked(student)} />
              </DeleteWrap>
            </TdStyled>
          </TrStyled>
        ))}
      </tbody>
    </TableStyled>
  );
};

const TableStyled = styled.table`
  width: 100%;
`;

const TableHeadStyled = styled.thead`
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
`;

const ThStyled = styled.th`
  padding: 14px 0px 10px 21px;
  border-top: 1px solid #E0E0E0;
`;

const TdStyled = styled.th`
  padding: 14px 0px 10px 21px;
`;

const TrStyled = styled.tr`
  border-top: 1px solid #E0E0E0;
  &:nth-of-type(2n) {
    background-color: #F2F2F2;
  }
`;

const DeleteWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
`;


const AssigmentNodeTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`;

const AssigmentNodeType = styled.div`
  font-size: 12px;
  line-height: 22px;
`;

const ViewReportLink = styled.a`
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-transform: capitalize;
  color: #9077F5;
`;

const EditIconStyled = styled(EditIconCircle)`
  & svg {
    fill: #9077F5;
  }

  display: inline;
  font-size: 18px;
  margin-right: 7px;
`;

const Delete = styled.div<{ isActive: boolean }>`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  margin-right: 29px;
  cursor: pointer;
  color: ${props => props.isActive ? '#9077F5' : '#C4C4C4'}
`;