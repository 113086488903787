import React, {
  useState, ReactChild, useEffect, ReactElement,
} from 'react';
import ReactDOM from 'react-dom';
import Modal from '../Form/FieldsEntity/Modal';

interface ModalPortalProps {
  children: ReactChild
}

const modalRoot = document.getElementById('modal-root');

const CreatePortalForModal = ({
  children,
}: ModalPortalProps) => {
  const createDiv = document.createElement('div');

  useEffect(() => {
    modalRoot?.appendChild(createDiv);
    return () => {
      modalRoot?.removeChild(createDiv);
    };
  }, []);

  return ReactDOM.createPortal(
    children,
    createDiv,
  );
};

const ModalInPortal = () => {
  const [showModal, setShowModal] = useState(false);

  const RenderModalInPortal = ({ children }: { children: ReactChild }) => (
    <CreatePortalForModal>
      <Modal
        onClose={() => setShowModal(false)}
      >
        {children}
      </Modal>
    </CreatePortalForModal>
  );

  return {
    ModalRender: showModal ? RenderModalInPortal : null,
    onShow: () => setShowModal(true),
    onClose: () => setShowModal(false),
  };
};

export default ModalInPortal;
