import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface RadioButtonProps {
  children: ReactNode
  onChange?: () => void
  radioName?: string
  className?: string
  defaultChecked?: boolean
  bestValue?: boolean
  onClick: () => void
}

const PayPeriodButton = ({
  children,
  onChange,
  radioName,
  className,
  defaultChecked,
  bestValue,
  onClick,
}: RadioButtonProps) => {
  return (
    <Label className={className}>
      {bestValue && (
        <Notification>Best value!</Notification>
      )}
      <Radio type="radio" onChange={onChange} name={radioName} defaultChecked={defaultChecked} onClick={onClick} />
      <Container>{children}</Container>
    </Label>
  );
};

export default PayPeriodButton;

const Label = styled.label`
  cursor: pointer;
  display: block;
  position: relative;
`;

const Radio = styled.input`
  display: none;
`;

const Container = styled.div`
  box-sizing: border-box;
  padding: 8px 38px;
  border: 2px solid #9077F5;
  border-radius: 10px;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #9077F5;
  background-color: #FFF;
  ${Label} > ${Radio}:checked + & {
    background-color: #9077F5;
    color: #FFF;
  }
`;

const Notification = styled.div`
  background-color: #F87700;
  width: 46px;
  height: 25px;
  border-radius: 4px;
  font-size: 9px;
  line-height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  color: #FFF;
  position: absolute;
  top: -12px;
  right: -10px;

  :after {
    content: '';
    display: block;
    background-color: #C16109;
    width: 14px;
    height: 14px;
    position: absolute;
    right: 4px;
    bottom: -6px;
    z-index: -1;
    transform: rotate(45deg);
  }
`;
