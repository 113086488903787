import React, { ReactNode } from 'react';
import { Form, Input, Button, Checkbox, Card } from 'antd';
import styled from 'styled-components/macro';


interface FormWrapProps {
  title: string,
  children: ReactNode
};

export const FormWrap = ({ title, children }: FormWrapProps) => {
  return (
    <FullScreenFormWrap>
      <CardStyled title={title}>
        {children}
      </CardStyled>
    </FullScreenFormWrap>
  );
};

const FullScreenFormWrap = styled.div`
  display: flex;
  // min-height: calc(100vh - 86px);
  // width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CardStyled = styled(Card)`
  width: 50%;
  max-width: 500px;
  @media (max-width: 768px) {
    width: 100%;
    height: 100vh;
  }
`;

const FormStyled = styled(Form)`
  & .ant-form-item-control: {
  }
`;

export const FormItem = styled(Form.Item)`
  align-items: stretch;
  flex-direction: column;
  & .ant-form-item-label {
    text-align: left;
  }
`;