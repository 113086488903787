import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import Topic from '../../Form/FieldsEntity/TopicForDashboard';
import StartButton from '../../Form/FieldsEntity/StartButton';
import { createStartLink } from './utils';
import { TopicAreasType, TopicRouteType } from '../../features/topic/types';

interface TopicsListOfSelectedTestProps {
  backgroundTopic?: string
  isSubTopics?: boolean
  topicItems?: TopicAreasType[]
}

const TopicsListOfSelectedTest = ({
  topicItems,
  backgroundTopic,
  isSubTopics
}: TopicsListOfSelectedTestProps) => {
  // TODO убрать этот отсюда
  const {
    subject,
    topic,
    topicAreas,
    subTopic,
  } = useParams<TopicRouteType>();

  const { push } = useHistory();

  const linkTo = topicAreas ? '#' : `${process.env.PUBLIC_URL}/dashboard/new-test/${subject}/${topic}`;

  const startSelectedTest = (selectedTopic: string) => createStartLink('test', subject, topic, topicAreas, selectedTopic);

  return (
    <>
      {topicItems?.map((item: any) => (
        <StyledTopic
          key={item.key}
          backgroundHover={ isSubTopics ? '#FFF' : backgroundTopic}
          onClick={() => push(`${linkTo}/${item.title}`)}
          isSubTopic={isSubTopics}
        >
          <TextWrapper>
            <TitleTopic fontSize={topicAreas ? '22px' : '28px'}>{item.title}</TitleTopic>
            {item.subTopicsLength && !topicAreas && (
              <DescriptionTopic>
                {item.subTopicsLength}
                {' '}
                sub-topics
              </DescriptionTopic>
            )}
          </TextWrapper>
          <StartButtonStyled onClick={(e) => { e.stopPropagation(); push(startSelectedTest(item.title)); }}>
            Start
          </StartButtonStyled>
        </StyledTopic>
      ))}
    </>
  );
};

export default TopicsListOfSelectedTest;

const StyledTopic = styled(Topic)<{isSubTopic?: boolean}>`
  height: 120px;
  padding-left: 50px;
  ${({ isSubTopic }) => isSubTopic && `
    cursor: default;
  `}

  :not(:last-child) {
    margin-bottom: 30px;
  }
`;

const TitleTopic = styled.span<{fontSize: string}>`
  display: block;
  font-weight: bold;
  font-size: ${({ fontSize }) => fontSize};
  line-height: 34px;
`;

const DescriptionTopic = styled.span`
  display: block;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
`;

const TextWrapper = styled.div`
  max-width: 380px;
  margin: 0 20px;
  flex-shrink: 0;
  margin-right: auto;
`;

const StartButtonStyled = styled(StartButton)`
  padding: 18px 20px;
`;
