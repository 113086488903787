import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import SVG from 'react-inlinesvg';
import TypeOfQuestion from './TypeOfQuestion';
import ShowingAnswerQuestion from './ShowingAnswerQuestion';
import {
  ReportIcon,
} from '../images/iconSvg';
import Modal from '../Form/FieldsEntity/Modal';
import ReportContentForModal from './ReportContentForModal';
import Notification from '../Form/FieldsEntity/Notification';
import FormElements from '../Form';
import { ChallengeType, QuestionType } from '../features/challenge/types';
import ShowLatex from './ShowLates';

const {
  Button,
} = FormElements;


interface ChallengeBodyTypes {
  // TODO FIX HACK
  currentQuestion: QuestionType,
  onAnswer: (answer: string) => void
  onSkip: () => void
  onPreviousQuestion?: () => void
  onNextQuestion?: () => void
  showAnswer?: boolean
  selectedLastQuestion?: boolean
  currentQuestionNum: number
  allQuestionsViewed?: boolean
  currentChalenge: ChallengeType
}

const ChallengeBody = ({
  currentQuestion,
  currentQuestionNum,
  onAnswer,
  onSkip,
  onPreviousQuestion,
  onNextQuestion,
  showAnswer,
  selectedLastQuestion,
  allQuestionsViewed,
  currentChalenge,
}: ChallengeBodyTypes) => {
  const [answer, setAnswer] = useState(currentQuestion?.userAnswer || '');
  const [isAnswered, setIsAnswered] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [notificationReport, setNotificationReport] = useState(false);

  const reportSentAndHideNotification = () => {
    setNotificationReport(true);
    setShowReportModal(false);
    setTimeout(() => setNotificationReport(false), 50000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // setAnswer('');
  }, [currentQuestion]);


  useEffect(() => {
    setAnswer(currentQuestion?.userAnswer || '');
    setIsAnswered(typeof currentQuestion?.userAnswer !== 'undefined');
  }, [currentQuestion, setAnswer, setIsAnswered]);

  const onSubmitAnswer = () => {
    onAnswer(answer);
  };

  const disableAnswer = currentChalenge.challengeType === 'test' && typeof currentQuestion.right !== 'undefined';


  return (
    <Wrapper>
      {notificationReport && (
        <Notification>
          Thank you, your report has been submitted.
        </Notification>
      )}
      {showReportModal && (
        <Modal onClose={() => setShowReportModal(false)}>
          <ReportContentForModal reportSent={() => reportSentAndHideNotification()} />
        </Modal>
      )}
      <Question>
        <TitleWrapper>
          <Title>
            Question
            {' '}
            {currentQuestionNum}
          </Title>
          {!showAnswer && (
            <ReportIcon onClick={() => setShowReportModal(true)} />
          )}
        </TitleWrapper>
        <TaskDescription>
          <ShowLatexStyled>{currentQuestion?.question}</ShowLatexStyled>
          {currentQuestion?.svg && (
            <QuestionImg src={currentQuestion.svg} />
          )}
        </TaskDescription>

        {showAnswer ? (
          <ShowingAnswerQuestion
            userAnswer={currentQuestion?.userAnswer}
            correctAnswer={currentQuestion?.correctAnswer}
            itemsQuestion={currentQuestion?.answers}
            typeQuestion={currentQuestion?.['answer-type']}
            currentQuestionItems={currentQuestion}
          />
        ) : (
          <TypeOfQuestion
            typeQuestion={currentQuestion['answer-type']}
            answer={answer}
            itemsQuestion={currentQuestion.answers}
            isAnswered={disableAnswer}
            onChangeAnswer={setAnswer}
          />
        )}
      </Question>
      {showAnswer ? (
        <ButtonWrapper>
          <ButtonPrevious
            onClick={onPreviousQuestion}
            disabled={currentQuestionNum === 1}
          >
            Previous question
          </ButtonPrevious>
          <ButtonNext
            onClick={onNextQuestion}
            disabled={selectedLastQuestion}
          >
            Next question
          </ButtonNext>
        </ButtonWrapper>
      ) : (
        <ButtonWrapper>
          <ButtonAnswer
            disabled={disableAnswer || !answer}
            onClick={onSubmitAnswer}
          >
            Answer
          </ButtonAnswer>
          {/* <ButtonSkip
            disabled={allQuestionsViewed}
            onClick={onSkip}
          >
            {currentChalenge.challengeType === 'exam' ? 'Skip' : 'Next question'}
          </ButtonSkip> */}
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

export default ChallengeBody;

const Wrapper = styled.div``;

const Question = styled.div`
  margin-bottom: 98px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 38px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 0;
`;

const TaskDescription = styled.p`
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  max-width: 720px;
  margin-bottom: 52px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  // max-width: 652px
`;

const ButtonAnswer = styled(Button)`
  min-width: 254px;
  width: auto;
  min-height: 74px;
  height: auto;
  align-self: baseline;
  transition: .2s ease-in-out;
  background-color: ${({ theme }) => theme.color};
  margin-right: 28px;
  // flex-grow: 1;
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  border-radius: 16px;
  padding: 20px 60px;
  cursor: pointer;

  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.hoverColor};
  }
`;

const ButtonSkip = styled(ButtonAnswer)`
  box-sizing: border-box;
  background-color: #FFF;
  border: 3px solid ${({ theme }) => theme.color};
  color: ${({ theme }) => theme.color};
  padding: 14px 54px;
  :hover:not(:disabled) {
    color: #9077F5;
    background-color: #FFF;
    border-color: #9077F5;
  }
  :disabled {
    color: #FFF;
    border: 3px solid transparent;
  }
`;

const ButtonPrevious = styled(ButtonSkip)`
  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.hoverColor};
    color: #FFF;
    border-color: transparent;
  }
  :disabled {
    color: #FFF;
  }
`;

const ButtonNext = styled(ButtonAnswer)`
`;


const QuestionImg = styled(SVG)`
  object-fill: cover;
  max-width: 560px;
  height: auto;
  margin-top: 17px;
  margin-bottom: 18px;
`;

const ShowLatexStyled = styled(ShowLatex)`
  & img {
    max-width: 100%;
  }
  & p {
    margin-bottom: 12px;
  }
`;
