import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import {
  getTeacherAssignmentsList,
  loadAssignmentsList,
  loadOneAssignment,
  changeAssignment,
  getTeacherAssignment,
  clearAssignment,
  getTeacherGroup,
  getTeacherGroups,
  loadTeacherGroup,
  loadTeacherGroups,
} from "../../../features/teacher/teacherState";
import Layout from "../../../Layout";
import { ApiChangeAssignment } from "../../../project-types/assignments/api-types";
import { GroupFields } from "../../../project-types/groups/types";
import HeaderForTeacherDashboard from "../../HeaderForTeacherDashboard";
import TeacherGroupBody from "./TeacherGroupBody";
import { AssignmentFields } from "../../../project-types/assignments/types";
import { FilterType } from "./AssigmentFilterItem";
import TeacherSelectDropdown from "../../TeacherSelectDropdown";

type FilterTypeWithoutAdd = Exclude<FilterType, FilterType.ADD_NEW>;

type FilteredAssignmentsType = {
  [key in FilterTypeWithoutAdd]: Array<AssignmentFields>;
};

const TeacherGroupPage = () => {
  const [selectedFilter, setSelectedFilter] = useState<FilterTypeWithoutAdd | null>(null);
  const [selectedAssignments, setSelectedAssignments] = useState<Array<string>>([]);

  const { groupId } = useParams<{ groupId: GroupFields["_id"] }>();

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAssignmentsList({ groupId }));
    dispatch(loadTeacherGroup(groupId));
    dispatch(loadTeacherGroups());
  }, [groupId]);

  const currentAssignment = useSelector(getTeacherAssignment);
  const assignments = useSelector(getTeacherAssignmentsList);
  const currentGroup = useSelector(getTeacherGroup);
  const groupList = useSelector(getTeacherGroups);

  const onDropdownItemClick = (id: string) =>
    history.push(`/teacher/group/${id}`);

  const onAddAssignment = () => {
    history.push(`/teacher/group/${groupId}/assignment/create/1`);
  };

  const onDeleteAssignments = () => {
    if (selectedAssignments.length === 0) return;

    //TODO waiting for backend
    console.log(selectedAssignments);
    setSelectedAssignments([]);
  }

  const onGoBackClickHandler = () => history.push(`/teacher/dashboard/my-groups`);

  const onSelectAssignment = (assignmentId: string) => {
    dispatch(clearAssignment());
    dispatch(loadOneAssignment(assignmentId));
  };

  const onSelectAssignments = (assignmentId: string) => {
    if (selectedAssignments.includes(assignmentId)) {
      setSelectedAssignments(selectedAssignments => selectedAssignments?.filter(id => id !== assignmentId));
    } else {
      setSelectedAssignments(selectedAssignments => [...selectedAssignments, assignmentId]);
    }
  };

  const onSelectAllAssignments = () => {
    if (selectedAssignments.length === assignments.length) {
      setSelectedAssignments([]);
    } else {
      const assignmentIds = assignments.map(assignment => assignment._id);
      setSelectedAssignments(assignmentIds);
    }
  };

  const onChangeAssignmentName = (newName: string, assignmentId: string) =>
    dispatch(changeAssignment(assignmentId, { name: newName }, { groupId }));

  const onSaveChangesClickHandler = (
    formData: ApiChangeAssignment["data"],
    assignmentId: string
  ) => dispatch(changeAssignment(assignmentId, formData, { groupId }));

  const dropdownContent: Array<{
    name: string;
    _id: string;
  }> = groupList.reduce((acc: Array<{ name: string; _id: string }>, item) => {
    const obj = { name: item.name, _id: item._id };
    acc.push(obj);
    return acc;
  }, []);

  const filteredAssignments: FilteredAssignmentsType = assignments.reduce(
    (acc: FilteredAssignmentsType, item) => {
      if (new Date() < new Date(item.dueDate)) {
        acc[FilterType.ACTIVE].push(item);
      } else {
        acc[FilterType.PAST].push(item);
      }

      return acc;
    },
    { [FilterType.ACTIVE]: [], [FilterType.PAST]: [] }
  );

  return (
    <Layout>
      <Wrapper>
        <HeaderForTeacherDashboard
          onAdd={() => {}}
          addButtonComponent={
            <TeacherSelectDropdown
              buttonText={currentGroup?.name || ""}
              dropdownContent={dropdownContent}
              onDropdownItemClickHandler={onDropdownItemClick}
            />
          }
          title="Assignments"
          showGoBack
          onBack={onGoBackClickHandler}
        />
        <TeacherGroupBody
          onChangeFilter={setSelectedFilter}
          onAddAssignment={onAddAssignment}
          assignments={
            selectedFilter ? filteredAssignments[selectedFilter] : assignments
          }
          currentFilter={selectedFilter}
          numOfActiveAssignments={filteredAssignments[FilterType.ACTIVE].length}
          numOfPastAssignments={filteredAssignments[FilterType.PAST].length}
          currentAssignment={currentAssignment}
          onSelectAssignment={onSelectAssignment}
          onChangeAssignmentName={onChangeAssignmentName}
          onSaveChangesClickHandler={onSaveChangesClickHandler}
          selectedAssignments={selectedAssignments}
          onSelectAssignments={onSelectAssignments}
          onSelectAllAssignments={onSelectAllAssignments}
          onDeleteAssignments={onDeleteAssignments}
        />
      </Wrapper>
    </Layout>
  );
};

export default TeacherGroupPage;

const Wrapper = styled.div`
  width: 100%;
  min-width: 480px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 117px;
`;
