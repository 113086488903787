import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import {
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import { tariffServices } from '../../config/tariffConfig';
import FormElements from '../../Form';
import CardForPlan from '../../Form/FieldsEntity/CardForPlan';
import PayPeriodButton from '../../Form/FieldsEntity/PayPeriodButton';
import Paypal from '../../Paypal';
import StripeInput from '../../PricingPage/StripeInput';
import { PayPlanPaypal, PayPlanStripe, PayPlanType } from './types';
import { addSubscription, changeTariff, getUserProfile } from '../../features/user/userState';
import { useDispatch, useSelector } from 'react-redux';
import SelectSubjectBoard from '../../SignUpSteps/SelectSubjectBoard';

const {
  Button,
} = FormElements;

interface ChangeSubscriptionFromModalProps {
  className?: string
  showModal?: boolean
  onClose: () => void
  onChangePlan: (priceId: string, system: string, ppSubscribeId?: string) => void
  priceList?: any
  currentPriceName?: string
}

const ChangeSubscriptionFromModal = ({
  onClose,
  onChangePlan,
  className,
  priceList,
  currentPriceName,
}: ChangeSubscriptionFromModalProps) => {
  // const [planPeriod, setPlanPeriod] = useState('year');
  const [tab, setTab] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState<string>('');
  const [selectedSubject, setSelectedSubject] = useState('');

  useEffect(() => {
    if (selectedPlan !== 'Otter One') {
      setSelectedSubject('');
    }
  }, [selectedPlan]);

  const changePlansPeriod = (period: string) => {
    return priceList.stripe.filter((item: PayPlanType) => item.recurring.interval === period && item.name !== currentPriceName);
  };

  const dispatch = useDispatch();

  const profile = useSelector(getUserProfile);

  const isOtterOneAndSubject = selectedPlan === 'Otter One' && selectedSubject !== '';

  const currentPriceList = changePlansPeriod('month');

  const currentTariff = priceList.stripe.find((item: PayPlanType) => currentPriceName === item.name);

  // const currentPaypalPrice = priceList.paypal.find((item: PayPlanPaypal) => item.id === currentPriceId);
  // const currentStripePrice = priceList.stripe.find((item: PayPlanStripe) => item.id === currentPriceId);

  const paypalPrice = priceList.paypal.find((item: PayPlanPaypal) => item.name === (selectedPlan || currentPriceName));
  const stripePrice = priceList.stripe.find((item: PayPlanStripe) => item.name === (selectedPlan || currentPriceName));

  console.log(paypalPrice)

  const setPaypalPlan = (priceId: string, system: string, ppSubscribeId?: string) => {
    if (profile.subscription?.system === 'paypal') {
      onChangePlan(priceId, system, ppSubscribeId);
      onClose();
    } else {
      //debugger;
      dispatch(addSubscription(paypalPrice.id, ppSubscribeId || '', 'paypal', false));
      onClose()
    }
  };

  const stripe = useStripe();
  const elements = useElements();


  const setStripePlan = (tariffId: string) => {
    dispatch(changeTariff(tariffId, 'stripe'));
    onClose();
  };


  const onSelecStripe = () => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement: any = elements.getElement(CardElement);

    stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    }).then(({ error, paymentMethod }) => {
      if (error) {
        console.log('[error]', error);
      } else {
        console.log('[PaymentMethod]', paymentMethod);
      }

      if (!paymentMethod) {
        throw new Error('paymentMethod');
      }

      console.log(stripePrice, paymentMethod);
      dispatch(changeTariff(stripePrice.id, 'stripe', paymentMethod.id));
      onClose();
      //setPaypalPlan(stripePrice.id, 'stripe', (paymentMethod as string))
    })



    //changeTariff(stripePrice?.id || '', );
  }

  return (
    <Container className={className}>
      <Title>Change subscription</Title>
      <Wrapper>
        <PayPeriodButtonStyled radioName="button" defaultChecked onClick={() => setTab(0)}>Change tariff</PayPeriodButtonStyled>
        <PayPeriodButtonStyled radioName="button" onClick={() => setTab(1)}>Change payment system</PayPeriodButtonStyled>
      </Wrapper>
      {tab === 0 && <>
      <TitleCurrentPlan>Your current subscription</TitleCurrentPlan>
        <CardForPlan
          planTitle={currentTariff?.name}
          isCurrent
          disabled
          price={currentTariff?.unit_amount_decimal}
          services={tariffServices[currentTariff?.id]}
        // totalPrice={currentTariff?.unit_amount}
        />
        <ButtonSelectWrap>
          <DescriptionButtonSelect>Choose a new subscription plan</DescriptionButtonSelect>
          {/* <PayPeriodButtonStyled
          radioName="select-period"
          onChange={() => setPlanPeriod('month')}
        >
          Monthly
        </PayPeriodButtonStyled>
        <PayPeriodButton
          radioName="select-period"
          onChange={() => setPlanPeriod('year')}
          defaultChecked
          bestValue
        >
          Yearly
        </PayPeriodButton> */}
        </ButtonSelectWrap>
        {currentPriceList.map((item: PayPlanType) => (
          <CardForPlanStyled
            key={item.id}
            planTitle={item.name}
            onChange={() => setSelectedPlan(item.name)}
            discount={item.discount}
            price={item.unit_amount}
            oldPrice={item.oldPrice}
            // totalPrice={item.unit_amount}
            nameRadio="pay-plan"
            services={tariffServices[item?.id]}
          />
        ))}

        {selectedPlan === 'Otter One' && (
          <SelectSubjectWrapper>
            <SelectSubjectBoard
              onSelectSubject={setSelectedSubject}
            />
          </SelectSubjectWrapper>
        )}

        {(isOtterOneAndSubject || selectedPlan !== 'Otter One') && (
          <>
            {profile.subscription?.system === 'paypal' && <Paypal
              onSubscribePaypal={(data) => { console.log(data); setPaypalPlan(data.selectedPlanId || '', 'paypal', data.subscriptionID) }}
              selectedPlanId={paypalPrice?.id}
            />}

            {profile.subscription?.system === 'stripe' &&
              <ButtonWrapper>
                <CancelButton onClick={onClose}>Cancel</CancelButton>
                <ChangePlanButton disabled={selectedPlan === ''} onClick={() => setStripePlan(stripePrice?.id)}>Change plan</ChangePlanButton>
              </ButtonWrapper>}
          </>
        )}
      </>
      }

      {/* <Reminder>
        The changements will be effective for your next payment on
        <Span>October 3, 2020</Span>
      </Reminder> */}

      {tab === 1 && <>
        {(profile.subscription?.status === 'canceled' || profile.subscription?.system === 'paypal') &&
          <>
            {/* <h1>Stripe</h1> */}
            <StripeInput />
            <ButtonWrapper>
              <ChangePlanButton onClick={onSelecStripe}>Switch to stripe</ChangePlanButton>
            </ButtonWrapper>
          </>}
        {(profile.subscription?.status === 'canceled' || profile.subscription?.system === 'stripe') &&
          <Paypal
            onSubscribePaypal={(data) => { console.log(data); setPaypalPlan(data.selectedPlanId || '', 'paypal', data.subscriptionID) }}
            selectedPlanId={paypalPrice?.id}
          />}</>}

    </Container>
  );
};

export default ChangeSubscriptionFromModal;

const Wrapper = styled.div`
  margin-bottom: 32px;
`;

const Container = styled.div`
  box-sizing: border-box;
  padding: 50px 60px 40px;
  max-width: 740px;
  width: 100vw;
  overflow: scrooll;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 25px;
  line-height: 26px;
  margin-bottom: 60px;
`;

const ButtonWrapper = styled.div`
  width: fit-content;
  display: flex;
  margin-left: auto;
  margin-top: 60px;
`;

const TitleCurrentPlan = styled.span`
  display: block;
  margin-bottom: 15px;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #9C9C9C;
  margin-top: 32px;
`;

const ChangePlanButton = styled(Button)`
  min-width: 128px;
  width: auto;
  height: auto;
  padding: 13px 25px;
  border: 3px solid transparent;
  color: #FFF;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  background-color: #9077F5;
  margin-left: 20px;
`;

const CancelButton = styled(ChangePlanButton)`
  margin-left: 0;
  background-color: #FFF;
  border-color: #9C9C9C;
  color: #9C9C9C;
  :hover:not(:disabled) {
    background-color: #FFF;
    border-color: #F87700;
    color: #F87700;
  }
`;

const ButtonSelectWrap = styled.div`
  display: flex;
  padding-top: 10px;
  padding-left: 40px;
  margin: 50px 0 30px;
`;

const DescriptionButtonSelect = styled.span`
  margin-right: 23px;
  flex-shrink: 0;
  width: 164px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
`;

const PayPeriodButtonStyled = styled(PayPeriodButton)`
  margin-right: 23px;
  display: inline-block;
`;

const CardForPlanStyled = styled(CardForPlan)`
  margin-bottom: 34px;
`;

const Reminder = styled.span`
  display: inline-block;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;

const Span = styled.span`
  margin-left: 5px;
  color: #9077F5;
`;

const SelectSubjectWrapper = styled.div`
  padding: 32px 0;
  border-top: 2px solid #E0E0E0;
  border-bottom: 2px solid #E0E0E0;
`;
