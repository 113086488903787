import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { ChallengeType, QuestionType } from '../features/challenge/types';
import SummaryExamGraph from './charts/SummaryExamGraph';
import TopicBoardResult from './charts/TopicBoardResult';
import SummaryStatisticsBoard from './SummaryStatisticsBoard';

interface SummaryResultExamProps {
  title?: string
  currentChallenge: ChallengeType
}

const SummaryResultExam = ({
  title,
  currentChallenge,
}: SummaryResultExamProps) => {
  const {
    questionsNumber,
    questions,

  } = currentChallenge;

  const questionAttempted = questions.length;

  const correctlyAnswered = questions.filter((item: QuestionType) => item.right).length;

  const score = currentChallenge.rate;

  const completedTime = new Date(currentChallenge.completedTime).getTime();

  const startTime = new Date(currentChallenge.challengeStartTime).getTime();

  const finishTime = new Date(currentChallenge.challengeFinishTime).getTime();

  const elapsedTime = completedTime - startTime;

  const fullTime = finishTime - startTime;

  const standardAgeScore = (score * 0.7) + 60;

  const timePerQuestion = Math.round(elapsedTime / 1000 / questionAttempted);

  const minutes = Math.floor(timePerQuestion / 60);
  let seconds: number | string = Math.floor(timePerQuestion - minutes * 60);
  if (`${seconds}`.length < 2) {
    seconds = `0${seconds}`;
  }

  type QuestionsByTopicType = Record<string, Array<QuestionType>>
  const qBytopics: QuestionsByTopicType = {};
  const questionsByTopics = questions.reduce((res, q) => {
    if (q.path && q.path[1]) {
      const topicName =q.path[1];
      res[topicName] = res[topicName] ? [...res[topicName], q]  : [q] ;
    }
    return res;
  }, qBytopics);


  console.log({ questionsByTopics });

  type TopicsScore = Record<string, number>;
  const tScore = {};
  const topicScore = Object.keys(questionsByTopics).map((n) => {
    const totalLen = questionsByTopics[n].length;
    const rightsNum = questionsByTopics[n].filter(q => q.right).length;
    return { title: n, value: Math.round((rightsNum / totalLen) * 100) };
  });

  console.log({ topicScore });

  //const [standardAgeScore, setStandardAgeScore] = useState(60);

  const sortedScore = [...topicScore].sort((a, b) => a.value - b.value);

  const goTo = sortedScore[0] ? sortedScore[0].title : '';
  const and = sortedScore[1] ? `and look at ${sortedScore[1].title}` : ``;

  const areas = sortedScore.length > 1 ? 'areas' : 'area';

  const comment = `You should now go to the ${goTo} area ${and} as these were your weakest ${areas}`;

  return (
    <Wrapper>
      {/* <input  type="number" onChange={e => setStandardAgeScore(parseInt(e.target.value))} /> */}
      <Title>{title}</Title>
      <Header>
        <HeaderDescription>Mock Exam</HeaderDescription>
        <HeaderDescription>Status: Completed</HeaderDescription>
      </Header>
      <TitleForContent>
        Mock Exam Summary
      </TitleForContent>
      <SummaryExamGraph
       
        standardAgeScore={standardAgeScore}
        score={score}
      />
      <SummaryStatisticsBoard
        standardAgeScore={standardAgeScore}
        questionsAttempted={questionAttempted}
        questionsNumber={questionsNumber}
        questionsAnsweredCorrectly={correctlyAnswered}
        score={score}
        elapsedTime={elapsedTime}
        fullTime={fullTime}
        timePerQuestion={`${minutes}:${seconds}`}
      />
      <TitleForContent>
        Topic Breakdown
      </TitleForContent>
      {/* Место для графика */}
      <TopicBoardResult topicScore={topicScore} />

      <CommentBlock>
        <CommentTitle>Comment</CommentTitle>
        <CommentText>
          {comment}
        </CommentText>
      </CommentBlock>
    </Wrapper>
  );
};

export default SummaryResultExam;

const Wrapper = styled.div`
  padding: 53px 50px 100px;
  width: 1005px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 40px;
  line-height: 43px;
  padding: 0 10px;
  margin-bottom: 39px;
`;

const Header = styled.div`
  padding: 0 10px 8px;
  border-bottom: 2px solid #E0E0E0;
  margin-bottom: 60px;
  display: flex;
`;

const HeaderDescription = styled.span`
  display: block;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500px;
  color: #9C9C9C;
  margin-right: 50px;
`;

const TitleForContent = styled.h2`
  margin-bottom: 40px;
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  padding: 0 10px;
`;

const CommentBlock = styled.div`
  width: 100%;
  display: flex;
  padding: 0 10px;
`;

const CommentTitle = styled.h3`
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin-right: 13px;
`;

const CommentText = styled.p`
  font-size: 20px;
  line-height: 26px;
`;
