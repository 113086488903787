import React from 'react';
import styled from 'styled-components/macro';
import Logo from '../Form/FieldsEntity/Logo';

const chagrinedOtter = require('../images/otter-incorrect-answer.svg');

const Page404 = () => (
  <Page>
    <LogoStyled />
    <Description>
      Oh no!
      <br />
      Page not found.
    </Description>
  </Page>
);

export default Page404;

const Page = styled.div`
  max-width: 1440px;
  width: 100%;
  min-height: 100vh;
  padding: 22px 79px;
  margin: 0 auto;

  @media (max-width: 1023px) {
    padding: 22px 20px;
  }
`;

const Description = styled.div`
  margin: 155px auto 0;
  display: flex;
  padding-top: 130px;
  justify-content: flex-end;
  max-width: 700px;
  height: 462px;
  background: url(${chagrinedOtter}) no-repeat left;
  background-size: contain;
  font-size: 47px;
  line-height: 57px;

  @media (max-width: 700px) {
    padding-top: 230px;
    background-size: 200px;
    margin-top: 50px;
    justify-content: center;
    text-align: center;
    background-position: top center;
    max-width: 100%;
    font-size: 22px;
    line-height: 24px;
    height: 100%;
  }
`;

const LogoStyled = styled(Logo)`
  @media (max-width: 1023px) {
    width: 115px;
    height: 38px;
    background-size: contain;
  }
`;
