import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

const logoSvg = require('../../images/logo.svg');

interface LogoProps {
  className?: string
}

const Logo = ({ className }: LogoProps) => {
  return (
    <ImagesLogo className={className} to="/" />
  );
};

const ImagesLogo = styled(Link)`
  display: block;
  flex-shrink: 0;
  width: 140px;
  height: 46px;
  background: url(${logoSvg}) no-repeat center;
`;

export default Logo;
