import {
  ChartType,
  StatType,
  ScalesType,
  ChartConfigType
} from "../types";

import { date, values } from "../scales";


const focusPointImg = require('./point.svg');
const lightFocusPoint = require('./ligthPoint.svg');

const drawPoints = (
  config: ChartConfigType,
  chart: ChartType,
  data: StatType[],
  scales: ScalesType,
  onClickPoint: (d: StatType) => void,
  onFocusPoint: (d?: StatType) => void,
  focusedPoint?: StatType
) => {
  console.log("------", `.linePoint`);

  const points = chart
    .selectAll<SVGGElement, unknown>(`.linePoint`)
    .data(data, (d: any) => d.id);

  const create = points.enter().append("g").attr("class", `linePoint`);

  const merge = create
    .merge(points)
    .attr(
      "transform",
      (d: StatType) =>
        `translate(${scales.dateScale(d.endTime)},${scales.valueScale(
          d.result
        )})`
  );
  
  merge.select("text").remove();

  merge
    .append("text")
    .attr("class", 'linePoint-text')
    .attr('y', '-15')
    .text(d => `${d.result}%`)
  

  merge.select("line").remove()

  merge.append("line")
    .attr("class", 'linePoint-dateLine')
    .attr("x1", 0)
    .attr("y1", 0)
    .attr("x2", 0)
    .attr("y2", d => config.size.height - scales.valueScale(d.result))
    //.attr("stroke-dasharray",  "4")

  merge.selectAll("image").remove();

  merge
    .append("svg:image")
    .attr("class", "linePoint-point")
    .attr("xlink:href", (d) => focusedPoint === d ? focusPointImg : lightFocusPoint)
    //.attr("x", -18)
    //.attr("y", -17)
    .attr("x", (d) => focusedPoint === d ? -18 : -6)
    .attr("y", (d) => focusedPoint === d ? -17 : -7)
    .on("mouseenter", (_, d: StatType) => onFocusPoint(d))
    .on("mouseleave", () => onFocusPoint())
    .on("click", (_, d: StatType) => {
      onClickPoint(d);
    });

  const remove = points.exit().remove();

  return { create, merge, remove };
};


export default drawPoints;