import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';

const teacherImg = require('../images/teacher.svg');

const defaultComment = 'Welcome to Otter Plus! A teacher will be reviewing your progress soon, and writing comments here :)';

interface TeacherCommentProps {
  comment?: string
  className?: string
}

const TeacherComment = ({
  comment,
  className,
}: TeacherCommentProps) => {
  const [showFullComment, setShowFullComment] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const commentRef = useCallback((ref) => {
    if (ref !== null) {
      const hideButton = ref.scrollHeight > ref.clientHeight;
      setShowButton(hideButton);
    }
  }, []);

  return (
    <RelativePositinContainer className={className}>
      <Box showFull={showFullComment}>
        <Images src={teacherImg} restyle={showFullComment} />
        <Comment ref={commentRef}>
          {comment || defaultComment}
          {showButton && (
            <ShowButton onClick={() => setShowFullComment(!showFullComment)} restyle={showFullComment}>
              {showFullComment ? 'Hide' : 'Show more'}
            </ShowButton>
          )}
        </Comment>
      </Box>
    </RelativePositinContainer>
  );
};

export default TeacherComment;

const RelativePositinContainer = styled.div`
  position: relative;
  margin-left: 510px;
  margin-top: auto;
  width: 100%;
  height: 165px;
  margin-bottom: 48px;
`;

const Box = styled.div<{showFull?: boolean}>`
  box-shadow: 0px 2px 8px #F0F2F3;
  display: flex;
  background-color: #EEEAFF;
  border-radius: 10px;
  min-height: 100%;
  height: ${({ showFull }) => (showFull ? 'auto' : '100%')};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;

const Images = styled.img<{restyle?: boolean}>`
  margin-left: 40px;
  margin-right: 32px;
  align-self: center;
  position: relative;
`;

const Comment = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  box-sizing: border-box;
  margin: 30px 35px;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const ShowButton = styled.span<{restyle?: boolean}>`
  display: block;
  position: ${({ restyle }) => (restyle ? 'static' : 'absolute')};
  bottom: 0;
  right: 0;
  background: linear-gradient(90deg, transparent 0%, #EEEAFF 30%);
  font-weight: 700;
  color: #9077F5;
  box-sizing: border-box;
  padding-left: ${({ restyle }) => (restyle ? '0px' : '60px')};;
  cursor: pointer;
`;
