export const fr = {
  translation: {
    navigation: {
      profile: 'Profil',
      dashboard: 'Tableau de bord',
      progress: 'Le progrès',
      about: 'À propos',
      pricing: 'Tarification',
      termsOfService: undefined,
      privacyPolicy: undefined,
      contact: undefined,
    },
    user: {
      student: 'Étudiant',
    },
    mainPage: {
      mainTitle: 'Entrée réussie à l\'école secondaire',
      mainDescription: 'La plateforme en ligne adaptative d\'Otter pour former les examens scolaires 11+ et indépendants en mathématiques, anglais, raisonnement verbal et non verbal.',
      firstSection: {
        title: undefined,
        description: undefined,
      },
      secondSection: {
        title: undefined,
        description: undefined,
      },
      thirdSection: {
        title: undefined,
        description: undefined,
      },
      fourthSection: {
        title: undefined,
        description: undefined,
      },
      fifthSection: {
        title: undefined,
        description: undefined,
      },
      sixthSection: {
        title: undefined,
        description: undefined,
      },
      seventhSection: {
        title: undefined,
        description: undefined,
      },
    },
    otter: {
      description: undefined,
    },
    action: {
      logIn: 'Le Login',
      signUp: 'Le Registre',
      trial: 'Démarrez un essai gratuit de 7 jours',
      startExam: undefined,
      startTest: undefined,
    },
    copyright: undefined,
    'New test': undefined,
    'Past tests': undefined,
    'Practice tests': undefined,
    'Practice exam': undefined,
  },
};
