import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { reportChallenge } from '../features/challenge/challengeState';
import Radio from '../Form/FieldsEntity/Radio';
import {
  PaperclipIcon,
} from '../images/iconSvg';
import FormElements from '../Form';
import { ReportType } from '../features/challenge/types';
import { ErrorsType } from '../utils/createForm';
import { InputError } from '../Form/FieldsEntity/FormInput';
import { useCurrentChalenge, useCurrentQuestion } from './ChalengeHooks';
import { useParams } from 'react-router-dom';
// import {} from '../features/user/userState';

const {
  Button,
} = FormElements;

const problemsItem = [{
  key: 1,
  problem: 'Typo or mistake',
}, {
  key: 2,
  problem: 'Can’t see images or formulas',
}, {
  key: 3,
  problem: 'Can’t send my answer',
}, {
  key: 4,
  problem: 'Other',
}];

interface ReportTypeProps {
  reportSent: () => void
}

const ReportContentForModal = ({ reportSent }: ReportTypeProps) => {
  const dispatch = useDispatch();

  const reportValidate = (values: ReportType) => {
    const errors: ErrorsType = {};
    if (!values.otherProblem && values.problem === 'Other') {
      errors.otherProblem = ['Can\'t be empty'];
    }

    return errors;
  };

  const currentChallengeId = useCurrentChalenge()._id;

  const { 
    currentQuestionNum,
  } = useParams<{ currentQuestionNum: string}>();

  return (
    <Container>
      <Title>Is there a problem?</Title>
      <Formik
        initialValues={{
          challengeId: currentChallengeId,
          challengeQuestion: currentQuestionNum,
          problem: '',
          otherProblem: '',
          file: {
            name: '',
          },
        }}
        validate={reportValidate}
        onSubmit={
          (values, { setSubmitting }) => {
            dispatch(reportChallenge(values));
            reportSent();
          }
        }
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <SelectProblemBox>
              {problemsItem.map((item) => (
                <StyledRadio
                  key={item.key}
                  name="problem"
                  value={item.problem}
                  hideIndicator
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                >
                  {item.problem}
                </StyledRadio>
              ))}
            </SelectProblemBox>
            {values.problem === 'Other' && (
              <>
                <TextArea
                  placeholder="Please describe a problem"
                  onChange={handleChange}
                  name="otherProblem"
                  autoFocus
                  error={errors.otherProblem !== undefined}
                />
                <InputErrorStyled errors={errors.otherProblem} />
                <AddReportFileLabel>
                  <AddReportFile
                    type="file"
                    name="imageFile"
                    accept=".jpeg, .jpg, .png"
                    onChange={(e: any) => setFieldValue('file', e.currentTarget.files[0])}
                  />
                  <AddReportFileIcon />
                  {values.file.name || 'Attach a file'}
                </AddReportFileLabel>
              </>
            )}
            <SendButton type="submit" disabled={values.problem === ''}>Send</SendButton>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ReportContentForModal;

const Container = styled.div`
  width: 750px;
  box-sizing: border-box;
  padding: 50px 90px 64px;
`;

const Form = styled.form``;

const Title = styled.h2`
  width: 100%;
  text-align: center;
  margin-bottom: 35px;
  font-size: 28px;
  line-height: 34px;
  font-weight: bold;
`;

const SelectProblemBox = styled.div`
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
`;

const StyledRadio = styled(Radio)`
margin: 0 8px 14px;
& span {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  box-sizing: border-box;
  padding: 12px 24px;
  border-width: 1px;
  }
`;

const TextArea = styled.textarea<{error?: boolean}>`
  width: 100%;
  height: 132px;
  border: ${({ error }) => (error ? '2px solid #F55F4B' : '1px solid #9C9C9C')};
  border-radius: 10px;
  margin: 16px 0 0px;
  box-sizing: border-box;
  padding: 16px;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  outline: none;
  ::placeholder {
    color: #9C9C9C;
  }
`;

const SendButton = styled(Button)`
  height: 70px;
  background-color: ${({ theme }) => theme.color};
  border-radius: 10px;

  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.hoverColor};
  }
`;

const AddReportFile = styled.input`
  display: none;
`;

const AddReportFileLabel = styled.label`
  margin: 11px 0 34px;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  cursor: pointer;
  width: fit-content;
`;

const AddReportFileIcon = styled(PaperclipIcon)`
  margin-right: 12px;
  & svg {
    fill: #9C9C9C;
  }
`;

const InputErrorStyled = styled(InputError)`
  padding-left: 0;
  
  & li {
    font-size: 14px;
    line-height: 18px;
  }
`;
