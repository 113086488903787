import React, { useState } from "react";
import styled from "styled-components/macro";
import { DropdownIcon } from "../images/iconSvg";

interface TeacherSelectDropdownProps {
  buttonText: string;
  dropdownContent: Array<{ name: string; _id: string }>;
  onDropdownItemClickHandler: (id: string) => void;
}

export default function TeacherSelectDropdown({
  buttonText,
  dropdownContent,
  onDropdownItemClickHandler,
}: TeacherSelectDropdownProps) {
  const [isDropdownShow, setIsDropdownShow] = useState(false);

  return (
    <Container>
      <Button
        isShow={isDropdownShow}
        onClick={() => setIsDropdownShow(!isDropdownShow)}
      >
        {buttonText}
        <DropdownIcon />
      </Button>
      {isDropdownShow && (
        <DropdownContent>
          {dropdownContent.map((item) => (
            <DropdownItem
              onClick={() => {
                setIsDropdownShow(false);
                onDropdownItemClickHandler(item._id);
              }}
            >
              {item.name}
            </DropdownItem>
          ))}
        </DropdownContent>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const Button = styled.button<{ isShow: boolean }>`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: #1f1f1f;
  display: flex;
  align-items: center;
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  svg {
    margin-left: 5px;
    transform: ${(props) => (props.isShow ? "rotate(180deg)" : "")};
  }
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 330px;
  border-radius: 4px;
  box-shadow: 0px 2px 8px #dedede;
  background: #fff;
`;

const DropdownItem = styled.div`
  font-size: 16px;
  line-height: 20px;
  padding: 13px 15px;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    background-color: #eeeaff;
  }
`;
