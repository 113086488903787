import React from 'react';
import styled from 'styled-components/macro';
import PieChart from '../Chart/PieChart/PieChart';

interface TopicCardProps {
  className?: string;
  title?: string
  selectedTopic?: boolean
  value: number
  widthChart: number
  heightChart: number
  onClick?: () => void
}

const TopicCard = ({
  className,
  title = 'Algebra',
  selectedTopic,
  value,
  heightChart,
  widthChart,
  onClick,
}: TopicCardProps) => {
  return (
    <Card className={className} selectedTopic={selectedTopic} onClick={onClick}>
      <Title>{title}</Title>
      <PieChart width={widthChart} height={heightChart} value={value} />
    </Card>
  );
};

export default TopicCard;

const Card = styled.div<{selectedTopic?: boolean}>`
  background-color: #FFF;
  box-shadow: 0px 2px 7px #F0F2F3;
  border-radius: 12px;
  box-sizing: border-box;
  //  padding resizing - border 3px;
  padding: 21px 33px 29px; 
  border: 3px solid ${({ selectedTopic }) => selectedTopic ? '#F87700' : 'transparent'};
  // min-width: 180px;
  width: fit-content;
  min-height: 216px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ${({ selectedTopic }) => !selectedTopic && (
    `:hover {
      border-color: #9077F5;
    }`
  )}
`;

const Title = styled.h6`
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
`;
