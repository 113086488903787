import React from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { GroupFields } from '../../project-types/groups/types';
import Elements from '../../Form';
import { EditIcon, TrashIcon } from '../../images/iconSvg';

const {
  Button,
  DefaultButton,
} = Elements;

export enum ControlsMode {
  ONLY_TRASH,
  EDIT_AND_DELETE
}

interface GroupBarProps {
  // groupInfo: GroupFields
  id: string,
  name: string,
  noteText: string,
  onRedirect: (id: string) => void,
  onEdit: (id: string) => void,
  onDelete: (id: string) => void,
  controlsMode: ControlsMode,
  activeAssigments: number
}

const GroupBar = ({
  id,
  name,
  noteText,
  onRedirect,
  onEdit,
  onDelete,
  controlsMode,
  activeAssigments
}: GroupBarProps) => {
  // const studentsLength = groupInfo.students.length;
  // const textStudentLength = studentsLength === 1 ? `${studentsLength} student` : `${studentsLength} students`;

  const redirectToGroupPage = () => {
    onRedirect(id);
    // push(`${process.env.PUBLIC_URL}/teacher/group/${groupInfo._id}`);
  };

  return (
    <GroupButton>
      <GroupBarInfo onClick={redirectToGroupPage}>
        <Title>{name}</Title>
        <StudentsLength>{noteText}</StudentsLength>
        <ActiveAssignments>
          <ActiveAssignmentsLength>{activeAssigments}</ActiveAssignmentsLength>
          {' '}
          active assignments
        </ActiveAssignments>
      </GroupBarInfo>
      <GroupBarOption>
        {controlsMode === ControlsMode.ONLY_TRASH && (
          <TrashButton onClick={(e) => { e.stopPropagation(); onDelete(id); }}>
            <TrashIconStyled />
          </TrashButton>
        )}

        {controlsMode === ControlsMode.EDIT_AND_DELETE && (
          <>
            <EditButton onClick={(e) => { e.stopPropagation(); onEdit(id); }}>
              <EditIconStyled />
              Edit
            </EditButton>
            <DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                onDelete(id);
              }}
            >
              Delete
            </DeleteButton>
          </>
        )}
      </GroupBarOption>
    </GroupButton>
  );
};

export default GroupBar;

const GroupButton = styled.div`
  background-color: #FFF;
  border-radius: 20px;
  padding: 23px 0px 20px;
  margin-bottom: 30px;
  box-shadow: 0px 2px 8px #F0F2F3;
  display: flex;
  transition: background-color .2s linear;
  width: 100%;
  text-align: left;

  :hover:not(:default) {
    background-color: #EEEAFF;
  }
`;

const GroupBarInfo = styled.div`
  padding: 0 38px;
  border-right: 2px solid #E0E0E0;
  flex-grow: 1;
  cursor: pointer;
`;

const GroupBarOption = styled.div`
  padding: 0 38px;
`;

const Title = styled.h3`
  margin-bottom: 2px;
  font-size: 22px;
  line-height: 22px;
  font-weight: bold;
`;

const StudentsLength = styled.span`
  margin-bottom: 17px;
  font-size: 12px;
  line-height: 22px;
  display: block;
`;

const ActiveAssignments = styled.span`
  font-size: 14px;
  line-height: 26px;
  display: block;
`;

const ActiveAssignmentsLength = styled.span`
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
`;

const EditButton = styled(Button)`
  height: auto;
  background-color: #9077F5;
  padding: 11px 25px;
  border-radius: 30px;
  color: #FFF;
  font-size: 18px;
  line-height: 22px;
  min-width: 140px;
  justify-content: flex-start;
`;

const EditIconStyled = styled(EditIcon)`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;

  svg {
    width: 10px;
    height: 10px;
    fill: #9077F5;
  }

  ${EditButton}:hover & svg {
    fill: #AA95FF;
  }
`;

const TrashButton = styled(DefaultButton)`
  text-align: center;
  margin-top: 24px;
  width: 100%;
  padding: 0 16px;
  font-size: 14px;
  line-height: 26px;
  font-weight: bold;
  color: #F55F4B;
`;

const TrashIconStyled = styled(TrashIcon)`
  svg {
    width: 26.67px;
    height: 33.33px;
    fill: #9077F5;
  }
`;

const DeleteButton = styled(DefaultButton)`
  text-align: center;
  margin-top: 13px;
  width: 100%;
  padding: 0 16px;
  font-size: 14px;
  line-height: 26px;
  font-weight: bold;
  color: #F55F4B;
`;
