import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';

const PolicyServiceSwitcher = () => {
  const { url } = useRouteMatch();

  return (
    <Wrapper>
      <LinkStyled
        to={`${process.env.PUBLIC_URL}/privacy-policy`}
        isSelected={url === '/privacy-policy'}
      >
        Privacy Policy
      </LinkStyled>
      <LinkStyled
        to={`${process.env.PUBLIC_URL}/terms-of-servise`}
        isSelected={url === '/terms-of-servise'}
      >
        Terms of Service
      </LinkStyled>
    </Wrapper>
  );
};

export default PolicyServiceSwitcher;

const Wrapper = styled.div`
  display: flex;
  height: 100px;
  margin-bottom: 98px;
`;

const LinkStyled = styled(Link)<{isSelected: boolean}>`
  display: block;
  padding: 33px;
  text-align: center;
  font-size: 34px;
  line-height: 34px;
  width: 50%;
  color: inherit;
  font-weight: bold;
  background-color: ${({ isSelected }) => (isSelected ? 'transparent' : '#EEEAFF')};

  :hover {
    color: inherit;
  }
`;
