import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components/macro';
import { ChalengePageType } from '../ExamTest/ExamCommonPage';
import FinishTest from '../ExamTest/FinishTest';
import { finishChallenge } from '../features/challenge/challengeState';
import { RouteType } from '../features/topic/types';
import Modal from '../Form/FieldsEntity/Modal';
import NotificationTime from './NotificationTime';
import ExamChallengeBody from './StrictChallengeBody';
import StrictChallengeLayout from './StrictChallengeLayout';

interface ExamStrictPageProps extends ChalengePageType {

}

const ExamStrictPage = ({
  currentChallenge,
  currentQuestionNum,
  onChangeQuestion,
  currentQuestion,
  numOfQuestions,
  onAnswer,
  onSkip,
  isFinished,
  setFinishTest,
  challengeType,
  finisChalenge,
  extendChalenge,
}: ExamStrictPageProps) => {
  const { push } = useHistory();
  const { finish } = useParams<RouteType>();

  const currentSubjectName = currentChallenge.topicId?.[0];

  const questionsAnswered = numOfQuestions === currentQuestionNum;

  const [answer, setAnswer] = useState<string | undefined>(currentQuestion?.userAnswer);

  const isSelectedAnswer = answer !== undefined;

  const dispatch = useDispatch();

  useEffect(() => {
    setAnswer(currentQuestion?.userAnswer);
  }, [currentQuestion]);

  const onBack = () => {
    onChangeQuestion(currentQuestionNum - 1);
  };

  const onNext = () => {
    onChangeQuestion(currentQuestionNum + 1);
  };

  const kickToFinishPage = () => {
    push(`${process.env.PUBLIC_URL}/challenge/${challengeType}/current/${currentQuestionNum}/quit-exam/${currentChallenge._id}`);
  };

  const onChangeAnswer = (answer: string | undefined) => {
    if (answer) {
      setAnswer(answer);
      onAnswer(answer, false);
    }
  };

  const quitExam = () => {
    dispatch(finishChallenge(currentChallenge._id, challengeType));
      // window.location.href = `${process.env.PUBLIC_URL}/chalenge/detailed-result/${challengeType}/${currentChallenge._id}`;
    // }
  };

  return (
    <StrictChallengeLayout
      currentChallenge={currentChallenge}
      onTimeOut={kickToFinishPage}
    >
      {finish !== 'quit-exam' && (
        <NotificationTime currentChallenge={currentChallenge} onTimeOut={kickToFinishPage} />
      )}
      <ExamChallengeBody
        onBack={onBack}
        onNext={onNext}
        subjectName={currentSubjectName}
        currentQuestion={currentQuestion}
        setSelectedAnswer={onChangeAnswer}
        selectedAnswer={answer}
        questionAnswered={questionsAnswered}
        onFinishChallenge={kickToFinishPage}
        onEndExam={quitExam}
        isSelectedAnswer={isSelectedAnswer}
      />
    </StrictChallengeLayout>
  );
};

export default ExamStrictPage;

const Board = styled.div`
  border: 10px solid #50ac50;
  border-radius: 4px;
  padding: 20px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 50px auto 0;
`;
