import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import CurrentPlan from './CurrentPlan';
import BillingHistory from './BillingHistory';
import ProfilePage from '../ProfilePage';
import { SIDEBAR_MENU } from '../../Form/FieldsEntity/Sidebar';
import { getUserProfile } from '../../features/user/userState';

const ProfileSubscription = () => {
  const userProfile = useSelector(getUserProfile);

  return (
    <ProfilePage selectedMenuItem={SIDEBAR_MENU.SUBSCRIPTION}>
      <Content>
        <CurrentPlan user={userProfile} />
        <BillingHistory user={userProfile} />
      </Content>
    </ProfilePage>
  );
};

const Content = styled.div`
  box-sizing: border-box;
  /* background-color: #FFF; */
  flex-grow: 1;
  max-width: 780px;
  margin-left: 97px;
  margin-right: 150px;
  flex-grow: 1;
  overflow: hidden;
  @media (max-width: 1400px) {
    margin-right: 50px;
  }
`;

export default ProfileSubscription;
