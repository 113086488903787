import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Autosuggest from 'react-autosuggest';
import { request } from '../../api';

export interface SchoolsListTypes {
  _id: string;
  name: string;
  rate: number;
}
interface InputSelectProps {
  className?: string;
  targetSchool?: string;
  clearOnSelect?: boolean;
  onChangeSchool: (target: string, school?: SchoolsListTypes) => void;
  disabled?: boolean;
  error?: boolean;
  name?: string;
}

const InputSelect = ({
  className,
  targetSchool,
  onChangeSchool,
  clearOnSelect,
  disabled,
  error,
  name
}: InputSelectProps) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState<SchoolsListTypes[]>([]);

  useEffect(() => {
    if (targetSchool !== undefined) {
      setValue(targetSchool);
    }
  }, [targetSchool]);

  const getSuggestionValue = (suggestion: any) => suggestion.name;

  const renderSuggestion = (suggestion: any) => <div>{suggestion.name}</div>;

  const onChange = (event: any, { newValue }: any) => {
    setValue(newValue);
    if (newValue === "") {
      onChangeSchool("");
    }
  };

  const onSuggestionsFetchRequested = ({ value }: any) => {
    request(
      {
        method: "GET",
        url: `schools/0/5/${value}`
      },
      [200, 403]
    )
      .then((e) => {
        if (e.data) {
          setSuggestions(e.data);
        }
      })
      .catch((error) => {
        setSuggestions([]);
      });
  };

  const renderInputComponent = (inputProps: any) => (
    <div>
      <input {...inputProps} name={name} disabled={disabled} />
      <div />
    </div>
  );

  const onSuggestionSelected = (
    event: any,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }: any
  ) => {
    if (method === "enter") {
      event.preventDefault();
    }

    onChangeSchool(suggestion._id, suggestion);
    if (clearOnSelect) {
      setValue('');
    }
  };

  const inputProps = {
    placeholder: "Enter school",
    value,
    onChange
  };

  return (
    <Wrapper
      className={className}
      restyle={suggestions.length === 0}
      error={error}
    >
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
        onSuggestionSelected={onSuggestionSelected}
        id="input-select-school"
        onSuggestionsClearRequested={() => {
          setSuggestions([]);
        }}
      />
    </Wrapper>
  );
};

export default InputSelect;

const Wrapper = styled.div<{ restyle?: boolean; error?: boolean }>`
  width: 100%;
  position: static;

  & .react-autosuggest__container {
    position: relative;
    .react-autosuggest__input {
      width: 100%;
      height: 56px;
      border-radius: ${({ restyle }) => (restyle ? "10px" : "10px 10px 0 0")};
      border: 1px solid ${({ error }) => (error ? "#F55F4B" : "#C4C4C4")};
      font-size: 16px;
      line-height: 22px;
      font-weight: normal;
      box-sizing: border-box;
      padding: 17px 25px 17px 15px;
      position: relative;
      z-index: 20;

      ::after {
        content: "";
        display: block;
        width: 4px;
        height: 4px;
        border: 1px solid;
        border-color: transparent transparent #1f1f1f #1f1f1f;
        position: absolute;
      }

      :focus {
        outline: none;
        border: 2px solid ${({ error }) => (error ? "#F55F4B" : "#9077F5")};
        padding: 16px 24px 16px 14px;
      }
    }

    .react-autosuggest__suggestions-container--open {
      position: absolute;
      top: 56px;
      left: 0;
      right: 0;
      background-color: #fff;
      // padding-top: 36px;
      border: 1px solid #c4c4c4;
      border-radius: 0 0 10px 10px;
      z-index: 25;
      box-shadow: 0px 6px 10px -2px #d7d7d7;
      overflow: hidden;

      .react-autosuggest__suggestions-list {
        list-style: none;
        padding: 0;
        margin: 0;

        .react-autosuggest__suggestion {
          box-sizing: border-box;
          padding: 17px 15px;
          cursor: pointer;
          :not(:last-child) {
            border-bottom: 1px solid #c4c4c4;
          }
        }

        .react-autosuggest__suggestion--highlighted {
          background-color: #eeeaff;
        }
      }
    }
  }
`;
