import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import FormElements from '../../Form';
import { InputError } from '../../Form/FieldsEntity/FormInput';
import LabelForInput from '../../Form/FieldsEntity/LabelForInput';

const {
  Input,
} = FormElements;

interface DateOfBirthProps {
  dateValue?: string,
  className?: string
  disabled?: boolean,
  onChange: (date: string) => void,
  errors?: any
}

const ChangeDate = ({
  dateValue,
  className,
  disabled,
  onChange,
  errors,
}: DateOfBirthProps) => {
  const [date, setDate] = useState<Date>(dateValue ? new Date(dateValue) : new Date());


  useEffect(() => {
    onChange(`${date}`);
  }, [date])

  const day = date.getDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getFullYear();


  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);
  


  const onChangeDay = (val: string) => {
    const day = parseInt(val, 10);
    if (day) {
      const newDate = new Date(date);
      newDate.setDate(day);
      setDate(newDate);
    }
  }

  const onChangeMonth = (val: string) => {
    const month = parseInt(val, 10);
    if (month) {
      const newDate = new Date(date);
      newDate.setMonth(month - 1);
      setDate(newDate);
    }
  }

  const onChangeYear = (val: string) => {
    const year = parseInt(val, 10);
    if (year) {
      const newDate = new Date(date);
      newDate.setFullYear(year);
      setDate(newDate);
    }
  };

  return (
    <DataInputs className={className}>
      <Container>
        <DayLabel name="Day">
          <StyledInput
            //onClick={() => dayRef.current && dayRef.current.select()}
            type="number"
            ref={dayRef}
            defaultValue={day}
            min={1}
            max={31}
            placeholder="1"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeDay(e.currentTarget.value)}
            disabled={disabled}
            error={errors}
          />
        </DayLabel>
        <MonthLabel name="Month">
          <StyledInput
            ref={monthRef}
            defaultValue={month}
            type="number"
            min={1}
            max={12}
            placeholder="1"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeMonth(e.currentTarget.value)}
            disabled={disabled}
            error={errors}
          />
        </MonthLabel>
        <YearLabel name="Year">
          <StyledInput
            ref={yearRef}
            defaultValue={year}
            type="number"
            placeholder="2000"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeYear(e.currentTarget.value)}
            disabled={disabled}
            error={errors}
          />
        </YearLabel>
      </Container>
      <InputError errors={errors} />
    </DataInputs>
  );
};

export default ChangeDate;

const DataInputs = styled.div`

`;

const Container = styled.div`
  display: flex;
`;

const StyledInput = styled(Input)`
  height: 56px;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  :disabled {
    background-color: #F6F8F9;
    color: #C9C9C9;
  }

  :focus {
    border: 2px solid ${({ error }) => error ? '#F55F4B' : '#9077F5'};
    padding: 16px 14px;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
`;

const DayLabel = styled(LabelForInput)`
  width: 81px;
  margin-right: 14px;
`;
const MonthLabel = styled(LabelForInput)`
  width: 81px;
  margin-right: 14px;
`;
const YearLabel = styled(LabelForInput)`
  width: 104px;
`;
