import React, { useState, useEffect } from 'react';
import { start } from 'repl';
import styled from 'styled-components/macro';
import { timeDiff } from '../../ExamTest/utils';
import { ChallengeType } from '../../features/challenge/types';
import {
  ExtraTimeIcon,
} from '../../images/iconSvg';



interface TimerTypes {
  chalenge: ChallengeType
  onTimeOut: () => void
  className?: string
}

const Timer = ({ chalenge, onTimeOut, className }: TimerTypes) => {
  const [timeCount, setTimeCount] = useState(100);

  useEffect(() => {
    if (timeCount <= 0 && chalenge.challengeType === 'exam') {
      if (chalenge._id) {
        onTimeOut();
      }
      setTimeCount(0);
      return;
    }


    let t: number;    
    if (chalenge.isActive) {
      t = setTimeout(
        () => {
          const n = chalenge.challengeType === 'test' ? 1 : -1;
          setTimeCount(timeCount + n)
        },
        1000, // 60000 m.sec = 60 sec = 1 min
      );
    }
    return () => clearTimeout(t)
  }, [timeCount, setTimeCount]);

  useEffect(() => {
    let timeout;
    if (chalenge._id) {
      if (chalenge.challengeType === 'test') {
        const endTime = chalenge.isActive ? new Date() : new Date(chalenge.completedTime);
        const diff = timeDiff(new Date(chalenge.challengeStartTime), endTime);
        setTimeCount(diff);
      } else {
        const endTime = chalenge.challengeFinishTime;
        const startTime = chalenge.isActive ? new Date() : new Date(chalenge.completedTime);
        const diff = timeDiff(startTime, new Date(endTime));
        setTimeCount(diff);
      }
    }
  }, [chalenge])

  const minutes = Math.floor(timeCount / 60);
  const seconds = Math.floor(timeCount % 60);

  console.log('timer==', { timeCount, minutes, seconds });

  return (
    <QuestTimer time={timeCount} hasRadMode={chalenge.challengeType === 'exam'} className={className}>
      {chalenge?._id && <>
      {chalenge.extended  && (
        <ExtraTimeIconStyled />
      )}
      {minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </>}
    </QuestTimer>
  );
};

export default Timer;

const QuestTimer = styled.div<{time: number, hasRadMode: boolean}>`
  width: 100px;
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  align-self: baseline;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  color: ${(props) => (props.time < 6 && props.hasRadMode ? '#F55F4B' : 'inherit')};
`;

const ExtraTimeIconStyled = styled(ExtraTimeIcon)`
  margin-right: 4px;
`;
