import React from 'react';
import styled from 'styled-components/macro';
import fill from 'lodash/fill';
import { useParams } from 'react-router-dom';
import { QuestionType } from '../features/challenge/types';
import ItemNumber from '../Form/FieldsEntity/ItemNumber';
import { QUESTION_NUMBER_STATUSES } from '../Form/FieldsEntity/ItemNumber';

interface ExamQuestionListProps {
  questions: Array<QuestionType>
  onChangeQuestion: (questionNum: number) => void
  numOfQuestion: number
  currentQuestionNum: number
  finishTest?: boolean
  showAnswerMode?: boolean
  className?: string
}

const ExamQuestionList = ({
  questions,
  numOfQuestion,
  currentQuestionNum,
  onChangeQuestion,
  finishTest,
  showAnswerMode,
  className,
}: ExamQuestionListProps) => {

  const empyQuestions = fill(Array(numOfQuestion - questions.length), {});

  return (
    <NumbersOfQuestionsBox className={className}>
      {questions.map((question, n) => {
        let questionType;
        if (currentQuestionNum === n + 1 && !finishTest) {
          questionType = QUESTION_NUMBER_STATUSES.SELECT;
        } else if (showAnswerMode  && question.userAnswer) {
          questionType = question.right ? QUESTION_NUMBER_STATUSES.CORRECT : QUESTION_NUMBER_STATUSES.INCORRECT;
        } else if (question.userAnswer) {
          questionType = QUESTION_NUMBER_STATUSES.ANSWERED;
        } else {
          questionType = QUESTION_NUMBER_STATUSES.SKIPPED;
        }
        
        return (
          <Link onClick={() => onChangeQuestion(n + 1)} key={n} hidePointer={finishTest}>
            <ItemNumberStyled
              key={question._id}
              itemNumber={n + 1}
              itemStatus={questionType}
            />
          </Link>
        );
      })}
      {empyQuestions.map((_, n) => (
        <ItemNumberStyled
          key={n}
          itemNumber={questions.length + n + 1}
          itemStatus={QUESTION_NUMBER_STATUSES.EMPTY}
        />
      ))}
    </NumbersOfQuestionsBox>
  )
};

export default ExamQuestionList;


const NumbersOfQuestionsBox = styled.div`
  display: flex;
  max-width: 423px;
  flex-wrap: wrap;
  margin: 0 -3px;
`;

const ItemNumberStyled = styled(ItemNumber)`
  margin: 0 3px 4px;
  color: ${({ theme, itemStatus }) => itemStatus === QUESTION_NUMBER_STATUSES.SELECT && theme.color};
  border-color: ${({ theme, itemStatus }) => itemStatus === QUESTION_NUMBER_STATUSES.SELECT && theme.color};
`;

const Link = styled.a<{hidePointer?: boolean}>`
  cursor: ${({hidePointer}) => hidePointer ? 'default' : 'pointer'}
`;
