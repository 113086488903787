import React, { ReactChild, ReactElement, useState } from 'react';
import styled from 'styled-components/macro';

interface AccordionProps {
  children: ReactChild
  title: ReactElement
  className?: string
  openColor?: string
  closedColor?: string
}

const Accordion = ({
  children,
  title,
  className,
  openColor = '#FFF',
  closedColor = '#FFF',
}: AccordionProps) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <Item className={className} closedColor={closedColor} openColor={openColor} show={show}>
      <TitleWrapper>
        {title}
        <ShowButton onClick={() => setShow(!show)} buttonAction={show} />
      </TitleWrapper>
      <Content show={show}>
        {show && children}
      </Content>
    </Item>
  );
};

export default Accordion;

const Item = styled.li<{closedColor: string, openColor: string, show: boolean}>`
  background-color: ${({ show, openColor, closedColor }) => (show ? openColor : closedColor)};
  box-sizing: border-box;
  padding: 35px;
  border-radius: 20px;
  :not(:last-child) {
    margin-bottom: 18px;
  }
  transition: .3s ease-in-out;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ShowButton = styled.button<{buttonAction?: boolean}>`
  background-color: #F87700;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 50%;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  position: relative;
  transform: ${({ buttonAction }) => (buttonAction ? 'rotate(0deg)' : 'rotate(90deg)')};
  transition: transform .3s ease-in-out;
  /* align-self: baseline; */
  ::before {
    content: '';
    display: block;
    width: 50%;
    height: 10%;
    border-radius: 1em;
    background-color: #FFF;
    position: absolute;
    opacity: ${({ buttonAction }) => (buttonAction ? 0 : 1)};
    transition: opacity .3s ease-in-out;
  }
  ::after {
    content: '';
    display: block;
    width: 50%;
    height: 10%;
    border-radius: 1em;
    background-color: #FFF;
    position: absolute;
    transform: ${({ buttonAction }) => (buttonAction ? 'rotate(0deg)' : 'rotate(90deg)')};
    transition: transform .3s ease-in-out;
  }
`;

const Content = styled.div<{show: boolean}>`
  transform: ${({ show }) => (show ? 'translateY(0)' : 'translateY(-10px)')};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: transform .6s ease-in-out, opacity .3s ease-in-out;
  transition-delay: 0, .3s;
`;
