import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface SectionWrapperProps {
  title: string
  children: ReactNode
}

const SectionWrapper = ({
  children,
  title,
}: SectionWrapperProps) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {children}
    </Wrapper>
  );
};

export default SectionWrapper;

const Wrapper = styled.div`
  margin-bottom: 120px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 25px;
  line-height: 26px;
  margin-bottom: 46px;
  text-transform: uppercase;
`;
