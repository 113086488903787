import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { timeDiff } from '../ExamTest/utils';
import { ChallengeType } from '../features/challenge/types';
import Notification from '../Form/FieldsEntity/Notification';

interface NotificationTimeProps {
  currentChallenge: ChallengeType;
  onTimeOut: () => void
}

const NotificationTime = ({
  currentChallenge,
  onTimeOut,
}: NotificationTimeProps) => {
  const [timeCount, setTimeCount] = useState(700);

  useEffect(() => {
    if (timeCount <= 0 && currentChallenge.challengeType === 'exam') {
      if (currentChallenge._id) {
        onTimeOut();
      }
      setTimeCount(0);
      return;
    }


    let t: number;
    if (currentChallenge.isActive) {
      t = setTimeout(
        () => {
          const n = currentChallenge.challengeType === 'test' ? 1 : -1;
          setTimeCount(timeCount + n);
        },
        1000, // 60000 m.sec = 60 sec = 1 min
      );
    }
    return () => clearTimeout(t);
  }, [timeCount, setTimeCount]);

  useEffect(() => {
    let timeout;
    if (currentChallenge._id) {
      if (currentChallenge.challengeType === 'test') {
        const endTime = currentChallenge.isActive ? new Date() : new Date(currentChallenge.completedTime);
        const diff = timeDiff(new Date(currentChallenge.challengeStartTime), endTime);
        setTimeCount(diff);
      } else {
        const endTime = currentChallenge.challengeFinishTime;
        const startTime = currentChallenge.isActive ? new Date() : new Date(currentChallenge.completedTime);
        const diff = timeDiff(startTime, new Date(endTime));
        setTimeCount(diff);
      }
    }
  }, [currentChallenge]);

  // const endDate: any = new Date(currentChallenge.challengeFinishTime);
  // const startDate: any = new Date(currentChallenge.challengeStartTime);

  // const timeToEnd = (endDate - startDate) / 1000;

  const minutesLeft = Math.floor(timeCount / 60);

  // console.log('time-count', timeCount, minutesLeft);

  if (minutesLeft <= 9 && minutesLeft > 5) {
    return <Notification>{minutesLeft + 1} minutes left</Notification>;
  }

  if (minutesLeft <= 4 && minutesLeft > 1) {
    return <YellowNotification>{minutesLeft + 1} minutes left</YellowNotification>;
  }

  if (minutesLeft <= 0) {
    return <RedNotification>{minutesLeft + 1} minutes left</RedNotification>;
  }

  return null;
};

export default NotificationTime;

const YellowNotification = styled(Notification)`
  background-color: #ebeb51;
`;

const RedNotification = styled(Notification)`
  background-color: #e95151;
`;
