import { fill } from 'lodash';
import React, { ReactNode, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components/macro';
import { ChallengeType } from '../features/challenge/types';
import { RouteType } from '../features/topic/types';
import Modal from '../Form/FieldsEntity/Modal';
import Timer from '../Form/FieldsEntity/Timer';
import ModalQuitExam from './ModalQuitExam';

const logoOtter = require('../images/whiteLogo.svg');

interface StrictChallengeLayoutProps {
  children: ReactNode
  currentChallenge: ChallengeType
  onTimeOut: () => void
}

const StrictChallengeLayout = ({
  children,
  currentChallenge,
  onTimeOut,
}: StrictChallengeLayoutProps) => {
  const { finish, challengeType, currentQuestionNum } = useParams<RouteType>();
  const { push } = useHistory();

  const {
    questionsNumber = 0,
    questions,
  } = currentChallenge;

  const questionsLeft = questionsNumber - questions.length;

  const emptyQuestions = fill(Array(questionsLeft), {});

  const onQuit = () => {
    push(`${process.env.PUBLIC_URL}/challenge/${challengeType}/current/${currentQuestionNum}/finish`)
  };

  const isEndExam = finish === 'quit-exam';

  return (
    <ChallengeLayout>
      {finish === 'finish' && (
        <ModalQuitExam
          questionsNumber={questionsNumber}
          questionsPassed={questions.length}
          challengeId={currentChallenge._id}
        />
      )}
      <Header>
        <Logo src={logoOtter} />
        {!isEndExam && (
          <RightEllements>
            <TimerStyled
              chalenge={currentChallenge}
              onTimeOut={onTimeOut}
            />
            <StrictButton onClick={onQuit}>Quit exam</StrictButton>
          </RightEllements>
        )}
      </Header>
      <Content>
        {children}
      </Content>
      <Footer>
        {!isEndExam && (
          <>
            <ProgressQuestions>
              <>
                {questions.map((item, n) => (
                  <QuestionsPassed key={n} />
                ))}
                {emptyQuestions.map((_, n) => (
                  <QuestionsLeft />
                ))}
              </>
            </ProgressQuestions>
            <NumQuestionsPassed>
              {questions.length}
              /
              {questionsNumber}
            </NumQuestionsPassed>
          </>
        )}
      </Footer>
    </ChallengeLayout>
  );
};

export default StrictChallengeLayout;

const ChallengeLayout = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  height: 80px;
  padding: 0 20px;
  background-color: #04CB9A;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const RightEllements = styled.div`
  /* margin-left: auto; */
  display: flex;
  align-items: center;
`;

const Content = styled.main`
  flex-grow: 1;
  padding: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const Footer = styled(Header)`
  height: auto;
  min-height: 80px;
  justify-content: center;
`;

const Logo = styled.img`
  width: 140px;
  margin: 0 auto 0 calc(50% - 70px);
`;

export const StrictButton = styled.button`
  background-color: #DFFAF4;
  border: 2px solid #1F1F1F;
  border-radius: 10px;
  padding: 4px 24px;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  /* margin-left: 38px; */
  cursor: pointer;

  :hover {
    background-color: #FFF;
  }

  :focus {
    outline: none;
  }
`;

const TimerStyled = styled(Timer)`
  line-height: inherit;
`;

const ProgressQuestions = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const QuestionsPassed = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #97E346;
  :not(:last-child) {
    margin-right: 5px;
  }
`;

const QuestionsLeft = styled(QuestionsPassed)`
  background-color: #FFF;
`;

const NumQuestionsPassed = styled.span`
  font-size: 22px;
  line-height: 26px;
  display: block;
  margin-left: 38px;
  font-weight: bold;
`;
