import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { fr } from './frenchTranslate';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      fr,
    },
    fallbackLng: 'en',
    debug: false,
    // whitelist: ['en', 'zh', 'es'], 
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
