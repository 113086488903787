import React, { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { subjectsStyles } from '../config/subjectsStyles';
import { getUserProfile, loadProfile } from '../features/user/userState';
import Logo from '../Form/FieldsEntity/Logo';

interface ExamTestTypes {
  children?: ReactNode
  className?: string
  hideLogo?: boolean
  isSubject?: string
}

const ExamTest = ({
  children, className, hideLogo, isSubject,
}: ExamTestTypes) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadProfile());
  }, []);

  const styles = subjectsStyles[isSubject || ''];

  return (
    <Page
      className={className}
      backgroundColor={styles?.backgroundChallenge.color}
      opacity={styles?.backgroundChallenge.opacity}
      backgroundImg={styles?.backgroundChallenge.img}
    >
      <LogoWrapper>
        {!hideLogo && (
          <Logo />
        )}
      </LogoWrapper>
      <Container
        shadowColor={styles?.shadowColor}
      >
        {children}
      </Container>
    </Page>
  );
};

export default ExamTest;

const Page = styled.div<{
  backgroundColor?: string,
  opacity?: number,
  backgroundImg?: string
}>`
  margin: 0 auto;
  /* max-width: 1440px; */
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  box-sizing: border-box;
  padding: 0 42px 42px;
  position: relative;
  ${({ backgroundColor, opacity, backgroundImg }) => `
    background-color: ${backgroundColor};
    ::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url(${backgroundImg}) repeat;
      opacity: ${opacity};
    }
  `}
`;

const LogoWrapper = styled.div`
  /* height: 42px; */
  padding: 17px 0;
  display: flex;
  justify-content: center;
  z-index: 10;
`;

const Container = styled.div<{shadowColor?: string}>`
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0px 2px 8px ${({ shadowColor }) => shadowColor || '#F0F2F3'};
  align-self: baseline;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  z-index: 10;
`;
