import { message } from 'antd';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { request } from '../api';
import CardContainer from './CardContainer';

const letterImage = require('../images/letter.svg');

const ConfirmEmailPage = () => {
  const { userId, secureCode } = useParams<{ userId: string, secureCode: string }>();

  const history = useHistory();

  useEffect(() => {
    request({
      method: 'POST',
      url: 'confirm-email',
      data: {
        userId, secureCode
      },
    }).then(({ data }) => {
      debugger;
      if (data.userType === 'teacher') {
        history.push('/teacher/join');
      } else {
        history.push('/login');
      }
    })
      .catch(() => {
        message.error('Confirmation error');
    });
  }, [userId, secureCode]);
  return (
    <div></div>
  )
};

export default ConfirmEmailPage;
