import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled, { keyframes } from 'styled-components/macro';
import { finishChallenge } from '../features/challenge/challengeState';
import { useCurrentChalenge } from './ChalengeHooks';
import Page from './PageForExam';



interface ExamResultProps {
  score: number;
  presetTime: number;
  elapsedTime: number;
  extraTime: boolean;
  evaluation: string;
}

// examScore 0 - 100, 
// examEvaluation excellent, 'not-bad' and default value is bad evaluation
/*
export const examResult: ExamResultProps = {
  score: 80,
  presetTime: 30,
  elapsedTime: 28,
  extraTime: false,
  evaluation: 'excellent',
};
*/

const otterLikeResult = require('../images/otter-correct-answer.svg');

const otterUnlikeResult = require('../images/otter-incorrect-answer.svg');

export const evaluationResult = (evaluation: number) => {
    if (evaluation > 71)
      return '#95D354';
    else if (evaluation > 51)
      return '#F87700';
    else
      return '#F55F4B';
};

const renderDescriptionByEvaluation = (evaluation: number) => {
    if (evaluation > 71)
      return 'Excellent!';
    else if (evaluation > 51)
      return 'Not bad. But you can do even better! Repeat the topics and try again.';
    else
      return 'That’s not your best - keep trying. But don’t worry! Repeat the topics and try again.';
};

interface ResultTestProps {

}

const ResultTest = ({

}: ResultTestProps) => {
  const { push } = useHistory();
  const { challengeId, challengeType } = useParams<{ challengeId: string, challengeType: string }>();

  const dispatch = useDispatch();
  const currentChalenge = useCurrentChalenge(challengeId);

  useEffect(() => {
    if (currentChalenge.isActive) {
      dispatch(finishChallenge(challengeId, challengeType));
    }
  }, [currentChalenge])

      // dispatch(finishChallenge());


  setTimeout(
    () => { window.location.href = `${process.env.PUBLIC_URL}/chalenge/detailed-result/${currentChalenge.challengeType}/${challengeId}` },
    1000,
  );

  return (
    <PageStyled>
      <Wrapper backgroundOtter={currentChalenge.rate > 71 ? otterLikeResult : otterUnlikeResult}>
        <Title>Your score</Title>
        <Result textColor={evaluationResult(currentChalenge.rate)}>
          {currentChalenge.rate}
          %
        </Result>
        <Description>
          {renderDescriptionByEvaluation(currentChalenge.rate)}
        </Description>
      </Wrapper>
    </PageStyled>
  );
};

export default ResultTest;

const HideResult = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  95% {
    opacity: 0;
    transform: translateY(0);
  }
  100% {
    display: none;
  }
`;

const PageStyled = styled(Page)`
  // transform: translateY(-100%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: #F6F8F9;
  // animation: ${HideResult} 5s linear;
  // opacity: 0;
`;

const Wrapper = styled.div<{backgroundOtter?: any}>`
  background-color: #FFF;
  max-width: 810px;
  width: 100vw;
  min-height: 468px;
  box-sizing: border-box;
  padding: 95px;
  background: url(${(props) => props.backgroundOtter}) no-repeat;
  background-position: center right 54px;
  background-size: auto 90%;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  margin-bottom: 11px;
`;

const Result = styled.span<{textColor: string}>`
  display: block;
  font-weight: bold;
  font-size: 64px;
  line-height: 56px;
  color: ${(props) => props.textColor};
  margin-bottom: 56px;
`;

const Description = styled.p`
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  max-width: 350px;
`;
