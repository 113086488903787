import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';
import Logo from '../../Form/FieldsEntity/Logo';
import FormElements from '../../Form';
import { getUserProfile, loadProfile } from '../../features/user/userState';
import UseLocalization from '../../Localization/UseLocalization';
import MainNavigation from './MainNavigation';
import DropdownMobileMenu from './DropdownMobileMenu';
import UserFrame from './UserFrame/UserFrame';

const {
  LinkButton,
} = FormElements;

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadProfile());
  }, []);

  const userProfile = useSelector(getUserProfile);

  const isRegistreduser = userProfile._id;

  const currentInterfaceType = userProfile.userType === 'teacher' ? 'teacher' : 'student';

  return (
    <Wrapper>
      <Container>
        <LogoStyled />
        <MainNavigation isLogged={!!isRegistreduser} currentInterfaceType={currentInterfaceType} />
        {isRegistreduser ? (
          <UserFrame
            userProfile={userProfile}
          />
        ) : (
          <>
            <ButtonsWrapper>
              <LinkButton to="/login" background={false}>
                <UseLocalization translateKey="action.logIn">
                  Log In
                </UseLocalization>
              </LinkButton>
              <LinkButton to="/registration/student" background>
                <UseLocalization translateKey="action.signUp">
                  Sign Up
                </UseLocalization>
              </LinkButton>
            </ButtonsWrapper>
          </>
        )}
        <MobileMenuIcon onClick={() => setShowMobileMenu(!showMobileMenu)} restyled={showMobileMenu}>
          <TopBread />
          <Meat />
          <BottomBread />
        </MobileMenuIcon>
      </Container>
      {showMobileMenu && (
        <DropdownMobileMenu />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #FFF;
  box-sizing: border-box;
  border-bottom: 1px solid #F2F2F2;
  position: sticky;
  top: 0;
  z-index: 1000;
  `;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  height: 90px;
  box-sizing: border-box;
  padding: 0 130px 0 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1400px) {
    padding: 0 50px;
  }

  @media (max-width: 986px) {
    padding: 14px 16px;
    height: auto;
  }
`;

const LogoStyled = styled(Logo)`
  @media (max-width: 986px) {
    width: 115px;
    height: 38px;
    background-size: contain;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;

  @media (max-width: 986px) {
    display: none;
  }
`;


const TopBread = styled.div`
  width: 28px;
  height: 3px;
  background-color: #1F1F1F;
  border-radius: 1px;
  transition: .1s linear;
`;

const Meat = styled.div`
width: 28px;
  height: 3px;
  background-color: #1F1F1F;
  border-radius: 1px;
`;

const BottomBread = styled.div`
width: 28px;
  height: 3px;
  background-color: #1F1F1F;
  border-radius: 1px;
  transition: .1s linear;
`;

const MobileMenuIcon = styled.div<{restyled?: boolean}>`
  display: none;
  flex-shrink: 0;
  align-self: center;
  width: 28px;
  height: 21px;
  flex-direction: column;
  justify-content: ${({ restyled }) => (restyled ? 'center' : 'space-between')};

  @media (max-width: 986px) {
    display: flex;
  }

  & ${Meat} {
    display: ${({ restyled }) => (restyled ? 'none' : 'block')};
  }

  & ${TopBread} {
    transform: ${({ restyled }) => restyled && 'translateY(1.5px) rotate(45deg)'};
    position: relative;
    background-color: ${({ restyled }) => (restyled ? '#9C9C9C' : '#1F1F1F')}
  }

  & ${BottomBread} {
    transform: ${({ restyled }) => restyled && 'translateY(-1.5px) rotate(-45deg)'};
    position: relative;
    background-color: ${({ restyled }) => (restyled ? '#9C9C9C' : '#1F1F1F')}
  }
`;

export default Header;
