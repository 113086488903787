import React from 'react';
import { Breadcrumb } from 'antd';

import { useLocation, Link } from 'react-router-dom'

import { ROUTES } from './config';

function Links() {
  const location = useLocation();

  const currentRoute = ROUTES.find(route => {
    return location.pathname.includes(route.link);
  }) || { title: '' }

  return (
    <Breadcrumb style={{ margin: '16px 0' }}>
      <Breadcrumb.Item><Link to={`${process.env.PUBLIC_URL}/`}>Home</Link></Breadcrumb.Item>
      <Breadcrumb.Item><Link to={`${process.env.PUBLIC_URL}/users`}>{currentRoute.title}</Link></Breadcrumb.Item>
    </Breadcrumb>
  )
};

export default Links;