import React from 'react';
import styled from 'styled-components/macro';

const AddPayments = () => {
  return (
    <Container>

    </Container>
  );
};

export default AddPayments;

const Container = styled.div`
  width: 760px;
  min-height: 400px;
`;
