import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getCurrentChallenge, getChallengeById, selectCurrentChallenge,
} from '../features/challenge/challengeState';
import { ChallengeType } from '../features/challenge/types';

export const useCurrentChalenge = (challengeId?: string) => {
  const dispatch = useDispatch();
  const currentChallenge = useSelector(selectCurrentChallenge);

  useEffect(() => {
    // TODO check this
    if (!currentChallenge.topicId || (challengeId && currentChallenge._id !== challengeId)) {
      if (!challengeId) {
        dispatch(getCurrentChallenge());
      } else {
        dispatch(getChallengeById(challengeId));
      }
    }
  }, [currentChallenge, challengeId]);

  return currentChallenge;
};

export const useCurrentQuestion = (
  currentChallenge: ChallengeType,
  currentQuestionNum: number,
) => currentChallenge?.questions[currentQuestionNum - 1];

export const useChangeQuestion = (genUrl: (questionNum: number) => string) => {
  const history = useHistory();
  const onChangeQuestion = (questionNum: number) => {
    history.push(genUrl(questionNum));
  };

  return onChangeQuestion;
};
