import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';
import Elements from '../Form';

const {
  Button,
} = Elements;

const lockImage = require('../images/lockImage.svg');
const copyImage = require('../images/copyImage.svg');

interface ComponentProps {
  onClickDone: () => void
  onCopyCode: () => void
  confModal: {
    title: string
    description: ReactElement
    subDescription: string
    code?: string
  }
}

const ModalContentForShareIviteCode = ({
  onClickDone,
  onCopyCode,
  confModal: { title, description, subDescription, code },
}: ComponentProps) => {

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Description>
        {description}
      </Description>
      <LockImage src={lockImage} />
      <SecretCode>
        {code}
        <CopyButton onClick={onCopyCode} />
      </SecretCode>
      <SubDescription>
        {subDescription}
      </SubDescription>
      <ButtonDone onClick={onClickDone}>Done</ButtonDone>
    </Wrapper>
  );
};

export default ModalContentForShareIviteCode;

const Wrapper = styled.div`
  padding: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 607px;
`;

const Title = styled.h1`
  margin-bottom: 21px;
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  text-align: center;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  max-width: 462px;
  margin-bottom: 25px;
`;

export const LinkInModalShareCode = styled.a`
  color: inherit;
  font-weight: bold;
`;

const LockImage = styled.img`
  width: 115px;
  height: 115px;
  margin-bottom: 27px;
`;

const SecretCode = styled.span`
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  margin-bottom: 26px;
`;

const CopyButton = styled.button`
  background: url(${copyImage}) no-repeat center;
  background-color: transparent;
  width: 26px;
  height: 26px;
  border: none;
  cursor: pointer;
  margin-left: 12px;
`;

const SubDescription = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: #9C9C9C;
  margin-bottom: 32px;
`;

const ButtonDone = styled(Button)`
  background-color: #95D354;
  align-self: flex-end;
  width: fit-content;
  padding: 16px 48px;
  :hover:not(:disabled) {
    background-color: #B6E784;
  }
`;
