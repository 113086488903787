import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import {
  Link,
  useHistory,
} from 'react-router-dom';
import {
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import PayPeriodButton from '../Form/FieldsEntity/PayPeriodButton';
import PayPlanCardVertical from '../Form/FieldsEntity/PayPlanCardVertical';
// import { PayPlanType } from '../ProfilePage/Subscription/ChangeSubscriptionForModal';
import SignUpSteps from './SignUpSteps';
import FormElements from '../Form';
import { PayPlanPaypal, PayPlanStripe, PayPlanType } from '../ProfilePage/Subscription/types';
import { useChangeTariffPeriod } from './hookChangePeriodTariff';
import { addSubscription, getUserProfile, loadProfile } from '../features/user/userState';
import { UserProfileType } from '../features/user/types';
import { tariffServices } from '../config/tariffConfig';
import Paypal from '../Paypal';
import { PaypalDataType } from '../Paypal/Paypal';
import { useGetPriceList } from '../Admin/UserEdit/hooks';
import StripeInput from '../PricingPage/StripeInput';
import SelectSubjectBoard from './SelectSubjectBoard';


const {
  Button, Input,
} = FormElements;

const FormSubscribe = () => {
  // const [selectedPeriod, setSelectedPeriod] = useState<string>('year');
  const [selectedNameTariff, setSelectedNameTariff] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const { push } = useHistory();

  const dispatch = useDispatch();

  const priceList = useGetPriceList();

  const stripe = useStripe();
  const elements = useElements();

  const userProfile: UserProfileType = useSelector(getUserProfile);

  const findIdByStripe = priceList.stripe.find((item: PayPlanStripe) => selectedNameTariff === item.name);

  const findIdByPaypal = priceList.paypal.find((item: PayPlanPaypal) => selectedNameTariff === item.name);

  const paypalIdTarif = findIdByPaypal?.id;

  const isOtterOneAndSubject = selectedNameTariff === 'Otter One' && userProfile.defaultExam && userProfile.defaultExam.length > 0;

  const formSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const cardElement: any = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
    }

    dispatch(addSubscription(paymentMethod?.id, findIdByStripe?.id || '', 'stripe'));
  };


  useEffect(() => {
    // first load page scrolling to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userProfile.priceId) {
      push(`${process.env.PUBLIC_URL}/dashboard/new-test`);
    }
  }, [userProfile]);

  const addPaypalSubscription = (data: PaypalDataType) => {
    console.log(data);
    dispatch(addSubscription(data.selectedPlanId, data.subscriptionID, 'paypal'));
  };

  const currentDate = new Date();


  const datePayment = new Date(currentDate.setDate(currentDate.getDate() + 7));

  const dayPayment = datePayment.getDate();
  const monthPayment = datePayment.toLocaleString('eng', {
    month: 'long',
  });

  return (
    <SignUpSteps>
      <Form id="subscribe" onSubmit={formSubmit}>
        <Title>2. Subscribe and start your 7 days free trial</Title>

        <Section>
          <WrapperPayPlanPeriod>
            <DescriptionPayPlanPeriod>Choose a plan</DescriptionPayPlanPeriod>
            {/* <PayPeriodMonthly radioName="pay-period" onChange={() => setSelectedPeriod('month')}>Monthly</PayPeriodMonthly>
            <PayPeriodYearly defaultChecked radioName="pay-period" bestValue onChange={() => setSelectedPeriod('year')}>Yearly</PayPeriodYearly> */}
          </WrapperPayPlanPeriod>
          <TariffsList>
            {priceList.stripe.map((item: PayPlanStripe, n: number) => (
              // TODO fix this hack and hardcode service
              <PayPlanCardVertical
                planTitle={item.name}
                price={item.unit_amount_decimal}
                // totalPrice={item.unit_amount}
                discount={item.discount}
                oldPrice={item.oldPrice}
                key={n}
                popular={false}
                nameRadio="tariff"
                service={tariffServices[item.id]}
                onChange={() => setSelectedNameTariff(item.name || '')}
                checked={item.name === selectedNameTariff}
                isPlus={item.name === 'Otter Plus'}
                required
              />
            ))}
          </TariffsList>
        </Section>

        {selectedNameTariff === 'Otter One' && (
          <Section>
            <SelectSubjectBoard />
          </Section>
        )}

        {(isOtterOneAndSubject || selectedNameTariff !== 'Otter One') && (
          <>
            <Section>
              <TitleSection>
                Paypal subscription
              </TitleSection>
              <Paypal
                selectedPlanId={paypalIdTarif}
                onSubscribePaypal={addPaypalSubscription}
              />
            </Section>

            <Section>
              <TitleSection>
                Enter your card details.
                <br />
                Your subscription will start now.
              </TitleSection>
              <DescriptionSection>
                During your 7-day free trial, you will not be charged.
                <br />
                After the 7-day free trial, your card will be charged £49.99.
                <br />
                You can cancel your subscription at any time, and will not be billed further.
              </DescriptionSection>
              <StripeInput />
            </Section>
          </>
        )}


        <SectionPromocodeAndPayInfo>
          <PromoCodeWrapper>
            <InputPromoCode
              placeholder="Have a Promo Code?"
              name="promocode"
              value={promoCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPromoCode(e.currentTarget.value)}
            />
            <AddPromoCodeButton type="button">
              Apply
            </AddPromoCodeButton>
          </PromoCodeWrapper>
          <PayInfoWrapper>
            {findIdByStripe?.unit_amount_decimal && (
              <>
                <PayInfo>
                  Due Now:
                  <SpanPayInfo> £0.00</SpanPayInfo>
                </PayInfo>
                <PayInfo>
                  Due {dayPayment}d {monthPayment}:
                  <SpanPayInfo> £{findIdByStripe?.unit_amount_decimal}</SpanPayInfo>
                </PayInfo>
              </>
            )}
          </PayInfoWrapper>
        </SectionPromocodeAndPayInfo>

        <ButtonWrapper>
          <LinkGoBack to={`${process.env.PUBLIC_URL}/sign-up-steps/2/profile-information`}>Go Back</LinkGoBack>
          <SubscribeButton disabled={!stripe && !isOtterOneAndSubject} type="submit">Subscribe</SubscribeButton>
        </ButtonWrapper>
      </Form>
    </SignUpSteps>
  );
};

export default FormSubscribe;

const Form = styled.form`
  display: block;
  background-color: #FFF;
  padding: 52px;
  border-radius: 10px;
  box-shadow: 0px 2px 8px #F0F2F3;
`;

const Title = styled.h1`
  font-weight: bold;
  fon-size: 28px;
  line-height: 34px;
  margin-bottom: 65px;
  text-align: center;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px 30px;
  margin-top: 60px;
  border-bottom: 2px solid #E0E0E0;

  :first-of-type {
    padding-bottom: 80px;
  }

  :nth-of-type(2) {
    padding-bottom: 50px;
  }
`;

const SectionPromocodeAndPayInfo = styled(Section)`
  flex-direction: inherit;
  justify-content: space-between;
  margin-top: 40px;
  border: none;
  padding-bottom: 0;
`;

const WrapperPayPlanPeriod = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: left;
  margin-bottom: 32px;
  width: 100%;
`;

const DescriptionPayPlanPeriod = styled.span`
  display: block;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  margin-right: 22px;
`;

const PayPeriodMonthly = styled(PayPeriodButton)`
  margin-right: 18px;
  & div:last-child {
    min-width: 170px;
    padding: 12px 34px;
    text-align: center;
    font-size: 22px;
  }
`;

const PayPeriodYearly = styled(PayPeriodMonthly)`
  margin-right: 0;
`;

const TariffsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: row-reverse; */
  width: 100%;
  margin: 0 -15px;
`;

const TitleSection = styled.h2`
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  align-self: flex-start;
  margin-bottom: 32px;
`;

const DescriptionSection = styled.p`
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 52px;
  align-self: flex-start;
`;

const PromoCodeWrapper = styled.div`
  display: flex;
`;

const InputPromoCode = styled(Input)`
  height: 56px;
  border-radius: 10px;
  min-width: 288px;
  margin-right: 16px;
  font-size: 20px;
  line-height: 26px;
`;

const AddPromoCodeButton = styled(Button)`
  height: auto;
  width: auto;
  min-width: 128px;
  background-color: #9077F5;
  font-size: 20px;
  line-height: 24px;
  padding: 15px 34px;
  border-radius: 10px;
  padding: 15px 18px;
`;

const PayInfoWrapper = styled.div`

`;

const PayInfo = styled.span`
  display: block;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
`;

const SpanPayInfo = styled.span`
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 77px;
`;

const SubscribeButton = styled(Button)`
  min-width: 128px;
  width: auto;
  height: auto;
  padding: 13px 54px;
  border: 3px solid transparent;
  color: #FFF;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  background-color: #9077F5;
  margin-left: auto;
  border-radius: 16px;
`;

const LinkGoBack = styled(Link)`
  display: block;
  padding: 13px 29px;
  border: 3px solid #9077F5;
  color: #9077F5;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  background-color: #FFF;
  border-radius: 16px;
`;