import React, { useState } from 'react';
import styled from 'styled-components/macro';
import PracticeTestCard from './PracticeTestCard';

interface ArrayItemTopicType {
  id: string
  key: number
  name: string
  topicName: string
  date: string
  elapsedTime: number
  result: number
}

export interface listTopicsTypes {
  key: number
  name: string
  topics: ArrayItemTopicType[]
}

interface PastPracticeTestsProps {
  practiceTests: listTopicsTypes[]
  onShowDetailResult: (id: string) => void
}

const PastPracticeTests = ({
  practiceTests,
  onShowDetailResult
}: any) => {

  return (
    <List>
      {practiceTests.map((item: listTopicsTypes) => (
        <PracticeTestCard onShowDetailResult={onShowDetailResult} key={item.key} topicItems={item} />
      ))}
    </List>
  );
};

export default PastPracticeTests;

const List = styled.ul`
  padding: 0;
  list-style: none;
`;
