import React from 'react';
import styled from 'styled-components/macro';
import Elements from '..';

const {
  Button,
} = Elements;

const otterAndLaptop = require('../../images/otter-and-laptop.svg');

interface NotificationLargeScreenProps {
  onClose: () => void
}

const NotificationLargeScreen = ({ onClose }: NotificationLargeScreenProps) => {
  return (
    <Wrapper>
      <Image src={otterAndLaptop} />
      <Title>
        Otter works better on larger screens.
      </Title>
      <Description>
        Please use a laptop or desktop to access this page.
      </Description>
      <DescriptionLandscape>
        Turn your device to landscape mode
      </DescriptionLandscape>
      <ButtonStyled onClick={onClose}>Okay</ButtonStyled>
      <Span onClick={onClose}>Proceed Anyway</Span>
    </Wrapper>
  );
};


export default NotificationLargeScreen;

const Wrapper = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(106, 84, 193, 0.95);
  z-index: 999999;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 33px;  

  @media (max-width: 1023px) {
    display: flex;
  }
`;

const Image = styled.img`
  object-fit: center;
  object-position: 10px 0;
  overflow: hidden;
  margin-bottom: 39px;
  height: 240px;
  width: 308px;
`;

const Title = styled.h6`
  font-weight: bold;
  font-size: 26px;
  line-height: 33px;
  text-align: center;
  color: #FFF;
  margin-bottom: 22px;

`;

const Description = styled.p`
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 34px;
  color: #FFF;
  text-align: center;

  @media (orientation: portrait) {
    display: none;
  }
`;

const DescriptionLandscape = styled(Description)`
  display: none;

  @media (orientation: portrait) {
    display: inline;
  }
`;

const ButtonStyled = styled(Button)`
  max-width: 300px;
  height: 56px;
  padding: 16px 14px;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 25px;
`;

const Span = styled.span`
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-decoration-line: underline;
  text-align: center;
  color: #F87700;
`;
