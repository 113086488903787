import React from 'react';
import styled from 'styled-components/macro';
import FormElements from '../Form';

const {
  Button, LinkButton,
} = FormElements;

interface ModalTimeProps {
  onAddTime?: () => void
  onFinishChalenge: () => void
}

const ModalTimeIsUp = ({ onAddTime, onFinishChalenge }: ModalTimeProps) => {
  return (
    <Container>
      <Title>The time is up!</Title>
      <Description>
        Finish the exam or add an extra 5 minutes to continue.
      </Description>
      <ButtonsWrapper>
        <FinishExamButton to="#" background onClick={onFinishChalenge}>Finish exam</FinishExamButton>
        <ExtraTimeButton onClick={onAddTime}>Add 5 minutes</ExtraTimeButton>
      </ButtonsWrapper>
    </Container>
  );
};

export default ModalTimeIsUp;

const Container = styled.div`
  width: 750px;
  // height: 414px;
  background-color: #FFF;
  box-sizing: border-box;
  padding: 50px 68px 84px;
`;

const Title = styled.h2`
  margin-bottom: 52px;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
`;

const Description = styled.p`
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 69px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -14px;
`;

const FinishExamButton = styled(LinkButton)`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 14px;
  background-color: #9077F5;
  border-radius: 16px;
  font-size: 28px;
  line-height: 34px;
  min-height: 74px;
  :hover {
    background-color: #AA95FF;
  }
`;

const ExtraTimeButton = styled(Button)`
  margin: 0 14px;
  color: #9077F5;
  background-color: #FFF;
  border: 3px solid #9077F5;
  border-radius: 16px;
  font-size: 28px;
  line-height: 34px;
  min-height: 74px;
  box-sizing: border-box;
  :hover {
    color: #FFF;
    border: 3px solid #AA95FF;
  }
`;
