import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { combineReducers } from 'redux';
import { AppThunk, RootState } from '../../app/store';

import { GameStateType, AnswerType, UpdateGameStateType } from './types';

import { request } from '../../api';

const initialGameState: GameStateType = {
  currentQuestion: {
    answered: false,
    id: '',
    status: '',
    title: '',
    images: [],
  },
  score: {
    total: 0,
  },
  isActive: false,
  isLoading: true,
};

export const gameState = createSlice({
  name: 'CurrentQuestion',
  initialState: initialGameState,
  reducers: {
    setCurrentState: (state, action: { payload: UpdateGameStateType }) => ({ ...state, ...action.payload }),
  },
});

export default combineReducers({
  gameState: gameState.reducer,
});

export const { setCurrentState } = gameState.actions;


export const loadGameState = (): AppThunk => (dispatch) => {
  request({
    method: 'GET',
    url: 'game/state',
    params: {},
  }, [200, 404]).then((response) => {
    if (response.status === 200) {
      if (response) {
        const { data } = response;
        const { currentQuestion, score } = data;
        dispatch(setCurrentState({
          currentQuestion,
          score,
          isActive: true,
          isLoading: false,
        }));
      }
    } else {
      dispatch(setCurrentState({
        isActive: false,
        isLoading: false,
      }));
    }
  }).catch(() => {
    dispatch(setCurrentState({
      isLoading: true,
    }));
  });
};

export const answerQuestion = (answer: AnswerType): AppThunk => (dispatch) => {
  request({
    method: 'POST',
    url: 'game/answer',
    data: {
      ...answer,
    },
  }).then((response) => {
    console.log('network error');
    // dispatch(loadQuestions());
  });
};
/*
export const createQuestion = (newQuestion: CreateQuestionParamsType): AppThunk => dispatch => {
  request({
    method: 'POST',
    url: `/questions`,
    data: {
      ...newQuestion
    },
  }).then((response: { data: { question: QuestionType } }) => {
    dispatch(loadQuestions());
  });
};
*/


export const getGameState = (state: RootState) => state.game.gameState;
// export const get
// export const selecActiveQustion = (state: RootState) => state.questions.gameState;
