import React, { MouseEvent, useState, useCallback } from "react";
import styled from "styled-components/macro";
import { boolean } from "yup/lib/locale";
import { Button } from "../../../Form/Form";
import {
  AssigmentTypes,
  AssignmentFields,
  CreateAssignmentFields,
} from "../../../project-types/assignments/types";
import { subTopics } from "../../../stories/Progress/SubTopicsBoard.stories";
import AssignmentQuestions from "../Assignment/AssignmentQuestions";
import AssignmentSettingsBlock from "../Assignment/AssignmentSettingsBlock";
import { TopicTreeForSelect } from "../Assignment/utils";
import TopicItem from "./TopicItem";

const getPath = (subject: any, path: string[]) => {
  const acc: any = subject;
  return path.reduce((result, part) => {
    return result.subTopics[part as any] as any;
  }, acc);
};

interface ComponentNameProps {
  assignment: CreateAssignmentFields;
  topics: TopicTreeForSelect;
  createdAssignment: AssignmentFields | null;
  onCreate: (assignment: CreateAssignmentFields) => void;
  onAssignmentQuestionsNextClick: () => void;
  onChangeNumberOfQuestionsHandler: (num: string) => void;
  onChangeCompexityLevelHandler: (values: [number, number]) => void;
  numberOfQuestions: string;
  complexityLevel: [number, number];
}

export default function AssignTopicPage({
  assignment,
  topics,
  onCreate,
  createdAssignment,
  onAssignmentQuestionsNextClick,
  numberOfQuestions,
  onChangeCompexityLevelHandler,
  onChangeNumberOfQuestionsHandler,
  complexityLevel,
}: ComponentNameProps) {
  const subject: any = topics[assignment.topicId[0]];

  const [expanded, setIsExpanded] = useState<{
    topic: Record<string, boolean | undefined>;
    subTopics: Record<string, boolean | undefined>;
  }>({
    topic: {},
    subTopics: {},
  });

  const [selected, setSelected] = useState<{
    topic: Record<string, boolean | undefined>;
    subTopics: Record<string, boolean | undefined>;
    subSubTopic: Record<string, boolean | undefined>;
  }>({
    topic: {},
    subTopics: {},
    subSubTopic: {},
  });

  const [selectedPath, setSelectedPath] = useState<string[]>([]);
  const [currentStage, setCurrentStage] = useState(1);

  const onClickItem = (type: keyof typeof expanded, id: string) => (e: any) => {
    e.stopPropagation();
    const isExpanded = expanded[type][id];
    setIsExpanded({
      ...expanded,
      [type]: { ...expanded[type], [id]: !isExpanded },
    });
  };

  const onSelect = (
    type: keyof typeof selected,
    id: string,
    path: string[]
  ) => () => {
    console.log("-------");
    const isSelected = selected[type][id];

    setSelected({
      topic: {},
      subTopics: {},
      subSubTopic: {},
      [type]: { [id]: !isSelected },
    });

    setSelectedPath(path);
  };

  const isFilled =
    Object.keys(selected.topic).length > 0 ||
    Object.keys(selected.subTopics).length > 0 ||
    Object.keys(selected.subSubTopic).length > 0;

  const onSelectCompleate = () => {
    onCreate({
      ...assignment,
      topicId: [assignment.topicId[0], ...selectedPath],
    });

    if (assignment.type === AssigmentTypes.PRACTICE) {
      setCurrentStage(2);
    } else {
      onAssignmentQuestionsNextClick();
    }
  };

  return (
    <>
      {currentStage === 1 && (
        <>
          <Header>
            <Title>
              {assignment.topicId[0]}
              &nbsp; practice
            </Title>
            <CreateAssignmentButton
              disabled={!isFilled}
              onClick={onSelectCompleate}
            >
              Create assignment
            </CreateAssignmentButton>
          </Header>
          <TopicsContainer>
          <TopicsList>
            {subject &&
              Object.keys(subject.subTopics)
                .reverse()
                .map((subjectName: any) => (
                  <TopicItem
                    image={getPath(subject, [subjectName]).icon}
                    name={subjectName}
                    key={subjectName}
                    description={`${
                      Object.keys(getPath(subject, [subjectName]).subTopics)
                        .length
                    } topic areas/ 12 sub-topics`}
                    isExpanded={expanded.topic[subjectName]}
                    onClick={onClickItem("topic", subjectName)}
                    isSelected={!!selected.topic[subjectName]}
                    onSelect={onSelect("topic", subjectName, [subjectName])}
                  >
                    <>
                      {Object.keys(getPath(subject, [subjectName]).subTopics)
                        .reverse()
                        .map((topicArea: any) => {
                          return (
                            <TopicItem
                              name={topicArea}
                              key={`${subjectName} ${topicArea}`}
                              description={`${
                                Object.keys(
                                  getPath(subject, [subjectName, topicArea])
                                    .subTopics
                                ).length
                              } sub-topics`}
                              isExpanded={expanded.subTopics[topicArea]}
                              onClick={onClickItem("subTopics", topicArea)}
                              isSelected={!!selected.subTopics[topicArea]}
                              onSelect={onSelect("subTopics", topicArea, [
                                subjectName,
                                topicArea,
                              ])}
                            >
                              {Object.keys(
                                getPath(subject, [subjectName, topicArea])
                                  .subTopics
                              )
                                .reverse()
                                .map((subTopic: any) => {
                                  return (
                                    <TopicItem
                                      name={subTopic}
                                      key={`${subjectName} ${topicArea} ${subTopic}`}
                                      onClick={() => {}}
                                      isSelected={
                                        !!selected.subSubTopic[subTopic]
                                      }
                                      onSelect={onSelect(
                                        "subSubTopic",
                                        subTopic,
                                        [subjectName, topicArea, subTopic]
                                      )}
                                    />
                                  );
                                })}
                            </TopicItem>
                          );
                        })}
                    </>
                  </TopicItem>
                ))}
          </TopicsList>
          {assignment.type === AssigmentTypes.PRACTICE && <AssignmentSettingsBlock
            numberOfQuestions={numberOfQuestions}
            complexityLevel={complexityLevel}
            onChangeCompexityLevelHandler={onChangeCompexityLevelHandler}
            onChangeNumberOfQuestionsHandler={onChangeNumberOfQuestionsHandler}
          />}
          </TopicsContainer>
        </>
      )}
      {currentStage === 2 && createdAssignment && (
        <AssignmentQuestions
          assignment={createdAssignment}
          onClickNext={onAssignmentQuestionsNextClick}
        />
      )}
    </>
  );
}

const Header = styled.h3`
  margin-bottom: 26px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  max-width: 914px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: #1f1f1f;
`;

const CreateAssignmentButton = styled(Button)`
  background: #9077f5;
  width: 276px;
  height: 56px;
  font-size: 18px;
  line-height: 22px;
`;

const TopicsList = styled.div`
  /* &:first-child {
    color: red;
  } */
  flex-shrink: 0;

  > * {
    &:first-child {
      border-top: 1px solid #e0e0e0;
    }
  }
  width: 100%;
  max-width: 914px;
`;

const TopicsContainer = styled.div`
  display: flex;
  width: 100%;
`;
