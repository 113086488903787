import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { deleteStudentFromGroup, editGroup, getTeacherGroup, loadTeacherGroup } from '../../../features/teacher/teacherState';
import { RouteType } from '../../../features/topic/types';
import { UserProfileType } from '../../../features/user/types';
import { onCopyValueInBuffer } from '../../../hooks/copyValue';
import Layout from '../../../Layout';
import { GroupFields, GroupOutType } from '../../../project-types/groups/types';
import { UserPublicType } from '../../../project-types/users/types';
import ModalInPortal from '../../../utils/ModalPortal';
import HeaderForTeacherDashboard from '../../HeaderForTeacherDashboard';
import ModalConfirmRemove from '../../ModalConfirmRemove';
import ModalContentForShareIviteCode, { LinkInModalShareCode } from '../../ModalContentForShareIviteCode';
import ModalContentFromAddGroup from '../ModalContentForAddGroup';
import ModalContentFromEditGroup from '../ModalContentForEditGroup';
import GroupRoasterBody from './GroupRoasterBody';
import ShareCodeBlock from './ShareCodeBlock';


const TeacherGroupRoasterPage = () => {
  const {
    ModalRender,
    onShow,
    onClose,
  } = ModalInPortal();

  const {
    ModalRender: ModalRenderShare,
    onShow: onShowShare,
    onClose: onCloseShare,
  } = ModalInPortal();

  const dispatch = useDispatch();
  const history = useHistory();

  const { groupId } = useParams<{ groupId: GroupFields['_id'] }>();

  const loadGroup = useCallback(() => {
    dispatch(loadTeacherGroup(groupId));
  }, [groupId]);

  useEffect(() => {
    loadGroup();
    // dispatch(loadTeacherGroup(groupId));
  }, [loadGroup, groupId]);

  const group = useSelector(getTeacherGroup);

  const RemovStudentModal = ModalInPortal();
  const [studentsForRemove, setStudentsForRemove] = useState<UserPublicType[] | null>(null);

  if (!group) {
    return (<></>);
  }

  const confModalShareCode = {
    title: 'Invite your students',
    code: group.inviteCode,
    description: (
      <>
        Have your students visit
        {' '}
        <LinkInModalShareCode href={`${process.env.PUBLIC_URL}/join`} target="_blank" rel="noreferrer">www.ottereducation.com/join</LinkInModalShareCode>
        {' '}
        and enter the code
      </>
    ),
    subDescription: 'Before inviting students, please note that Otter Education assumes you have received parent permission (or meet an exception from parent consent requirements) for any students you add to your study group.',
  };

  const onCopyCode = () => {
    if (group?.inviteCode) {
      onCopyValueInBuffer(group.inviteCode);
    }
  };

  const updateGroup = (groupFields: Pick<GroupOutType, 'name'>) => {
    dispatch(editGroup({ _id: group?._id, ...groupFields }));
  };


  const onDeleteStudents = (users: UserPublicType[]) => {
    debugger;
    setStudentsForRemove(users);
    RemovStudentModal.onShow();
  };

  const deleteStudents = () => {
    if (studentsForRemove) {
      dispatch(deleteStudentFromGroup(groupId, studentsForRemove));
      RemovStudentModal.onClose();
    }
  };

  return (
    <>
      {ModalRender && (
        <ModalRender>
          <ModalContentFromEditGroup
            group={group}
            onNext={() => {
              onClose();
              loadGroup();
            }}
            onClose={onClose}
            copyCode={onCopyCode}
            updateGroup={updateGroup}
          />
        </ModalRender>
      )}
      {ModalRenderShare && (
        <ModalRenderShare>
          <ModalContentForShareIviteCode
            onClickDone={onCloseShare}
            onCopyCode={onCopyCode}
            confModal={confModalShareCode}
          />
        </ModalRenderShare>
      )}
      {RemovStudentModal.ModalRender && (
        <RemovStudentModal.ModalRender>
          <ModalConfirmRemove
            title="Delete student"
            description={`You are about to delete the selected student. Please note that
              the student data cannot be restored once deleted.
              Are you sure you want to proceed`}
            onClickDone={deleteStudents}
            onClickCancel={RemovStudentModal.onClose}
          />
        </RemovStudentModal.ModalRender>
      )}
      <Layout>
        <Wrapper>
          <HeaderForTeacherDashboard
            onAdd={onShowShare}
            title="Assignments"
            showGoBack
            editEntityName={`Group ${group?.name}`}
            noteText={`${group?.students.length} students`}
            onEdit={onShow}
            onBack={() => {
              history.push('/teacher/dashboard/my-groups');
            }}
          />
          <ShareCodeStyled code={group.inviteCode} onShare={onShowShare} />
          {/* <div onClick={onShowShare}>Share code123</div> */}
          <GroupRoasterBody onAddStudent={onShowShare} group={group} onDelete={onDeleteStudents} />
        </Wrapper>
      </Layout>
    </>
  );
};

export default TeacherGroupRoasterPage;

const Wrapper = styled.div`
  width: 100%;
  min-width: 480px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 117px;
`;

const ShareCodeStyled = styled(ShareCodeBlock)`
  margin-top: -35px;
  margin-bottom: 40px;
`;
