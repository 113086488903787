import React from 'react';
import {
  CardElement,
} from '@stripe/react-stripe-js';
import styled from 'styled-components';

const StripeInput = () => (
    <CardElementStyled
      options={{
        style: {
          base: {
            fontSize: '20px',
            color: '#424770',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#9e2146',
          },
        },
      }}
    />
  );

  const CardElementStyled = styled(CardElement)`
    max-width: 610px;
    width: 100%;
    align-self: flex-start;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    height: 56px;
    box-sizing: border-box;
    padding: 15px 20px;
`;


export default StripeInput; 