import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { EditIcon, PayCardIcon, RightIcon } from '../images/iconSvg';

import {
  TypeProps,
} from './type';

const checkMark = require('../images/check-mark.svg');

const RadioAbout = ({
  className, children, value, name, onChange, onClick, checked, defaultChecked, required,
}: TypeProps) => (
  <label className={className}>
    <input
      type="radio"
      value={value}
      name={name}
      onChange={onChange}
      onClick={onClick}
      checked={checked}
      defaultChecked={defaultChecked}
      required={required}
    />
    <span>{children}</span>
  </label>
);

const CheckboxAbout = ({
  className, children, value, name, onChange, checked, required,
}: TypeProps) => (
  <label className={className}>
    <input required type="checkbox" value={value} name={name} checked={checked} onChange={onChange} />
    <span>{children}</span>
  </label>
);

export const Input = styled.input<{error?: boolean}>`
  width: 100%;
  background-color: #FFF;
  box-sizing: border-box;
  border: 1px solid ${({ error }) => (error ? '#F55F4B' : '#C4C4C4')};
  border-radius: 10px;
  padding: 17px 15px;
  ::placeholder {
    color: #9C9C9C;
  }
  :focus {
    outline: none;
  }
`;

export const Radio = styled(RadioAbout)`
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  min-height: 20px;
  & input[type="radio"] {
    display: block;
    opacity: 0;
    width: 1px;
    height: 1px;
    position: absolute;
    top: 20px;
    left: 10px;
  }
  & > span {
    display: inline-flex;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    ::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #C4C4C4;
      /* background-color: #FFF; */
      margin-right: 15px;
      flex-shrink: 0;
      /* align-self: baseline; */
    }
  }
  & > input:checked + span::before {
    background-color: #F87700;
    border: none;
  }
`;

export const Checkbox = styled(CheckboxAbout)`
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  display: block;
  position: relative;
  & input[type="checkbox"] {
    display: block;
    opacity: 0;
    width: 1px;
    height: 1px;
    position: absolute;
    top: 20px;
    left: 10px;
  }
  & > span {
    display: inline-flex;
    /* align-items: center; */
    ::before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      border-radius: 2px;
      border: 1px solid #C4C4C4;
      margin-right: 15px;
      background: url(${checkMark}) no-repeat center;
    }
  }
  & > input:checked + span::before {
    background-color: #F87700;
    border: none;
  }
`;

export const CheckboxNormal = styled(Checkbox)`
  & > span::before  {
    // background-image: none;
  }

  & span:before {
    background-color: none;
  }

  & > input + span::before {
    background-color: none;
    background: none;
  }

  & > input:checked + span::before {
    border-radius: 2px;
    border: 1px solid #C4C4C4;
    background: url(${checkMark}) no-repeat center;
    background-color: #F87700;
  }
`;
 
 

export const Button = styled.button`
  width: 100%;
  height: 66px;
  box-sizing: border-box;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F87700;
  color: #FFF;
  font-weight: bold;
  font-size: 25px;
  border-radius: 16px;
  /* transition: .2s ease-in-out; */
  outline: none;
  cursor: pointer;
  :hover:not(:disabled) {
    background-color: #AA95FF;
  }
  :disabled {
    background-color: #E0E0E0;
    cursor: default;
  }
`;

export const LinkButton = styled(Link)<{background: boolean}>`
  text-decoration: none;
  background-color: ${(props) => (props.background ? '#9077F5' : 'none')};
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.background ? '#FFF' : 'inherit')};
  box-sizing: border-box;
  padding: 12px 24px;
  font-size: 22px;
  font-weight: bold;
  :hover {
    color: ${(props) => (props.background ? '#FFF' : 'inherit')};
  }
`;

export const SidebarButton = ({
  children, icon, selected, ...more
}: any) => (
  <CreateSidebarButton selected={selected} {...more}>
    <Icon>{icon}</Icon>
    {children}
  </CreateSidebarButton>
);

const CreateSidebarButton = styled.a<{selected?: boolean}>`
  display: block;
  /* width: 100%; */
  height: 97px;
  background-color: ${(props) => (props.selected ? '#FFF' : 'transparent')};
  border-radius: 16px;
  border: none;
  box-sizing: border-box;
  padding-left: 38px;
  margin-left: 123px;
  display: flex;
  align-items: center;
  transition: .2s ease-in-out background-color;
  margin-bottom: 30px;
  outline: none;
  font-size: 22px;
  font-weight: ${(props) => (props.selected ? 'bold' : '500')};
  line-height: 27px;
  box-shadow: ${(props) => (props.selected ? '0px 2px 8px #F0F2F3' : 'none')};
  cursor: pointer;
  color: inherit;
  :hover {
    background-color: #FFF;
    color: inherit;
  }

  @media (max-width: 1400px) {
    margin-left: 31px;
  }
`;

const Icon = styled.div`
  font-size: 28px;
  margin-right: 18px;
`;

export const PlusButton = styled.button<{buttonAction?: boolean}>`
  background-color: #F87700;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 50%;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  position: relative;
  ::before {
    content: '';
    display: block;
    width: 50%;
    height: 10%;
    border-radius: 1em;
    background-color: #FFF;
  }
  ::after {
    content: '';
    display: block;
    width: 50%;
    height: 10%;
    border-radius: 1em;
    background-color: #FFF;
    position: absolute;
    transform: ${({ buttonAction }) => (buttonAction ? 'rotate(0deg)' : 'rotate(90deg)')};
  }
`;

export const ArrowButton = (props: any) => (
  <ButtonWrapArrow {...props}><RightIcon /></ButtonWrapArrow>
);

const ButtonWrapArrow = styled(Button)`
  width: 32px;
  height: 32px;
  background-color: #EEEAFF;
  border-radius: 7px;
`;

export const DefaultButton = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
`;
