import { useEffect, useState } from 'react';
import { message } from 'antd';
import { request } from '../api';

export const useLoadTopicStats = () => {
  const [topicStats, setTopicStats] = useState([]);

  useEffect(() => {
    request({
      method: 'GET',
      url: '/challenge/stats/all/average',
    }, [200, 403]).then((response) => {
      if (response.data) {
        const reduce = response.data.reduce(
          (config: any, value: any) => {
            const result = config;
            result[value.topic] = value.avg;
            return result;
          }, {},
        );
        setTopicStats(reduce);
      }
    }).catch((error) => {
      message.error(error.response.data);
    });
  }, []);

  return topicStats;
};
