import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import {
  ClockIcon, ArrowIcon,
} from '../../images/iconSvg';

interface StartButtonProps {
  typeStart?: string
  iconSize?: string
  className?: string
  linkTo?: string
  onClick: (e: React.SyntheticEvent<HTMLButtonElement, MouseEvent>) => void
  children: ReactNode
  disabled?: boolean
}

const iconSelect = (name: string) => {
  switch (name) {
    case 'exam':
      return <ClockIcon />;
    default:
      return <ArrowIcon />;
  }
};

const StartButton = ({ typeStart = 'test', iconSize = '28px', className, linkTo, onClick, children, disabled }: StartButtonProps) => {

  return (
    <Button className={className} onClick={onClick} disabled={disabled}>
      <ButtonName>{children}</ButtonName>
      <ButtonIcon iconSize={iconSize}>
        {iconSelect(typeStart)}
      </ButtonIcon>
    </Button>
  );
};

StartButton.defaultProps = {
  onClick: () => { }
};

const Button = styled.button`
  box-sizing: border-box;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  border: none;
  background-color: #FFF;
  border-radius: 30px;
  min-height: 66px;
  cursor: pointer;
  transition: .2s ease-in-out;
  outline: none;
  flex-shrink: 0;
  :hover {
    background-color: #F87700;
  }
`;

const ButtonName = styled.span`
  display: inline-block;
  font-weight: bold;
  font-size: 25px;
  line-height: 0px;
  color: #F87700;
  margin-right: 16px;
  /* transition: .2s ease-in-out; */
  ${Button}:hover & {
    color: #FFF;
  }
`;

const ButtonIcon = styled.div<{iconSize?: string}>`
  font-size: ${props => props.iconSize};
  // height: ${props => props.iconSize};
  /* transition: .2s ease-in-out; */
  ${Button}:hover & svg {
    fill: #FFF;
  }
`;

export default StartButton;
