import { message } from 'antd';
import { generate } from 'generate-password';
import { request } from '../../api';

export const createAdmin = (values: any) => (setSecretQR: Function) => {
  request(
    {
      method: 'POST',
      url: 'admin/create-admin',
      data: values,
    }, [200],
  )
    .then(({ data }) => {
      setSecretQR(data.secret);
      message.info('Admin created');
    })
    .catch((errors) => {
      if (errors.response.status === 403) {
        message.warning('You dont have access to this action');
      } else if (errors.response?.data?.errors?.user) {
        message.warning(errors.response.data.errors.user[0]);
      } else {
        message.warning('Error');
      }
      
      console.log(errors);
    });
};

export const password = () => generate({
  length: 10,
  numbers: true,
});
