import { GroupFields } from '../groups/types';
import { UserPublicType } from '../users/types';

export enum AssigmentTypes {
  REVISION = 'REVISION',
  PRACTICE = 'PRACTICE',
  TIMED_PRACTICE = 'TIMED_PRACTICE',
  MOCK_EXAM = 'MOCK_EXAM'
}

export interface Qestion {
  question: string;
  variables: object;
  'answer-type': string;
  templateId: string;
  _id: string;
  correctAnswer: string;
  explanation: string;
  svg: string;
  answers: Array<string>;
  path: Array<string>;
}
export interface AssignmentFields {
  _id: string,
  group: GroupFields,
  name: string,
  type: AssigmentTypes,
  dueDate: string,
  topicId: Array<string>,
  questions: Array<Qestion>,
  maxLevel: number,
  minLevel: number,
  studentsSelected: Array<UserPublicType>,
}

// TODO fix type later
export type CreateAssignmentFields = Omit<AssignmentFields, '_id' | 'questions' | 'maxLevel' | 'minLevel' | 'group' | 'studentsSelected'> & { group: GroupFields['_id'] };

export interface QuestionsParams {
  quantity: number,
  maxLevel: number,
  minLevel: number,
}
