import React from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import FormElements from '../Form';
import CardContainer from './CardContainer';

const {
  Button,
} = FormElements;

const letterImage = require('../images/letter.svg');

const LetterSent = () => {
  const { push } = useHistory();
  return (
    <CardContainer>
      <Wrapper>
        <Title>Check you mailbox</Title>
        <Description>
          We’ve send you an email with instructions on how to reset your password
        </Description>
        <LetterImage />
        <Button type="button" onClick={() => push(`${process.env.PUBLIC_URL}/`)}>Return to Home Page</Button>
      </Wrapper>
    </CardContainer>
  );
};

const Title = styled.h1`
  margin-bottom: 28px;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
`;

const Description = styled.p`
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 36px;
  text-align: center;
  max-width: 300px;
`;

const LetterImage = styled.div`
  width: 110px;
  height: 90px;
  background: url(${letterImage}) no-repeat;
  background-size: cover;
  margin-bottom: 36px;
`;

const Wrapper = styled.form`
  box-sizing: border-box;
  padding: 60px 30px 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default LetterSent;
