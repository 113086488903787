import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';

import 'react-datepicker/dist/react-datepicker.css';

import { Select, Input, Form, DatePicker } from 'antd';
import { get, StringChain } from 'lodash';


import InputSelect, { SchoolsListTypes } from '../../ProfilePage/AcademicInformation/InputSelect';

import LabelForInput from '../../Form/FieldsEntity/LabelForInput';
import FirstAndLastNameInput from '../../ProfilePage/PersonalInformation/FirstAndLastNameInput';
import ChangeDate from '../../ProfilePage/PersonalInformation/ChangeDate';
import ChangeGender from '../../ProfilePage/PersonalInformation/ChangeGender';

import TargetSchoolsList from '../../ProfilePage/AcademicInformation/TargetSchoolsList';
import { SchoolType } from '../../features/user/types';

import FormElements from '../../Form';

import Emails from './fields/Emails';

const {
  Button, Radio,
} = FormElements;


const { Option } = Select;

interface FilterValueProps {
  onFilter: (filter: FilterValuesType) => void
}

interface DateOfBirth {
  gt?: string,
  lt?: string
}

export interface FilterValuesType {
  fullName?: string,
  gender?: string|undefined,
  currentSchool?: string|undefined,
  targetSchools?: string[],
  role?: string[],
  email?: string[],
  name?: string,
  condition?: string
  dateOfBirth?: DateOfBirth
}

const Filter = ({ onFilter }: FilterValueProps) => {
  const initialValues: FilterValuesType  = {
    fullName: '',
    gender: undefined,
    currentSchool: undefined,
    targetSchools: undefined,
    email: [],
    condition: 'and',
    dateOfBirth: undefined,
    role: undefined
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
      // validate={}

        onSubmit={
        (values, { setSubmitting }) => {

          const vals: any  = {};

          console.log(vals)

          const cleanValues = Object.keys(values).reduce((acc: any , key: any) => {

            const value: any = get(values, key) || undefined;
            if (!value) {
              return acc;
            }
            if (value.length && value.length > 0) {
              acc[key] = value;
            }
            if (Object.keys(value).length > 0) {
              acc[key] = value;
            }
            return acc;
          }, vals);



          console.log(cleanValues)


          onFilter(cleanValues);
          // onSubmit(values);
          // dispatch(updateProfile(values));
        }
      }
      >
        {({
          values,
          handleSubmit,
          handleReset,
          setFieldValue,
          errors,
        }) => (
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <Container>
              <Wrapper>
                <Title>Text</Title>
                <Label label="Usertype">
                  <Select mode="multiple"  onChange={value => setFieldValue('role', value)}>
                    <Option value="CEO">CEO</Option>
                    <Option value="SUPER_ADMIN">superadmin</Option>
                    <Option value="ADMIN">admin</Option>
                    <Option value="USER">user</Option>
                  </Select>
                </Label>
                <Label label="Fullname">
                  <Input
                    onChange={(e) => setFieldValue('fullName', e.target.value)}
                    value={values.fullName}
                  />
                </Label>
                <ChangeGenderStyled
                  onChangeGender={(gender: string) => {
                    setFieldValue('gender', gender);
                    console.log(gender);
                  }}
                  value={values.gender}
                />
              </Wrapper>


              {/* <Wrapper>
                <Title>Gender</Title>
                <ChangeDate
                    dateValue={values.dateOfBirth}
                    onChange={(date) => {
                      console.log(date);
                    }}
                  />
                
              </Wrapper> */}

              

              <Wrapper>
                <Title>Birthdate</Title>
                <Label label="From:" labelCol={{span: 5}}>
                  <DatePicker onChange={(date) => setFieldValue('dateOfBirth', { ...values.dateOfBirth, gt: date  } )} />
                </Label>
                <Label label="To:" labelCol={{span: 5}}>
                  <DatePicker onChange={(date) => setFieldValue('dateOfBirth', { ...values.dateOfBirth, lt: date  })} />
                </Label>
              </Wrapper>

              <Wrapper>
                <Title>Emails (comma separated)</Title>
                <Label label="">
                  <Input onChange={(e) => {
                    const emails = e.target.value.split(',');
                    if (emails.length > 0 && !(emails.length === 1 && emails[0] === '')) {
                      setFieldValue('email', emails.map(email => email.trim()))
                    } else {
                      setFieldValue('email', [])
                    }
                  }
                } value={values.email} />
                </Label>
              </Wrapper>

              <Wrapper>
                <Title>Condition</Title>
                <Label label="">
                  <Select defaultValue="and" onChange={val => setFieldValue('condition', val)}>
                    <Option value="and">and</Option>
                    <Option value="or">or</Option>
                  </Select>
                </Label>
              </Wrapper>

              <ButtonWrapper>
                <CancelButton type="reset">Cancel</CancelButton>
                <SaveButton type="submit">Find</SaveButton>
              </ButtonWrapper>
            </Container>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Filter;


const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;


const InputSelectStyled = styled(InputSelect)`
  max-width: 570px;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  // margin-bottom: 12px;
`;

const AcademicTitle = styled(Title)`
  margin-top: 48px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  margin-right: 48px;
  // width: 320px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-right: auto;
  margin-bottom: 24px;
`;

const SaveButton = styled(Button)`
  min-width: 128px;
  width: auto;
  height: auto;
  padding: 13px 25px;
  border: 3px solid transparent;
  color: #FFF;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  background-color: #9077F5;
  margin-left: 20px;
`;

const CancelButton = styled(SaveButton)`
  margin-left: 0;
  background-color: #FFF;
  border-color: #9C9C9C;
  color: #9C9C9C;
  :hover:not(:disabled) {
    background-color: #FFF;
    border-color: #F87700;
    color: #F87700;
  }
`;

const Label = styled(Form.Item)`
  font-size: 16px;
  margin-bottom: 16px;
  // display: block;
`;

const ChangeGenderStyled = styled(ChangeGender)`
  margin: 0;
  margin-top: 10px;
  padding: 0;
`;
