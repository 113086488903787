import { scaleLinear } from 'd3-scale';
import React from 'react';
import styled from 'styled-components/macro';

const mask = require('./images/fakeGraphics/mask.svg');


const graphImagesArray = [
  require('./images/fakeGraphics/graph.svg'),
  require('./images/fakeGraphics/graph1.svg'),
  require('./images/fakeGraphics/graph2.svg'),
  require('./images/fakeGraphics/graph3.svg'),
  require('./images/fakeGraphics/graph4.svg'),
  require('./images/fakeGraphics/graph5.svg'),
  require('./images/fakeGraphics/graph6.svg'),
  require('./images/fakeGraphics/graph7.svg'),
  require('./images/fakeGraphics/graph8.svg'),
];


interface SummaryExamGraphProps {
  standardAgeScore: number,
  score: number
}


const percentsScale = scaleLinear().domain([70, 130]).range([22, 76.5]);


const starlineScale = scaleLinear().domain([60, 130]).range([0, 8]);

//65-75,75-85,86-95
// 1 .   2 .   3 

const starLines = [65,75,85,95,105,115,125,135];
function getStarLine(sas: number) {
  // const i = starLines.findIndex(l => sas < l + 1);
  // console.log({sas, i, LINE: starLines[i]});
  return starLines.findIndex(l => sas < l + 1);
}



const SummaryExamGraph = ({ standardAgeScore, score }: SummaryExamGraphProps) => {
  const index = getStarLine(standardAgeScore) //Math.round(starlineScale(standardAgeScore - 5))//Math.round(score / 10) - 1
  const currentGraph = graphImagesArray[index];

  return (
    <Wrapper>
      <GraphMask src={mask} />
      <GraphImage src={currentGraph} />
      {/* 0 - 99% string */}
      <Indication leftPosition={`${percentsScale(standardAgeScore)}%`} />
    </Wrapper>
  );
};

export default SummaryExamGraph;

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 45px;
  position: relative;
  overflow: hidden;
`;

const GraphImage = styled.img`
  width: 100%;
  height: 70%;
`;

const GraphMask = styled(GraphImage)`
width: 101%;
  height: 100%;
  position: absolute;
  top: -1px;
  left: -2px;
  right: -2px;
  bottom: 0;
  z-index: 10;
`;

const Indication = styled.div<{leftPosition: string}>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  left: ${({ leftPosition }) => leftPosition};
  bottom: 6.1%;
  background-color: #9077F5;

  ::before {
    content: '';
    /* max-height: 100%; */
    height: calc(100vh - 20px);
    border-left: 3px dashed #C6BAF5;
    position: absolute;
    bottom: 20px;
    left: 8px;
  }
`;
