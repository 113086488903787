import React, { ReactNode } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import {
  BellIcon,
} from '../../images/iconSvg';

interface TypeProps {
  children: ReactNode;
  className?: string;
}

const Notification = ({ children, className }: TypeProps) => {
  return (
    <Container className={className}>
      <BellIconStyled />
      {children}
    </Container>
  );
};

export default Notification;

const DropAnimation = keyframes`
  0% {
    transform: translateY(-100%);
  }

  5% {
    transform: translateY(0%);
    opacity: 1;
  }

  95% {
    transform: translateY(0%);
    opacity: 1;
  }

  100% {
    transform: translateY(-100%);
  }
`;

const Container = styled.div`
  width: 1035px;
  height: 45px;
  border-radius: 10px;
  background-color: #95D354; 
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #FFF;
  transform: translateY(-100%);
  opacity: 0;
  animation: ${DropAnimation} 5s linear;
`;

const BellIconStyled = styled(BellIcon)`
  font-size: 25px;
  margin-right: 12px;
`;
