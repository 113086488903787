import React from 'react';
import styled from 'styled-components/macro';
const copyImage = require('../../../images/copyImage.svg');

interface ShareCodeBlockProps {
  className?: string
  code?: string
  onShare: () => void
}

export default function ShareCodeBlock({ className, code, onShare }: ShareCodeBlockProps) {
  return (
    <ShareTitle className={className} onClick={onShare}>
      Share group code: &nbsp;
      {code}
      <CopyButton />
    </ShareTitle>
  );
}

const ShareTitle = styled.div`
  cursor: pointer;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: #9077F5;
  display: flex;
`;

const CopyButton = styled.button`
  background: url(${copyImage}) no-repeat center;
  background-color: transparent;
  width: 26px;
  height: 26px;
  border: none;
  cursor: pointer;
  margin-left: 12px;
`;
