import React from 'react';
import { useSelector } from 'react-redux';
import { useGetPriceList } from '../../Admin/UserEdit/hooks';
import { tariffNameById } from '../../config/tariffConfig';
import { avatars } from '../../database/avatars';
import { getUserProfile } from '../../features/user/userState';
import { useGetChallengeHistory } from '../../hooks/useGetChallengesHistory';
import { PayPlanPaypal, PayPlanStripe, PayPlanType } from '../../ProfilePage/Subscription/types';
import ChildStatisticsLayout from './ChildStatisticsLayout';

const ChildStaisticsBoard = () => {

  const user = useSelector(getUserProfile);

  const userAvatar = avatars.find((item) => item.avatarId === user.avatar);

  // const priceList = useGetPriceList();

  // const userPlanStripe = priceList.stripe.find((item: PayPlanStripe) => item.id === user.priceId);
  // const userPlanPayPal = priceList.paypal.find((item: PayPlanPaypal) => item.id === user.priceId);

  // const userPlan = userPlanStripe || userPlanPayPal;

  const currentTariffName = tariffNameById[user.priceId || ''] || 'Inactive';

  const userBirth = new Date(user.dateOfBirth || '');

  const currentDate = new Date();

  const getYear = () => {
    const yearNum = currentDate.getFullYear() - userBirth.getFullYear();
    const age = userBirth.getMonth() > currentDate.getMonth() ? yearNum - 1 : yearNum;
    return age;
  };

  const examsNumber: number = useGetChallengeHistory('exam').length;

  const testsNumbers: number = useGetChallengeHistory('test').length;

  const examDate = () => {
    const date = new Date(user.nextExamDate || '');

    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  };

  return (
    <ChildStatisticsLayout
      user={user}
      userAvatar={userAvatar}
      currentTariffName={currentTariffName}
      userAge={getYear()}
      examsNumber={examsNumber}
      testsNumber={testsNumbers}
      examDate={examDate()}
    />
  );
};

export default ChildStaisticsBoard;
