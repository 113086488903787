import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';

import {
  Formik,
  // Form,
} from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import ChangeDate from '../ProfilePage/PersonalInformation/ChangeDate';
import ChangeGender from '../ProfilePage/PersonalInformation/ChangeGender';
import FirstAndLastNameInput from '../ProfilePage/PersonalInformation/FirstAndLastNameInput';
import SignUpSteps from './SignUpSteps';
import FormElements from '../Form';
import {
  profileValidator,
} from '../ProfilePage/PersonalInformation/PersonalInformation';
import InputSelect, {
  SchoolsListTypes,
} from '../ProfilePage/AcademicInformation/InputSelect';
import LabelForInput from '../Form/FieldsEntity/LabelForInput';
// import { schoolsList } from "../ProfilePage/AcademicInformation/AcademicInformation";
import TargetSchoolsList from '../ProfilePage/AcademicInformation/TargetSchoolsList';

import {
  getProfileFieldErrors,
  getUserProfile, loadProfile, profileFormActions, updateProfile,
} from '../features/user/userState';
import { useChangeValue, ErrorsType } from '../utils/createForm';
import { InputError } from '../Form/FieldsEntity/FormInput';
import { SchoolType, UserProfileOnInitType, UserProfileType } from '../features/user/types';
import { avatars } from '../database/avatars';
import ModalChangeAvatar from '../ProfilePage/PersonalInformation/ModalChangeAvatar';

const labelCurrentSchoolInfo = 'Please enter student’s current school.';

const { Button, Radio } = FormElements;

/*
interface InitialProfileInformationType {
  fullName: string;
  dateOfBirth: string;
  email: string;
  password: string;
  gender: string;
  avatar: string;
} */


const FormProfileInformation = () => {
  const [disabledChangeDate, setDisabledChangeDate] = useState(false);
  const [showAvatars, setShowAvatars] = useState(false);
  const { push } = useHistory();

  // const formSubmit = (e: React.FormEvent) => {
  //   push(`${process.env.PUBLIC_URL}/sign-up-steps/2/subscribe`);
  //   e.preventDefault();
  // };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadProfile());
  }, []);

  const initialValuesFromBackend: UserProfileType = useSelector(getUserProfile);

  const initialValues: UserProfileOnInitType = {
    ...initialValuesFromBackend,
  };

  const { currentSchool, priceId } = initialValues;


  useEffect(() => {
    if (priceId) {
      push(`${process.env.PUBLIC_URL}/dashboard/new-test`);
    }
  }, [initialValues]);

  const newAvatarsList = avatars.slice(0, 14);

  return (
    <SignUpSteps>
      {initialValues.fullName && (
      <Formik
        initialValues={{
          fullName: initialValues.fullName,
          dateOfBirth: initialValues.dateOfBirth,
          gender: initialValues.gender,
          avatar: initialValues.avatar,
        }}
        validate={(values: UserProfileOnInitType) => {
          // console.log('validate---', values);
          const errors: ErrorsType = {};

          const profileErrors = profileValidator({
            fullName: values.fullName,
            dateOfBirth: values.dateOfBirth,
            gender: values.gender,
            avatar: values.avatar,
          });

          // if (!values.currentSchool) {
          //   errors.currentSchool = ['Current school is required'];
          // }

          if (!values.targetSchools) {
          }

          /*
          if (!values.targetSchools && values.targetSchools.length) {
            errors.targetSchools = ['Target schools is required'];
          } */

          console.log('errors==', { ...errors, ...profileErrors });

          return { ...errors, ...profileErrors };
        }}
        onSubmit={(values) => {
          console.log('submit====', values);
          dispatch(updateProfile(values));
          push(`${process.env.PUBLIC_URL}/sign-up-steps/2/subscribe`);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form id="step-profile-information" onSubmit={handleSubmit}>
            <Title>1. Set up your student account</Title>
            <Section>
              <TitleForSection>Personal Information</TitleForSection>
              <ContentForSection>
                <FirstAndLastNameInput
                  nameValue={values.fullName}
                  onChangeFullName={(name: string) => setFieldValue('fullName', name)}
                  errors={errors.fullName}
                />

                <TitleForInputs>Date of birth</TitleForInputs>
                <DateInputWrapper>
                  <ChangeDate
                    dateValue={values.dateOfBirth}
                    onChange={(date: string) => {
                      console.log('date=---', date);
                      setFieldValue('dateOfBirth', date);
                    }}
                    errors={errors.dateOfBirth}
                  />
                  {/* <ChangeGender
                    value={values.gender}
                    onChangeGender={(gender: string) => setFieldValue('gender', gender)}
                  /> */}
                </DateInputWrapper>

                <TitleForInputs>Select an avatar</TitleForInputs>
                <AvatarsWrapper>
                  {newAvatarsList.map((item: any) => (
                    <RadioStyled
                      onChange={() => setFieldValue('avatar', item.avatarId)}
                      key={item.avatarId}
                      checked={item.avatarId === values.avatar}
                      name="avatar-profile"
                    >
                      <Image src={item.avatarImg} />
                    </RadioStyled>
                  ))}
                </AvatarsWrapper>
                <ModalChangeAvatar
                  onCancel={() => setShowAvatars(false)}
                  onChangeAvatar={(avatarId) => setFieldValue('avatar', avatarId)}
                  showModal={showAvatars}
                  value={values.avatar}
                />
                <MoreAvatarsButton type="button" onClick={() => setShowAvatars(true)}>See more avatars</MoreAvatarsButton>
                <InputError errors={errors.avatar} />
              </ContentForSection>
            </Section>
            <Section>
              <TitleForSection>Academic Information</TitleForSection>
              <ContentForSection>
                <CurrentSchoolLabel
                  name="Current school"
                  labelInfo={labelCurrentSchoolInfo}
                >
                  <InputSelectStyled
                    onChangeSchool={(target: string) => setFieldValue('currentSchool', target)}
                    targetSchool={initialValues.currentSchool?.name}
                    error={
                        !!(errors?.currentSchool && errors.currentSchool.length > 0)
                      }
                  />
                  <InputError errors={errors.currentSchool} />
                </CurrentSchoolLabel>

                <TitleForInputs>Approximate Exam Date</TitleForInputs>
                <DateInputWrapper>
                  {!disabledChangeDate && (
                  <ChangeDate
                    dateValue={values.nextExamDate}
                    onChange={(date: string) => setFieldValue('nextExamDate', date)}
                    errors={errors.nextExamDate}
                  />
                  )}
                  <RadioDisabledDate
                    onClick={() => setDisabledChangeDate(!disabledChangeDate)}
                    checked={disabledChangeDate}
                  >
                    Don’t know/not applicable
                  </RadioDisabledDate>
                </DateInputWrapper>

                <TargetSchoolsList
                  schoolsList={[]}
                  targetSchoolsList={initialValues.targetSchools || []}
                  onChangeTargetSchools={(
                    targetSchools: string[],
                  ) => {
                    setFieldValue('targetSchools', targetSchools);
                  }}
                  errors={errors.targetSchools}
                />
              </ContentForSection>
            </Section>
            <SaveButton type="submit" form="step-profile-information">
              Next step
            </SaveButton>
          </Form>
        )}
      </Formik>
      )}
    </SignUpSteps>
  );
};

export default FormProfileInformation;

const Form = styled.form`
  display: block;
  background-color: #fff;
  padding: 52px;
  border-radius: 10px;
  box-shadow: 0px 2px 8px #f0f2f3;
`;

const Title = styled.h1`
  font-weight: bold;
  fon-size: 28px;
  line-height: 34px;
  margin-bottom: 65px;
  text-align: center;
`;

const Section = styled.div`
  display: flex;
  border-bottom: 2px solid #e0e0e0;
  box-sizing: border-box;
  padding: 0 30px 30px;
  margin-top: 60px;
  :nth-child(2) {
    padding-bottom: 50px;
  }
`;

const TitleForSection = styled.h2`
  max-width: 175px;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #9077f5;
  margin-right: 53px;
  margin-top: 20px;
`;

const ContentForSection = styled.div`
  width: 100%;
`;

const TitleForInputs = styled.h3`
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 11px;
`;

const DateInputWrapper = styled.div`
  display: flex;
  margin-bottom: 38px;
`;

const AvatarsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Image = styled.img`
  width: 72px;
  height: 72px;
`;

const RadioStyled = styled(Radio)`
  margin: 0 7px 15px;
  & span {
    flex-direction: column;
    :before {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  & > input:checked + span::before {
    background-color: #9077f5;
  }
`;

const InputSelectStyled = styled(InputSelect)`
  max-width: 570px;
`;

const CurrentSchoolLabel = styled(LabelForInput)`
  margin-bottom: 40px;
`;

const RadioDisabledDate = styled(Radio)`
  margin-left: 26px;
  align-self: baseline;
  margin-top: 34px;
  :not(:last-child) {
    margin-right: 20px;
  }

  & > span {
    font-size: 16px;
    font-weight: normal;
    :before {
      width: 25px;
      height: 25px;
    }
  }

  & > input:checked + span::before {
    background-color: #9077f5;
  }
`;

const SaveButton = styled(Button)`
  min-width: 128px;
  width: auto;
  height: auto;
  padding: 13px 82px;
  border: 3px solid transparent;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  background-color: #9077f5;
  margin-left: auto;
  margin-top: 56px;
`;

const MoreAvatarsButton = styled(SaveButton)`
  background-color: #FFF;
  border-color: #9077F5;
  color: #9077F5;
  margin: 56px auto 0;

  :hover {
    border-color: transparent;
    color: #FFF;
  }
`;
