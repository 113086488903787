import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components/macro';
import { finishChallenge } from '../features/challenge/challengeState';
import { QuestionType } from '../features/challenge/types';
import { RouteType } from '../features/topic/types';
import ExampleTextCard, { ExampleType } from './ExampleCardText';
import ModalSubmittingAnswers from './ModalSubmittingAnswers';
import StrictChallengeAnswerControl from './StrictChallengeAnswerControl';
import StrictChallengeDescrip from './StrictChallengeDescrip';
import StrictChallengeLayout, { StrictButton } from './StrictChallengeLayout';
import StrictChallengeSwitchs from './StrictChallengeSwitchs';

const logoImg = require('../images/logo.svg');

interface StrictChallengeBodyProps {
  subjectName?: string
  currentQuestion: QuestionType
  setSelectedAnswer: (answer: string | undefined) => void,
  selectedAnswer: string | undefined,
  onFinishChallenge: () => void,
  questionAnswered: boolean
  onBack: () => void,
  onNext: () => void
  onEndExam: () => void
  isSelectedAnswer: boolean
}

const StrictChallengeBody = ({
  subjectName,
  selectedAnswer,
  setSelectedAnswer,
  currentQuestion,
  questionAnswered,
  onFinishChallenge,
  onBack,
  onNext,
  onEndExam,
  isSelectedAnswer,
}: StrictChallengeBodyProps) => {
  const { finish } = useParams<RouteType>();
  // const [selectedAnswer, setSelectedAnswer] = useState<string | undefined>(currentQuestion?.userAnswer);
  const [showStatusLoad, setShowStatusLoad] = useState(false);

  const dispatch = useDispatch();

  if (finish === 'quit-exam') {

    const endExam = () => {
      setShowStatusLoad(true);
      onEndExam();
    };

    return (
      <ContentWrapper>
        <Board>
          {showStatusLoad && (
            <ModalSubmittingAnswers />
          )}
          <TitleForEndExam>
            End of exam
          </TitleForEndExam>
          <DescriptioForEndExam>
            That is the end of exam.
            <br />
            <br />
            Click on “End test” now.
          </DescriptioForEndExam>
          <StrictButtonStyled onClick={endExam}>End test</StrictButtonStyled>
        </Board>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      <Board>
        <Title>{subjectName}</Title>
        <Wrapper>
          {currentQuestion?.text && (
            <ExampleTextCard text={currentQuestion.text } title={currentQuestion?.['text-title']} />
          )}
          <AnswerControlWrap>
            <StrictChallengeDescrip
              description={currentQuestion}
              widgetView={currentQuestion?.text ? true : undefined}
            />
            <StrictChallengeAnswerControl
              userAnswer={selectedAnswer}
              itemsFromButton={currentQuestion}
              column={currentQuestion?.text ? true : undefined}
              onSelectAnswer={(answer) => setSelectedAnswer(answer)}
            />
          </AnswerControlWrap>
        </Wrapper>
      </Board>
      <StrictChallengeSwitchs
        onBack={onBack}
        onNext={onNext}
        onFinish={onFinishChallenge}
        questionAnswered={questionAnswered}
        show={currentQuestion ? true : false}
        isSelectedAnswer={isSelectedAnswer}
      />
    </ContentWrapper>
  );
};

export default StrictChallengeBody;

const ContentWrapper = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
`;

const Board = styled.div`
  border: 6px solid #04CB9A;
  border-radius: 4px;
  padding: 20px;
  min-height: calc(100vh - 260px);
  display: flex;
  flex-direction: column;
  
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
`;

const AnswerControlWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TitleForEndExam = styled.h1`
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  margin-bottom: 34px;
`;

const DescriptioForEndExam = styled.p`
  font-size: 16px;
  line-height: 18px;
  max-width: 231px;
`;

const StrictButtonStyled = styled(StrictButton)`
  margin: 0 auto;
`;
