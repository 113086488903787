import React, { ReactChild } from 'react';
import styled from 'styled-components/macro';

const logoImg = require('../images/logo.svg');

interface ComponentProps {
  children: ReactChild
}

const PageWraperForTeacherSetUp = ({
  children,
}: ComponentProps) => {
  return (
    <Page>
      <LogoImg src={logoImg} alt="logo" />
      {children}
    </Page>
  )
}

export default PageWraperForTeacherSetUp;

const Page = styled.div`
  min-height: 100vh;
  padding: 22px 80px 200px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
`;

const LogoImg = styled.img`
  margin-bottom: 118px;
  align-self: flex-start;
`;
