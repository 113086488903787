import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components/macro';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { debug } from 'console';
import StartButton from '../Form/FieldsEntity/StartButton';
import {
  ClockIcon,
  QuestionIcon,
} from '../images/iconSvg';
import Page from './PageForExam';
import FormElements from '../Form';

import {
  startChallenge, getCurrentChallenge, selectCurrentChallenge, addQuestion,
} from '../features/challenge/challengeState';
import colorStyle from '../colorStyle';
import { examsConf } from '../config/examsConf';
import { examsInformation } from '../config/examsInformation';
import Modal from '../Form/FieldsEntity/Modal';
import InstructionModal from './InstructionModal';


const otterScooter = require('../images/otter-scooter.svg');

const ExamStartInfo = () => {
  const {
    subject, topic, subtopic, challengeType, topicAreas,
  } = useParams<{ subject: string, topic: string, topicAreas: string, subtopic: string, challengeType: keyof typeof colorStyle }>();
  const [showInstruction, setShowInstruction] = useState(false);
  const [disableStartButton, setDisableStartButton] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const titleOnStartPage = subtopic || topicAreas || topic || subject;

  // challenge/current

  const onStartChallenge = () => {
    setDisableStartButton(true);
    dispatch(startChallenge({
      subject, topic, topicAreas, subtopic,
    }, challengeType));
  };

  useEffect(() => {
    dispatch(getCurrentChallenge());
  }, []);

  const currentChallenge = useSelector(selectCurrentChallenge);

  const challengeTime = challengeType === 'exam' ? examsConf[subject].time : 30;

  const challengeQuestionsNum = examsConf[subject].questions;

  useEffect(() => {
    // dispatch(addQuestion());
    if (currentChallenge._id) {
      const { length } = currentChallenge.questions;
      const lastAnsweredQuestionNum = length; //currentChallenge.questions.findIndex(q => !q.userAnswer);
      const currentQuestion = (length === 0) ? 1 : lastAnsweredQuestionNum;
      history.push(`${process.env.PUBLIC_URL}/challenge/${challengeType}/current/${currentQuestion}`);
    }
  }, [currentChallenge]);

  return (
    <ThemeProvider theme={colorStyle[challengeType].theme}>
      <Page>
        {showInstruction && challengeType === 'exam' && (
          <ModalStyled onClose={() => setShowInstruction(false)}>
            <InstructionModal
              onStart={onStartChallenge}
              subject={subject}
              disabledButton={disableStartButton}
            />
          </ModalStyled>
        )}
        <Wrapper>
          <TitleWrapper>
            <Title>{titleOnStartPage}</Title>
            <ReturnButton onClick={() => history.goBack()}>Return to dashboard</ReturnButton>
          </TitleWrapper>
          <Header>
            <TestType>{challengeType === 'exam' ? 'Mock exam' : 'Practice test'}</TestType>
            <SubjectTest>
              Subject:
              {' '}
              {subject}
            </SubjectTest>
            {challengeType === 'test' && (
              <Topic>
                Topic:
                {' '}
                {topic}
              </Topic>
            )}
          </Header>
          {challengeType === 'exam' && (
            <NumberOfQuestions>
              <QuestionIconStyled />
              {challengeQuestionsNum}
              {' '}
              questions
            </NumberOfQuestions>
          )}
          <AllottedTime>
            <ClockIconStyled />
            {challengeTime}
            {' '}
            minutes
          </AllottedTime>
          {/* {challengeType === 'exam' && (
            <Description>
              {examDescription}
            </Description>
          )} */}
          <StartButtonStyled
            onClick={() => (challengeType === 'exam' ? setShowInstruction(true) : onStartChallenge())}
            typeStart={challengeType}
            iconSize="35px"
            disabled={disableStartButton}
          >
            Start
            {' '}
            {challengeType === 'exam' ? challengeType : ''}
          </StartButtonStyled>
        </Wrapper>
      </Page>
    </ThemeProvider>
  );
};

export default ExamStartInfo;

const ModalStyled = styled(Modal)`
  margin-top: 32px;
`;

const Wrapper = styled.div`
  max-width: 1005px;
  min-height: 600px;
  width: 100vw; 
  background: url(${otterScooter}) no-repeat right 98px bottom 24px;
  box-sizing: border-box;
  padding: 50px 48px 84px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 40px;
  line-height: 42px;
  padding-left: 12px;
  margin: 0;
`;

const Header = styled.div`
  display: flex;
  box-sizing: border-box;
  padding-left: 12px;
  padding-bottom: 8px;
  border-bottom: 2px solid #E0E0E0;
  margin-bottom: 50px;
`;

const TestType = styled.span`
  display: block;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #9C9C9C;
  margin-right: 50px;
`;

const SubjectTest = styled(TestType)`

`;

const Topic = styled(SubjectTest)`
  margin-right: 0;
`;

const NumberOfQuestions = styled.span`
  display: block;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 12px;
  margin-bottom: 20px;
  height: 40px;
`;

const AllottedTime = styled(NumberOfQuestions)`
  margin-bottom: 40px;
`;

const ClockIconStyled = styled(ClockIcon)`
  margin-right: 20px;
  font-size: 34px;
  & svg {
    fill: ${({ theme }) => theme.color};
  }  
`;

const QuestionIconStyled = styled(QuestionIcon)`
  margin-right: 20px;
  font-size: 34px;
  & svg {
    fill: ${({ theme }) => theme.color};
  }
`;

const StartButtonStyled = styled(StartButton)`
  margin-top: auto;
  margin-left: 12px;
  background-color: ${({ theme }) => theme.color};
  width: fit-content;
  & span {
    color: #FFF;
  }
  & svg {
    fill: #FFF;
  }
  :hover {
    background-color: ${({ theme }) => theme.hoverColor};
  }
`;

const Description = styled.p`
  max-width: 447px;
  font-size: 20px;
  line-height: 26px;
  padding-left: 12px;
  margin-bottom: 48px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const ReturnButton = styled.button`
  background-color: #FFF;
  border-radius: 10px;
  border: 3px solid #9C9C9C;
  padding: 9px 22px;
  color: #9C9C9C;
  font-size: 16px;
  line-height: 26px;
  font-weight: bold;
  cursor: pointer;

  :hover {
    color: #F55F4B;
    border-color: #F55F4B;
  }
`;
