import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import Accordion from '../Form/FieldsEntity/Accordion';
import Layout from '../Layout';
import Footer from '../Layout/Footer/Footer';

const faqs = [
  {
    question: 'Does Otter only cover the ISEB 11+?',
    description: 'Far from it! While the ISEB Common Pre-Test is the only fully online 11+ exam at the moment, it looks like many others are heading in that direction. We also cover GL, CEM, London Consortium, UKIset, Grammar Schools and school-specific 11+. Simply find your school in our list and we’ll tailor the learning from there.',
  },
  {
    question: 'How often should my child use Otter?',
    description: 'We get this question a lot! The answer is somewhat dependent on each individual child and where they are in their learning. We find our users typically find some time 5-6 times per week to practice on our platform. In the run-up to the exam, we recommend focusing on our mock exams to let your child get the sense of timing and working under pressure. Speak to one of our education experts for more advice.',
  },
  {
    question: 'Should I sit down with my child to work with Otter?',
    description: 'Otter is designed so that children can progress independently though all our topic blocks. However, it is also very effective for parents and tutors to work together with children on our platform.',
  },
  {
    question: 'What about the mock tests?',
    description: 'Once you have progressed through and covered the learning in all the topic areas then the mock tests will be your focus. We recommend sitting with the laptop in a quiet place so your child can get the send of the real exam while they work through the questions. In the run-up to the exam, regular mock tests are very important!',
  },
  {
    question: 'What subjects do you cover?',
    description: 'We currently only offer English, maths, verbal reasoning and non-verbal reasoning. However, more are on the way so watch this space!',
  },
  {
    question: 'Does Otter work with private tutors?',
    description: 'Many private tutors use our platform as a tool to prepare children for the 11+. We are actually partners with the UK’s leading tuition providers and many of our in-house team are experienced tutors. Our platform has been designed with tutor input and is highly effective when used together with a tutor.',
  },
  {
    question: 'Are your mock exams adaptive like the real exam?',
    description: 'Yes! We have invested in the most advanced AI technology that replicates the real dynamic adaptive exam that your child will have to sit. The Otter AI monitors every question that your child answers and the next question is chosen based on their answer.',
  },
  {
    question: 'What is the difference between the packages you offer?',
    description: 'With our most basic package, you can choose one subject of English, maths verbal and non-verbal reasoning and have unlimited mock tests in that subject. With our standard package, you have access to all of English, maths verbal and non-verbal reasoning and have unlimited mock tests in each. With Otter Plus, our premium package, you have full access to all subjects, unlimited mock exams and are appointed an experienced tutor who is on hand ANY time to answer your or your child\'s questions in the pop-up chat window in real-time.',
  },
  {
    question: 'What about data use?',
    description: 'Data protection is very important to us. We securely store any details you provide and your information is not retained if you cancel. As part of our adaptive questions and to calculate your child’s score, we require their DOB and also ask for first name, surname, current school and target schools. The data you provide us with is not used for any kind of marketing and we comply fully with GDPR.',
  },
  {
    question: 'How can I cancel my subscription?',
    description: 'If you wish to cancel your subscription, you need to click on your avatar on the top right of the page. After clicking on the avatar a table will unroll; there you should click on Subscription. This action will transfer you to the Subscription menu where you can edit or cancel your subscription. If after cancelling you wish to restore your subscription, with exactly the same actions you can go back to the Subscription menu and restore your subscription by clicking on Renew subscription button.',
  },
  {
    question: 'What if I need to make a second account for my other child?',
    description: 'The Otter system is adaptive and the AI tailors questions and the learning for each individual based on their strengths and weaknesses.  It is therefore very important for siblings to have separate accounts.  To make a separate account simply follow the sign-up process again as usual but using a separate email address.',
  },
];

const FaqPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Page>
        <Title>Frequently Asked Questions</Title>
        <WrapperAccordions>
          {faqs.map((items: {question: string, description: string}) => (
            <AccordeonStyled
              title={<AccordionTitle>{items.question}</AccordionTitle>}
              closedColor="#EEEAFF"
            >
              <Description>
                {items.description}
              </Description>
            </AccordeonStyled>
          ))}
        </WrapperAccordions>
      </Page>
      <Footer />
    </Layout>
  );
};

export default FaqPage;

const Page = styled.div`
  padding: 100px 24px 166px;
  height: 100%;
`;

const Title = styled.h1`
  font-size: 50px;
  line-height: 50px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 100px;
`;

const WrapperAccordions = styled.div`
  max-width: 1047px;
  margin: 0 auto;
  list-style: none;
`;

const AccordeonStyled = styled(Accordion)`
  min-height: 120px;
  margin-bottom: 18px;
  padding: 40px;
`;

const AccordionTitle = styled.h2`
  font-size: 28px;
  line-height: 34px;
  font-weight: bold;
  margin-bottom: 0;
  margin-right: 16px;
`;

const Description = styled.p`
  margin-top: 34px;
  font-size: 18px;
  line-height: 30px;
`;
