import { range } from 'lodash';
import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components/macro';

export interface ExampleType {
  type?: string
  text: string
  title?: string
  content?: string
}

const lineHeight = 20;

const ExampleTextCard = ({ text, title }: ExampleType) => {
  const [colonsCount, setColonsCount] = useState(0);
  const [currentColon, setCurrentColon] = useState(0);
  const [wHeight, setWHeight] = useState(0);

  const wrapperRef = useRef<HTMLInputElement>(null);
  const textRef = useRef<HTMLInputElement>(null);

  function updatePages() {
    setWHeight(0);

    if (wrapperRef && wrapperRef.current && textRef && textRef.current) {
      const newColonsCount = Math.ceil(textRef.current.clientHeight / wrapperRef.current.clientHeight);
      setColonsCount(newColonsCount);

      setCurrentColon(0);

      if (wrapperRef.current.clientHeight % lineHeight > 0) {
        const newHeight = wrapperRef.current.clientHeight - (wrapperRef.current.clientHeight % lineHeight);
        setWHeight(newHeight);
      }
    }
  }

  useLayoutEffect(() => {
    updatePages();
    window.addEventListener('resize', updatePages);
    return () => window.removeEventListener('resize', updatePages);
  }, [wrapperRef, textRef]);

  function getTextTop() {
    return (wrapperRef && wrapperRef.current) ?
      -currentColon * wrapperRef.current.clientHeight : 0;
  }

  return (
    <TextCardWrapper>
      <ExampleTextPaddingWrapper>
        <ExampleTextWrapper ref={wrapperRef} style={(wHeight > 0) ? { height: wHeight } : {}}>
          <ExampleText
            ref={textRef}
            style={{ top: getTextTop() }}
          >
            <ExampleTitle>{title}</ExampleTitle>
            <TextWithLineNumbers>{text}</TextWithLineNumbers>
          </ExampleText>
        </ExampleTextWrapper>
      </ExampleTextPaddingWrapper>
      <ButtonsWrapper>
        {range(colonsCount).map((key: number) => (
          <SwitchButton
            className={(key === currentColon) ? 'selected' : ''}
            key={`colon-btn-${ key }`}
            onClick={() => setCurrentColon(key)}
          >
            {key + 1}
          </SwitchButton>
        ))}
      </ButtonsWrapper>
    </TextCardWrapper>
  );
};

export default ExampleTextCard;

interface IProps {
    children: string;
}


const TextWithLineNumbers = ({ children }:IProps) => {
  const numbersRef = useRef<HTMLInputElement>(null);
  const [maxNumber, setMaxNumber] = useState(10);
  useEffect(() => {
    if (numbersRef && numbersRef.current) {
      setMaxNumber(Math.round(numbersRef.current.clientHeight / lineHeight));
    }
  });
  return (
    <Text ref={numbersRef}>
      <ReactMarkdownStyled>
        { children }
      </ReactMarkdownStyled>
      <LineNumbers ref={numbersRef}>
        {range(maxNumber).map((lineNum: number) => (
          <div>
            { (lineNum === 0 || (lineNum + 1) % 5 === 0) ? lineNum + 1 : <br /> }
          </div>
        ))}
      </LineNumbers>
    </Text>
  );
};

const TextCardWrapper = styled.div`
  max-width: calc(200px + 25%);
  border: 4px solid #a0a0a0;
  background-color: #a0a0a0;
  border-radius: 2px;
  flex-grow: 0;
  flex-shrink: 0;
  align-self: baseline;
  margin: 0 64px 0 32px;
`;

const ExampleTitle = styled.h6`
  font-size: 25px;
  margin-bottom: 0;
  text-align: center;
  font-weight: bold;
  font-style: italic;
  line-height: ${lineHeight * 2}px;
`;

const ExampleTextPaddingWrapper = styled.div`
  background-color: #F6F8F9;
  padding: 10px;
`;

const ExampleTextWrapper = styled.div`
  background-color: #F6F8F9;
  max-height: calc(100vh - 450px);
  overflow: hidden;
  text-overflow: '';
`;

const ExampleText = styled.p`
  background-color: #F6F8F9;
  padding-right: 10px;
  padding-left: 30px;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  top: 0px;
  position:relative;
  line-height: ${lineHeight}px;
`;

const ButtonsWrapper = styled.div`
  padding: 15px;
  padding-bottom: 11px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const SwitchButton = styled.button`
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: 2px solid gray;
  border-radius: 4px;
  font-weight: bold;
  :not(:last-child) {
    margin-right: 8px;
  }
  &.selected {
    background-color: #a0a0a0;
    border: 2px solid blue;
    border-radius: 4px;
  }
`;

const LineNumbers = styled.div`
  position: absolute;
  height: 100%;
  width: 20px;
  top: 0px;
  left: -25px;
  line-height: ${lineHeight}px;
  font-weight: bold;
`;

const Text = styled.div`
    transform: rotate(0deg);
`;

const ReactMarkdownStyled = styled(ReactMarkdown)`
  & p {
    margin-bottom: ${lineHeight}px;
  }
`;
