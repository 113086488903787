import { ApiChangeGroup, ApiCreateGroup, ApiDeleteFromGroup, ApiGetOneGroup, ApiGroupList } from '../../project-types/groups/api-types';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import { ApiCreateTeacher, ApiDeleteStudent, ApiGetStudents } from '../../project-types/teacher/api-types';
import { GroupFields, GroupOutType } from '../../project-types/groups/types';

import { request } from '../../api';

import { createAxiosConfig } from '../../project-types/common/axios';
import { HTTP_METHODS } from '../../project-types/common/http-types';
import { signUpActions } from '../user/userState';
import { UserPublicType } from '../../project-types/users/types';
import { ApiChangeAssignment, ApiChangeQuestion, ApiCreateAssigment, ApiCreateQuestions, ApiGetAssignmentsList, ApiGetOneAssignment } from '../../project-types/assignments/api-types';
import { AssignmentFields, AssigmentTypes } from '../../project-types/assignments/types';

interface initialType {
  signUp: Pick<ApiCreateTeacher['data'], 'fullName' | 'password'>,
  isTeacherRegistered: boolean
  teacherGroups: GroupFields[]
  teacherStudents: UserPublicType[]
  teacherGroup: GroupOutType | null,
  assignment: AssignmentFields | null,
  assignments: AssignmentFields[],
}

const initialValue: initialType = {
  signUp: {
    fullName: '',
    password: '',
  },
  isTeacherRegistered: false,
  teacherGroups: [],
  teacherStudents: [],
  teacherGroup: null,
  assignment: null,
  // assignment: {
  //   topicId: [
  //     "Maths",
  //     "Algebra"
  //   ],
  //   _id: "610476a1ea89270b2d8d3f74",
  //   group: "61025470c74e553f415059db",
  //   name: "tmp_assignments",
  //   type: AssigmentTypes.REVISION,
  //   dueDate: "0122-12-31T20:39:40.000Z",
  //   questions: [
  //     {
  //       "answers": [
  //         "160 ",
  //         "128 ",
  //         "256 ",
  //         "96 "
  //       ],
  //       "path": [
  //         "Maths",
  //         "Algebra",
  //         "Sequences",
  //         "Special sequences",
  //         "12"
  //       ],
  //       "_id": "61047a72ea89270b2d8d3f8a",
  //       "question": "What is the sum of the missing numbers in the sequence?\n\n\n*The sequence:* 4, 8, 16, __ , 64, __ .",
  //       "variables": {
  //         "a": 4,
  //         "_answersorder": [
  //           0,
  //           2,
  //           1,
  //           3
  //         ]
  //       },
  //       "svg": "",
  //       "answer-type": "multiple",
  //       "templateId": "6021bccf75a55e00071dff07",
  //       "explanation": "",
  //       "correctAnswer": "0"
  //     },
  //   ],
  //   maxLevel: 6,
  //   minLevel: 1,
  // },
  assignments: [],
};

type actionSignUpValues = {
  payload: Pick<ApiCreateTeacher['data'], 'fullName' | 'password'>
}

type actionIsTeacherRegistered = {
  payload: initialType['isTeacherRegistered']
}

type actionTeacherGroups = {
  payload: GroupFields[]
}

type actionTeacherGroup = {
  payload: GroupOutType
}

type actionTeacherUsers = {
  payload: UserPublicType[]
}

type actionTeacherAssignments = {
  payload: AssignmentFields[]
}


type actionTeacherAssignment = {
  payload: AssignmentFields
}


const teacherSlice = createSlice({
  name: 'teacher',
  initialState: initialValue,
  reducers: {
    setTeacherSignUp: (state, action: actionSignUpValues) => {
      return { ...state, signUp: action.payload };
    },
    setIsTeacherRegistered: (state, action: actionIsTeacherRegistered) => {
      return { ...state, isTeacherRegistered: action.payload };
    },
    setTeacherGroups: (state, action: actionTeacherGroups) => {
      return { ...state, teacherGroups: action.payload };
    },
    setTeacherGroup: (state, action: actionTeacherGroup) => {
      return { ...state, teacherGroup: action.payload };
    },
    setTeacherStudents: (state, action: actionTeacherUsers) => {
      return { ...state, teacherStudents: action.payload };
    },
    setAssignment: (state, action: actionTeacherAssignment) => {
      return { ...state, assignment: action.payload };
    },
    clearAssignment: (state) => { return { ...state, assignment: null } },
    setAssignmentsList: (state, action: actionTeacherAssignments) => {
      return { ...state, assignments: action.payload };
    },
  },
});

export default teacherSlice;

export const {
  setTeacherSignUp, setIsTeacherRegistered, setTeacherGroups, setTeacherStudents, setTeacherGroup,
  setAssignment, setAssignmentsList, clearAssignment
} = teacherSlice.actions;

export const getTeacherSignUp = (state: RootState) => state.teacher.signUp;
export const getIsTeacherRegistered = (state: RootState) => state.teacher.isTeacherRegistered;
export const getTeacherGroups = (state: RootState) => state.teacher.teacherGroups;
export const getTeacherGroup = (state: RootState) => state.teacher.teacherGroup;
export const getTeacherStudents = (state: RootState) => state.teacher.teacherStudents;

export const getTeacherAssignmentsList = (state: RootState) => state.teacher.assignments;
export const getTeacherAssignment = (state: RootState) => state.teacher.assignment;

export const signUpTeacher = (values: ApiCreateTeacher['data']): AppThunk => (dispatch) => {
  request(createAxiosConfig<ApiCreateTeacher>({
    url: ['/teacher'],
    method: HTTP_METHODS.POST,
    data: values,
  })).then(() => {
    dispatch(setIsTeacherRegistered(true));
  }).catch((errors: { response: { data: ApiCreateTeacher['failResponse'] } }) => {
    dispatch(signUpActions.setFieldsErrors(errors.response.data));
  });
};

export const loadTeacherGroups = (): AppThunk => (dispatch) => {
  request(createAxiosConfig<ApiGroupList>({
    url: ['/group'],
    method: HTTP_METHODS.GET,
  })).then((responce: { data: ApiGroupList['successResponse'] }) => {
    dispatch(setTeacherGroups(responce.data.groupsList));
  }).catch(console.error);
};

export const loadTeacherGroup = (id: GroupFields['_id']): AppThunk => (dispatch) => {
  request(createAxiosConfig<ApiGetOneGroup>({
    url: ['/group', '/', id],
    method: HTTP_METHODS.GET,
  })).then((responce: { data: ApiGetOneGroup['successResponse'] }) => {
    dispatch(setTeacherGroup(responce.data.group));
  }).catch(console.error);
};

export const editGroup = (group: Partial<GroupFields>): AppThunk => (dispatch) => {
  if (!group?._id) {
    throw new Error('No id for group');
  }
  request(createAxiosConfig<ApiChangeGroup>({
    url: ['/group', '/', group?._id],
    method: HTTP_METHODS.PATCH,
    data: {
      ...group,
    },
  }))
    .then((response: { data: ApiChangeGroup['successResponse'] }) => {
      // dispatch(setTeacherGroup(response.data.group));
    })
    .catch(console.error);
};

export const createTeacherGroup = (values: ApiCreateGroup['data']): AppThunk => (dispatch) => {
  request(createAxiosConfig<ApiCreateGroup>({
    url: ['/group'],
    method: HTTP_METHODS.POST,
    data: values,
  })).then(() => {
    dispatch(loadTeacherGroups());
  }).catch(console.error);
};

export const loadTeacherStudents = (): AppThunk => (dispatch) => {
  request(createAxiosConfig<ApiGetStudents>({
    url: ['teacher/students'],
    method: HTTP_METHODS.GET,
  })).then((response: { data: ApiGetStudents['successResponse'] }) => {
    dispatch(setTeacherStudents(response.data.students));
  }).catch(console.error);
};

export const deleteTeacherGroup = (id: string): AppThunk => (dispatch) => {
  // TODO fix type
  request(createAxiosConfig({
    url: ['/group/', id],
    method: HTTP_METHODS.DELETE,
  })).then((responce: { data: ApiGroupList['successResponse'] }) => {
    // dispatch(setTeacherGroups(responce.data.groupsList));
    dispatch(loadTeacherGroups());
  }).catch(console.error);
};

export const createAssignment = (assignment: ApiCreateAssigment['data'], questionParams?: ApiCreateQuestions['data']): AppThunk => (dispatch) => {
  request(createAxiosConfig<ApiCreateAssigment>({
    url: ['/assignment'],
    data: assignment,
    method: HTTP_METHODS.POST,
  })).then((response: { data: ApiCreateAssigment['successResponse'] }) => {
    dispatch(setAssignment(response.data.assignment));
    if (assignment.type === AssigmentTypes.PRACTICE) {
      const settings = questionParams || { quantity: 20, maxLevel: 6, minLevel: 1 };
      dispatch(createQuestions(response.data.assignment._id, settings));
    }
  });
};

export const createQuestions = (groupId: GroupFields['_id'], questionParams: ApiCreateQuestions['data']): AppThunk => (dispatch) => {
  request(createAxiosConfig<ApiCreateQuestions>({
    url: ['/assignment', '/', groupId, '/questions'],
    data: questionParams,
    method: HTTP_METHODS.POST,
  })).then((response: { data: ApiCreateAssigment['successResponse'] }) => {
    dispatch(setAssignment(response.data.assignment));
  });
};


export const loadAssignmentsList = (params: { groupId: GroupFields['_id'] }): AppThunk => (dispatch) => {
  request(createAxiosConfig({
    url: ['/group/', params.groupId, '/assignments'],
    method: HTTP_METHODS.GET,
  })).then((response: { data: ApiGetAssignmentsList['successResponse'] }) => {
    dispatch(setAssignmentsList(response.data.assignments));
  });
};

export const changeAssignment = (assignmentId: string, formData: ApiChangeAssignment['data'], params: { groupId: GroupFields['_id'] }): AppThunk => (dispatch) => {
  request(createAxiosConfig<ApiChangeAssignment>({
    url: ['/assignment', '/', assignmentId],
    method: HTTP_METHODS.PATCH,
    data: formData,
  })).then((response: { data: ApiChangeAssignment['successResponse'] }) => {
    dispatch(loadAssignmentsList(params));
    dispatch(setAssignment(response.data.assignment));
  });
}

export const loadOneAssignment = (assignmentId: string): AppThunk => (dispatch) => {
  request(createAxiosConfig<ApiGetOneAssignment>({
    url: ['/assignment', '/', assignmentId],
    method: HTTP_METHODS.GET,
  })).then((response: { data: ApiGetOneAssignment['successResponse'] }) => {
    dispatch(setAssignment(response.data.assignment));
  });
};

export const deleteStudentFromAllGroup = (id: UserPublicType['_id']): AppThunk => (dispatch) => {
  // TODO fix type
  request(createAxiosConfig<ApiDeleteStudent>({
    url: ['teacher/students/', id],
    method: HTTP_METHODS.DELETE,
  })).then((responce: { data: ApiDeleteStudent['successResponse'] }) => {
    // dispatch(setTeacherGroups(responce.data.groupsList));
    dispatch(loadTeacherStudents());
  }).catch(console.error);
};

// const deleteStudents = async (groupId: GroupFields['_id'], students: UserPublicType[]) => {
//   for (let i = 0; i < students.length; i += 1) {
//     request(createAxiosConfig<ApiDeleteFromGroup>({
//       url: ['/group', '/', groupId, '/student/', students[i]._id],
//       method: HTTP_METHODS.DELETE,
//     }))
//   }
// };

const deleteStudentsHack = async (groupId: GroupFields['_id'], students: UserPublicType[]) => {
  for (let i = 0; i < students.length; i += 1) {
    debugger
    // eslint-disable-next-line no-await-in-loop
    await request(createAxiosConfig<ApiDeleteFromGroup>({
      url: ['/group', '/', groupId, '/student/', students[i]._id],
      method: HTTP_METHODS.DELETE,
    }));
  }
  return true;
};

export const deleteStudentFromGroup = (groupId: GroupFields['_id'], students: UserPublicType[]): AppThunk => (dispatch) => {
  // TODO fix type

  debugger;

  deleteStudentsHack(groupId, students).then(() => {
    // dispatch(setTeacherGroups(responce.data.groupsList));
    debugger;
    dispatch(loadTeacherGroup(groupId));
  }).catch(console.error);
};


const replaceQuestionHack = async (assignmentId: AssignmentFields['_id'], questionsIds: string[]) => {
  let lastResult: ApiChangeQuestion['successResponse'] | null = null;
  for (let i = 0; i < questionsIds.length; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    lastResult = await request(createAxiosConfig<ApiChangeQuestion>({
      url: ['/assignment', '/', assignmentId, '/questions/', questionsIds[i]],
      method: HTTP_METHODS.PATCH,
    }));
  }

  debugger;
  return (lastResult as any).data.assignment;
};

export const replaceQuestions = (assignmentId: AssignmentFields['_id'], questionsIds: string[]): AppThunk => (dispatch) => {
  replaceQuestionHack(assignmentId, questionsIds).then((assignment) => {
    if (assignment) {
      debugger;
      dispatch(setAssignment(assignment));
    }
  });
};
