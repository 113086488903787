import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Modal from '../Form/FieldsEntity/Modal';
import {
  AttentionIcon,
} from '../images/iconSvg';
import FormElements from '../Form';

const {
  Button,
} = FormElements;

interface ResumeTestSesionProps {
  show?: boolean
  onResume: () => void
  onCancel: () => void
  onStart: () => void
}

const ResumeTestSesion = ({
  show,
  onCancel,
  onStart,
  onResume,
}: ResumeTestSesionProps) => {

  if (show) {
    return (
      <Modal unclosed onClose={() => {}}>
        <Container>
          <TitleWrapper>
            <AttentionIconStyled />
            <Title>
              Resume Test Session?
            </Title>
          </TitleWrapper>
          <Description>
            It appears that you have an ongoing test session.
            <br />
            Do you want to resume it or start a new session?
          </Description>
          <ButtonsWrapper>
            <CancelButton onClick={onCancel}>Cancel</CancelButton>
            <StartButton onClick={onStart}>Stop current</StartButton>
            <ResumeButton onClick={onResume}>Resume test</ResumeButton>
          </ButtonsWrapper>
        </Container>
      </Modal>
    );
  }

  return null;
};

export default ResumeTestSesion;

const Container = styled.div`
  box-sizing: border-box;
  padding: 55px 55px 50px;
  min-width: 740px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 0;
`;

const AttentionIconStyled = styled(AttentionIcon)`
  font-size: 70px;
  margin-right: 20px;
  & svg {
    fill: #9077F5;
  }
`;

const Description = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 50px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const ResumeButton = styled(Button)`
  width: auto;
  height: auto;
  padding: 14px 28px;
  background-color: #9077F5;
  color: #FFF;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
`;

const StartButton = styled(ResumeButton)`
  margin-right: 18px;
  color: #9077F5;
  background-color: #FFF;
  border: 3px solid #9077F5;
  :hover {
    color: #FFF;
    border-color: transparent;
  }
`;

const CancelButton = styled(StartButton)`
  margin-right: auto;
  border: 3px solid #9C9C9C;
  color: #9C9C9C;
  :hover:not(:disabled) {
    background-color: #FFF;
    color: #F87700;
    border-color: #F87700;
  }
`;
