import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  answerQuestion,
  addQuestion,
  finishChallenge,
  skipQuestion,
  extendChalenge,
  flushChanlenge,
} from '../features/challenge/challengeState';

import { useChangeQuestion, useCurrentChalenge, useCurrentQuestion } from './ChalengeHooks';


import ExamCommonPage from './ExamCommonPage';
import colorStyle from '../colorStyle';
import ExamStrictPage from '../StrictChallenge/ExamStrictPage';

interface ChallengeProps {
  green?: boolean
}

const Chalenge = ({
  green,
}: ChallengeProps) => {
  const {
    currentQuestionNum: currentQuestionNumRaw,
    finish,
    challengeType,
  } = useParams<{ currentQuestionNum: string, finish: string, challengeType: keyof typeof colorStyle}>();
  const currentQuestionNum = Number(currentQuestionNumRaw);
  const isFinished = Boolean(finish);

  const history = useHistory();
  const dispatch = useDispatch();

  const currentChallenge = useCurrentChalenge();
  const currentQuestion = useCurrentQuestion(currentChallenge, currentQuestionNum);

  // questionNumber
  const NUM_OF_QUESTIONS = currentChallenge.questionsNumber || 20;
  // const NUM_OF_QUESTIONS = 20;

  useEffect(() => {
    if (currentChallenge.isActive && !currentQuestion && currentQuestionNum <= NUM_OF_QUESTIONS) {
      dispatch(addQuestion());
    }
  }, [currentChallenge._id, currentQuestion]);

  const onChangeQuestion = useChangeQuestion((questionNum) => `${process.env.PUBLIC_URL}/challenge/${challengeType}/current/${questionNum}`);

  // TODO убрать логику переодов между страницами на уровень ниже
  const onAnswer = (answer: string, toNext = true) => {
    if (currentQuestion) {
      dispatch(answerQuestion(currentQuestion._id, answer));
    }

    if (challengeType === 'exam') {
      if (currentChallenge.questions.length < NUM_OF_QUESTIONS && toNext) {
        onChangeQuestion(Number(currentQuestionNum) + 1);
      }
    } else if (challengeType === 'test' && toNext) {
      if (currentChallenge.questions.length < NUM_OF_QUESTIONS) {
        // dispatch(addQuestion());
      }
      history.push(`${process.env.PUBLIC_URL}/chalenge/correct-answers/${challengeType}/${currentChallenge._id}/${currentQuestionNum}`);
    }
  };

  // TODO убрать логику переодов между страницами на уровень ниже
  const onSkip = () => {
    // if (Number(currentQuestionNum) === currentChallenge.questions.length) {
    //   dispatch(addQuestion());
    // }

    if (currentChallenge.challengeType === 'test') {
      dispatch(skipQuestion(currentQuestion._id));
      // dispatch(addQuestion());
      history.push(`${process.env.PUBLIC_URL}/chalenge/correct-answers/${challengeType}/${currentChallenge._id}/${currentQuestionNum}`);
    } else if (currentChallenge.questions.length < NUM_OF_QUESTIONS) {
      // dispatch(addQuestion());
      onChangeQuestion(Number(currentQuestionNum) + 1);
    }
  };

  const setFinishTest = (finish: boolean) => {
    if (finish) {
      history.push(`${process.env.PUBLIC_URL}/challenge/${challengeType}/current/${currentQuestionNum}/finish`);
    } else {
      history.push(`${process.env.PUBLIC_URL}/challenge/${challengeType}/current/${currentQuestionNum}`);
    }
  };

  // TODO протестить
  const finisChalenge = () => {
    // dispatch(finishChallenge());
    history.push(`${process.env.PUBLIC_URL}/challenge/finish/${currentChallenge._id}`);
  };

  const forceFinishExam = () => {
    dispatch(finishChallenge(currentChallenge._id, challengeType));
    dispatch(flushChanlenge());
    history.push(`${process.env.PUBLIC_URL}/challenge/finish/${currentChallenge._id}`);
  };

  const onExtendChalenge = () => {
    dispatch(extendChalenge());
  };

  if (challengeType === 'exam' && green) {
    return (
      <ExamStrictPage
        currentQuestionNum={currentQuestionNum}
        currentChallenge={currentChallenge}
        currentQuestion={currentQuestion}
        numOfQuestions={NUM_OF_QUESTIONS}
        isFinished={isFinished}
        onChangeQuestion={onChangeQuestion}
        onAnswer={onAnswer}
        onSkip={onSkip}
        setFinishTest={setFinishTest}
        challengeType={challengeType}
        finisChalenge={forceFinishExam}
        extendChalenge={onExtendChalenge}
      />
    );
  }
  return (
    <ThemeProvider theme={colorStyle[challengeType].theme}>
      <ExamCommonPage
        currentQuestionNum={currentQuestionNum}
        currentChallenge={currentChallenge}
        currentQuestion={currentQuestion}
        numOfQuestions={NUM_OF_QUESTIONS}
        isFinished={isFinished}
        onChangeQuestion={onChangeQuestion}
        onAnswer={onAnswer}
        onSkip={onSkip}
        setFinishTest={setFinishTest}
        challengeType={challengeType}
        finisChalenge={finisChalenge}
        extendChalenge={onExtendChalenge}
      />
    </ThemeProvider>
  );
};

export default Chalenge;
