import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { createAssignment, getTeacherAssignment, changeAssignment } from '../../../features/teacher/teacherState';
import { getAllSubjects, getSubjects } from '../../../features/topic/topicState';
import Layout from '../../../Layout';
import { AssigmentTypes, AssignmentFields, CreateAssignmentFields } from '../../../project-types/assignments/types';
import { GroupFields } from '../../../project-types/groups/types';
import HeaderForTeacherDashboard from '../../HeaderForTeacherDashboard';
import AssignTopicPage from '../SelectTopic/AssignTopicPage';
import AssignmentQuestions from './AssignmentQuestions';
import AssignmentSettings from './AssignmentSettings';
import AssignComplete from './AssignComplete';
import { createTopicTree } from './utils';
import { ApiChangeAssignment, ApiCreateQuestions } from '../../../project-types/assignments/api-types';
import { GoBackIcon } from '../../../images/iconSvg';
import StagingComponent from '../../StagingComponent';

const AssignmentCreatePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { groupId, step } = useParams<{ groupId: GroupFields['_id'], step: string }>();

  // remove default value
  const [assignmentInProgess, setAssignmentInProgess] = useState<CreateAssignmentFields | null>({
    group: groupId,
    name: 'tmp_assignments',
    type: AssigmentTypes.REVISION,
    dueDate: '123',
    topicId: ['Maths'],
  });
  const [numOfQuestions, setNumOfQuestions] = useState('20');
  const [complexityLevel, setComplexityLevel] = useState<[number, number]>([1, 6]);

  const assignment = useSelector(getTeacherAssignment);

  useEffect(() => {
    dispatch(getAllSubjects({}));
  }, []);

  const subjects = useSelector(getSubjects);
  const subjectNames = Object.keys(subjects.tree);


  // const topics = Object.keys(subjects[])

  const onCreateAssignments = (assignments: CreateAssignmentFields) => {
    const settings: ApiCreateQuestions['data'] = { quantity: +numOfQuestions, minLevel: complexityLevel[0], maxLevel: complexityLevel[1] };
    dispatch(createAssignment(assignments, settings));
  };

  const onAssignmentQuestionsNextClick = () => history.push(`/teacher/group/${groupId}/assignment/create/3`);

  const onFirstStep = (subject: string, assignmentType: AssigmentTypes) => {
    setAssignmentInProgess({
      group: groupId,
      name: 'tmp_assignments',
      type: assignmentType,
      dueDate: new Date().toDateString(),
      topicId: [subject],
    });
    history.push(`/teacher/group/${groupId}/assignment/create/2`);
  };

  const topics = (subjects.tree) ? createTopicTree(subjects.tree, subjects.icons) : {};

  console.log('topics==', assignment);

  const stages = ['Assignment settings', 'Assign content', 'Confirm'];

  let content = (<></>);

  const onFinish = () => {
    history.push(`/teacher/group/${groupId}`);
  };
  const onBack = () => history.push(backUrl);

  const onAssignClickHandler = (formData: ApiChangeAssignment['data']) => {
    if (!assignment) return;
    dispatch(changeAssignment(assignment._id, formData, { groupId }));
    onFinish();
  };

  switch (step) {
    case "1": {
      content = (
        <AssignmentSettings onNext={onFirstStep} subjectNames={subjectNames} />
      );
      break;
    }
    case "2": {
      content = assignmentInProgess ? (
        <AssignTopicPage
          numberOfQuestions={numOfQuestions}
          onChangeCompexityLevelHandler={setComplexityLevel}
          complexityLevel={complexityLevel}
          onChangeNumberOfQuestionsHandler={setNumOfQuestions}
          onCreate={onCreateAssignments}
          topics={topics}
          onAssignmentQuestionsNextClick={onAssignmentQuestionsNextClick}
          assignment={assignmentInProgess}
          createdAssignment={assignment}
        />
      ) : (
        <></>
      );
      break;
    }
    case "3": {
      if (assignment) {
        content = (
          <AssignComplete
            onAssignClickHandler={onAssignClickHandler}
            assignment={assignment}
          />
        );
      } else {
        content = <></>;
      }
      break;
    }
    default: {
      content = <>404</>;
    }
  }

  const backUrl = parseInt(step, 10) > 1 ? `/teacher/group/${groupId}/assignment/create/${parseInt(step, 10) - 1}` : `/teacher/group/${groupId}`;

  return (
    <>
      <Layout>
        <Wrapper>
          <Header>
            <GoBack onClick={onBack} href="#">
              <GoBackIconStyled />
              <>Go back</>
            </GoBack>
            <StagingComponent stages={stages} currentPageIndex={step} />
          </Header>
          {content}
        </Wrapper>
      </Layout>
    </>
  );
};

export default AssignmentCreatePage;

const Wrapper = styled.div`
  width: 100%;
  min-width: 480px;
  /* max-width: 1200px; */
  // margin: 0 auto;
  margin-top: 182px;
  /* max-width: 914px; */
  margin-left: 160px;
`;

const Header = styled.header`
  border-bottom: 2px solid #E0E0E0;
  margin-bottom: 56px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 25px;
  max-width: 914px;
`;

const GoBack = styled.a`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #C4C4C4;
  display: flex;
  align-items: center;

    &:hover {
      color: #C4C4C4;
    }
`;

const GoBackIconStyled = styled(GoBackIcon)`
  font-size: 15px;
  margin-right: 7px;

    &svg {
      fill: #C4C4C4;
    }
`;
