import React, { useEffect } from 'react';
import { Layout, Menu, Breadcrumb, Button } from 'antd';
import { useLocation } from 'react-router-dom'
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { ROUTES } from './config';
// import { auth } from '../api';
import { getUserProfile, loadProfile, logOut, PROFILE_URLS } from '../../features/user/userState';
const { Header, Content, Footer } = Layout;


function Links() {
  const location = useLocation();
  console.log('location', ROUTES, location);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadProfile(PROFILE_URLS.ADMIN));
  }, []);

  const onLogOut = () => {
    dispatch(logOut());
    window.location.href = `${process.env.PUBLIC_URL}/login`;
  };

  return (
    <Header style={{position: 'sticky', top: 0, zIndex: 999}}>
      <Menu theme="dark" mode="horizontal"  defaultSelectedKeys={[location.pathname]} >
        {ROUTES.map(item => (
          <Menu.Item key={item.link}>
            <a href={item.link}>{item.title}</a>
          </Menu.Item>
        ))}
        <AuthItem key={10}>
          <Button onClick={onLogOut}>
            Log out
          </Button>
        </AuthItem>
      </Menu>
    </Header>
  )
};

const AuthItem = styled(Menu.Item)`
  float: right;
`

export default Links;