import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import InputSelect, { SchoolsListTypes } from './InputSelect';
import LabelForInput from '../../Form/FieldsEntity/LabelForInput';
import FormElements from '../../Form';
import { InputError } from '../../Form/FieldsEntity/FormInput';
import { SchoolType } from '../../features/user/types';

const {
  PlusButton,
} = FormElements;

const labelTargetSchoolsInfo = 'Add up to 5 senior schools to which you are applying. We will generate an optimal learning program that will raise performance to the level required by the schools.';

interface TargetSchoolsListProps {
  schoolsList: SchoolsListTypes[]
  targetSchoolsList?: SchoolsListTypes[]
  onChangeTargetSchools: (targetSchools: string[]) => void
  errors?: any
}


const sortSchools = (schools: SchoolsListTypes[]) => schools.slice().sort((a, b) => {
  if (!a.name || !b.name) {
    return 0;
  }
  return a.name.localeCompare(b.name);
});


const TargetSchoolsList = ({
  onChangeTargetSchools,
  targetSchoolsList,
  errors,
}: TargetSchoolsListProps) => {
  const initialTargetSchoolsList = targetSchoolsList ? sortSchools(targetSchoolsList) : [];
  const [editedSchools, setEditedSchools] = useState<SchoolsListTypes[]>(initialTargetSchoolsList);

  const updateSchools = (schools: SchoolsListTypes[]) => {
    const sortedSchools = sortSchools(schools);
    setEditedSchools(sortedSchools);
    const schoolIds = sortedSchools.map((item) => item._id);
    onChangeTargetSchools(schoolIds);
  }

  const removeCurrentTargetSchool = (arrayKey: number) => {
    const updatedTargetSchools = [...editedSchools];
    updatedTargetSchools.splice(arrayKey, 1);
    updateSchools(updatedTargetSchools);
  };


  const addSelectedSchool = (school?: SchoolsListTypes) => {
    if (school) {
      const updatedTargetSchools = [...editedSchools];
      updatedTargetSchools.push(school);
      updateSchools(updatedTargetSchools);
    }
  };

  const sortedSchools = editedSchools.slice().sort((a, b) => {
    return a.name.localeCompare(b.name)
  });

  return (
    <Wrapper>
      {sortedSchools.map((item: SchoolsListTypes, n: number) => (
        <TargetSchoolLabel
          key={n}
          name={`Target school ${n + 1}`}
          labelInfo={n === 0 ? labelTargetSchoolsInfo : ''}
        >
          <InputWrapper>
            <InputSelectStyled
              targetSchool={item.name}
              onChangeSchool={(_, school) => addSelectedSchool(school)}
              disabled={true}
              error={errors && errors.length !== 0}
            />
            <DeleteButton onClick={() => removeCurrentTargetSchool(n)} type="button" />
          </InputWrapper>
        </TargetSchoolLabel>
      ))}
      <InputError errors={errors} />
      {sortedSchools.length < 5 &&
        <TargetSchoolLabel name={`Add new target school`}>
          <InputWrapper>
            <InputSelectStyled
              clearOnSelect={true}
              onChangeSchool={(_, school) => addSelectedSchool(school)}
            />
          </InputWrapper>
        </TargetSchoolLabel>
      }
    </Wrapper>
  );
};

export default TargetSchoolsList;

const Wrapper = styled.div`

`;

const InputSelectStyled = styled(InputSelect)`
  max-width: 570px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const TargetSchoolLabel = styled(LabelForInput)`
  margin-bottom: 14px;
`;

const TargetSchoolNewLabel = styled(TargetSchoolLabel)`
  margin-top: 23px;
`;

const DeleteButton = styled(PlusButton)`
  width: 34px;
  height: 34px;
  margin-left: 17px;
  align-self: baseline;
  margin-top: 10px;
  cursor: pointer;
  ::before {
    transform: rotate(45deg);
    width: 65%;
  }
  ::after {
    transform: rotate(-45deg);
    width: 65%;
  }
`;

const AddButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  cursor: pointer;
`;

const AddButton = styled(PlusButton)`
  background-color: #9077F5;
`;

const AddButtonSpan = styled.span`
  color: #9077F5;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  margin-left: 16px;
`;
