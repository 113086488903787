import React from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { HEADER_NAV, RouteType } from '../../features/topic/types';
import UseLocalization from '../../Localization/UseLocalization';

interface NavigationProps {
  isLogged?: boolean
  currentInterfaceType: string
}

export type MenuLinksType = {
  link: string,
  menuName: string
}

const studentAboutMenuConf: MenuLinksType[] = [
  { menuName: 'About Us', link: '/about-us' },
  { menuName: 'Why Choose Otter?', link: '/how-to-use' },
  { menuName: 'Understanding the 11+', link: '/eleven-plus' },
  { menuName: 'FAQ', link: '/faq' },
];

const teacherAboutMenuConf: MenuLinksType[] = [
  { menuName: 'About Us', link: '/teacher/about/about-us' },
  { menuName: 'FAQ', link: '/teacher/about/FAQ' },
  { menuName: 'Why Choose Otter', link: '/teacher/about/why-choose-otter' },
];

const navigationConf = [
  {
    navName: 'About',
    menuConf: studentAboutMenuConf,
    forUserType: 'student',
    isShowByUserNotLoged: true,
    translateKey: 'navigation.about',
  },
  {
    navName: 'Pricing',
    pathTo: 'pricing',
    forUserType: 'student',
    isShowByUserNotLoged: true,
    translateKey: 'navigation.pricing',
  },
  {
    navName: 'Dashboard',
    pathTo: 'dashboard/new-test',
    forUserType: 'student',
    isShowByUserNotLoged: false,
    translateKey: 'navigation.dashboard',
  },
  {
    navName: 'Progress',
    pathTo: 'progress/overall',
    forUserType: 'student',
    isShowByUserNotLoged: false,
    translateKey: 'navigation.progress',
  },
  {
    navName: 'Dashboard',
    pathTo: 'teacher/dashboard/my-groups',
    forUserType: 'teacher',
    isShowByUserNotLoged: false,
    translateKey: 'navigation.dashboard',
  },
  {
    navName: 'About',
    menuConf: teacherAboutMenuConf,
    forUserType: 'teacher',
    isShowByUserNotLoged: true,
    translateKey: 'navigation.about',
  },
];

const MainNavigation = ({
  isLogged,
  currentInterfaceType,
}: NavigationProps) => {
  const { headerNav } = useParams<RouteType>();
  const { url } = useRouteMatch();

  console.log(headerNav);

  return (
    <Navigation>
      {navigationConf.map((nav, n) => {
        if (currentInterfaceType === nav.forUserType) {
          if (nav.isShowByUserNotLoged || isLogged) {
            return (
              <NavigationItem tabIndex={0} key={n}>
                <LinkStyled
                  to={nav.pathTo ? `${process.env.PUBLIC_URL}/${nav.pathTo}` : '#'}
                  selected={nav.menuConf ? !!nav.menuConf.find((menuConf) => menuConf.link === url) : headerNav === nav.navName.toLowerCase()}
                >
                  <UseLocalization translateKey="navigation.about">
                    {nav.navName}
                  </UseLocalization>
                </LinkStyled>
                {nav.menuConf && (
                  <AboutMenu>
                    {nav.menuConf.map((menu, n) => (
                      <AboutMenuLink key={n} to={menu.link}>{menu.menuName}</AboutMenuLink>
                    ))}
                  </AboutMenu>
                )}
              </NavigationItem>
            );
          }
        }
      })}
    </Navigation>
  );
};

export default MainNavigation;

const Navigation = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;

  @media (max-width: 986px) {
    display: none;
  }
`;

const NavigationItem = styled.li`
  position: relative;

  :not(:last-child) {
    margin-right: 69px;
  }

  :focus {
    outline: none;
  }
`;

const LinkStyled = styled(Link)<{selected?: boolean}>`
  line-height: 26px;
  font-size: 1.56em;
  font-weight: 500;
  color: ${(props) => (props.selected ? '#F87700' : 'inherit')};
  :hover {
    color: #F87700;
  }
`;

const AboutMenuLink = styled(Link)`
  display: block;
  width: 100%;
  padding: 15px;
  text-align: center;
  font-size: 18px;
  line-height: 17px;
  transition: background-color .3s ease-in-out;
  color: inherit;

  :hover {
    background-color: #EEEAFF;
    color: inherit;
  }
`;

const AboutMenu = styled.div`
  width: 258px;
  position: absolute;
  top: 120%;
  left: calc(50% - (258px / 2));
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0px 2px 8px #F0F2F3;
  overflow: hidden;
  visibility: hidden;
  transform: translateY(-10px);
  opacity: 0;
  transition: .3s ease-out;
  transition-property: opacity, transform;

  ${NavigationItem}:focus-within & {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
`;
