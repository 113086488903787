import React from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { TitleH2 } from "../../styledElements/styledTextElements";
import { UserPublicType } from "../../project-types/users/types";

interface ModalContentForViewReportProps {
  assignmentName: string;
  assignmentDueDate: string;
  assignmentStudents: Array<UserPublicType>;
}

export default function ModalContentForViewReport({
  assignmentName,
  assignmentDueDate,
  assignmentStudents,
}: ModalContentForViewReportProps) {
  const formatDate = (dueDate: string) =>
    moment(new Date(dueDate)).format("MMM Do, hh:mm A");

  //TODO waiting for student complete date

  return (
    <Wrapper>
      <StyledTitleH2>{assignmentName}</StyledTitleH2>
      <InfoBlock>
        <Text>Due: {formatDate(assignmentDueDate)}</Text>
        <Text>Completed: 1 / {assignmentStudents.length}</Text>
      </InfoBlock>
      <Table>
        <HeadTR>
          <TH>STUDENT</TH>
          <TH>DATE AND TIME COMPLETED</TH>
        </HeadTR>
        {assignmentStudents.map((student) => (
          <TR>
            <TD>{student.fullName}</TD>
            <TD>{formatDate(assignmentDueDate)}</TD>
            {/* <TD>{student.dueDate ? formatDate(student.dueDate) : '-'}</TD> */}
          </TR>
        ))}
      </Table>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 42px;
  max-width: 1040px;
  width: 100vw;
  padding-left: 75px;
  padding-right: 150px;
`;

const StyledTitleH2 = styled(TitleH2)``;

const InfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 7px;
  border-bottom: 2px solid #e0e0e0;
  margin-bottom: 65px;
  margin-top: 38px;
`;

const Text = styled.span`
  font-size: 18px;
  line-height: 26px;
  color: #1f1f1f;
`;

const Table = styled.table`
  width: 100%;
`;

const TH = styled.th`
  padding: 14px 0 10px 21px;
`;

const TD = styled.td`
  padding: 31px 0 27px 21px;
`;

const TR = styled.tr`
  border-bottom: 2px solid #e0e0e0;
  &:last-child {
    border-bottom: 1px solid #e0e0e0;
  }
`;

const HeadTR = styled.tr`
  background-color: #eeeaff;
  font-weight: bold;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
`;
