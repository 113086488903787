import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import ChangeSubscriptionFromModal from '../../ProfilePage/Subscription/ChangeSubscriptionForModal';
import { PayPlanType } from '../../ProfilePage/Subscription/types';
import { request } from '../../api';
import { useGetPriceList } from './hooks';

interface ChangeUserTarifProps {
  userId?: string
  currentPriceId: string | undefined
  getUser: Function
}

const ChangeUserTariff = ({
  userId,
  currentPriceId,
  getUser,
}: ChangeUserTarifProps) => {

  const priceList = useGetPriceList();

  const changeTariff = (priceId: string) => {
    request(
      {
        method: 'POST',
        url: 'admin/change-subscription',
        data: {
          userId,
          priceId,
        },
      },
      [200],
    )
      .then(() => {
        message.info('Tariff changed');
        getUser();
      });
  };

  const { push } = useHistory();

  return (
    <ChangeTariff
      priceList={priceList}
      onClose={() => push(`${process.env.PUBLIC_URL}/users`)}
      onChangePlan={(priceId: string) => changeTariff(priceId)}
      currentPriceName={currentPriceId}
    />
  );
};

export default ChangeUserTariff;

const ChangeTariff = styled(ChangeSubscriptionFromModal)`
  padding: 0;
`;
