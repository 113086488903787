import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import Layout from '../Layout';
import Footer from '../Layout/Footer/Footer';

const otterComputer = require('../images/otter-and-computer.svg');
const momAndSon = require('../images/mom-and-son.svg');
const teacherYellow = require('../images/teacher-background-yellow.svg');
const schoolImage = require('../images/school.svg');

const testIcon = require('../images/icons/test-icon.svg');
const questionIcon = require('../images/icons/question-icon.svg');
const simulationIcon = require('../images/icons/simulation-icon.svg');

const accessIcon = require('../images/icons/access-icon.svg');
const blueBackground = require('../images/blueBackground.svg');

const HowToUseOtter = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Page>

        <MainTitle>
          Your companion to
          {' '}
          <SpanOrange>11+</SpanOrange>
          {' '}
          success
        </MainTitle>
        <MainDescription>
          Otter is the all-in-one package you need to learn, practise and run
          unlimited realistic mock exams from home.
        </MainDescription>

        <SectionWrapper>
          <OtterComputerImage src={otterComputer} />
          <Section>
            <SectionTitle>Otter for Parents</SectionTitle>
            <SectionDescription>
              With Otter you have access to our fully comprehensive bank of 11+ questions,
              covering all topics in maths, English, verbal reasoning and non-verbal
              reasoning. Our AI technology adapts in real-time to each child meaning
              all the questions are tailored to challenge and reinforce skills at every
              stage. Furthermore, our questions are dynamic so you will never see the same
              thing twice and our in-house team is continuously expanding our question bank.
            </SectionDescription>
          </Section>
        </SectionWrapper>

        <BlueSectionWrapper>
          <SectionIcon style={{ backgroundImage: `url(${testIcon})` }}>
            <IconDescription>
              Unlimited mock tests
            </IconDescription>
          </SectionIcon>
          <SectionIcon style={{ backgroundImage: `url(${questionIcon})` }}>
            <IconDescription>
              Expertly written realistic exam-style questions
            </IconDescription>
          </SectionIcon>
          <SectionIcon style={{ backgroundImage: `url(${simulationIcon})` }}>
            <IconDescription>
              The most realistic exam simulator available
            </IconDescription>
          </SectionIcon>
        </BlueSectionWrapper>

        <MomAndSonWrapper>
          <MomAndSonImage src={momAndSon} />
          <Section>
            <SectionTitle>
              The only tool
              <br />
              you need
            </SectionTitle>
            <SectionList>
              <SectionListItem>Monitor and track progress</SectionListItem>
              <SectionListItem>Identify gaps</SectionListItem>
              <SectionListItem>Develop exam technique</SectionListItem>
              <SectionListItem>Target topic areas associated with your chosen school test</SectionListItem>
              <SectionListItem>School-specific mock exams</SectionListItem>
            </SectionList>
          </Section>
        </MomAndSonWrapper>

        <SchoolSectionWrapper>
          <SchoolImage src={schoolImage} />
          <SchoolSection>
            <SectionTitle>Targets your chosen school</SectionTitle>
            <SchoolSectionDescription>
              Compare your score with our school-specific
              benchmarks and see how you compare with others aiming for your target school.
              <br />
              <br />
              We’ll let you know if you are on course and, if not, what to focus on and how to improve.
            </SchoolSectionDescription>
          </SchoolSection>
        </SchoolSectionWrapper>

        <SectionWrapperBackground>
          <Section>
            <SectionTitlePurple>
              Otter for Schools
              <br />
              and Teachers
            </SectionTitlePurple>
            <SectionDescription>
              Otter is
              {' '}
              <strong>FREE</strong>
              {' '}
              for schools and designed for teachers.
            </SectionDescription>
            <SectionListInBackgroundBlock>
              <SectionListItem>Run the most realistic mock exams available</SectionListItem>
              <SectionListItem>Adaptive AI engine adjusts for all abilities</SectionListItem>
              <SectionListItem>Set and mark homework</SectionListItem>
              <SectionListItem>Monitor class progress</SectionListItem>
            </SectionListInBackgroundBlock>
          </Section>
          <TeacherYellowImage src={teacherYellow} />
        </SectionWrapperBackground>
      </Page>
      <Footer />
    </Layout>

  );
};

export default HowToUseOtter;

const Page = styled.div`
  padding: 100px 0px 0px;
  min-height: 100vh;
`;

const MainTitle = styled.h1`
  margin-bottom: 48px;
  font-size: 50px;
  line-height: 50px;
  font-weight: bold;
  text-align: center;
`;

const SpanOrange = styled.span`
  color: #F87700;
  font-size: 60px;
`;

const MainDescription = styled.p`
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  margin: 0 auto 90px;
  width: min(60%, 770px);
`;

const SectionWrapper = styled.div`
  margin: 0 48px 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1240px) {
    flex-direction: column;
    margin-left: 48px;
    margin-right: 48px;
  }
`;

const Section = styled.section`

`;

const Image = styled.img`
  width: clamp(200px, 50vw, 465px);

  @media (max-width: 1240px) {
    margin: 0 0 32px;
  }
`;

const OtterComputerImage = styled(Image)`
  margin-right: 100px;
`;

const SchoolImage = styled(Image)`
  margin-right: 107px;
  width: clamp(200px, 50vw, 530px);
`;

const MomAndSonImage = styled(Image)`
  margin-left: 107px;
`;

const TeacherYellowImage = styled(Image)`
  width: clamp(200px, 50vw, 556px);
  margin-left: 35px;
`;

const SectionTitle = styled.h2`
  font-size: 40px;
  line-height: 50px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #F87700;
`;

const SectionDescription = styled.p`
  max-width: 511px;
  font-size: 18px;
  line-height: 30px;

  @media (max-width: 1400px) {
    max-width: 100%;
  }
`;

const BlueSectionWrapper = styled.div`
  padding: 40px 0px;
  display: flex;
  justify-content: center;
  background-color: #EEEAFF;
  margin-bottom: 80px;
`;

const SectionIcon = styled.div`
  padding-top: 134px;
  width: min(247px, 25%);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto;

  :not(:last-child) {
    margin-right: min(96px, 10%);
  }
`;

const IconDescription = styled.span`
  text-align: center;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  display: block;
`;

const MomAndSonWrapper = styled(SectionWrapper)`
  flex-direction: row-reverse;
  @media (max-width: 1240px) {
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }
`;

const SectionList = styled.ul`
  padding-left: 0px;
  list-style: none;
  max-width: 366px;
`;

const SectionListItem = styled.li`
  padding-left: 56px;
  font-size: 18px;
  line-height: 22px;
  background: url(${accessIcon}) no-repeat left center;
  :not(:last-child) {
    margin-bottom: 28px;
  }
`;

const SchoolSection = styled(Section)`
  max-width: 505px;

  @media (max-width: 1240px) {
    max-width: 100%;
  }
`;

const SchoolSectionDescription = styled(SectionDescription)`
  max-width: 478px;

  /* @media (max-width: 1240px) {
    max-width: 100%;
  } */
`;

const SchoolSectionWrapper = styled(SectionWrapper)`
  margin-top: 114px;
  margin-bottom: 0;
`;

const SectionWrapperBackground = styled(SectionWrapper)`
  background: url(${blueBackground}) no-repeat bottom -10px center;
  background-size: auto;
  margin: 0;
  padding: 150px 48px 85px;

  @media (max-width: 1240px) {
    flex-direction: column-reverse;
    margin: 0;
  }
`;

const SectionTitlePurple = styled(SectionTitle)`
  color: #9077F5;
`;

const SectionListInBackgroundBlock = styled(SectionList)`
  max-width: 478px;
`;
