import { scaleLinear, scaleBand, scaleTime } from "d3-scale";
import { ChartConfigType, DateType, RangeType } from "./types";

import { min, max } from "d3-array";

export const date = (config: ChartConfigType, dates: DateType[]) => {
  const xScale = scaleTime()
    .range([0, config.size.width])

  
    const startDate = dates[0];
    const endDate = dates[dates.length - 1] || startDate;

  xScale.domain([startDate, endDate])
  //.nice()

  return xScale;
};

export const values = (config: ChartConfigType, values: RangeType[]) => {
  console.log("min-ma", [min(values), max(values)]);
  const yScale = scaleLinear()
    .domain([0, 100])
    .range([config.size.height, 0])
    .nice();

  return yScale;
};
