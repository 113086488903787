import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUserProfile, logIn } from '../features/user/userState';
import FormWrap from './CardContainer';
import FormElements from '../Form';
import UserMenu from './UserMenu';
import { checkCaptha } from './hooks';

import './showCaptha.css';
import { getCurrentCode } from '../features/invite-code/inviteState';

const { Input, Button } = FormElements;

const Game = () => {
  const dispatch = useDispatch();

  const { push } = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    checkCaptha().then(({ token }) => dispatch(logIn({ email, password, captcha: token })));
  }
  const userState = useSelector(getUserProfile);

  console.log(userState);
  
  useEffect(() => {
    debugger;
    if (userState.role === "USER") {
      if (userState.userType === 'teacher') {
        return push(`${process.env.PUBLIC_URL}/teacher/dashboard/my-groups`);
      } else {
        if (userState.priceId) {
          return push(`${process.env.PUBLIC_URL}/dashboard/new-test`);
        };
        return push(`${process.env.PUBLIC_URL}/sign-up-steps/1/profile-information`);
      }
    }

    if (userState.userType === 'teacher') {
      return push(`${process.env.PUBLIC_URL}/teacher/dashboard/my-groups`);
    }

    return () => {};
  }, [userState, push]);

  useEffect(() => {
    const el = document.querySelector('.grecaptcha-badge');
    if (el) {
      (el as any).style.opacity = 1;
    }
    return () => {
      const el = document.querySelector('.grecaptcha-badge');
      if (el) {
        (el as any).style.opacity = 0;
      }
    };
  }, []);

  return (
    <FormWrap>
      <UserMenu createNewUser={false} />
      <Form onSubmit={onSubmit}>
        <InputStyled
          placeholder="Email or username"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <InputStyled
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <LinkStyled to={`${process.env.PUBLIC_URL}/password-recovery`}>Forgot your password?</LinkStyled>
        <Button type="submit">Log In</Button>
      </Form>
    </FormWrap>
  );
};

const Form = styled.form`
  box-sizing: border-box;
  padding: 50px 30px 30px;
`;

const InputStyled = styled(Input)`
  margin-bottom: 15px;
`;

const LinkStyled = styled(Link)`
  display: inline-block;
  text-decoration: underline;
  color: #9077f5;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  text-align: center;
  margin-bottom: 39px;
`;

export default Game;
