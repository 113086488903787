import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import FormElements from '../Form';
import CardContainer from './CardContainer';
import { emailValidate } from './validators';
import { InputError } from '../Form/FieldsEntity/FormInput';
import { recoveryPassword } from '../features/user/userState';

const {
  Input, Button,
} = FormElements;

const ForgottenPasswordPage = () => {
  const dispatch = useDispatch();

  return (
    <CardContainer>
      <Formik
        initialValues={{ email: '' }}
        validate={emailValidate}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(recoveryPassword({ email: values.email }));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Title>Forgotten Password?</Title>
            <Description>
              We will send you an email with instructions on how to reset your password
            </Description>
            <InputWrapper>
              <InputStyled
                placeholder="Email"
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={!!errors.email}
              />
              <InputError errors={errors.email} />
            </InputWrapper>
            <Button type="submit">Reset Password</Button>
          </Form>
        )}
      </Formik>
    </CardContainer>
  );
};

const Title = styled.h1`
  margin-bottom: 28px;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
`;

const Description = styled.p`
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 36px;
  text-align: center;
  max-width: 300px;
`;

const InputStyled = styled(Input)`
`;

const Form = styled.form`
  box-sizing: border-box;
  padding: 60px 30px 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 28px;
`;

export default ForgottenPasswordPage;
