// TODO remove it

/* eslint-disable no-new-wrappers */

export function calcCompleatedTime(compleatedTime: string, challengeStartTime: string) {
  if (compleatedTime) {
    const a: any = new Number(new Date(compleatedTime));
    const b: any = new Number(new Date(challengeStartTime));
    return `${Math.floor((a - b) / 1000 / 60)}`;
  }
  return '0';
}


export function timeDiff(startTime: Date, endTime: Date) {
  const start: any = new Number(startTime);
  const end: any = new Number(endTime);
  return Math.floor((end - start) / 1000);
}
