type TopicFields = Record<string, {
  icon: IconsForSelect,
  subTopics: object
}>;

export type TopicTreeForSelect = Record<string, object>;
export type IconsForSelect = Record<string, {
  'hover-color': string,
  icon: string,
  'mime-type': string,
  _id: string
}>


export const createTopicTree = (tree: TopicTreeForSelect,
  icons: IconsForSelect,
  depth: any = 0,
  parent: string | undefined = undefined) : TopicFields => {
  const maxDepth = 4;
  const accInitial: TopicFields = {};
  return Object.keys(tree).reduce((acc: any, value: string) => {
    // console.log(value);
    if (depth === maxDepth) {
      return acc;
    }

    acc[value as any] = {
      icon: icons[[parent, value].filter((part) => part).join('/')],
      subTopics: createTopicTree(tree[value] as any, icons, depth + 1, value)
    };

    return acc;
  }, accInitial);
};
