import React, { useState } from 'react';
import styled from 'styled-components/macro';
import TextArea from 'antd/lib/input/TextArea';
import { PurpleButton } from '../Features/Buttons';
import { createNoteFromUser } from '../UsersTable/hooks';
import { UserProfileBaseType } from '../../features/user/types';

interface UserNoteProps {
  user: UserProfileBaseType,
  onUpdate: any
}

const UserNote = ({
  user,
  onUpdate 
}: UserNoteProps) => {
  const [note, setNote] = useState(user.note);

  const userId = user._id;

  return (
    <Wrapper>
      {user && (
        <>
          <TextArea autoSize value={note} onChange={(e) => setNote(e.target.value)} />
          <CreateNote onClick={() => onUpdate({ userId, note })}>Save note</CreateNote>
        </>
      )}
    </Wrapper>
  );
};

export default UserNote;

const Wrapper = styled.div`
  max-width: 1240px;
`;

const CreateNote = styled(PurpleButton)`
  margin-left: 0;
  margin-top: 24px;
`;
