import React from 'react';
import styled from 'styled-components';

interface ProgressBarProps {
  className?: string
  valueProgress?: number
  topic?: string
}

const colorByValue = (value: number) => {
  if (value >= 71) {
    return '#9077F5';
  } if (value >= 51) {
    return '#FEAC60';
  }
  return '#FC7E6D';
};

const ProgressBar = ({
  className,
  valueProgress = 0,
  topic,
}: ProgressBarProps) => (
  <Wrapper className={className}>
    <Description>
      <Topic>{topic}</Topic>
      <ProgressNum>
        {valueProgress}
        {' '}
        %
      </ProgressNum>
    </Description>
    <Progress max={100} value={valueProgress} colorBar={colorByValue(valueProgress)} />
  </Wrapper>
);

export default ProgressBar;

const Wrapper = styled.div`
  
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Topic = styled.div`
  font-size: 16px;
  line-height: 21px;
`;

const ProgressNum = styled(Topic)`
  font-weight: 500;
`;

const Progress = styled.progress<{colorBar: string}>`
  width: 100%;
  height: 8px;
  border-radius: 10px;
  border: none;
  overflow: hidden;
  background-color: #F2F2F2;
  ::-webkit-progress-bar {
    background-color: #F2F2F2;
  }
  ::-webkit-progress-value {
    background-color: ${props => props.colorBar};
    border-radius: 10px;
  }
  ::-moz-progress-bar {
    background-color: ${props => props.colorBar};
    border-radius: 10px;
  }
`;
