import {
  DateType
} from "./types";


export const monthView = (d: DateType) => {
  return `${d.toDateString().slice(8, 10)} ${d.toDateString().slice(4, 7)}`
}

export const weekView = (d: DateType) => {
  return `${d.toDateString().slice(0, 2)}`
}

export const yearView = (d: DateType) => {
  return `${d.toDateString().slice(4, 7)}`
}
