import * as yup from 'yup';
import { TeacherSetUpCommonType, TYPE_TEACHER } from './types';

const oneOfTeacher = [
  TYPE_TEACHER.AGENCY_TEACHER,
  TYPE_TEACHER.PRIVATE_TUTOR,
  TYPE_TEACHER.SCHOOL_TEACHER,
];

export const teacherSchema: yup.SchemaOf<TeacherSetUpCommonType> = yup.object({
  teacherType: yup.string().oneOf(oneOfTeacher).required(),
  school: yup.string()
    .when(['teacherType'], {
      is: (teacherType: TYPE_TEACHER) => teacherType === TYPE_TEACHER.SCHOOL_TEACHER,
      then: yup.string().required(),
      otherwise: yup.string().optional(),
    }),
  agency: yup.string()
    .when(['teacherType'], {
      is: (teacherType: TYPE_TEACHER) => teacherType === TYPE_TEACHER.AGENCY_TEACHER,
      then: yup.string().required(),
      otherwise: yup.string().optional(),
    }),
});
