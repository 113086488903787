import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';
import {
  getUserProfile,
  signUp,
  getSignUpFormFields,
  signUpActions,
} from '../features/user/userState';

import { InputError, InputPasswordErrors, InputWrap } from '../Form/FieldsEntity/FormInput';
import FormElements from '../Form';
import {
  useChangeValue,
  ErrorsType,
} from '../utils/createForm';
import { checkCaptha } from './hooks';
import { passwordVerification } from '../hooks/passwordValidator';

import './showCaptha.css';

const { Input, Checkbox, Button } = FormElements;

export const TermsAndPrivacy = ({
  onCheck,
  className,
}: {
  onCheck: () => void
  className?: string
}) => {
  return (
    <CheckboxStyled
      required
      value="confirm"
      onChange={onCheck}
      className={className}
    >
      <TextChechbox>
        I accept the
        {' '}
        <a
          href={`${process.env.PUBLIC_URL}/privacy-policy`}
          target="_blank"
          rel="noreferrer"
        >
          Terms & Privacy
        </a>
      </TextChechbox>
    </CheckboxStyled>
  );
};

const StudentSignUp = () => {
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
  const userState = useSelector(getUserProfile);
  const dispatch = useDispatch();

  const { push } = useHistory();

  const { values, errors } = useSelector(getSignUpFormFields);

  const validator = (elName: string, newValue: any) => {
    const newErrors: ErrorsType = { ...errors };
    return errors;
  };

  const { changeValue, changeInputValue } = useChangeValue(
    signUpActions,
    validator,
  );

  const linkToUserVerify = () => push(`${process.env.PUBLIC_URL}/user/verify`);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (passwordErrors.length) return;


    checkCaptha().then(({ token }) => dispatch(signUp({ ...values, userType: 'student', captcha: token }, linkToUserVerify)));
  };

  // TODO: fix hak valid
  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const verifyPassword = passwordVerification(e.target.value);

    if (verifyPassword.length) {
      return setPasswordErrors(verifyPassword);
    }

    setPasswordErrors([]);

    return changeInputValue('password')(e);
  };

  useEffect(() => {
    const el = document.querySelector('.grecaptcha-badge');
    if (el) {
      (el as any).style.opacity = 1;
    }
    return () => {
      const el = document.querySelector('.grecaptcha-badge');
      if (el) {
        (el as any).style.opacity = 0;
      }
    };
  }, []);

  return (
    <Form onSubmit={onSubmit}>
      <fieldset>
        <InputWrapStyled>
          <InputStyled
            id="name"
            name="fullName"
            onChange={changeInputValue('fullName')}
            placeholder="Full name"
            required
          />
        </InputWrapStyled>
        <InputWrapStyled>
          <InputStyled
            required
            id="email"
            type="email"
            onChange={changeInputValue('email')}
            placeholder="Email address"
          />
          <InputError errors={errors.user} />
        </InputWrapStyled>
        <InputWrapStyled>
          <InputStyled
            required
            id="password"
            type="password"
            onChange={onChangePassword}
            placeholder="Set a password"
          />
          <InputPasswordErrors errors={passwordErrors} />
        </InputWrapStyled>

        <TermsAndPrivacy onCheck={() => changeInputValue('confirm')} />
      </fieldset>
      <Button type="submit">Create account</Button>
    </Form>
  );
};

const Form = styled.form`
  box-sizing: border-box;
  padding: 39px 30px 30px;
`;

const InputWrapStyled = styled(InputWrap)`
  margin-bottom: 15px;
`;

const InputStyled = styled(Input)<{ require?: boolean }>``;

const CheckboxStyled = styled(Checkbox)`
  margin: 17px 0 32px 13px;
`;

const TextChechbox = styled.p`
  font-size: 16px;
  line-height: 20px;
  & a {
    color: #1f1f1f;
    text-decoration: underline;
  }
`;

export default StudentSignUp;
