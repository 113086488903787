import React, { MouseEvent, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Row, RowDown } from '../../../images/iconSvg';
import { RadioStyled } from '../Assignment/Elements';
import { IconsForSelect } from '../Assignment/utils';

interface TopicItemProps {
  name: string
  description?: string
  image?: any
  children?: ReactNode
  isExpanded?: boolean
  onClick: (e: any) => void,
  isSelected: boolean,
  onSelect: () => void
}

export default function TopicItem({
  name, description, image, children, isExpanded, onClick, isSelected, onSelect,
}: TopicItemProps) {
  return (
    <TopicWrap onClick={children ? onClick : (e: any) => { e.stopPropagation() }}>
      <ItemBlock>
        {children && (isExpanded ? (<RowDownStyled />) : (<RowStyled />))}
        {image && (
          <IconWrap>
            <TopicIcon src={image.icon} />
          </IconWrap>
        )}

        <TitleAndDescription>
          <Title>{name}</Title>
          <Description>{description}</Description>
        </TitleAndDescription>
        <RadioWrapStyled>
          <RadioStyled onClick={onSelect} checked={isSelected} />
        </RadioWrapStyled>
      </ItemBlock>

      {children && isExpanded && <SubtopicsWrap>{children}</SubtopicsWrap>}
    </TopicWrap>
  );
}

const TopicWrap = styled.div`
  cursor: pointer;
`;

const ItemBlock = styled.div`
  height: 120px;
  display: flex;
  align-items: center;
  &:hover {
    background: #F2F2F2;
  }
  border-bottom: 1px solid #E0E0E0;
  padding-left: 30px;
`;

const TopicIcon = styled.img`
  width: 55px;
  height: 55px;
`;

const IconWrap = styled.div`
  background: #EEEAFF;
  width: 72px;
  height: 72px;
  margin-right: 31px;
  border-radius: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
`;

const TitleAndDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #1F1F1F;
`;

const RowStyled = styled(Row)`
  margin-right: 23px;
`;

const RowDownStyled = styled(RowDown)`
  margin-right: 11px;
`

const SubtopicsWrap = styled.div`
  margin-left: 64px;
`;

const RadioWrapStyled = styled.div`
  flex-grow: 2;
  text-align: right;
  margin-right: 39px;
`;