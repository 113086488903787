import React from 'react';
import styled from 'styled-components/macro';
import { Formik } from 'formik';
import { createSupervisorCode } from '../UsersTable/hooks';
import FormElements from '../../Form';
import { Input } from 'antd';

const {
  Button,
} = FormElements;

interface SuperVisorCodeProps {
  userId?: string
}

const SuperVisorCode = ({
  userId,
}: SuperVisorCodeProps) => {

  return (
    <Wrapper>
      {userId && (
        <Formik
          initialValues={{userId, topic: ''}}
          onSubmit={(values) => {
            createSupervisorCode(values.userId);
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              <ButtonWrapper>
                <SaveButton type="submit">Create</SaveButton>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      )}
    </Wrapper>
  );
};

export default SuperVisorCode;

const Wrapper = styled.div`
  max-width: 320px;
`;

const Form = styled.form`

`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
`;

export const SaveButton = styled(Button)`
  min-width: 128px;
  width: auto;
  height: auto;
  padding: 13px 25px;
  border: 3px solid transparent;
  color: #FFF;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  background-color: #9077F5;
`;
