import React from 'react';
import styled from 'styled-components/macro';

interface IconProps {
  className?: string
  onClick?: () => void
}

export const EditIcon = ({ className, onClick }: IconProps) => (
  <IconWrap onClick={onClick} className={className}>
    <svg width="1em" height="1em" viewBox="0 0 23 23" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.3467 11.8514L7.90092 22.2972C7.45095 22.7472 6.84066 23 6.2043 23H1.19969C0.537121 23 0 22.4629 0 21.8003V16.7957C0 16.1593 0.252792 15.5491 0.702764 15.0991L11.1486 4.65328L18.3467 11.8514Z" />
      <path d="M20.0433 10.1548L12.8452 2.95666L15.0991 0.702763C16.0361 -0.234255 17.5553 -0.234254 18.4923 0.702765L22.2972 4.50768C23.2343 5.4447 23.2343 6.9639 22.2972 7.90092L20.0433 10.1548Z" />
    </svg>
  </IconWrap>
);

export const TrashIcon = ({ className, onClick }: IconProps) => (
  <IconWrap onClick={onClick} className={className}>
    <svg width="28" height="34" viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.33594 5.33301H25.6693V28.6663C25.6693 31.4278 23.4307 33.6663 20.6693 33.6663H7.33594C4.57451 33.6663 2.33594 31.4278 2.33594 28.6663V5.33301Z" fill="#E0E0E0" />
      <rect x="0.667969" y="3.66602" width="26.6667" height="3.33333" rx="1" fill="white" />
      <path d="M7.33594 1.33301C7.33594 0.780723 7.78365 0.333008 8.33594 0.333008H19.6693C20.2216 0.333008 20.6693 0.780723 20.6693 1.33301V5.33301H7.33594V1.33301Z" fill="#E0E0E0" />
      <rect x="0.667969" y="3.66602" width="26.6667" height="3.33333" rx="1" fill="#E0E0E0" />
      <path d="M10.668 12V25.3333" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.3359 12V25.3333" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </IconWrap>
);

export const EditIconCircle = ({ className, onClick }: IconProps) => (
  <IconWrap onClick={onClick} className={className}>
    <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="9" fill="#9077F5" />
      <path d="M10.7861 9.09168L8.06111 11.8167C7.94373 11.9341 7.78452 12 7.61851 12H6.31296C6.14012 12 6 11.8599 6 11.687V10.3815C6 10.2155 6.06595 10.0563 6.18333 9.93889L8.90832 7.2139L10.7861 9.09168Z" fill="white" />
      <path d="M11.2287 8.64908L9.35092 6.7713L9.93889 6.18333C10.1833 5.93889 10.5796 5.93889 10.8241 6.18333L11.8167 7.17592C12.0611 7.42036 12.0611 7.81667 11.8167 8.06111L11.2287 8.64908Z" fill="white" />
    </svg>
  </IconWrap>
);

export const PayCardIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="1.2em" height="1.2em" viewBox="0 0 36 26" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.75 0.75C2.85051 0.75 0.5 3.10051 0.5 6V20C0.5 22.8995 2.8505 25.25 5.75 25.25H30.25C33.1495 25.25 35.5 22.8995 35.5 20V6C35.5 3.10051 33.1495 0.75 30.25 0.75H5.75ZM5.75 7.75C4.7835 7.75 4 8.5335 4 9.5C4 10.4665 4.7835 11.25 5.75 11.25H30.25C31.2165 11.25 32 10.4665 32 9.5C32 8.5335 31.2165 7.75 30.25 7.75H5.75ZM5.75 14.75C4.7835 14.75 4 15.5335 4 16.5C4 17.4665 4.7835 18.25 5.75 18.25H14.5C15.4665 18.25 16.25 17.4665 16.25 16.5C16.25 15.5335 15.4665 14.75 14.5 14.75H5.75Z" />
    </svg>
  </IconWrap>
);

export const ProgressIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6 0C2.68629 0 0 2.68629 0 6V24C0 27.3137 2.68629 30 6 30H24C27.3137 30 30 27.3137 30 24V6C30 2.68629 27.3137 0 24 0H6ZM26.7482 9.83207C27.2078 9.14278 27.0215 8.21148 26.3322 7.75195C25.6429 7.29242 24.7116 7.47868 24.2521 8.16797L19.0841 15.9199L11.3322 10.7519C10.6429 10.2924 9.71161 10.4787 9.25208 11.168L3.25208 20.168C2.79256 20.8573 2.97882 21.7886 3.66811 22.2481C4.3574 22.7076 5.28871 22.5214 5.74824 21.8321L10.9162 14.0801L18.6681 19.2481C19.3574 19.7076 20.2887 19.5214 20.7482 18.8321L26.7482 9.83207Z" />
    </svg>
  </IconWrap>
);

export const SubjectsIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" rx="5" fill="#1F1F1F" />
      <path d="M4.63867 6.55149C4.63867 5.49453 5.49551 4.6377 6.55246 4.6377H11.3801C12.437 4.6377 13.2938 5.49453 13.2938 6.55149V11.3791C13.2938 12.436 12.437 13.2929 11.3801 13.2929H6.55246C5.49551 13.2929 4.63867 12.436 4.63867 11.3791V6.55149ZM4.63867 18.6205C4.63867 17.5635 5.49551 16.7067 6.55246 16.7067H11.3801C12.437 16.7067 13.2938 17.5635 13.2938 18.6205V23.448C13.2938 24.505 12.437 25.3618 11.3801 25.3618H6.55246C5.49551 25.3618 4.63867 24.505 4.63867 23.448V18.6205ZM16.7076 6.55149C16.7076 5.49453 17.5645 4.6377 18.6214 4.6377H23.449C24.506 4.6377 25.3628 5.49453 25.3628 6.55149V11.3791C25.3628 12.436 24.506 13.2929 23.449 13.2929H18.6214C17.5645 13.2929 16.7076 12.436 16.7076 11.3791V6.55149ZM16.7076 18.6205C16.7076 17.5635 17.5645 16.7067 18.6214 16.7067H23.449C24.506 16.7067 25.3628 17.5635 25.3628 18.6205V23.448C25.3628 24.505 24.506 25.3618 23.449 25.3618H18.6214C17.5645 25.3618 16.7076 24.505 16.7076 23.448V18.6205Z" fill="#FFF" stroke="#1F1F1F" />
    </svg>
  </IconWrap>
);

export const SubtopicsIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6 0C2.68629 0 0 2.68629 0 6V24C0 27.3137 2.68629 30 6 30H24C27.3137 30 30 27.3137 30 24V6C30 2.68629 27.3137 0 24 0H6ZM6 9C6 8.17157 6.67157 7.5 7.5 7.5H22.5C23.3284 7.5 24 8.17157 24 9C24 9.82843 23.3284 10.5 22.5 10.5H7.5C6.67157 10.5 6 9.82843 6 9ZM6 15C6 14.1716 6.67157 13.5 7.5 13.5H22.5C23.3284 13.5 24 14.1716 24 15C24 15.8284 23.3284 16.5 22.5 16.5H7.5C6.67157 16.5 6 15.8284 6 15ZM7.5 19.5C6.67157 19.5 6 20.1716 6 21C6 21.8284 6.67157 22.5 7.5 22.5H22.5C23.3284 22.5 24 21.8284 24 21C24 20.1716 23.3284 19.5 22.5 19.5H7.5Z" />
    </svg>
  </IconWrap>
);

export const PaperclipIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.8165 3.25995L3.92886 9.94592C2.1537 11.6691 2.1537 14.463 3.92886 16.1862C5.70403 17.9094 8.58215 17.9094 10.3573 16.1862L18.1633 8.60873L20 10.3917L12.194 17.9691C9.40447 20.677 4.88171 20.677 2.09216 17.9691C-0.697388 15.2612 -0.697388 10.8709 2.09216 8.163L8.97979 1.47702C11.0086 -0.492342 14.2978 -0.492342 16.3266 1.47702C18.3554 3.44639 18.3554 6.63936 16.3266 8.60873L9.43897 15.2947C8.17099 16.5256 6.11519 16.5256 4.84721 15.2947C3.57924 14.0638 3.57924 12.0682 4.84721 10.8374L11.7348 4.15141L13.5715 5.93434L6.68392 12.6203C6.43032 12.8665 6.43032 13.2656 6.68392 13.5118C6.93751 13.7579 7.34867 13.7579 7.60227 13.5118L14.4899 6.8258C15.5043 5.84112 15.5043 4.24463 14.4899 3.25995C13.4755 2.27527 11.8309 2.27527 10.8165 3.25995Z" />
    </svg>
  </IconWrap>
);

export const AddIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="1em" height="1em" viewBox="0 0 31 30" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.5 30C24.0604 30 31 23.2843 31 15C31 6.71573 24.0604 0 15.5 0C6.93959 0 0 6.71573 0 15C0 23.2843 6.93959 30 15.5 30ZM17.05 9C17.05 8.17157 16.356 7.5 15.5 7.5C14.644 7.5 13.95 8.17157 13.95 9V13.5H9.3C8.44396 13.5 7.75 14.1716 7.75 15C7.75 15.8284 8.44396 16.5 9.3 16.5H13.95V21C13.95 21.8284 14.644 22.5 15.5 22.5C16.356 22.5 17.05 21.8284 17.05 21V16.5H21.7C22.556 16.5 23.25 15.8284 23.25 15C23.25 14.1716 22.556 13.5 21.7 13.5H17.05V9Z" />
    </svg>
  </IconWrap>
);

export const MinusIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="1em" height="1em" viewBox="0 0 50 50" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50ZM15 22.5C13.6193 22.5 12.5 23.6193 12.5 25C12.5 26.3807 13.6193 27.5 15 27.5H35C36.3807 27.5 37.5 26.3807 37.5 25C37.5 23.6193 36.3807 22.5 35 22.5H15Z" />
    </svg>
  </IconWrap>
);

export const CheckIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="1em" height="1em" viewBox="0 0 30 30" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM22.0607 13.0607C22.6464 12.4749 22.6464 11.5251 22.0607 10.9393C21.4749 10.3536 20.5251 10.3536 19.9393 10.9393L13.5 17.3787L10.0607 13.9393C9.47487 13.3536 8.52513 13.3536 7.93934 13.9393C7.35355 14.5251 7.35355 15.4749 7.93934 16.0607L12.4393 20.5607C13.0251 21.1464 13.9749 21.1464 14.5607 20.5607L22.0607 13.0607Z" />
    </svg>
  </IconWrap>
);

export const SandwitchActiveIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="17" r="17" fill="#F87700" />
      <path d="M10.4219 11.7383H23.5766" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.4219 17H23.5766" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.4219 22.2617H23.5766" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </IconWrap >
);


export const ClockIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="1em" height="1em" viewBox="0 0 36 36" fill="#F87700" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M18 35.5C27.665 35.5 35.5 27.665 35.5 18C35.5 8.33502 27.665 0.5 18 0.5C8.33502 0.5 0.5 8.33502 0.5 18C0.5 27.665 8.33502 35.5 18 35.5ZM21.5 11C21.5 10.0335 20.7165 9.25 19.75 9.25C18.7835 9.25 18 10.0335 18 11V18H12.75C11.7835 18 11 18.7835 11 19.75C11 20.7165 11.7835 21.5 12.75 21.5H19.75C20.7165 21.5 21.5 20.7165 21.5 19.75V11Z" />
    </svg>
  </IconWrap>
);

export const ClockIconOutlined = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="31" height="30" viewBox="0 0 31 30" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.5117 9C17.3401 9 18.0117 9.67157 18.0117 10.5V16.5C18.0117 17.3284 17.3401 18 16.5117 18H12.0117C11.1833 18 10.5117 17.3284 10.5117 16.5C10.5117 15.6716 11.1833 15 12.0117 15H15.0117V10.5C15.0117 9.67157 15.6833 9 16.5117 9Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.0117 3C8.3843 3 3.01172 8.37258 3.01172 15C3.01172 21.6274 8.3843 27 15.0117 27C21.6391 27 27.0117 21.6274 27.0117 15C27.0117 8.37258 21.6391 3 15.0117 3ZM0.0117188 15C0.0117188 6.71573 6.72745 0 15.0117 0C23.296 0 30.0117 6.71573 30.0117 15C30.0117 23.2843 23.296 30 15.0117 30C6.72745 30 0.0117188 23.2843 0.0117188 15Z" />
    </svg>
  </IconWrap>
);

export const ArrowIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="1em" height="1em" viewBox="0 0 28 28" fill="#F87700" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM12.1899 21.9899L19.1899 14.9899C19.7367 14.4432 19.7367 13.5568 19.1899 13.0101L12.1899 6.01005C11.6432 5.46332 10.7568 5.46332 10.2101 6.01005C9.66332 6.55678 9.66332 7.44321 10.2101 7.98995L16.2201 14L10.21 20.01C9.66332 20.5568 9.66332 21.4432 10.21 21.9899C10.7568 22.5367 11.6432 22.5367 12.1899 21.9899Z" />
    </svg>
  </IconWrap>
);

export const DropdownIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 8L9.21739 12L14.4348 8"
        stroke="#1F1F1F"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </IconWrap>
);

export const RightIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="8" height="12" viewBox="0 0 8 12" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.19756 0.616089L7.76167 5.44451C7.99611 5.64777 7.99611 5.97614 7.76167 6.17992L2.19756 11.0083C1.85912 11.3024 1.30848 11.3024 0.969437 11.0083C0.630997 10.7143 0.630997 10.237 0.969437 9.94298L5.72923 5.81195L0.969437 1.68197C0.630997 1.38739 0.630997 0.910144 0.969437 0.616089C1.30848 0.322033 1.85912 0.322033 2.19756 0.616089Z" />
    </svg>
  </IconWrap>
);

export const AttentionIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="1em" height="1em" viewBox="0 0 34 34" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34ZM18.7 8.5C18.7 7.56112 17.9389 6.8 17 6.8C16.0611 6.8 15.3 7.56112 15.3 8.5V18.7C15.3 19.6389 16.0611 20.4 17 20.4C17.9389 20.4 18.7 19.6389 18.7 18.7V8.5ZM17 27.2C17.9389 27.2 18.7 26.4389 18.7 25.5C18.7 24.5611 17.9389 23.8 17 23.8C16.0611 23.8 15.3 24.5611 15.3 25.5C15.3 26.4389 16.0611 27.2 17 27.2Z" />
    </svg>
  </IconWrap>
);

export const ArrowTailIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="1em" height="1em" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.16781 7.42302H20V10.577H6.16781L11.4728 15.7698L9.19447 18L0 9L9.19447 0L11.4728 2.23019L6.16781 7.42302Z" fill="#C4C4C4" />
    </svg>
  </IconWrap>
);

export const SupportIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="21em" height="1em" viewBox="0 0 24 24" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.0008 22.1998C17.6341 22.1998 22.2008 17.6331 22.2008 11.9998C22.2008 6.3665 17.6341 1.7998 12.0008 1.7998C6.36748 1.7998 1.80078 6.3665 1.80078 11.9998C1.80078 17.6331 6.36748 22.1998 12.0008 22.1998ZM10.8927 13.3009C10.8077 13.8725 11.2925 14.3442 11.8703 14.3442H12.3499C12.6326 14.3442 12.8665 14.1245 12.8844 13.8424C12.9425 13.305 13.1723 12.8361 13.5738 12.4358L14.2157 11.8024C14.7176 11.2979 15.0689 10.84 15.2697 10.4287C15.4704 10.0119 15.5708 9.57045 15.5708 9.10432C15.5708 8.07884 15.2617 7.28642 14.6436 6.72706C14.0255 6.16222 13.1564 5.8798 12.0364 5.8798C10.927 5.8798 10.05 6.17593 9.4055 6.76819C9.02327 7.12233 8.75342 7.55783 8.59597 8.0747C8.40843 8.6903 8.96263 9.22771 9.60616 9.22771C10.1509 9.22771 10.557 8.78398 10.936 8.36991C10.9889 8.3121 11.0413 8.25485 11.0934 8.19948C11.3312 7.94722 11.6455 7.82109 12.0364 7.82109C12.8606 7.82109 13.2727 8.28448 13.2727 9.21126C13.2727 9.51835 13.1934 9.81174 13.0349 10.0914C12.8764 10.3656 12.5568 10.7248 12.0761 11.169C11.6006 11.6077 11.273 12.0546 11.0934 12.5098C11.0045 12.7352 10.9376 12.9989 10.8927 13.3009ZM10.9508 15.9646C10.713 16.2005 10.5942 16.5021 10.5942 16.8695C10.5942 17.2314 10.7104 17.5303 10.9429 17.7661C11.1806 18.0019 11.4923 18.1198 11.878 18.1198C12.2636 18.1198 12.5727 18.0019 12.8051 17.7661C13.0429 17.5303 13.1617 17.2314 13.1617 16.8695C13.1617 16.5021 13.0402 16.2005 12.7972 15.9646C12.5595 15.7234 12.253 15.6027 11.878 15.6027C11.5029 15.6027 11.1938 15.7234 10.9508 15.9646Z" />
    </svg>
  </IconWrap>
);

export const ReportIcon = (props: any) => (
  <IconWrapStyled {...props}>
    <svg width="29" height="26" viewBox="0 0 29 26" fill="#C4C4C4" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.30492 0H23.7014C26.6226 0 29 2.41227 29 5.37163V16.2752C29 19.2346 26.6226 21.6437 23.7014 21.6437H12.1621L9.69116 25.2277C9.35834 25.7125 8.81265 25.9987 8.22929 26C7.64655 26.0013 7.09961 25.7137 6.76365 25.2303L4.17273 21.512C1.79154 20.9903 0 18.8433 0 16.2752V5.37163C0 2.41227 2.38307 0 5.30492 0ZM14.5 14.7402C13.6466 14.7402 12.954 15.4565 12.954 16.3401C12.954 17.2237 13.6466 17.9387 14.5 17.9387C15.3534 17.9387 16.046 17.2237 16.046 16.3401C16.046 15.4565 15.354 14.7402 14.5 14.7402ZM16.0416 12.0531V5.45751C16.0416 4.5739 15.3722 3.8576 14.5 3.8576C13.6271 3.8576 12.9584 4.5739 12.9584 5.45751V12.0531C12.9584 12.9367 13.6271 13.653 14.5 13.653C15.3722 13.653 16.0416 12.9361 16.0416 12.0531Z" />
    </svg>
    <DropdownWrapper>
      <Dropdown>Error report</Dropdown>
    </DropdownWrapper>
  </IconWrapStyled>
);

export const QuestionIcon = (props: any) => (
  <IconWrap {...props}>
    <svg width="1em" height="1em" viewBox="0 0 34 34" fill="#F87700" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34ZM15.1531 19.1686C15.0115 20.1212 15.8195 20.9073 16.7826 20.9073H17.5819C18.053 20.9073 18.4429 20.5411 18.4726 20.071C18.5695 19.1753 18.9525 18.3938 19.6217 17.7266L20.6915 16.671C21.528 15.8301 22.1135 15.0669 22.4481 14.3815C22.7827 13.6868 22.95 12.9511 22.95 12.1742C22.95 10.4651 22.4349 9.14435 21.4047 8.2121C20.3745 7.2707 18.9261 6.8 17.0594 6.8C15.2104 6.8 13.7487 7.29355 12.6745 8.28065C12.0375 8.87087 11.5877 9.59671 11.3253 10.4582C11.0128 11.4842 11.9364 12.3798 13.009 12.3798C13.9169 12.3798 14.5937 11.6403 15.2253 10.9502C15.3135 10.8538 15.4008 10.7584 15.4877 10.6661C15.884 10.2457 16.4079 10.0355 17.0594 10.0355C18.433 10.0355 19.1198 10.8078 19.1198 12.3524C19.1198 12.8642 18.9877 13.3532 18.7236 13.8194C18.4594 14.2763 17.9267 14.875 17.1255 15.6153C16.333 16.3465 15.7871 17.0914 15.4877 17.85C15.3395 18.2257 15.228 18.6652 15.1531 19.1686ZM15.25 23.6081C14.8538 24.0011 14.6557 24.5038 14.6557 25.1161C14.6557 25.7194 14.8494 26.2175 15.2368 26.6105C15.633 27.0035 16.1525 27.2 16.7953 27.2C17.4381 27.2 17.9531 27.0035 18.3406 26.6105C18.7368 26.2175 18.9349 25.7194 18.9349 25.1161C18.9349 24.5038 18.7324 24.0011 18.3274 23.6081C17.9311 23.2059 17.4204 23.0048 16.7953 23.0048C16.1701 23.0048 15.655 23.2059 15.25 23.6081Z" />
    </svg>
  </IconWrap>
);

export const CloseIcon = (props: any) => (
  <IconWrap {...props}>
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.4413 0.558676C20.1862 1.30358 20.1862 2.5113 19.4413 3.2562L3.2562 19.4413C2.5113 20.1862 1.30358 20.1862 0.558676 19.4413C-0.186224 18.6964 -0.186224 17.4887 0.558676 16.7438L16.7438 0.558676C17.4887 -0.186224 18.6964 -0.186224 19.4413 0.558676Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.558675 0.558675C1.30357 -0.186225 2.5113 -0.186225 3.2562 0.558675L19.4413 16.7438C20.1862 17.4887 20.1862 18.6964 19.4413 19.4413C18.6964 20.1862 17.4887 20.1862 16.7438 19.4413L0.558675 3.2562C-0.186225 2.5113 -0.186225 1.30357 0.558675 0.558675Z" />
    </svg>
  </IconWrap>
);

export const ExitIcon = (props: any) => (
  <IconWrap {...props}>
    <svg width="1em" height="1em" viewBox="0 0 29 29" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.8 0C2.59675 0 0 2.59675 0 5.8V10.15H2.9V5.8C2.9 4.19837 4.19837 2.9 5.8 2.9H23.2C24.8016 2.9 26.1 4.19837 26.1 5.8V23.2C26.1 24.8016 24.8016 26.1 23.2 26.1H5.8C4.19837 26.1 2.9 24.8016 2.9 23.2V18.85H0V23.2C0 26.4033 2.59675 29 5.8 29H23.2C26.4033 29 29 26.4033 29 23.2V5.8C29 2.59675 26.4033 0 23.2 0H5.8Z" />
      <path d="M1.45 13.05C0.649187 13.05 0 13.6992 0 14.5C0 15.3008 0.649188 15.95 1.45 15.95H15.3446L11.668 19.6265C11.1018 20.1928 11.1018 21.1109 11.668 21.6771C12.2343 22.2434 13.1524 22.2434 13.7186 21.6771L19.8024 15.5934C20.1073 15.3276 20.3 14.9363 20.3 14.5C20.3 14.0637 20.1073 13.6724 19.8024 13.4066L13.7186 7.32286C13.1524 6.7566 12.2343 6.7566 11.668 7.32286C11.1018 7.88912 11.1018 8.80721 11.668 9.37347L15.3446 13.05H1.45Z" />
    </svg>
  </IconWrap>
);

export const BellIcon = (props: any) => (
  <IconWrap {...props}>
    <svg width="1em" height="1em" viewBox="0 0 23 23" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M21.0149 19.3996L19.3695 16.6568C18.6095 15.3909 18.208 13.9409 18.208 12.4651V10.0625C18.208 7.02938 16.184 4.46393 13.4163 3.63688V1.91668C13.4163 0.859625 12.5567 0 11.4996 0C10.4426 0 9.58295 0.859625 9.58295 1.91668V3.63688C6.81531 4.46393 4.79131 7.02938 4.79131 10.0625V12.4651C4.79131 13.9409 4.38975 15.3899 3.63075 16.6559L1.98531 19.3986C1.89619 19.5472 1.89425 19.7312 1.97956 19.8816C2.06487 20.0321 2.22299 20.125 2.39549 20.125H20.6038C20.7763 20.125 20.9354 20.0321 21.0207 19.8826C21.106 19.7331 21.1031 19.5471 21.0149 19.3996Z" />
        <path d="M8.48193 21.0835C9.02243 22.2124 10.1667 23.0002 11.4997 23.0002C12.8327 23.0002 13.977 22.2124 14.5175 21.0835H8.48193Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="23" height="23" />
        </clipPath>
      </defs>
    </svg>
  </IconWrap>
);

export const ExtraTimeIcon = (props: any) => (
  <IconWrap {...props}>
    <svg width="37" height="33" viewBox="0 0 37 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.0586 9.55908C17.887 9.55908 18.5586 10.2307 18.5586 11.0591V17.0591C18.5586 17.8875 17.887 18.5591 17.0586 18.5591H12.5586C11.7302 18.5591 11.0586 17.8875 11.0586 17.0591C11.0586 16.2307 11.7302 15.5591 12.5586 15.5591H15.5586V11.0591C15.5586 10.2307 16.2302 9.55908 17.0586 9.55908Z" fill="#1F1F1F" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.5586 3.55908C8.93118 3.55908 3.55859 8.93167 3.55859 15.5591C3.55859 22.1865 8.93118 27.5591 15.5586 27.5591C22.186 27.5591 27.5586 22.1865 27.5586 15.5591C27.5586 8.93167 22.186 3.55908 15.5586 3.55908ZM0.558594 15.5591C0.558594 7.27481 7.27432 0.559082 15.5586 0.559082C23.8429 0.559082 30.5586 7.27481 30.5586 15.5591C30.5586 23.8434 23.8429 30.5591 15.5586 30.5591C7.27432 30.5591 0.558594 23.8434 0.558594 15.5591Z" fill="#1F1F1F" />
      <path fillRule="evenodd" clipRule="evenodd" d="M28.5 18.4286C25.1468 18.4286 22.4286 21.1468 22.4286 24.5C22.4286 27.8532 25.1468 30.5714 28.5 30.5714C31.8532 30.5714 34.5714 27.8532 34.5714 24.5C34.5714 21.1468 31.8532 18.4286 28.5 18.4286ZM20 24.5C20 19.8056 23.8056 16 28.5 16C33.1944 16 37 19.8056 37 24.5C37 29.1944 33.1944 33 28.5 33C23.8056 33 20 29.1944 20 24.5ZM26.5571 21.5857C26.5571 20.5127 27.427 19.6429 28.5 19.6429C29.573 19.6429 30.4429 20.5127 30.4429 21.5857V22.5571H31.4143C32.4873 22.5571 33.3571 23.427 33.3571 24.5C33.3571 25.573 32.4873 26.4429 31.4143 26.4429H30.4429V27.4143C30.4429 28.4873 29.573 29.3571 28.5 29.3571C27.427 29.3571 26.5571 28.4873 26.5571 27.4143V26.4429H25.5857C24.5127 26.4429 23.6429 25.573 23.6429 24.5C23.6429 23.427 24.5127 22.5571 25.5857 22.5571H26.5571V21.5857Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M28.5 32C32.6421 32 36 28.6421 36 24.5C36 20.3579 32.6421 17 28.5 17C24.3579 17 21 20.3579 21 24.5C21 28.6421 24.3579 32 28.5 32ZM29.25 21.5C29.25 21.0858 28.9142 20.75 28.5 20.75C28.0858 20.75 27.75 21.0858 27.75 21.5V23.75H25.5C25.0858 23.75 24.75 24.0858 24.75 24.5C24.75 24.9142 25.0858 25.25 25.5 25.25H27.75V27.5C27.75 27.9142 28.0858 28.25 28.5 28.25C28.9142 28.25 29.25 27.9142 29.25 27.5V25.25H31.5C31.9142 25.25 32.25 24.9142 32.25 24.5C32.25 24.0858 31.9142 23.75 31.5 23.75H29.25V21.5Z" fill="#F55F4B" />
    </svg>
  </IconWrap>
);

export const CorrectIcon = (props: any) => (
  <IconWrap {...props}>
    <svg width="1em" height="1em" viewBox="0 0 47 47" fill="#95D354" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.38 0C10.47 0 0 10.47 0 23.38C0 36.29 10.47 46.76 23.38 46.76C36.29 46.76 46.76 36.29 46.76 23.38C46.75 10.47 36.29 0 23.38 0ZM20.6 37.53L8.36 25.2L14.18 19.38L20.13 25.33L33.21 10.31L39.03 16.13L20.6 37.53Z" />
    </svg>
  </IconWrap>
);

export const IncorrectIcon = (props: any) => (
  <IconWrap {...props}>
    <svg width="1em" height="1em" viewBox="0 0 47 47" fill="#F55F4B" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.38 0C10.47 0 0 10.47 0 23.38C0 36.29 10.47 46.76 23.38 46.76C36.29 46.76 46.76 36.29 46.76 23.38C46.76 10.47 36.29 0 23.38 0ZM36.14 29.83L29.83 36.14L23.38 29.69L16.93 36.14L10.62 29.83L17.07 23.38L10.62 16.93L16.93 10.62L23.38 17.07L29.83 10.62L36.14 16.93L29.69 23.38L36.14 29.83Z" />
    </svg>
  </IconWrap>
);

export const PhoneIcon = (props: any) => (
  <IconWrap {...props}>
    <svg width="1em" height="1em" viewBox="0 0 42 42" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.5477 2.49838L17.8898 6.6584L17.9223 6.69019C19.1091 7.87945 19.5967 9.5283 19.5966 11.014C19.5966 12.4997 19.1091 14.1485 17.9224 15.3378L15.8097 17.4551C16.3071 19.1977 17.4925 21.1197 19.143 22.7846C20.7976 24.4538 22.719 25.6622 24.4925 26.1667L26.6039 24.0507C27.7911 22.8609 29.4386 22.371 30.9245 22.371C32.4103 22.371 34.0577 22.8609 35.245 24.0507L39.5758 28.3907C40.7625 29.58 41.25 31.2288 41.25 32.7145C41.25 34.2003 40.7623 35.849 39.5757 37.0383C36.3464 40.2746 32.1335 41.25 30.2023 41.25C27.2131 41.25 24.5134 41.0661 21.4052 39.612C18.3748 38.1944 15.1073 35.6422 10.7254 31.2508C6.28417 26.7998 3.75967 23.1601 2.36315 19.9618C0.947461 16.7197 0.75 14.0624 0.75 11.7375C0.75 10.1111 0.954384 8.55436 1.6351 6.96276C2.309 5.3871 3.39161 3.91275 4.9508 2.35017C6.17158 1.12673 7.85452 0.697744 9.33646 0.754994C10.8128 0.812027 12.4 1.35912 13.5477 2.49838Z" />
    </svg>
  </IconWrap>
);

export const LetterIcon = (props: any) => (
  <IconWrap {...props}>
    <svg width="1.1em" height="1em" viewBox="0 0 44 37" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.6 0.866699C2.95492 0.866699 0 4.3205 0 8.58099V29.1524C0 33.4129 2.95492 36.8667 6.6 36.8667H37.4C41.0451 36.8667 44 33.4129 44 29.1524V8.58099C44 4.3205 41.0451 0.866699 37.4 0.866699H6.6ZM7.69179 6.3482C6.63685 5.6436 5.29297 6.072 4.69015 7.30504C4.08732 8.53808 4.45384 10.1089 5.50877 10.8135L20.9088 21.0992C21.5851 21.5509 22.4154 21.5509 23.0918 21.0992L38.4918 10.8135C39.5467 10.1089 39.9132 8.53808 39.3104 7.30504C38.7076 6.072 37.3637 5.6436 36.3088 6.3482L22.0003 15.9049L7.69179 6.3482Z" />
    </svg>
  </IconWrap>
);

// export const ExitIcon = (props: any) => (
//   <IconWrap {...props}>
//     <svg width="1em" height="1em" viewBox="0 0 29 29" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
//       <path d="M5.8 0C2.59675 0 0 2.59675 0 5.8V10.15H2.9V5.8C2.9 4.19837 4.19837 2.9 5.8 2.9H23.2C24.8016 2.9 26.1 4.19837 26.1 5.8V23.2C26.1 24.8016 24.8016 26.1 23.2 26.1H5.8C4.19837 26.1 2.9 24.8016 2.9 23.2V18.85H0V23.2C0 26.4033 2.59675 29 5.8 29H23.2C26.4033 29 29 26.4033 29 23.2V5.8C29 2.59675 26.4033 0 23.2 0H5.8Z" />
//       <path d="M1.45 13.05C0.649187 13.05 0 13.6992 0 14.5C0 15.3008 0.649188 15.95 1.45 15.95H15.3446L11.668 19.6265C11.1018 20.1928 11.1018 21.1109 11.668 21.6771C12.2343 22.2434 13.1524 22.2434 13.7186 21.6771L19.8024 15.5934C20.1073 15.3276 20.3 14.9363 20.3 14.5C20.3 14.0637 20.1073 13.6724 19.8024 13.4066L13.7186 7.32286C13.1524 6.7566 12.2343 6.7566 11.668 7.32286C11.1018 7.88912 11.1018 8.80721 11.668 9.37347L15.3446 13.05H1.45Z" />
//     </svg>
//   </IconWrap>
// );

export const GroupIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="1em" height="1em" viewBox="0 0 36 36" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.19638 19.2178H7.98047C7.69669 19.9947 7.54165 20.8332 7.54165 21.7071V32.2294C7.54165 32.5938 7.60507 32.9435 7.72045 33.2688H3.11773C1.39838 33.2688 -0.00034523 31.87 -0.00034523 30.1507V24.4145C-0.000415325 21.5491 2.33086 19.2178 5.19638 19.2178Z" />
      <path d="M28.4579 21.7071C28.4579 20.8331 28.3029 19.9947 28.0191 19.2178H30.8032C33.6687 19.2178 36 21.5491 36 24.4146V30.1508C36 31.87 34.6013 33.2688 32.8819 33.2688H28.2791C28.3945 32.9434 28.4579 32.5938 28.4579 32.2294V21.7071Z" />
      <path d="M14.8173 16.5107H21.1821C24.0476 16.5107 26.3789 18.842 26.3789 21.7075V32.2298C26.3789 32.8038 25.9136 33.2692 25.3395 33.2692H10.6598C10.0858 33.2692 9.62046 32.8039 9.62046 32.2298V21.7075C9.62046 18.842 11.9517 16.5107 14.8173 16.5107Z" />
      <path d="M18.0002 2.73145C21.4464 2.73145 24.25 5.53509 24.25 8.98132C24.25 11.3189 22.9598 13.3604 21.0545 14.4322C20.1508 14.9406 19.1089 15.2311 18.0002 15.2311C16.8915 15.2311 15.8496 14.9406 14.9459 14.4322C13.0405 13.3604 11.7504 11.3188 11.7504 8.98132C11.7504 5.53516 14.554 2.73145 18.0002 2.73145Z" />
      <path d="M28.9744 8.55664C31.5517 8.55664 33.6484 10.6534 33.6484 13.2307C33.6484 15.808 31.5517 17.9047 28.9744 17.9047C28.3206 17.9047 27.6981 17.7693 27.1326 17.5259C26.1548 17.1049 25.3486 16.3597 24.8497 15.4263C24.4996 14.7712 24.3004 14.0239 24.3004 13.2307C24.3004 10.6534 26.3971 8.55664 28.9744 8.55664Z" />
      <path d="M7.02518 8.55664C9.60249 8.55664 11.6992 10.6534 11.6992 13.2307C11.6992 14.0239 11.5 14.7713 11.1499 15.4263C10.651 16.3598 9.84479 17.105 8.86703 17.5259C8.3015 17.7693 7.67895 17.9047 7.02518 17.9047C4.44787 17.9047 2.35115 15.808 2.35115 13.2307C2.35115 10.6534 4.44787 8.55664 7.02518 8.55664Z" />
    </svg>
  </IconWrap>
);

export const AvatarIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="1em" height="1em" viewBox="0 0 42 42" fill="#1F1F1F" xmlns="http://www.w3.org/2000/svg">
      <circle cx="21" cy="21" r="18" />
      <circle cx="21.0016" cy="13.8004" r="5.4" fill="white" />
      <path d="M20.9992 22.7998C18.2992 22.7998 11.5492 24.1498 10.1992 28.1998C11.5492 32.2498 16.9492 33.5998 20.9992 33.5998C25.0492 33.5998 30.4492 32.2498 31.7992 28.1998C30.4492 24.1498 23.6992 22.7998 20.9992 22.7998Z" fill="white" />
    </svg>
  </IconWrap>
);

export const GoBackIcon = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.93425 6.18585H16V8.81415H4.93425L9.17828 13.1415L7.35558 15L0 7.5L7.35558 0L9.17828 1.85849L4.93425 6.18585Z" fill="#C4C4C4" />
    </svg>
  </IconWrap>
);

const IconWrap = styled.div`
  display: block;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  position: relative;
`;

const IconWrapStyled = styled(IconWrap)`
  cursor: pointer;
`;

const DropdownWrapper = styled.div`
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const Dropdown = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: -10px;
  width: 107px;
  height: 28px;
  background-color: #EEEAFF;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  ${IconWrap}:hover & {
    display: flex;
  }
`;

export const Row = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="10" height="21" viewBox="0 0 10 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 20L8.5 10.6832L0.999999 1.36646" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </IconWrap >
);

export const RowDown = ({ className }: IconProps) => (
  <IconWrap className={className}>
    <svg width="22" height="11" viewBox="0 0 22 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.43359 1.93359L10.7504 9.43359L20.0671 1.93359" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </IconWrap >
);