import { ReactText } from 'react';
import { message } from 'antd';
import { request } from '../../api';

export const createMailList = (usersId: ReactText[], listName: string) => {
  request(
    {
      method: 'POST',
      url: 'admin/mail-list',
      data: {
        userIds: usersId,
        name: listName,
      },
    }, [200],
  )
    .then()
    .catch(() => message.error('Mail list not created'));
};

export const createSupervisorCode = (userId: string) => {
  request(
    {
      method: 'POST',
      url: 'admin/issue-supervisor-code',
      data: {
        userId
      },
    },
    [200],
  )
    .then(() => message.success(`Super access for 15 minutes allowed`));
};

export const createNoteFromUser = (userId: any, note: string) => {
  request(
    {
      method: 'POST',
      url: 'admin/user/edit-notes',
      data: {
        userIds: userId,
        note,
      },
    },
    [200],
  )
    .then(() => message.success('Note created'));
};
