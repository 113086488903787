export const schoolsPretested: string[] = [
  'Abbot\'s Hill School Bunkers Lane, Hemel Hempstead HP3 8RP United Kingdom',
  'Alleyn\'s School Townley Road, Dulwich, London SE22 8SU United Kingdom',
  'Ampleforth College York YO62 4ER United Kingdom',
  'Ardingly College College Road, Haywards Heath RH17 6SQ United Kingdom',
  'Bedford School De Parys Avenue, Bedford Road, Bedford MK40 2TU United Kingdom',
  'Benende School Cranbrook TN17 4AA United Kingdom',
  'Berkhamsted Senior School Castle Street, Berkhamsted HP4 2BB United Kingdom',
  'Bloxham School Bloxham, Banbury OX15 4PE United Kingdom',
  'Bradfield College Bradfield, Reading RG7 6AU United Kingdom',
  'Brentwood School Ingrave Road, Brentwood CM15 8AS United Kingdom',
  'Brighton College Eastern Road, Brighton BN2 0AL United Kingdom',
  'Bryanston School Blandford DT11 0PX United Kingdom',
  'Canford School Wimborne BH21 3AD United Kingdom',
  'Caterham School Harestone Valley, Caterham CR3 6YA United Kingdom',
  'Channing School Highgate, London N6 5HF United Kingdom',
  'Charterhouse Godalming GU7 2DX United Kingdom',
  'City of London School Queen Victoria Street, London EC4V3AL United Kingdom',
  'Cranleigh School Cranleigh GU6 8QQ United Kingdom',
  'Culford School Bury St Edmunds IP28 6TX United Kingdom',
  'Dauntsey\'s School High Street, West Lavington, Devizes SN10 4HE United Kingdom',
  'Downside School Stratton-on-the-Fosse, Radstock, Bath BA3 4RJ United Kingdom',
  'Dulwich College London SE21 7LD United Kingdom',
  'Eaton Square Upper School 106 Piccadilly, London W1J 7NL United Kingdom',
  'Epsom College College Road, Epsom KT17 4JQ United Kingdom',
  'Eton College Windsor, Berkshire SL4 6DW United Kingdom',
  'Felsted School Stebbing Road, Felsted CM6 3LL United Kingdom',
  'Francis Holland School Regent\'s Park, Ivor Place, London NW1 6XR United Kingdom',
  'Freemen\'s School Ashtead Park KT21 1ET United Kingdom',
  'Fulham Senior 200 Greyhound Road, London W14 9SD United Kingdom',
  'Gateways School Harewood, Leeds LS17 9LE United Kingdom',
  'Godolphin and Latymer School Iffley Road, Hammersmith, London W6 0PG United Kingdom',
  'Haberdashers\' Monmouth Schools St Catherine\'s House, 17 Hereford Road, Monmouth NP25 3HG United   Kingdom',
  'Haileybury School Hertford SG13 7NU United Kingdom',
  'Hampton School Hanworth Road, Hampton TW12 3HD United Kingdom',
  'Harrodian Senior School Lonsdale Road, London SW13 9QN United Kingdom',
  'Harrow School 5 High Street, Harrow on the Hill HA1 3HP United Kingdom',
  'Headington School Oxford OX3 7TD United Kingdom',
  'Hull Collegiate School Tranby Lane, Anlaby HU10 7EH United Kingdom',
  'Hurstpierpoint College College Lane, Hassocks BN6 9JS United Kingdom',
  'James Allen\'s Girls\' School East Dulwich Grove, London SE22 8TE United Kingdom',
  'Kew House School Kew House, 6 Capital Interchange Way, Brentford TW8 0EX United Kingdom',
  'King Edward\'s School Witley Godalming GU8 5SG United Kingdom',
  'King\'s College Taunton TA1 3LA United Kingdom',
  'King\'s College School Wimbledon Common, London SW19 4TT United Kingdom',
  'King\'s School Rochester Satis House, Boley Hill, Rochester ME1 1TE United Kingdom',
  'Knightsbridge Senior School 67 Pont Street, London SW1X 0BD United Kingdom',
  'Lancing College Lancing BN15 0RW United Kingdom',
  'Latymer Upper School King Street, Hammersmith, London W6 9LR United Kingdom',
  'Leeds Grammar School Alwoodley Gates, Harrogate Road, Leeds LS17 8GS United Kingdom',
  'Leys School Cambridge CB2 7AD United Kingdom',
  'Lord Wandsworth College Long Sutton, Hook RG29 1TB United Kingdom',
  'Maida Vale School 18 Saltram Crescent, London W9 3HR United Kingdom',
  'Marlborough College Marlborough SN8 1PA United Kingdom',
  'Mayfield School The Old Palace, High Street, Mayfield TN20 6PH United Kingdom',
  'Mill Hill School The Ridgeway, Mill Hill, London NW7 1QS United Kingdom',
  'New Hall School Boreham, Chelmsford CM3 3HS United Kingdom',
  'Northwood College Maxwell Road, Northwood HA6 2YE United Kingdom',
  'Notting Hill and Ealing School 2 Cleveland Road, Ealing, London W13 8AX United Kingdom',
  'Oratory School Woodcote, Reading, South Oxfordshire RG8 0PJ United Kingdom',
  'Palmers Green High School 104 Hoppers Road, London N21 3LJ United Kingdom',
  'Pangbourne College Pangbourne RG8 8LA United Kingdom',
  'Putney High School 35 Putney Hill, London SW15 6BH United Kingdom',
  'Queen\'s College 43-49 Harley Street, London W1G 8BT United Kingdom',
  'Radley College Abingdon OX14 2HR United Kingdom',
  'Reed\'s School Sandy Lane, Cobham KT11 2ES United Kingdom',
  'Royal Grammar School High Street, Guildford GU1 3BB United Kingdom',
  'S. Anselm\'s College Bakewell DE45 1DP United Kingdom',
  'Seaford College Lavington Park, Petworth GU28 0NB United Kingdom',
  'South Hampstead High School 3 Maresfield Gardens, London NW3 5SS United Kingdom',
  'St Albans High School 3 Townsend Avenue, St Albans, AL1 3SJ United Kingdom',
  'St Benedict\'s School 54 Eaton Rise, Ealing, London W5 2ES United Kingdom',
  'St Dunstan\'s College Stanstead Road, London SE6 4TY United Kingdom',
  'St Edward\'s School Woodstock Road, Oxford OX2 7NN United Kingdom',
  'St Helen\'s School Northwood HA6 3AS United Kingdom',
  'St Paul\'s School Lonsdale Road, Barnes, London SW13 9JT United Kingdom',
  'St Swithun\'s School Alresford Road, Winchester SO21 1HA United Kingdom',
  'Stowe School Stowe, Buckingham MK18 5EH United Kingdom',
  'Streatham & Clapham High School 42 Abbotswood Road, Streatham, London SW16 1AW United Kingdom',
  'Sutton Valence School Sutton Valence, Maidstone ME17 3HL United Kingdom',
  'Tonbridge School Tonbridge TN9 1JP United Kingdom',
  'Trinity School Shirley Park, Croydon CR9 7AT United Kingdom',
  'University College School Frognal, Hampstead, London NW3 6XH United Kingdom',
  'Uppingham School Uppingham LE15 9QE United Kingdom',
  'Wellington College Crowthorne RG45 7PU United Kingdom',
  'Westminster School Little Dean\'s Yard, Westminster, London SW1P 3PF United Kingdom',
  'Wetherby Senior School 9 Bulstrode Street, London W1U 2QU United Kingdom',
  'Whitgift School Haling Park, South Croydon, London CR2 6YT United Kingdom',
  'Wimbledon High School Mansel Road, London SW19 4AB United Kingdom',
  'Winchester College College Street, Winchester SO23 9NA United Kingdom',
  'Woldingham School Marden Park, Woldingham CR3 7YA United Kingdom',
  'Worth School Paddockhurst Road, Turners Hill, Crawley RH10 4SD United Kingdom',
];
