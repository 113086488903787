import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Input } from '../../Form/Form';
import './group-code.css';

export enum Stages {
  ENTER_CODE,
  LOGIN_OR_SIGNUP
}

interface GroupCodeProps {
  onSubmitCode: (code: string) => void
  onSignUp: () => void
  onSignIn: () => void
  stage: Stages
}

export default function GroupCode({
  onSubmitCode,
  stage,
  onSignUp,
  onSignIn
}: GroupCodeProps) {
  const [code, setCode] = useState('');

  const submitCode = () => {
    onSubmitCode(code);
  };

  return (
    <div className="enterCode">
      <div className="enterCode__card">
        {stage === Stages.ENTER_CODE && (
          <div>
            <h2 className="enterCode__card__title">Enter your group code</h2>
            <div className="enterCode__card__inputWrap">
              <Input
                onChange={(e) => setCode(e.target.value)}
                className="enterCode__card__input"
                maxLength={7}
              />
              <div className="enterCode__card__inputPlaces">
                _ _ _ _ _ _ _
              </div>
            </div>
            <ButtonStyled disabled={!code} onClick={submitCode}>
              Continue
            </ButtonStyled>
          </div>
        )}

        {stage === Stages.LOGIN_OR_SIGNUP && (
          <>
            <span className="enterCode__card__joined">You're joining: </span>
            <span className="enterCode__card__group">Group 1 </span>
            <ButtonStyled onClick={onSignUp}>
              Create a new account
            </ButtonStyled>
            <ButtonWhiteStyled onClick={onSignIn}>
              Log in to your existing account
            </ButtonWhiteStyled>
          </>
        )}
      </div>
      <p className="enterCode__text">
        By signing up for Otter, you agree to our&nbsp;
        <a href="#">Terms Of Service</a>
        &nbsp;and&nbsp;
        <a href="#">Privacy Policy</a>
        .
      </p>
    </div>
  );
};

const ButtonStyled = styled(Button)`
  background-color: #AA95FF;
  width: 100%;

  :hover:not(:disabled) {
    background-color: #F87700;
  }

  @media screen and (max-width: 700px) {
    font-size: 20px;
  }
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
`;

const ButtonWhiteStyled = styled(Button)`
  background-color: #FFFFFF;
  border: 3px solid #9077F5;
  margin-top: 16px;
  color: #9077F5;

  :hover:not(:disabled) {
    color: white;
    border: 3px solid #F87700;
    background-color: #F87700;
  }

  @media screen and (max-width: 700px) {
    font-size: 20px;
  }
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
`;
