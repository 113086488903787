import React from 'react';
import styled from 'styled-components/macro';
import {
  CheckIcon,
} from '../../images/iconSvg';

interface PayPlanCardVerticalProps {
  onChange?: () => void
  discount?: boolean
  disabled?: boolean
  planTitle?: string
  nameRadio?: string
  className?: string
  oldPrice?: number | string
  price: number | string
  totalPrice?: number | string
  popular?: boolean
  service: string[]
  checked?: boolean
  required?: boolean
  isPlus?: boolean
}

const PayPlanCardVertical = ({
  onChange,
  checked,
  discount,
  disabled,
  oldPrice,
  price,
  totalPrice,
  planTitle,
  nameRadio,
  className,
  popular,
  service,
  required,
  isPlus,
}: PayPlanCardVerticalProps) => (
  <Label className={className}>
    <Radio type="radio" onChange={onChange} checked={checked} disabled={disabled} name={nameRadio} required={required} />
    <Card isPlus={isPlus}>
      {popular && (
      <Popular>Popular</Popular>
      )}
      <Title>{planTitle}</Title>

      <PriceWrap>
        {discount && (
        <OldPrice>
          £
          {oldPrice}
          /mo
        </OldPrice>
        )}
        <Price>
          £
          {price}
          /mo
        </Price>
        {totalPrice && (
        <TotalPrice>
          £
          {totalPrice}
          {' '}
          total
        </TotalPrice>
        )}
      </PriceWrap>

      <ListOfServices>
        {service?.map((item: string, n: number) => (
          <Service key={n}>
            <CheckIconStyled />
            <ServiceTitle>{item}</ServiceTitle>
          </Service>
        ))}
      </ListOfServices>
    </Card>
  </Label>
);

export default PayPlanCardVertical;

const Label = styled.label`
  display: block;
  cursor: pointer;
  position: relative;
  width: calc((100% / 3) - 30px);
  height: 343px;
  margin: 0 15px 30px;
  flex-shrink: 0;

  @media (max-width: 1100px) {
    width: calc((100% / 2) - 30px);
  }

  @media (max-width: 860px) {
    width: 100%;
  }
`;

const Popular = styled.span`
  width: 152px;
  height: 26px;
  background-color: #F87700;
  text-align: center;
  color: #FFF;
  font-weight: bold;
  font-size: 16px;
  transform: rotate(45deg);
  position: absolute;
  top: 22px;
  right: -42px;
`;

const Radio = styled.input`
  width: 1px;
  height: 1px;
  display: block;
  opacity: 0;
`;

const Card = styled.div<{current?: boolean, isPlus?: boolean}>`
  width: 100%;
  background-color:${({ isPlus }) => (isPlus ? '#FFE16A' : '#EEEAFF')};
  height: 100%;
  box-sizing: border-box;
  border: 4px solid ${({ isPlus }) => (isPlus ? '#FFE16A' : '#EEEAFF')};
  padding: 59px 20px 42px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  overflow: hidden;
  position: relative;
  transition: .1s linear;

  :hover {
    border-color: ${({ isPlus }) => (isPlus ? '#FCD53A' : '#9077F5')};
    transform: scale(1.06);
    background-color: #FFF;
  }

  ${Label} > ${Radio}:checked + & {
    background-color: ${({ isPlus }) => (isPlus ? '#F87700' : '#9077F5')};
    border-color: ${({ isPlus }) => (isPlus ? '#F87700' : '#9077F5')};
    color: #FFF;
    transform: scale(1.06);
  }
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 38px;
`;

const ListOfServices = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Service = styled.li`
  display: flex;
`;

const CheckIconStyled = styled(CheckIcon)`
  font-size: 12px;
  align-self: baseline;
  margin-top: 5px;
  & svg {
    fill: #1F1F1F;
    ${Label} > ${Radio}:checked + ${Card} & {
      fill: #FFF;
    }
  }
`;

const ServiceTitle = styled.span`
  display: block;
  margin-left: 9px;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;

const PriceWrap = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;

const Price = styled.span`
  display: block;
  font-weight: bold;
  font-size: 33px;
  line-height: 40px;
  margin-bottom: 12px;
`;

const OldPrice = styled.span`
  display: block;
  font-style: italic;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-decoration: line-through;
  color: #9C9C9C;
  ${Label} > ${Radio}:checked + ${Card} & {
    color: #FFF;
  }
`;

const TotalPrice = styled.span`
  display: block;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;
