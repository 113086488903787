import { SchoolFieldsType } from '../school/types';
import { UserPublicType } from '../users/types';

export enum TYPE_TEACHER {
  SCHOOL_TEACHER = 'SCHOOL_TEACHER',
  AGENCY_TEACHER = 'AGENCY_TEACHER',
  PRIVATE_TUTOR = 'PRIVATE_TUTOR',
}

interface TeacherSetUpBaseType {
  teacherType: string,
}

export interface SchoolTeacherSetupType extends TeacherSetUpBaseType {
  school: SchoolFieldsType['_id'],
}

export interface AgencyTeacherSetupType extends TeacherSetUpBaseType {
  agency: string,
}

export interface PrivateTeacherSetupType extends TeacherSetUpBaseType {

}

export interface TeacherSetUpCommonType extends
  Partial<PrivateTeacherSetupType>,
  Partial<AgencyTeacherSetupType>,
  Partial<SchoolTeacherSetupType> {

}

export interface TeacherPublicType extends TeacherSetUpCommonType {
  _id: string;
  user: UserPublicType;
}
