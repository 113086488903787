import React from 'react';
import styled from 'styled-components/macro';
import {
  CheckIcon,
} from '../../images/iconSvg';

interface CardForPlanPlops {
  onChange?: () => void
  discount?: boolean
  isCurrent?: boolean
  disabled?: boolean
  planTitle?: string
  nameRadio?: string
  className?: string
  oldPrice?: number | string
  price: number | string
  totalPrice?: number | string
  services: string[]
}

const CardForPlan = ({
  onChange,
  discount,
  isCurrent,
  disabled,
  oldPrice,
  price,
  totalPrice,
  planTitle,
  nameRadio,
  className,
  services = [],
}: CardForPlanPlops) => {

  return (
    <Label className={className}>
      {discount && (
        <DiscountPrice>Save 25%</DiscountPrice>
      )}
      <Radio type="radio" onChange={onChange} checked={isCurrent} disabled={disabled} name={nameRadio} />
      <Card current={isCurrent}>
        <Title>{planTitle}</Title>
        <Wrapper>
          <ListOfServices>
            {services.map((service: string) => (
              <Service>
                <CheckIconStyled />
                <ServiceTitle>{service}</ServiceTitle>
              </Service>
            ))}
          </ListOfServices>
          <PriceWrap>
            <PriceMonth>
              {discount && (
                <OldPrice>£{oldPrice}/mo</OldPrice>
              )}
              £{price}/mo
            </PriceMonth>
            {totalPrice && (
              <TotalPrice>£{totalPrice} total</TotalPrice>
            )}
          </PriceWrap>
        </Wrapper>
      </Card>
    </Label>
  );
};

export default CardForPlan;

const Label = styled.label`
  display: block;
  cursor: pointer;
  overflow: hidden;
  position: relative;
`;

const DiscountPrice = styled.span`
  width: 152px;
  height: 26px;
  background-color: #F87700;
  text-align: center;
  color: #FFF;
  font-weight: bold;
  font-size: 16px;
  transform: rotate(45deg);
  position: absolute;
  top: 22px;
  right: -42px;
`;

const Radio = styled.input`
  display: none;
`;

const Card = styled.div<{current?: boolean}>`
  width: 100%;
  background-color: #EEEAFF;
  min-height: 136px;
  box-sizing: border-box;
  border: 3px solid transparent;
  padding: 17px 23px 11px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;

  :hover {
    border-color: #9077F5;
  }

  ${Label} > ${Radio}:checked + & {
    background-color: #FFF;
    border-color: ${({ current }) => current ? '#9077F5' : '#F87700'};
  }
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
`;

const Wrapper = styled.div`
  display: flex;
  min-height: 50px;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
`;

const ListOfServices = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Service = styled.li`
  display: flex;
`;

const CheckIconStyled = styled(CheckIcon)`
  font-size: 12px;
  & svg {
    fill: #F87700;
  }
`;

const ServiceTitle = styled.span`
  display: block;
  margin-left: 9px;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;

const PriceWrap = styled.div``;

const PriceMonth = styled.span`
  font-weight: bold;
  font-size: 26px;
  line-height: 26px;
`;

const OldPrice = styled.span`
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  margin-right: 16px;
  text-decoration: line-through;
`;

const TotalPrice = styled.span`
  display: block;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  color: #9C9C9C;
  text-align: right;
  line-height: 17px;
`;
