import React from 'react';
import styled from 'styled-components/macro';
import ContentForWelcomePage from '../Form/FieldsEntity/ContentForWelcomePage';
import PageWraperForTeacherSetUp from './PageWrapperForTeacherSetUp';

const WelcomeTeacher = () => {
  return (
    <PageWraperForTeacherSetUp>
      <ContentWelcomePage
        linkToNext={`${process.env.PUBLIC_URL}/login`}
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Habitant pellentesque pharetra suscipit tortor eu fermentum magna. Tristique vitae facilisis ipsum donec consectetur nulla tristique vestibulum."
      />
    </PageWraperForTeacherSetUp>
  );
};

export default WelcomeTeacher;


const ContentWelcomePage = styled(ContentForWelcomePage)`
  max-width: 1005px;
  justify-content: center;
`;
