import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Page from './PageForExam';
// import exam from './exam';
import ChalengeBody from './ChalengeBody';
import Timer from '../Form/FieldsEntity/Timer';
import Modal from '../Form/FieldsEntity/Modal';
import ModalTimeIsUp from './ModalTimeIsUp';
import FormElements from '../Form';
import FinishTest from './FinishTest';
import NotificationOfTheCorrectAnswer from './NotificationOfTheCorrectAnswer';
// import { TypeQuestion } from './type';

import ExamQuestionList from './ExamQuestionList';
import { ChallengeType, QuestionType } from '../features/challenge/types';

const {
  Button,
} = FormElements;

const TIME_STATE_IN_PROGRESS = 'TIME_STATE_IN_PROGRESS';
const TIME_STATE_EXTRA = 'TIME_STATE_EXTRA';
const TIME_STATE_IS_UP = 'TIME_STATE_IS_UP';
// const QUESTION_STATE_SKIP = 'QUESTION_STATE_SKIP';
// const QUESTION_STATE_ANSWERED = 'QUESTION_STATE_ANSWERED';

export interface ChalengePageType {
  currentChallenge: ChallengeType,
  currentQuestionNum: number,
  onChangeQuestion: (questionNum: number) => void,
  currentQuestion: QuestionType,
  numOfQuestions: number,
  onAnswer: (answer: string, toNext?: boolean) => void,
  onSkip: () => void,
  isFinished: boolean,
  setFinishTest: (finish: boolean) => void
  challengeType: string
  finisChalenge: () => void
  extendChalenge: () => void
}


const ExamCommonPage = ({
  currentChallenge,
  currentQuestionNum,
  onChangeQuestion,
  currentQuestion,
  numOfQuestions,
  onAnswer,
  onSkip,
  isFinished,
  setFinishTest,
  challengeType,
  finisChalenge,
  extendChalenge
}: ChalengePageType) => {
  const [stateTime, setStateTime] = useState(TIME_STATE_IN_PROGRESS);

  useEffect(() => {
    if (currentChallenge.questions.length === numOfQuestions) {
      // setFinishTest(true);
    }
  }, []);

  const isSubject = currentChallenge.topicId?.[0];

  const endDate: any = new Date(currentChallenge.challengeFinishTime);
  const startDate: any = new Date(currentChallenge.challengeStartTime);

  const timeToEnd = (endDate - startDate) / 1000;

  const textOnFinishButton = challengeType === 'exam' ? 'Finish exam' : 'End session';

  return (
    <Page isSubject={isSubject}>
      {stateTime === TIME_STATE_IS_UP && (
        <Modal unclosed onClose={() => {}}>
          <ModalTimeIsUp onFinishChalenge={finisChalenge} onAddTime={() => {
            setStateTime(TIME_STATE_EXTRA)
            extendChalenge();
          }} />
        </Modal>
      )}
      {/* showModal true or false */}
      <Wrapper>
        <QuestionProgress>
          {currentChallenge.questions && <ExamQuestionList
            questions={currentChallenge.questions}
            onChangeQuestion={onChangeQuestion}
            numOfQuestion={numOfQuestions}
            currentQuestionNum={Number(currentQuestionNum)}
            finishTest={isFinished}
            showAnswerMode={challengeType === 'test'}
          />}
          {typeof timeToEnd === 'number' && <Timer
            chalenge={currentChallenge}
            onTimeOut={() => setStateTime(TIME_STATE_IS_UP)}
          />}
          {!isFinished && (
            <ButtonFinish onClick={() => setFinishTest(true)}>{textOnFinishButton}</ButtonFinish>
          )}
        </QuestionProgress>
        {isFinished ? (
          <FinishTest
            onContinue={() => setFinishTest(false)}
            questionsList={currentChallenge.questions}
            numOfQuestion={numOfQuestions}
            challengeId={currentChallenge._id}
            challengeType={challengeType}
            finisChalenge={finisChalenge}
          />
        ) : (currentQuestion
              && (
            <ChalengeBody
                currentChalenge={currentChallenge}
                currentQuestion={currentQuestion}
                currentQuestionNum={currentQuestionNum}
                onAnswer={onAnswer}
                onSkip={() => onSkip()}
                allQuestionsViewed={currentQuestionNum === numOfQuestions}
              />
              )
        )}
      </Wrapper>
    </Page>
  );
};

export default ExamCommonPage;

const Wrapper = styled.div`
  max-width: 1005px;
  width: 100vw; 
  box-sizing: border-box;
  padding: 50px 60px 84px;
`;

const QuestionProgress = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 120px;
`;

const ButtonFinish = styled(Button)`
  min-width: 184px;
  width: auto;
  min-height: 50px;
  height: auto;
  align-self: baseline;
  border-radius: 12px;
  font-size: 20px;
  line-height: 26px;
  transition: .2s ease-in-out;
  background-color: ${({ theme }) => theme.color};
  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.hoverColor};
  }
`;
