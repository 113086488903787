import React from 'react';
import styled from 'styled-components/macro';
import HeaderForTeacherDashboard from '../HeaderForTeacherDashboard';

interface TeacherGroupLayoutProps {
  onAddNewGroup: () => void
  children: JSX.Element[]
}

const TeacherGroupsLayout = ({
  onAddNewGroup,
  children,
}: TeacherGroupLayoutProps) => (
  <Wrapper>
    <HeaderForTeacherDashboard
      onAdd={onAddNewGroup}
      addButtonName="Add new group"
      title="My groups"
    />
    {children.length > 0
      ? children
      : (
        <Description>
          You don&apos;t have any groups created yet
        </Description>
      )}
  </Wrapper>
);

TeacherGroupsLayout.defaultProps = {
  children: [],
};

export default TeacherGroupsLayout;

const Wrapper = styled.div`
  width: 100%;
  min-width: 480px;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 22px;
`;
