import React, { useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import Modal from '../../Form/FieldsEntity/Modal';
import ChangeSubscriptionFromModal from './ChangeSubscriptionForModal';
import { SUBSCRIPTION_STATUS } from './CurrentPlan';
import { PayPlanType } from './types';
import FormElements from '../../Form';

const {
  Button,
} = FormElements;

const STAGES = {
  NOTIFICATION: 'NOTIFICATION',
  CHANGE: 'CHANGE',
};

interface ModalChangeSubscriptionProps {
  showModal: boolean
  onChangeTariff: (priceId: string, system: string, ppSubscribeId?: string) => void
  onClose: () => void
  priceList: PayPlanType
  currentPriceName: string
  statusSubscription: string
}

const ModalChangeSubscription = ({
  showModal,
  onChangeTariff,
  onClose,
  priceList,
  currentPriceName,
  statusSubscription,
}: ModalChangeSubscriptionProps) => {
  const currentStagesByStatusSubscription = useMemo(() => (
    statusSubscription === SUBSCRIPTION_STATUS.ACTIVE ? STAGES.CHANGE : STAGES.NOTIFICATION
  ), [statusSubscription]);

  const [currentStages, setCurrentStages] = useState(currentStagesByStatusSubscription);

  if (!showModal) return null;

  if (currentStages === STAGES.NOTIFICATION) {
    return (
      <Modal onClose={onClose} unclosed>
        <Wrapper>
          <Title>Renew Subscription</Title>
          <Description>
            By clicking “Renew Now”, you agree to renew Otter Plus and
            pay £44,99 automatically each month until you cancel,
            starting on March 31, 2021
          </Description>
          <ButtonWrapper>
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <RenewButton onClick={() => setCurrentStages(STAGES.CHANGE)}>Renew Now</RenewButton>
          </ButtonWrapper>
        </Wrapper>
      </Modal>
    );
  }

  return (
    <Modal onClose={onClose}>
      <ChangeSubscriptionFromModal
        showModal={showModal}
        priceList={priceList}
        currentPriceName={currentPriceName}
        onClose={onClose}
        onChangePlan={onChangeTariff}
      />
    </Modal>
  );
};

export default ModalChangeSubscription;

const Wrapper = styled.div`
  padding: 50px 70px 44px;
  max-width: 740px;
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 25px;
  line-height: 26px;
  margin-bottom: 30px;
`;

const Description = styled.p`
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 40px;
`;

const ButtonWrapper = styled.div`
  width: fit-content;
  display: flex;
  margin-left: auto;
`;

const RenewButton = styled(Button)`
  min-width: 128px;
  width: auto;
  height: auto;
  padding: 13px 25px;
  border: 3px solid transparent;
  color: #FFF;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  background-color: #9077F5;
  margin-left: 20px;
`;

const CancelButton = styled(RenewButton)`
  margin-left: 0;
  background-color: #FFF;
  border-color: #9C9C9C;
  color: #9C9C9C;
  :hover:not(:disabled) {
    background-color: #FFF;
    border-color: #F87700;
    color: #F87700;
  }
`;
