import React from 'react';
import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import FormElements from '../Form';
import { TermsAndPrivacy } from './StudentSignUp';
import { InputError, InputPasswordErrors } from '../Form/FieldsEntity/FormInput';
import { ErrorsType } from '../utils/createForm';
import { passwordVerification } from '../hooks/passwordValidator';
import { useDispatch } from 'react-redux';
import { setTeacherSignUp } from '../features/teacher/teacherState';
import { useHistory } from 'react-router-dom';

const { Input, Checkbox, Button } = FormElements;

const teacherSignUpValidate = (values: {fullName: string, password: string}) => {
  const errors: ErrorsType = {};
  if (!values.fullName) {
    errors.fullName = ['Full name is required'];
  }

  if (values.password) {
    const passwordErrors = passwordVerification(values.password);

    if (passwordErrors.length > 0) {
      errors.password = passwordErrors;
    }
  }

  return errors;
};

const TeacherSignUp = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const teacherSignUp = useFormik({
    initialValues: {
      fullName: '',
      password: '',
    },
    validate: teacherSignUpValidate,
    onSubmit: (values) => {
      dispatch(setTeacherSignUp(values));
      push(`${process.env.PUBLIC_URL}/set-up-teacher`);
    },
  });

  const valuesIsEmpty = !teacherSignUp.values.fullName || !teacherSignUp.values.password;

  return (
    <Form onSubmit={teacherSignUp.handleSubmit}>
      <InputWrapper>
        <InputStyled
          placeholder="Full name"
          name="fullName"
          type="text"
          onChange={teacherSignUp.handleChange}
          onBlur={teacherSignUp.handleBlur}
          value={teacherSignUp.values.fullName}
          minLength={2}
          require
        />
        <InputError errors={teacherSignUp.errors.fullName} />
      </InputWrapper>
      <InputWrapper>
        <InputStyled
          placeholder="Set a password"
          name="password"
          onChange={teacherSignUp.handleChange}
          onBlur={teacherSignUp.handleBlur}
          value={teacherSignUp.values.password}
          type="password"
          require
        />
        <InputPasswordErrors errors={teacherSignUp.errors.password} />
      </InputWrapper>

      <TermsAndPrivacyStyled
        onCheck={console.log}
      />
      <Button type="submit" disabled={valuesIsEmpty || !teacherSignUp.isValid}>Fill questionnaire</Button>
    </Form>
  );
};

export default TeacherSignUp;

const Form = styled.form`
  box-sizing: border-box;
  padding: 39px 30px 30px;
`;

const InputStyled = styled(Input)<{ require?: boolean }>``;

const CheckboxStyled = styled(Checkbox)`
  margin: 17px 0 32px 13px;
`;

const TextChechbox = styled.p`
  font-size: 16px;
  line-height: 20px;
  & a {
    color: #1f1f1f;
    text-decoration: underline;
  }
`;

const TermsAndPrivacyStyled = styled(TermsAndPrivacy)`
  margin-top: 49px;
  margin-bottom: 33px;
`;

const InputWrapper = styled.div`
  margin-bottom: 15px;
`;
