import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { RootState } from '../../app/store';
import Topic from '../../Form/FieldsEntity/TopicForDashboard';
import StartButton from '../../Form/FieldsEntity/StartButton';
// import topicsList from './topicsItems';
import { ArrowTailIcon } from '../../images/iconSvg';
import TopicsListOfSelectedTest from './TopicsListOfSelectedTest';
import { SubjectType, TopicAreasType, TopicRouteType, TopicType } from '../../features/topic/types';
import { getTopicInfo, getTopicsInfo } from '../../features/topic/topicState';
import { TopicsType } from '../../ProgressPage/type';
import { subjectsStyles } from '../../config/subjectsStyles';

interface TopicAreasProps {

}

const TopicAreas = ({
}: TopicAreasProps) => {
  const { subject, topic, topicAreas, subTopic } = useParams<TopicRouteType>();
  const { push } = useHistory();

  const topicsItems = useSelector((state: RootState) => getTopicsInfo(state, subject || 'Maths'));

  const currentTopicItems = topicsItems.find((item) => item.title === topic);

  const currentTopicAreas = useSelector((state: RootState) => getTopicsInfo(state, subject || '', topic || ''));
  const subTopicItems = useSelector((state: RootState) => getTopicsInfo(state, subject || '', topic || '', topicAreas || ''));

  const linkToStart = (e: React.SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (topicAreas) {
      return push(`${process.env.PUBLIC_URL}/challenge/start/test/${subject}/${topic}/${topicAreas}`);
    }
    return push(`${process.env.PUBLIC_URL}/challenge/start/test/${subject}/${topic}`);
  };

  return (
    <Wrapper>
      <TestsSubTitle to={`${process.env.PUBLIC_URL}/dashboard/new-test/${subject}`}>
        <Arrow />
        {' '}
        Back to topic areas
      </TestsSubTitle>
      <MainTopic
        icon={currentTopicItems?.icon}
        background={currentTopicItems?.backgroundHover}
      >
        <TextWrapper>
          <TopicName>{topicAreas || topic}</TopicName>
          <DescriptionTopic>
            Practice test. All sub-topics
          </DescriptionTopic>
        </TextWrapper>
        <StartButtonStyled onClick={linkToStart}>
          Start
        </StartButtonStyled>
      </MainTopic>
      <TopicAreasBox>
        <BracketUnionBox />
        <TopicsList>
          <TitleAreas>
            <LinkTopicAreas current={false} to={`${process.env.PUBLIC_URL}/dashboard/new-test/${subject}`}>
              Topics
            </LinkTopicAreas>
            {' '}
            /
            {' '}
            <LinkTopicAreas current={!topicAreas} to={`${process.env.PUBLIC_URL}/dashboard/new-test/${subject}/${topic}`}>
              Topic areas
            </LinkTopicAreas>
            {topicAreas && (
              <>
                {' '}
                /
                {' '}
                <LinkTopicAreas current to="#">
                  sub-topics
                </LinkTopicAreas>
              </>
            )}
          </TitleAreas>
          <TopicsListOfSelectedTest topicItems={topicAreas ? subTopicItems : currentTopicAreas} isSubTopics={!!topicAreas} backgroundTopic={currentTopicItems?.backgroundHover} />
        </TopicsList>
      </TopicAreasBox>
    </Wrapper>
  );
};

export default TopicAreas;

const Wrapper = styled.div`
  margin-bottom: 230px;
`;

const TestsSubTitle = styled(Link)`
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 39px;
  margin-bottom: 27px;
  color: #C4C4C4;
  text-align: left;
  display: flex;
  align-items: center;
  :hover {
    color: #C4C4C4;
  }
`;

const Arrow = styled(ArrowTailIcon)`
  margin-right: 9px;
`;

const TopicAreasBox = styled.div`
  margin-top: 70px;
  width: 100%;
  display: flex;
`;

const MainTopic = styled(Topic)<{background?: string}>`
  background-color: ${(props) => props.background};
`;

const BracketUnionBox = styled.div`
  width: 23px;
  /* height: 80%; */
  text-align: right;
  flex-shrink: 0;
  align-self: stretch;
  border-left: 10px solid #FCD53A;
  position: relative;
  margin: 8px 9px 49px 68px;
  ::before {
    content: '';
    display: block;
    width: 100%;
    height: 10px;
    background-color: #FCD53A;
    border-radius: 0 50% 50% 0;
    position: absolute;
    top: 0;
    left: 0;
  }
  ::after {
    content: '';
    display: block;
    width: 100%;
    height: 10px;
    background-color: #FCD53A;
    border-radius: 0 50% 50% 0;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

const TopicsList = styled.div`
  flex-grow: 1;
`;

const TitleAreas = styled.h5`
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #C4C4C4;
`;

const StyledTopic = styled(Topic)`
  height: 120px;
  padding-left: 50px;

  :not(:last-child) {
    margin-bottom: 30px;
  }
`;

const StartButtonStyled = styled(StartButton)`
  padding: 18px 20px;
`;

const TextWrapper = styled.div`
  margin: 0 20px;
  flex-shrink: 0;
  /* margin-right: auto; */
`;

const TitleTopic = styled.span`
  display: block;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
`;

const DescriptionTopic = styled.span`
  display: block;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
`;

const LinkTopicAreas = styled(Link)<{current: boolean}>`
  color: ${(props) => (props.current ? '#1F1F1F' : 'inherit')};
  :hover {
    color: ${(props) => (props.current ? '#1F1F1F' : 'inherit')};
  }
`;

const TopicName = styled.span`
  display: block;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
`;
