import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

type ValuesType = {
  [key: string]: any;
}

export type ErrorsType = {
  [key: string]: any;
}

type FormType = {
  values: ValuesType,
  errors: ErrorsType
};

type ValidatorType = (elName: string, value: any) => ErrorsType;

const initialSignUpForm: FormType = {
  values: {},
  errors: {},
};

const createForm = (name: string) => {
  const Form = createSlice({
    name,
    initialState: initialSignUpForm,
    reducers: {
      setFormFields: (state, action: { payload: ValuesType }) => ({
        ...state, values: { ...state.values, ...action.payload }
      }),
      setFieldsErrors: (state, action: { payload: ValuesType }) => { 
        return {
        ...state, errors: { ...state.errors, ...action.payload }
      }},
      flushErrors: (state) => { 
        return {
        ...state, errors: { },
      }}
    },
  });

  return { ...Form };
};

export default createForm;


export const fieldIsChanged = (values: ValuesType, name: string) => {
  if (typeof values[name] !== 'undefined') {
    return true;
  }
  return false;
};

export const useChangeValue = (formActions: any, validator?: ValidatorType) => {
  const dispatch = useDispatch();

  const changeValue = (name: string, value: any) => {
    // const errors = validator(name, value);
    // dispatch(formActions.setFieldsErrors(errors));
    dispatch(formActions.setFormFields({ [name]: value }));
  };


  const changeInputValue = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    changeValue(name, value);
  };

  return { changeInputValue, changeValue };
};
