import React from 'react';
import styled from 'styled-components/macro';

interface ItemNumberProps {
  className?: string
  itemNumber: number
  itemStatus?: string
}

export const QUESTION_NUMBER_STATUSES = {
  SELECT: 'SELECT',
  ANSWERED: 'ANSWERED',
  CORRECT: 'CORRECT',
  INCORRECT: 'INCORRECT',
  SKIPPED: 'SKIPPED',
  EMPTY: 'EMPTY',
};

const selectBackgroundColor = (itemStatus: string) => {
  switch (itemStatus) {
    case QUESTION_NUMBER_STATUSES.SELECT:
      return '#FFF';
    case QUESTION_NUMBER_STATUSES.ANSWERED:
      return '#9077F5';
    case QUESTION_NUMBER_STATUSES.CORRECT:
      return '#95D354';
    case QUESTION_NUMBER_STATUSES.INCORRECT:
      return '#F55F4B';
    case QUESTION_NUMBER_STATUSES.SKIPPED:
      return '#9C9C9C';
    case QUESTION_NUMBER_STATUSES.EMPTY:
      return '#E0E0E0';
    default:
      return '#E0E0E0';
  }
};

const ItemNumber = ({
  className, itemNumber, itemStatus = '',
}: ItemNumberProps) => (
  <Wrapper
    itemStatus={itemStatus}
    className={className}
    backgroundColor={selectBackgroundColor(itemStatus)}
  >
    {itemNumber}
  </Wrapper>
);

export default ItemNumber;

const Wrapper = styled.div<{backgroundColor: string, itemStatus?: string}>`
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => (props.backgroundColor === '#FFF' ? '#F87700' : '#FFF')};
  background-color: ${(props) => props.backgroundColor};
  box-sizing: border-box;
  border: ${(props) => (props.backgroundColor === '#FFF' ? '2px solid #F87700' : 'none')};
`;
