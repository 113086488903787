import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import FormElements from '../../Form';
import { InputError, InputPasswordErrors } from '../../Form/FieldsEntity/FormInput';
import LabelForInput from '../../Form/FieldsEntity/LabelForInput';
import { passwordVerification } from '../../hooks/passwordValidator';

const {
  Input,
} = FormElements;

const pattern = '[A-Za-z0-9]{6,}';

interface ChangePasswordProps {
  onChangePassword: (password: string) => void
  title?: string
  className?: string
  onConfirm: (confirm: boolean) => void
}

const ChangePassword = ({
  onChangePassword,
  title,
  className,
  onConfirm,
}: ChangePasswordProps) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const isVerifyPass = passwordVerification(password);

  useEffect(() => {
    onChangePassword(password);
    if (password !== confirmPassword || isVerifyPass.length > 0) {
      onConfirm(false);
    }
    if (password === confirmPassword) {
      onConfirm(true);
    }
  }, [confirmPassword, password]);

  return (
    <Wrapper className={className}>
      <Title>{title || 'Change your password'}</Title>
      <NewPasswordLabel name="New Password">
        <InputWrapper>
          <StyledInput
            type="password"
            autoComplete="off"
            value={password}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
            ) => setPassword(e.currentTarget.value)}
            error={isVerifyPass.length > 0 && password !== ''}
            pattern={pattern}
          />
          <ErrorsWrapper>
            {/* {password.length < 6 && password.length !== 0 && (
              <InvalidMessage>
                Should be at least 6 characters long and should contain a mixture of letters and numbers
              </InvalidMessage>
            )} */}
            {password !== '' && (
              <InputErrorStyled errors={isVerifyPass} />
            )}
          </ErrorsWrapper>
        </InputWrapper>
      </NewPasswordLabel>
      <ConfirmNewPasswordLabel name="Confirm New Password">
        <InputWrapper>
          <StyledInput
            type="password"
            autoComplete="off"
            value={confirmPassword}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement>,
            ) => setConfirmPassword(e.currentTarget.value)}
            error={(password !== confirmPassword || isVerifyPass.length > 0) && confirmPassword !== ''}
            pattern={pattern}
          />
          {password !== confirmPassword && (
            <InvalidMessage>
              Passwords do not match
            </InvalidMessage>
          )}
        </InputWrapper>
      </ConfirmNewPasswordLabel>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  // margin-bottom: 20px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
`;

const StyledInput = styled(Input)<{value: string, error?: boolean}>`
  height: 56px;
  font-weight: normal;
  font-size: 16px;
  width: 360px;
  align-self: baseline;
  :focus {
    border: 2px solid ${({ error }) => error ? '#F55F4B' : '#9077F5'};
    padding: 16px 14px;
  }
  ${({ value, error }) => (value !== '' && !error) && (
    `:invalid {
      padding: 16px 14px;
      border: 2px solid #F55F4B;
    }
    :valid {
      padding: 16px 14px;
      border: 2px solid #95D354;
    }`
  )}
`;

const NewPasswordLabel = styled(LabelForInput)`
  // max-width: 360px;
  margin-bottom: 8px;
`;

const ConfirmNewPasswordLabel = styled(LabelForInput)`
  // max-width: 360px;
`;

const InvalidMessage = styled.span`
    display: block;
    width: 224px;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #F55F4B;
    flex-shrink: 0;
    margin-left: 38px;
`;

const InputWrapper = styled.div`
    display: flex;
    width: max-content;
    align-items: center;
`;

const ErrorsWrapper = styled.div``;

const InputErrorStyled = styled(InputPasswordErrors)`
    margin-left: 38px;
    max-width: 200px;
`;

export default ChangePassword;
