import React from 'react';
import styled from 'styled-components/macro';
import ShowLatex from '../ExamTest/ShowLates';
import { QuestionType } from '../features/challenge/types';

interface StrictChallengeAnswerControlProps {
  itemsFromButton: QuestionType
  column?: boolean
  onSelectAnswer: (answer: string) => void,
  userAnswer?: string
}

interface RadioButtonsProps {
  circle?: boolean
  onSelectAnswer: (answer: string) => void
  itemsFromButton?: string[],
  value?: string
}

const RadioButtons = ({
  itemsFromButton,
  circle,
  onSelectAnswer,
  value,
}: RadioButtonsProps) => {
  const a = () => {
  }
  
  return (
    <>
      {itemsFromButton && itemsFromButton.map((answer: string, key: number) => (
        <Label>
          <Radio checked={value===`${key}`} type="radio" name="answer" onChange={() => onSelectAnswer(`${key}`)} />
          <Content circle={circle}>
            <ShowLatexStyled>
              {answer}
            </ShowLatexStyled>
          </Content>
        </Label>
      ))}
    </>
  );
};

const StrictChallengeAnswerControl = ({
  itemsFromButton,
  column,
  onSelectAnswer,
  userAnswer
}: StrictChallengeAnswerControlProps) => {
  const answerType = itemsFromButton?.['answer-type'];

  const renderTypeAnswer = () => {
    if (answerType === 'input') {
      return null;
    } if (answerType === 'multiple') {
      return (
        <RadioButtons value={userAnswer} itemsFromButton={itemsFromButton?.answers} circle={column} onSelectAnswer={onSelectAnswer} />
      );
    }
    return null;
  }

  return (
    <Wrapper column={column}>
      {renderTypeAnswer()}
    </Wrapper>
  );
};

export default StrictChallengeAnswerControl;

const Wrapper = styled.div<{column?: boolean}>`
  display: flex;
  margin-top: ${({ column }) => !column && 'auto'};
  ${({ column }) => column && `
    flex-direction: column;
    align-items: flex-start;
  `}
`;

const Label = styled.label`
  flex-grow: 1;
  margin-bottom: 10px;
  cursor: pointer;
  :not(:last-child) {
    margin-right: 10px;
  }
`;

const Radio = styled.input`
  display: none;
`;

const Content = styled.div<{circle?: boolean}>`
  ${({ circle }) => !circle && 'border: 4px solid #F87700;'}
  min-height: ${({ circle }) => !circle && '106px'};
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 18px;
  font-weight:  500;
  ${({ circle }) => circle && `
    flex-direction: row;
    ::before {
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 4px solid #F87700;
      margin-right: 10px;
    }
  `}

  :hover {
    border-color: #caca41;
    ${({ circle }) => circle && `
    ::before {
      border-color: #caca41;
    }
    `}
  }

  ${Label} ${Radio}:checked + & {
    border-color: blue;
    ${({ circle }) => circle && `
    ::before {
      border-color: blue;
    }
  `}
  }
`;

const ShowLatexStyled = styled(ShowLatex)`
  & img {
    max-width: 100%;
  }
`;
