import React from 'react';
import { useDispatch } from 'react-redux';
import { avatars } from '../../../database/avatars';
import { UserProfileType } from '../../../features/user/types';
import { logOut } from '../../../features/user/userState';
import StudentFrame from './StudentFrame';
import TeacherFrame from './TeacherFrame';

interface UserFrameProps {
  userProfile: UserProfileType
}

const UserFrame = ({
  userProfile,
}: UserFrameProps) => {
  const dispatch = useDispatch();

  const studentAvatar = avatars.find((avatar) => avatar.avatarId === userProfile.avatar)?.avatarImg;

  const onLogOut = () => {
    dispatch(logOut());
  };

  if (userProfile.userType === 'teacher') {
    return (
      <TeacherFrame
        onLogOut={onLogOut}
        userProfile={userProfile}
      />
    );
  }

  return (
    <StudentFrame
      userProfile={userProfile}
      studentAvatar={studentAvatar}
      onLogOut={onLogOut}
    />
  );
};

export default UserFrame;
