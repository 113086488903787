import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { request } from '../../api';
import PastExamTests, { ChalengeItemType } from '../../DashboardPage/PastTests/PastExamTests';
import PastPracticeTests from '../../DashboardPage/PastTests/PastPracticeTests';
import SectionWrapper from '../../DashboardPage/PastTests/SectionWrapper';

const pastExams = [
  {
    id: '5faacbb2c9b8256a00ec4ff1',
    key: 1,
    date: '2020-11-10 17:59:46.995Z',
    elapsedTime: 10,
    extraTime: true,
    result: 60,
  },
  {
    id: '5faacbb2c9b8256a00ec4ff1',
    key: 1,
    date: '2020-11-10 17:59:46.995Z',
    elapsedTime: 10,
    extraTime: false,
    result: 20,
  },
];

const pastTests: any = [

];

interface PastChalengesProps {
  id: string
}

const PastChalenges = ({ id }: PastChalengesProps) => {
  const history = useHistory();

  const [pastExams, setPastExams] = useState<ChalengeItemType[]>([]);

  useEffect(() => {
    request(
      {
        method: 'POST',
        url: `admin/challenge/history`,
        data: {
          userId: id,
          type: 'test'
        },
      },
      [200],
    ).then(({ data }) => {
      setPastExams(data);
    });
  }, [id, setPastExams]);

  const showPastExamResult = (chalengeId: string) => {
    // history.push(`/chalenge/correct-answers/exam/${chalengeId}/1`);
  };

  const showPastTestResult = (chalengeId: string) => {
    // history.push(`/chalenge/correct-answers/test/${chalengeId}/1`);
  };

  return (
    <Container>
      <SectionWrapper title="Past exams">
        <PastExamTests onShowDetailResult={showPastExamResult} examStatistic={pastExams} />
      </SectionWrapper>
      <SectionWrapper title="Past tests">
        <PastPracticeTests onShowDetailResult={showPastTestResult} practiceTests={pastTests} />
      </SectionWrapper>
    </Container>
  );
};

export default PastChalenges;

const Container = styled.div`
  max-width: 992px;
`;
