import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components/macro';
import ShowLatex from '../ExamTest/ShowLates';
import { QuestionType } from '../features/challenge/types';

interface StrictChallengeDescripProps {
  description: QuestionType;
  widgetView?: boolean
}

const StrictChallengeDescrip = ({
  description,
  widgetView,
}: StrictChallengeDescripProps) => (
  <Wrapper>
    <Description restyle={widgetView}>
      <ShowLatexStyled>
        {description?.question}
      </ShowLatexStyled>
    </Description>
    {description?.svg && (
      <QuestionImg src={description.svg} />
    )}
  </Wrapper>
);

export default StrictChallengeDescrip;

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

const Description = styled.div<{restyle?: boolean}>`
  font-size: 16px;
  margin: ${({ restyle }) => restyle ? 0 : '0 auto'};
  /* max-width: 1024px; */
  width: fit-content;
`;

const QuestionImg = styled(SVG)`
  object-fit: cover;
  max-width: 560px;
  height: auto;
  margin-top: 32px;
  margin-bottom: 18px;
`;

const ShowLatexStyled = styled(ShowLatex)`
  & p {
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 12px;
  }

/* TODO: Check when it is needed */
  /* & p:last-child span:first-child {
*   padding-top: 32px;
*   margin: 0 auto;
*   display: block;
*   width: fit-content;
* } */

  & img {
    max-width: 100%;
  }
`;
