import React from 'react';
import ContentForWelcomePage from '../Form/FieldsEntity/ContentForWelcomePage';
import SignUpSteps from './SignUpSteps';

const FinishStepPage = () => {
  return (
    <SignUpSteps>
      <ContentForWelcomePage
        linkToNext={`${process.env.PUBLIC_URL}/dashboard/new-test`}
        description="You are ready to start your exam preparation with Otter."
      />
    </SignUpSteps>
  );
};

export default FinishStepPage;
