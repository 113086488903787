import React from 'react';
import styled from 'styled-components/macro';
import { Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { SaveButton } from '../../ProfilePage/PersonalInformation/ModalChangeAvatar';
import { sendMail } from './UserEdit';

export interface MailValuesType {
  userId: string
  subject: string
  text: string
}

const SendMail = () => {
  const { id } = useParams<{id: string}>();

  return (
    <Formik
      initialValues={{ userId: id, subject: '', text: '' }}
      onSubmit={
        (values) => {
          sendMail(values);
        }
      }
    >
      {({
        handleChange,
        handleSubmit,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Input
            name="subject"
            value={values.subject}
            onChange={handleChange}
            style={{ marginBottom: 24 }}
            size="large"
            placeholder="Enter subject mail"
            required
          />
          <TextArea
            name="text"
            value={values.text}
            onChange={handleChange}
            style={{ minHeight: 400, padding: 12 }}
            placeholder="Enter message"
            required
          />
          <SendButton>Send</SendButton>
        </Form>
      )}
    </Formik>
  );
};

export default SendMail;

const Form = styled.form`
  max-width: 1020px;
  width: 100%;
`;

const SendButton = styled(SaveButton)`
  margin-top: 24px;
  margin-left: 0;
`;
