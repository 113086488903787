import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getIsTeacherRegistered, getTeacherSignUp, setIsTeacherRegistered, signUpTeacher } from '../features/teacher/teacherState';
import { getUserProfile } from '../features/user/userState';
import { teacherSchema } from '../project-types/teacher/schemas';
import { TeacherSetUpCommonType, TYPE_TEACHER } from '../project-types/teacher/types';
import { checkCaptha } from '../User/hooks';
import { ErrorsType } from '../utils/createForm';
import TeacherSetUpBody from './TeacherSetUpBody';


interface TeacherSetUpType extends TeacherSetUpCommonType {
  email: string,
}

export type TEACHER_CONF_TYPE = {
  name: string
  type: TYPE_TEACHER
  initialValue: TeacherSetUpType
}

export const TEACHER_SET_UP_CONF: TEACHER_CONF_TYPE[] = [
  {
    name: 'School teacher',
    type: TYPE_TEACHER.SCHOOL_TEACHER,
    initialValue: {
      school: '',
      email: '',
      teacherType: TYPE_TEACHER.SCHOOL_TEACHER,
    },
  },
  {
    name: 'Agency teacher',
    type: TYPE_TEACHER.AGENCY_TEACHER,
    initialValue: {
      agency: '',
      teacherType: TYPE_TEACHER.AGENCY_TEACHER,
      email: '',
    },
  },
  {
    name: 'Private tutor',
    type: TYPE_TEACHER.PRIVATE_TUTOR,
    initialValue: {
      teacherType: TYPE_TEACHER.PRIVATE_TUTOR,
      email: '',
    },
  },
];

const TeacherSetUp = () => {
  const [selectedConf, setSelectedConf] = useState(TEACHER_SET_UP_CONF[0]);

  const validation = (values: TeacherSetUpType) => {
    const errors: ErrorsType = {};

    if (!values.email) {
      errors.email = ['Email is required'];
    }

    if (selectedConf.type === TYPE_TEACHER.AGENCY_TEACHER) {
      if (!values.agency) {
        errors.agency = ['Agency name is required'];
      }
    }

    if (selectedConf.type === TYPE_TEACHER.SCHOOL_TEACHER) {
      if (!values.school) {
        errors.school = ['Specify the school where you work'];
      }
    }

    return errors;
  };

  const signUpValues = useSelector(getTeacherSignUp);
  const isTeacherRegistered = useSelector(getIsTeacherRegistered);

  const dispatch = useDispatch();

  const { push } = useHistory();

  useEffect(() => {
    if (isTeacherRegistered) {
      push(`${process.env.PUBLIC_URL}/user/verify`);
    }
    return () => {
      dispatch(setIsTeacherRegistered(false));
    };
  }, [isTeacherRegistered]);

  return (
    <Formik
      enableReinitialize
      // validate={validation}
      validationSchema={teacherSchema}
      initialValues={selectedConf.initialValue}
      onSubmit={(values) => {
        const fields = {
          email: values.email,
          password: signUpValues.password,
          fullName: signUpValues.fullName,
          userType: 'teacher',
          teacherProfile: {
            teacherType: values.teacherType,
            school: values.school,
            agency: values.agency,
          },
        };
        checkCaptha().then(({ token }) => dispatch(signUpTeacher({ ...fields, captcha: token })));
      }}
    >
      {(props) => (
        <TeacherSetUpBody
          formikProps={props}
          selectedConf={selectedConf}
          onSelectConf={setSelectedConf}
          setUpConf={TEACHER_SET_UP_CONF}
        />
      )}
    </Formik>
  );
};

export default TeacherSetUp;
