import { useEffect, useState } from 'react';
import { request } from '../api';
import { ChallengeType } from '../features/challenge/types';

export const useGetChallengeHistory = (challengeType: string) => {
  const [challenges, setChallenges] = useState<ChallengeType[] | never[]>([]);

  useEffect(() => {
    request(
      {
        method: 'POST',
        url: 'challenge/history',
        data: {
          type: challengeType,
        },
      }, [200],
    )
      .then(({ data }) => {
        setChallenges(data);
      });
  }, []);

  return challenges;
};
