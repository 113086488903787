import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { RightIcon } from '../images/iconSvg';
import FormElements from '../Form';

const {
  Button,
} = FormElements;

export interface schoolItemsTypes {
  _id: string
  name: string
  rate: number
}

interface ScrollSelectSchoolProps {
  targetSchools: schoolItemsTypes[]
  onChange: (currentschool: schoolItemsTypes) => void
}

const ScrollSelectSchool = ({
  targetSchools,
  onChange,
}: ScrollSelectSchoolProps) => {
  const [arrayKey, setArrayKey] = useState(0);

  const schoolTargetNumber = arrayKey + 1;

  const currentschool = targetSchools[arrayKey];

  useEffect(() => {
    onChange(currentschool);
  }, [arrayKey]);

  return (
    <Wrapper>
      <ButtonLeft disabled={arrayKey === 0} onClick={() => setArrayKey(arrayKey - 1)}><RightIconStyled /></ButtonLeft>
      <Item>
        <Title>Target school {schoolTargetNumber}</Title>
        <SchoolName>{currentschool.name}</SchoolName>
      </Item>
      <ButtonRight disabled={arrayKey === targetSchools.length - 1} onClick={() => setArrayKey(arrayKey + 1)}><RightIconStyled /></ButtonRight>
    </Wrapper>
  );
};

export default ScrollSelectSchool;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonRight = styled(Button)`
  width: 32px;
  height: 32px;
  background-color: #EEEAFF;
  border-radius: 7px;
`;

const ButtonLeft = styled(ButtonRight)`
  transform: rotate(180deg);
`;

const RightIconStyled = styled(RightIcon)`
  ${ButtonRight}:hover:not(:disabled) & svg {
    fill: #FFF;
  }
  ${ButtonRight}:disabled & svg {
    fill: #FFF;
  }
`;

const Item = styled.div`
  min-width: 215px;
  box-sizing: border-box;
  padding: 0 25px;
  text-align: center;
`;

const Title = styled.span`
  display: block;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
`;

const SchoolName = styled(Title)`
  font-weight: normal;
  width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
