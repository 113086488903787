import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { SIDEBAR_MENU } from '../../Form/FieldsEntity/Sidebar';
import Layout from '../../Layout';
import ChildStaisticsBoard from '../ChildStatisticsBoard';
import DashboardPage from '../DashboardPage';

interface NewTestProps {
  children?: ReactNode
}

const NewTest = ({ children }: NewTestProps) => {

  return (
    <Layout>
      <ChildStaisticsBoard />
      <DashboardPage selectedMenuItem={SIDEBAR_MENU.NEW_TEST}>
        <Content>
          <TopicsWrapper>
            {children}
          </TopicsWrapper>
        </Content>
      </DashboardPage>
    </Layout>
  );
};

export default NewTest;

const Content = styled.div`
  // margin-left: 97px;
  flex-shrink: 0;
  width: min(100%, 830px);
`;

const TopicsWrapper = styled.div`
  /* max-width: 750px; */
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 200px;
`;
