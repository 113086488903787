import React from 'react';
import MainPage from './MainPage';

const SmartMainPage = () => {

  return (
    <MainPage />
  );
};

export default SmartMainPage;
