import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import Routes from './routes';

import Menu from './layout/Menu';
import Breadcrumb from './layout/Breadcrumb';

const { Header, Content, Footer } = Layout;


function AdminLayout() {
  return (
    <Router>
      <Layout className="layout">
        <Menu />
        <Content style={{ padding: '0 50px 50px', minHeight: 'calc(100vh - 64px)', boxSizing: 'border-box' }}>
          <Breadcrumb />
          <div className="site-layout-content">
            <Routes />
          </div>
        </Content>
      </Layout>
    </Router>
  );
}

export default styled(AdminLayout)`
  color: red;
`;
