import {
  ChartType,
  ChartConfigType,
  ScalesSetType,
  StatType,
  DateType,
  RangeType,
  ScalesType,
  ScalesCallbacksType,
  TargetSchoolType
} from "../types";

import { date, values } from "../scales";

export function drawTargetLine(
  config: ChartConfigType,
  chart: ChartType,
  dates: DateType[],
  results: RangeType[],
  target: TargetSchoolType
) {
  const dateScale = date(config, dates);
  const valueScale = values(config, results);

  const targetLine = chart
    .selectAll<SVGGElement, unknown>(`.lineTarget`)
    .data([target.rate], (d: any) => d.id);

  const create = targetLine.enter().append("g").attr("class", "lineTarget");

  create
    .attr("transform", (d) => `translate(0, ${valueScale(d)})`)
    .append("line")
    .attr("class", "lineTarget-line")
    .attr("x1", -5)
    .attr("y1", 0)
    .attr("x2", config.size.width + 5)
    .attr("y2", 0);
  
  create
    .append("text")
    .attr('class', 'lineTarget-text')

  
  create
    .merge(targetLine)
    .transition()
    .duration(300)
    .attr("transform", (d) => `translate(0, ${valueScale(d)})`)
    .select('.lineTarget-text')
    .text(d => `${target.name} ${d}%`)
    .attr('y', '-6')
}
//
