export const createStartLink = (type: string, subject?: string, topic?: string, topicAreas?: string, selectedTopic?: string) => {
  console.log(type, subject, topic, topicAreas, selectedTopic);
  let path = [subject];
  if (topic) {
    path = [subject, topic];
    if (selectedTopic) {
      path = [subject, topic, selectedTopic];
    }
    if (selectedTopic && topicAreas) {
      path = [subject, topic, topicAreas, selectedTopic];
    }
  }


  const urlPath = path.join('/');

  return `${process.env.PUBLIC_URL}/challenge/start/${type}/${urlPath}`;
}