import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { subjects } from '../../../features/topic/topicState';
import { Button, Input, Radio } from '../../../Form/Form';
import { AssigmentTypes } from '../../../project-types/assignments/types';
import { RadioList, RadioStyled } from './Elements';

interface AssignmentSettingsProps {
  subjectNames: string[],
  onNext: (subject: string, assignmentType: AssigmentTypes) => void
}

export default function AssignmentSettings({ subjectNames, onNext }: AssignmentSettingsProps) {
  const [subject, setSubject] = useState<string | null>();
  const [type, setType] = useState<AssigmentTypes | null>(null);

  const AssignmentTypes = {
    [AssigmentTypes.REVISION]: 'Revision',
    [AssigmentTypes.PRACTICE]: 'Practice',
  };

  const onClickNext = () => {
    if (subject && type) {
      onNext(subject, type);
    }
  };

  return (
    <>
      <SettingsGroup>
        <Header>
          Choose a subject
        </Header>
        <RadioList>
          {subjectNames.map((subjectItem) => (
            <RadioStyled
              checked={subject === subjectItem}
              onClick={() => setSubject(subjectItem)}
            >
              {subjectItem}
            </RadioStyled>
          ))}
        </RadioList>
      </SettingsGroup>
      <SettingsGroup>
        <Header>
          Choose a subject
        </Header>
        <RadioList>
          {Object.entries(AssignmentTypes).map(([key, name]) => (
            <RadioStyled
              checked={type === key}
              onClick={() => setType((key as AssigmentTypes))}
            >
              {name}
            </RadioStyled>
          ))}
        </RadioList>
        <BottomLine />
        <NextButton disabled={!subject || !type} onClick={onClickNext}>Select assignments</NextButton>
      </SettingsGroup>
    </>
  );
}

const Header = styled.h3`
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: #1F1F1F;
  margin-bottom: 26px;
`;

const SettingsGroup = styled.section`
  margin-bottom: 54px;
`;

const BottomLine = styled.div`
  margin: 54px 0 30px 0;
  border-top: 2px solid #E0E0E0;
`;

const NextButton = styled(Button)`
  width: 356px;
  height: 66px;
  background: #AA95FF;
  border-radius: 10px;
`;