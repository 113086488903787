import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

interface ProfileEditContainerProps {
  children: ReactNode
  current: string
}

const ProfileEditContainer = ({
  children,
  current,
}: ProfileEditContainerProps) => {
  return (
    <Content>
      <Header>
        <LinkTitle current={current === 'personal'} to={`${process.env.PUBLIC_URL}/profile/edit/personal`}>
          Personal Information
        </LinkTitle>
        <LinkTitle current={current === 'academic'} to={`${process.env.PUBLIC_URL}/profile/edit/academic`}>
          Academic Information
        </LinkTitle>
      </Header>
      <Body>
        {children}
      </Body>
    </Content>
  );
};

const Content = styled.div`
  width: 100%;
  background-color: #FFF;
  box-shadow: 0px 2px 8px #F0F2F3;
  border-radius: 20px;
  box-sizing: border-box;
  margin-bottom: 35px;
  box-sizing: border-box;
  margin-left: 97px;
  margin-right: 150px;
  flex-grow: 1;
  // overflow: hidden;
  @media (max-width: 1400px) {
    margin-right: 50px;
  }
`;

const Header = styled.div`
  display: flex;
  height: 100px;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
`;

const LinkTitle = styled(Link)<{current?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  color: inherit;
  font-size: 25px;
  line-height: 34px;
  font-weight: bold;
  flex-grow: 1;
  background-color: ${({ current }) => !current && '#EEEAFF'};
  :hover {
    color: inherit;
  }
`;

const Body = styled.div`
  padding: 34px 56px;
`;

export default ProfileEditContainer;
