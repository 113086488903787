import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Formik } from 'formik';
import * as Antd from 'antd';
import FormElements from '../../Form';
import { createAdmin, password } from './hooks';
import { InputError } from '../../Form/FieldsEntity/FormInput';
import { ErrorsType } from '../../utils/createForm';

const {
  Button,
} = FormElements;

const initialValidator = (values: any) => {
  const errors: ErrorsType = {};
  if (!values.email) {
    errors.email = ['Email is required'];
  }

  if (!values.password) {
    errors.confirm = ['Password is required'];
  }

  return errors;
};

const generatePassword = (setFieldValue: Function) => {
  const generate = password();
  setFieldValue('password', generate);
};

const CreateAdmin = () => {
  const [secretQR, setSecretQR] = useState('');

  return (
    <Wrapper>

      <Formik
        initialValues={{ email: '', password: '', role: 'ADMIN' }}
        validate={initialValidator}
        onSubmit={(values) => {
          createAdmin(values)(setSecretQR);
        }}
      >
        {({
          handleChange,
          handleSubmit,
          setFieldValue,
          values,
          errors,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Label
              label="Email"
              labelAlign="left"
              // validateStatus={values.email === '' ? 'error' : ''}
              required
            >
              <Input type="email" name="email" placeholder="Enter email" onChange={handleChange} required size="large" value={values.email} />
              <InputError errors={errors.email} />
            </Label>

            <Label
              label="Password"
              labelAlign="left"
              required
            >
              <Input name="password" placeholder="Enter password" onChange={handleChange} required size="large" value={values.password} />
              <InputError errors={errors.password} />
              <GeneratePasswordButton htmlType="button" onClick={() => generatePassword(setFieldValue)}>Generate password</GeneratePasswordButton>
            </Label>

            <Label
              label="Role"
              labelAlign="left"
            >
              <Select style={{width: 240}} defaultValue="admin" onChange={(value) => setFieldValue('role', value)} size="large">
                <Option value="ADMIN">Admin</Option>
                <Option value="SUPER_ADMIN">Super admin</Option>
              </Select>
            </Label>

            <ButtonWrapper>
              {/* <CancelButton type="reset">Cancel</CancelButton> */}
              <SaveButton type="submit" disabled={values.password === '' || values.email === ''}>Create</SaveButton>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
      {secretQR !== '' && (
        <Label label="QR-code">
          <Image src={secretQR} />
        </Label>
      )}
    </Wrapper>
  );
};

export default CreateAdmin;

const Wrapper = styled.div`
  display: flex;
  margin-top: 24px;
`;

const Image = styled.img`
  flex-shrink: 0;
  align-self: baseline;
  margin-left: 48px;
`;

const Form = styled.form`
  width: 100%;
  max-width: 540px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-right: auto;
  margin-top: 48px;
  margin-bottom: 24px;
`;

const SaveButton = styled(Button)`
  min-width: 128px;
  width: auto;
  height: auto;
  padding: 8px 18px;
  border: 3px solid transparent;
  color: #FFF;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  background-color: #9077F5;
  // margin-left: 20px;
`;

const CancelButton = styled(SaveButton)`
  margin-left: 0;
  background-color: #FFF;
  border-color: #9C9C9C;
  color: #9C9C9C;
  :hover:not(:disabled) {
    background-color: #FFF;
    border-color: #F87700;
    color: #F87700;
  }
`;

const Label = styled(Antd.Form.Item)`
  margin-bottom: 24px;
  flex-direction: column;
`;

const Input = styled(Antd.Input)`
  
`;

const GeneratePasswordButton = styled(Antd.Button)`
  margin-top: 12px;
`;

const Select = styled(Antd.Select)``;

const Option = styled(Select.Option)``;
