import React from 'react';
import styled from 'styled-components/macro';

interface TeamType {
  name: string
  description: string
  photo: string
  position?: string
}

const teamData: TeamType[] = [
  {
    name: 'Sebastian',
    description: 'Sebastian was a fully funded scholar at Harrow and a British Chess Captain. He runs a leading educational consultancy in Russia as well as a software development consultancy that has aided several well-known startups. Sebastian co-founded Otter with his old school friend Henry.',
    photo: require('../images/teamPhoto/happyme.jpg'),
    position: 'Co-founder, Director',
  },
  {
    name: 'Henry',
    description: 'Henry worked for several years in London as a senior business strategy consultant leading key projects for a variety of Fortune 500 companies and private equity firms. With a masters degree in engineering and having made a name as one of London’s top private tutors, Henry co-founded Otter with school friend Sebastian.',
    photo: require('../images/teamPhoto/henry@2x.jpg'),
    position: 'Co-founder, Director',
  },
  {
    name: 'Michael',
    description: 'Michael is from New Jersey and specialises in economics, political science, and French. Previously, Michael has worked with the behavioural science team at Clover Health, where he used his economics and health policy acumen to help develop methods of increasing client engagement. He is now using his skills and experience to support mathematics at Otter.',
    photo: require('../images/teamPhoto/Michael.jpg'),
    position: 'Question Writer',
  },
  {
    name: 'Yuri',
    description: 'Yuri has worked for large Russian tech companies such as Yandex and Rostelekom. As a senior developer with a wide skill set, Yuri successfully manages teams, recruits IT personnel, and handles both frontend and backend development. A veteran of DevOps, he can deploy using all the latest cloud technologies.',
    photo: require('../images/teamPhoto/yuri_large.jpg'),
    position: 'Front-end Lead',
  },
  {
    name: 'Lyubov',
    description: 'With a BA in Roman-German languages, MA in Applied Social Psychology, Lyubov worked in civil aviation in the fast-paced environment of Moscow’s largest airport. Now she is applying her managerial experience and organisation skills at Otter.',
    photo: require('../images/teamPhoto/lyubov.jpg'),
    position: 'Project Manager',
  },
  {
    name: 'Serhii',
    description: 'Serhii has more than 10 years of experience in programming and team management. He has two degrees: in Applied Mathematics and in... Stage and Screen Acting! He enjoys solving complex programming problems with simple tools.',
    photo: require('../images/teamPhoto/Serhii.jpg'),
    position: 'Back-end Team Lead',
  },
  // {
  //   name: 'Otter',
  //   description: 'Your best friend!',
  //   photo: defaultPhoto,
  //   position: 'Otter',
  // },
  {
    name: 'Joy',
    description: 'With a PhD in Mathematics Education from the world-renowned UCL Institute of Education and a teacher with a wealth of experience in both the state and private sectors for over 15 years, Joy oversees educational content for Otter.',
    photo: require('../images/teamPhoto/joy.jpg'),
    position: 'Educational Director',
  },
  {
    name: 'Andrey',
    description: 'Andrey has more than 5 years of backend development in Python, Node.js, and PHP, deep knowledge of MongoDB and PostgreSQL databases. He has a deep background in the development of financial trading bots and applies the same mission-critical attitude to performance and stability to Otter’s back-end.',
    photo: require('../images/teamPhoto/andrey_large.jpg'),
    position: 'Back-end Developer',
  },
  {
    name: 'Ekaterina',
    description: 'Ekaterina is an experienced interface designer with a background in graphic design. A diverse skill set allows her to create beautiful designs that function intuitively for users. She is the force behind our wonderful Otter graphics!',
    photo: require('../images/teamPhoto/Ekaterina.jpg'),
    position: 'Lead Designer for Otter',
  },
  {
    name: 'Maxim',
    description: 'Maxim is a specialist in industrial assembly turned programmer with a specialism in mathematics. He enjoys the challenges of front-end programming and brings to Otter his industrial coding experience. In his free time, he plays the violin and grows mint on his windowsill.',
    photo: require('../images/teamPhoto/maksim_large.jpg'),
    position: 'Front-end Developer',
  },
  {
    name: 'Varvara',
    description: 'Varvara is a Full-stack UX/UI designer with a degree in Arts who has worked for Artemy Lebedev studio (Russia’s most famous) and other big companies. Her 7-year long work experience includes participation in art exhibitions, the full development of unique branding, website, mobile applications, and typographic design.',
    photo: require('../images/teamPhoto/varvara_large.jpg'),
    position: 'Full-stack Designer',
  },
  {
    name: 'Paul',
    description: 'Born in 1964 in Moscow, Russia, in 1981 Paul immigrated to Canada. From 1983 to 1987 he attended the University of Western Ontario (biology, philosophy). In 1994 Paul returned to Russia, where he established himself as a translator/interpreter, English teacher, and traveller assistant. Since 2013 Paul is based in Kandalaksha, Murmansk Region, mostly teaching English to local children.',
    photo: require('../images/teamPhoto/paul.jpg'),
    position: 'Question Writer',
  },
  {
    name: 'Chuong',
    description: 'Chuong is an expert in Clojure and ClojureScript and the network technology stack at Otter.  He heads up our backend, admin panel, and authentication system.',
    photo: require('../images/teamPhoto/Chuong.jpg'),
    position: 'Senior Back-end Developer',
  },
  {
    name: 'Dominic',
    description: 'Dominic was an international chess player representing England and is now in charge of social media at Otter.',
    photo: require('../images/teamPhoto/Dom.jpg'),
    position: 'Social Media Director',
  },
  {
    name: 'Oscar',
    description: 'Oscar is an accredited TEFL instructor who has taught English and Chinese (Mandarin) in schools in China. Oscar also has developed leading multi-sensory learning programmes for Chinese schools. Now he is applying his teaching experience and linguistic training in formulating English Language and Linguistics questions for Otter.',
    photo: require('../images/teamPhoto/Oscar.jpg'),
    position: 'Question Writer',
  },
  {
    name: 'Mikhail',
    description: 'Mikhail devoted 10 years of his life to code and the development of complex programming decisions. With his architectural thinking and knowledge of numerous programming languages, he defines functional programming as his preferred activity. In Otter, Mikhail is engaged in improving the Questions and Images Generation Service.',
    photo: require('../images/teamPhoto/Mikhail.jpg'),
    position: 'Full-stack Developer',
  },
  // {
  //   name: 'Otter',
  //   description: '',
  //   photo: defaultPhoto,
  //   position: '',
  // },
  // {
  //   name: 'Otter',
  //   description: '',
  //   photo: defaultPhoto,
  //   position: '',
  // },
  // {
  //   name: 'Otter',
  //   description: '',
  //   photo: defaultPhoto,
  //   position: '',
  // },
  // {
  //   name: 'Otter',
  //   description: '',
  //   photo: defaultPhoto,
  //   position: '',
  // },
  // {
  //   name: 'Otter',
  //   description: '',
  //   photo: defaultPhoto,
  //   position: '',
  // },
  // {
  //   name: 'Otter',
  //   description: '',
  //   photo: defaultPhoto,
  //   position: '',
  // },
  // {
  //   name: 'Otter',
  //   description: '',
  //   photo: defaultPhoto,
  //   position: '',
  // },
  // {
  //   name: 'Otter',
  //   description: '',
  //   photo: defaultPhoto,
  //   position: '',
  // },
  // {
  //   name: 'Otter',
  //   description: '',
  //   photo: defaultPhoto,
  //   position: '',
  // },
  // {
  //   name: 'Otter',
  //   description: '',
  //   photo: defaultPhoto,
  //   position: '',
  // },
];

interface OtterTeamProps {
  className?: string
}

const OtterTeam = ({ className }: OtterTeamProps) => {
  return (
    <Wrapper className={className}>
      {teamData.map((item: TeamType) => (
        <GroupMember>
          <AbsoluteWrapper>
            <Content>
              <Photo src={item.photo} alt="" width="" />
            </Content>
            <ContentDescription>
              <Name>{item.name}</Name>
              <Description>{item.description}</Description>
              <Position>{item.position}</Position>
            </ContentDescription>
          </AbsoluteWrapper>
        </GroupMember>
      ))}
    </Wrapper>
  );
};

export default OtterTeam;

const Wrapper = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
`;

const GroupMember = styled.li`
  width: 20%;
  position: relative;
  display: block;
  ::after {
    content: '';
    display: block;
    padding-top: 113%;
  }

  @media (max-width: 1439px) {
    width: 25%;
  }

  @media (max-width: 1140px) {
    width: calc(100% / 3);
  }

  @media (max-width: 767px) {
    width: calc(100% / 2);
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Content = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
`;

const ContentDescription = styled(Content)`
  background-color: #FFF;
  border: 4px solid #F87700;
  padding: 22px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateX(-100%);
  transition: .3s ease;
  transition-property: opacity;

  @media (max-width: 880px) {
    padding: 10px 8px;
  }
`;

const Photo = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top;
`;

const AbsoluteWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  :hover ${ContentDescription} {
    opacity: 1;
  }
`;

const Name = styled.span`
  display: block;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 18px;

  @media (max-width: 880px) {
    margin-bottom: 6px;
    font-size: 18px;
    line-height: 20px;
  }
`;

const Description = styled.p`
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 8px;
`;

const Position = styled.span`
  display: block;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  margin-top: auto;

  @media (max-width: 880px) {
    font-size: 12px;
    line-height: 14px;
  }
`;
