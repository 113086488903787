import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {
  ClockIconOutlined,
  ExtraTimeIcon,
} from '../../images/iconSvg';
import FormElements from '../../Form';

const {
  Button,
} = FormElements;

export interface ChalengeItemType {
  id: string
  key: number
  date: string
  elapsedTime: number
  extraTime: boolean
  result: number
}

interface PastExamTestsProps {
  examStatistic?: ChalengeItemType[]
  onShowDetailResult: (chalengeId: string) => void
}

const PastExamTests = ({
  examStatistic = [],
  onShowDetailResult,
}: PastExamTestsProps) => {
  const [numberOfResultsShown, setNumberOfResultsShown] = useState<number>(3);

  const itemsResults = examStatistic.slice(0, numberOfResultsShown);

  return (
    <Container>
      <Board>
        <Title>Click to see your SAS score</Title>
        <HeaderBoard>
          <HeaderBoardTitle>Date</HeaderBoardTitle>
          <HeaderBoardTitle>Test completion time</HeaderBoardTitle>
          <HeaderBoardTitle>Test results</HeaderBoardTitle>
        </HeaderBoard>
        {itemsResults.map((item: ChalengeItemType) => (
          <ItemsBoard key={item.key} onClick={() => onShowDetailResult(item.id)}>
            <Item>{new Date(item.date).toLocaleDateString()}</Item>
            <Item>
              <TimeSpan>
                <TimeIconWrapper>
                  {item.extraTime ? (
                    <ExtraTimeIcon />
                  ) : (
                    <ClockIconOutlinedStyled />
                  )}
                </TimeIconWrapper>
                {item.elapsedTime}
                {' '}
                minutes
              </TimeSpan>
            </Item>
            <Item>
              <ResultSpan>
                {item.result} %
              </ResultSpan>
            </Item>
          </ItemsBoard>
        ))}
      </Board>
      <ButtonWrapper>
        <ShowMoreButton
          disabled={examStatistic.length === numberOfResultsShown || examStatistic.length < 3}
          onClick={() => setNumberOfResultsShown(numberOfResultsShown + 3)}
        >
          Show more
        </ShowMoreButton>
        <HideButton
          disabled={numberOfResultsShown === 3}
          onClick={() => setNumberOfResultsShown(3)}
        >
          Hide
        </HideButton>
      </ButtonWrapper>
    </Container>
  );
};

export default PastExamTests;

const Container = styled.div`
  box-sizing: border-box;
  padding: 45px 32px 50px;
  border-radius: 20px;
  background-color: #EEEAFF;
`;

const Title = styled.h3`
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  margin-bottom: 32px;
  text-align: center;
`;

const Board = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

const HeaderBoard = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

const HeaderBoardTitle = styled.span`
  display: block;
  width: 33%;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
`;

const ItemsBoard = styled.ul`
  height: 80px;
  background-color: #FFF;
  cursor: pointer;
  border-radius: 10px;
  list-style: none;
  padding: 0;
  display: flex;
  box-sizing: border-box;
  padding: 15px 0;
  border: 3px solid transparent;
  :hover {
    border-color: #9077F5;
  }
`;

const Item = styled.li`
  width: 33%;
  height: 100%;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  :not(:last-child) {
    border-right: 1px solid #E0E0E0;
  }
`;

const TimeIconWrapper = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  margin-right: 4px;
`;

const ClockIconOutlinedStyled = styled(ClockIconOutlined)`
  display: inline-block;
  font-size: 30px;
`;

const TimeSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ResultSpan = styled.span`
  font-weight: bold;
  font-size: 22px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  width: fit-content;
`;

const ShowMoreButton = styled(Button)`
  width: auto;
  height: auto;
  padding: 15px 40px;
  font-size: 22px;
  line-height: 26px;
  background-color: #9077F5;
`;

const HideButton = styled(ShowMoreButton)`
  margin-left: 15px;
  padding: 12px 50px;
  background-color: #FFF;
  :not(:disabled) {
    border: 3px solid #9077F5;
    color: #9077F5;
  }
  :hover:not(:disabled) {
    color: #FFF;
    border-color: transparent;
  }
`;
