import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

interface UserMenuType {
  createNewUser: boolean;
}

const UserMenu = ({ createNewUser }: UserMenuType) => {
  if (createNewUser) {
    return (
      <TitleWrap>
        <Title to="#">Sign Up</Title>
        <TitleDark to={`${process.env.PUBLIC_URL}/login`}>Log In</TitleDark>
      </TitleWrap>
    );
  }
  return (
    <TitleWrap>
      <TitleDark to={`${process.env.PUBLIC_URL}/registration/student`}>
        Sign Up
      </TitleDark>
      <Title to="#">Log In</Title>
    </TitleWrap>
  );
};

export default UserMenu;

const TitleWrap = styled.div`
  height: 100px;
  display: flex;
`;

const Title = styled(Link)`
  text-decoration: none;
  color: #1f1f1f;
  flex-grow: 1;
  font-weight: bold;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    color: #1f1f1f;
  }
`;

const TitleDark = styled(Title)`
  background-color: #eeeaff;
`;
