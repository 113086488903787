import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import LabelForInput from '../../Form/FieldsEntity/LabelForInput';
import FormElements from '../../Form';
import { InputError } from '../../Form/FieldsEntity/FormInput';
import { isArray } from 'lodash';

const {
  Input,
} = FormElements;

interface FirstAndLastNameInputProps {
  onChangeFullName: (firstname: string) => void
  nameValue?: string
  className?: string
  required?: boolean
  errors?: any
}

const FirstAndLastNameInput = ({
  onChangeFullName,
  nameValue = '',
  className,
  required,
  errors,
}: FirstAndLastNameInputProps) => {

  return (
    <InputWrapper className={className}>
      <FirstNameLabel name="Full Name">
        <StyledInput placeholder="Name" value={nameValue} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeFullName(e.currentTarget.value)} error={errors?.length} required={required} />
        <InputError errors={errors} />
      </FirstNameLabel>
    </InputWrapper>
  );
};

export default FirstAndLastNameInput;

const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const StyledInput = styled(Input)`
  flex-grow: 1;
  height: 56px;
  font-weight: normal;
  font-size: 16px;
  :focus {
    border: 2px solid ${({ error }) => error ? '#F55F4B' : '#9077F5'};
    padding: 16px 14px;
  }
`;

const FirstNameLabel = styled(LabelForInput)`
  margin-right: 20px;
  max-width: 294px;
`;

const SurnameLabel = styled(LabelForInput)`
  max-width: 294px;
`;
