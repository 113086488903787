import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Modal from '../Form/FieldsEntity/Modal';
import UseLocalization from '../Localization/UseLocalization';
import ModalContactUs from './ModalContactUs';

const facebookIcon = require('../images/icons/facebook.svg');
const twitterIcon = require('../images/icons/twitter.svg');
const whatsappIcon = require('../images/icons/whatsapp.svg');
const instagramIcon = require('../images/icons/instagram.svg');

const Contacts = () => {
  const [showModalContact, setShowModalContact] = useState(false);

  return (
    <Background>
      {showModalContact && (
        <Modal onClose={() => setShowModalContact(false)}>
          <ModalContactUs
            onClose={() => setShowModalContact(false)}
          />
        </Modal>
      )}
      <Container>
        <Description>
          <UseLocalization translateKey="contact.firstDescription">
            Otter is free for schools.
          </UseLocalization>
          <br />
          <Span onClick={() => setShowModalContact(true)}>
            <UseLocalization translateKey="contact.link">
              Contact us
            </UseLocalization>
          </Span>
          {' '}
          <UseLocalization translateKey="contact.second">
            to find out more.
          </UseLocalization>
        </Description>
        <LinksWrapper>
          <Link href="https://www.facebook.com/Otter-Education-141467427775723" style={{ backgroundImage: `url(${facebookIcon})` }} target="_blank" rel="noreferrer" />
          <Link href="https://twitter.com/OtterEducation" style={{ backgroundImage: `url(${twitterIcon})` }} target="_blank" rel="noreferrer" />
          <Link href="https://www.instagram.com/ottereducation/" style={{ backgroundImage: `url(${instagramIcon})` }} target="_blank" rel="noreferrer" />
          <Link href="tel:+442035190942" style={{ backgroundImage: `url(${whatsappIcon})` }} />
        </LinksWrapper>
      </Container>
    </Background>
  );
};

export default Contacts;

const Background = styled.div`
  background-color: #FFF;
`;

const Container = styled.div`
  box-sizing: border-box;
  padding: 82px 50px;

  @media (max-width: 1279px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (max-width: 700px) {
    padding: 38px 33px;
  }
`;

const Description = styled.span`
  display: block;
  font-weight: bold;
  font-size: 44px;
  line-height: 46px;
  text-align: center;
  margin-bottom: 46px;

  @media (max-width: 700px) {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 30px;
  }
`;

const LinksWrapper = styled.div`
  width: fit-content;
  width: -moz-fit-content;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
`;

const Span = styled.span`
  color: #F87700;
  cursor: pointer;
  :hover {
    color: #FF912C;
  }
`;

const Link = styled.a`
  display: block;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  :not(:last-child) {
    margin-right: 37px;
  }

  @media (max-width: 700px) {
    font-size: 15px;
    line-height: 20px;
    width: 44px;
    height: 44px;
    background-size: contain;
  }
`;
