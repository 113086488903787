import React from 'react';
import styled from 'styled-components/macro';
import TextArea from 'antd/lib/input/TextArea';
import { UserProfileBaseType } from '../../features/user/types';
import { PurpleButton } from '../Features/Buttons';

interface TeacherCommentProps {
  onUpdateUser: (userData: UserProfileBaseType) => void
  value?: string
}

const TeacherComment = ({
  onUpdateUser,
  value,
}: TeacherCommentProps) => {
  const [comment, setComment] = React.useState<string | undefined>(value);

  const onUpdate = () => {
    onUpdateUser({ teacherComment: comment });
  };

  return (
    <Wrapper>
      <TextArea autoSize value={comment} onChange={(e) => setComment(e.target.value)} style={{ minHeight: 200 }} />
      <CreateNote onClick={onUpdate}>Save note</CreateNote>
    </Wrapper>
  );
};

export default TeacherComment;

const Wrapper = styled.div`
  max-width: 860px;
`;

const CreateNote = styled(PurpleButton)`
  margin-left: 0;
  margin-top: 24px;
`;
