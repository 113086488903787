import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import {
  Link,
  useParams,
} from 'react-router-dom';
import {
  RightIcon,
} from '../images/iconSvg';

const logoImg = require('../images/logo.svg');

interface SignUpStepsProps {
  children: ReactNode
}

const SignUpSteps = ({ children }: SignUpStepsProps) => {
  const { step } = useParams<{step: string}>();

  const linkToStepTwo = Number(step) >= 2 ? `${process.env.PUBLIC_URL}/sign-up-steps/${step}/subscribe` : '#';
  const linkToFinishStep = Number(step) >= 3 ? `${process.env.PUBLIC_URL}/sign-up-steps/${step}/finish-step` : '#';

  return (
    <Page>
      <LogoImg src={logoImg} />
      <Content>
        <StepsWrapper>
          <Step to={`${process.env.PUBLIC_URL}/sign-up-steps/${step}/profile-information`} currentStep={step}>
            1. Set up your account
            <RightIconStyled />
          </Step>
          <Step to={linkToStepTwo} currentStep={step}>
            2. Subscribe
            <RightIconStyled />
          </Step>
          <Step to={linkToFinishStep} currentStep={step}>
            3. Welcome to Otter
          </Step>
        </StepsWrapper>
          {children}
      </Content>
    </Page>
  );
};

export default SignUpSteps;

const Page = styled.div`
  min-height: 100vh;
  padding: 22px 80px 200px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
`;

const LogoImg = styled.img`
  margin-bottom: 118px;
`;

const Content = styled.div`
  max-width: 1005px;
  width: 100%;
  margin: 0 auto;
`;

const StepsWrapper = styled.div`
  display: flex;
  padding-left: 47px;
  margin-bottom: 30px;
`;

const RightIconStyled = styled(RightIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 18px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #C4C4C4;
  font-size: 10px;
  & svg {
    fill: #FFF;
  }
`;

const Step = styled(Link)<{currentStep: string}>`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #C4C4C4;
  cursor: default;
  :not(:last-child) {
    margin-right: 18px;
  }
  :hover {
    color: #C4C4C4;
  }

  :nth-child(-n+${({ currentStep }) => currentStep}) {
    cursor: pointer;
    color: #9077F5;
    & ${RightIconStyled} {
      background-color: #9077F5;
    }
  }
`;
