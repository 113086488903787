import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { TopicRouteType } from '../../features/topic/types';

interface MenuType {
  items: ArrayItemType[]
  className?: string
  defaultValue?: number
  onSelect: (name: string) => void
  disabled?: boolean
}

interface ArrayItemType {
  id: number
  name: string
}

// TODO rename defaultValue to value
const MenuWithItemSelection = ({ items, className, onSelect, defaultValue = 1, disabled }: MenuType) => {
  // const getNumberOnSubject = items.find((item, n) => )
  const [itemSelected, setItemSelected] = useState(defaultValue);

  useEffect(() => {
    setItemSelected(defaultValue);
  }, [defaultValue]);

  const selectItem = (num: number, name: string) => {
    // setItemSelected(num);
    onSelect(name);
  };

  useEffect(() => {
    if (disabled && items.length > 0) {
      console.log(defaultValue)
      const selectedSubject = items[defaultValue - 1];
      selectItem(selectedSubject.id, selectedSubject.name);
    }
  }, [defaultValue]);

  return (
    <MenuContainer className={className} disabled={disabled}>
      <Items>
        {items.map((item) => (
          <ItemWrapper key={item.id} onClick={() => disabled || selectItem(item.id, item.name)}>
            <ItemName selected={itemSelected === item.id}>
              {item.name}
            </ItemName>
          </ItemWrapper>
        ))}
      </Items>
      <IndicatorBox>
        <Slider toMove={itemSelected - 1} widthSlider={items.length} />
      </IndicatorBox>
    </MenuContainer>
  );
};

export default MenuWithItemSelection;

const MenuContainer = styled.div<{disabled?: boolean}>`
  width: 100%;
  ${({ disabled }) => disabled && 'opacity: 0.3'};
`;

const Items = styled.div`
  display: flex;
`;

const ItemWrapper = styled.div`
  width: 25%;
  text-align: center;
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
  box-sizing: border-box;
  padding: 10px 20px;
  cursor: pointer;
`;

const ItemName = styled.span<{selected: boolean}>`
  display: inline-block;
  font-size: 22px;
  line-height: 24px;
  font-weight: ${(props) => (props.selected ? 'bold' : '500')};
`;

const IndicatorBox = styled.div`
  width: 100%;
  min-height: 10px;
  max-height: 10px;
  flex-shrink: 0;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  background: linear-gradient(to bottom, transparent 40%, #E0E0E0 40%, #E0E0E0 60%, transparent 60%);
`;

const Slider = styled.div<{
  toMove: number
  widthSlider?: number
}>`
  width: calc(100% / ${props => props.widthSlider});
  background-color: #F87700;
  transform: translateX(calc(100% * ${(props) => props.toMove}));
  transition: .5s ease-in-out;
`;
