import React, { ReactNode, useState } from 'react';
import styled from 'styled-components/macro';
import {
  QuestionIcon,
} from '../../images/iconSvg';

interface LabelForInputProps {
  children: ReactNode
  name: string
  className?: string
  labelInfo?: string
  leftTitle?: string
}

const LabelForInput = ({
  children,
  name,
  className,
  labelInfo,
  leftTitle,
}: LabelForInputProps) => {
  const [showLabelInfo, setShowLabelInfo] = useState(false);

  return (
    <Label className={className}>
      <NameWrapper>
        <Name>{name}</Name>
        {labelInfo && (
          <LabelInfoWrapper
            onMouseEnter={() => setShowLabelInfo(true)}
            onMouseLeave={() => setShowLabelInfo(false)}
          >
            <InfoIcon />
            <Description showDescription={showLabelInfo}>{labelInfo}</Description>
          </LabelInfoWrapper>
        )}
        {leftTitle && (
          <LeftTitle>{leftTitle}</LeftTitle>
        )}
      </NameWrapper>
      {children}
    </Label>
  );
};

export default LabelForInput;

const Label = styled.label`
  width: 100%;
  display: block;
`;

const Name = styled.span`
  display: block;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;

const LeftTitle = styled(Name)`
  padding-left: 32px;
  margin-left: auto;
`;

const NameWrapper = styled.div`
  padding: 0 12px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

const InfoIcon = styled(QuestionIcon)`
  font-size: 17px;
  margin-left: 8px;
  & svg {
    fill: #9077F5;
  }
`;

const Description = styled.span<{showDescription?: boolean}>`
display: ${({ showDescription }) => (showDescription ? 'block' : 'none')};
position: absolute;
bottom: 26px;
font-weight: normal;
font-size: 14px;
line-height: 18px;
background-color: #EEEAFF;
border: 1px solid #9077F5;
border-radius: 6px;
  box-sizing: border-box;
  padding: 6px 8px;
  max-width: 418px;
  width: max-content;
  width: -moz-max-content;
  ::before {
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    border: 1px solid;
    border-color: transparent transparent #9077F5 #9077F5;
    background-color: #EEEAFF;
    position: absolute;
    bottom: -4px;
    left: 12px;
    transform: rotate(-45deg) skew(-14deg, -14deg);
  }
`;

const LabelInfoWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;
