import { omit } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import ShowLatex from '../../../ExamTest/ShowLates';
import { replaceQuestions } from '../../../features/teacher/teacherState';
import { Button, CheckboxNormal, Radio } from '../../../Form/Form';
import { QuestionIcon } from '../../../images/iconSvg';
import { AssignmentFields, QuestionsParams } from '../../../project-types/assignments/types';

interface AssignmentQuestionsProps {
  assignment: AssignmentFields,
  onClickNext: () => void
}

type QuestionCheckedType = Record<number, any>;

export default function AssignmentQuestions({ assignment, onClickNext }: AssignmentQuestionsProps) {


  const dispatch = useDispatch();

  const [checkedQuestions, setCheckedQuestions] = useState<QuestionCheckedType>({});

  const setIsChecked = (student: QuestionCheckedType, questionNumber: number) => () => {
    // Hack worng type String in user type
    if (checkedQuestions[questionNumber]) {
      setCheckedQuestions(omit(checkedQuestions, questionNumber));
    } else {
      setCheckedQuestions({ ...checkedQuestions, [questionNumber]: student });
    }
  };

  const checkAll = (e: any) => {
    if (!e.target.checked) {
      setCheckedQuestions({});
    } else {
      const res: any = {};
      const studnetsObject = assignment.questions.reduce((acc: QuestionCheckedType, student: any, n: number) => {
        acc[n] = student;
        return acc;
      }, res);
      setCheckedQuestions(studnetsObject);
    }
  };

  const onClickReplace = () => {
    debugger;
    console.log(Object.values(checkedQuestions));
    // onDelete(Object.values(checkedQuestions));
    dispatch(replaceQuestions(assignment._id, Object.keys(checkedQuestions)));
  };


  return (
    <>
      <Header>
        <Title>
          {assignment.topicId[0]}
          &nbsp;
          practice
        </Title>
        <CreateAssignmentButton disabled={false} onClick={onClickNext}>
          Create assignment
        </CreateAssignmentButton>
      </Header>
      <QuestionControlsWrap>
        <NumberOfQuestions>
          {`${assignment.questions.length} questions`}
        </NumberOfQuestions>
        <ReplaceQuestions>
          <ReplaceText isActive={Object.keys(checkedQuestions).length > 0} onClick={onClickReplace}>
            {assignment.questions.length > 0 ? `Replace (${Object.keys(checkedQuestions).length})` : `Replace`}
            <QuestionIconStyled />
          </ReplaceText>
          <CheckboxForReplaceAll onChange={checkAll} />
        </ReplaceQuestions>
      </QuestionControlsWrap>
      {assignment.questions.map((question, questionNumber) => (
        <QuestionBlock>
          <ShowLatex>{question.question}</ShowLatex>
          <RadioList>
            {question.answers.map((answer, n) => (
              <RadioStyled checked={question.correctAnswer === `${n}`}>{answer}</RadioStyled>
            ))}
          </RadioList>
          <CheckboxForReplace checked={!!checkedQuestions[questionNumber]} onChange={setIsChecked(question, questionNumber)} />
        </QuestionBlock>
      ))}
    </>
  );
}

const Header = styled.h3`
  margin-bottom: 26px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 914px;
  margin-top: 100px;
  margin-bottom: 100px;
`;


const Title = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  color: #1F1F1F;
`;

const CreateAssignmentButton = styled(Button)`
  background: #9077F5;
  width: 276px;
  height: 56px;
  font-size: 18px;
  line-height: 22px;
`;

const QuestionBlock = styled.div`
  background: #FFFFFF;
  padding: 98px 60px 50px 60px;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
  max-width: 914px;
`;

const RadioList = styled.section`
  margin-top: 63px;
  display: flex;
  flex-direction: column;
`;

const RadioStyled = styled(Radio)`
  & > span {
    font-size: 18px;
    line-height: 26px;
    color: #1F1F1F;
    font-weight: normal;
  }
  margin-bottom: 12px;
  & > input:checked + span::before {
    background-color: #95D354;
    border: none;
  }
`;

const QuestionControlsWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 29px 27px 27px;
  max-width: 914px;
`;

const NumberOfQuestions = styled.div`
  font-size: 22px;
  line-height: 26px;
`;

const ReplaceText = styled.div<{ isActive: boolean }>`
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  width: 166px;
  justify-content: space-between;
  margin-right: 57px;
  color: ${props => props.isActive ? '#9077F5' : '#C4C4C4'};
  cursor: pointer;
  width: fit-content;
`;

const ReplaceQuestions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const QuestionIconStyled = styled(QuestionIcon)`
  & svg {
    fill: #C4C4C4;
    width: 25px;
    height: 25px;
    margin-left: 11px;
  }
`;

const CheckboxForReplace = styled(CheckboxNormal)`
  display: flex;
  position: absolute;
  right: 29px;
  top: 26px;
`;

const CheckboxForReplaceAll = styled(CheckboxNormal)`
  display: flex;
`;