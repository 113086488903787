import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { combineReducers } from 'redux';
// import { message } from 'antd';

import { AppThunk, RootState } from '../../app/store';
import { request } from '../../api';

import { SubjectsType, TopicRouteType, TopicsType, TopicType, TopicAreasType } from './types';
import { isEmpty } from 'lodash';

const initialState = {
  icons: {},
  tree: {},
};

export const subjects = createSlice({
  name: 'Subjects',
  initialState,
  reducers: {
    setSubjects: (state, action: { payload: {icons: {}, tree: {}} }) => {
      return ({ ...action.payload });
    },
  },
});

export default combineReducers({
  subjects: subjects.reducer,
});


const { setSubjects } = subjects.actions;

export const getAllSubjects = (routeParams: TopicRouteType): AppThunk => (dispatch) => {
  request({
    method: 'GET',
    url: '/challenge/topic-tree',
    data: {},
  }, [200]).then((e) => {
    if (e.data.tree) {
      dispatch(setSubjects(e.data.tree));

      // set default values
      if (!routeParams.subject) {
        const subject = Object.keys(e.data.tree)[0];
        // setSubjectNav({ ...routeParams, subject }, history);
      }
    }
  })
  .catch(error => {
    console.log('err getAllSubjects', error);
    // message.error(error.response.data);
  });
};

export const getSubjects = (state: RootState) => state.topic.subjects;

export const getSubjectName = (subject: any) => subject;

export const getSubjectsInfo = (state: RootState, subject: string) => {
  let specializationItemDefault: number = 1;
  const specializationItems = Object.keys(state.topic.subjects.tree).map((item, i) => {
    if (item === subject) {
      specializationItemDefault = i + 1;
    }

    return {
      id: i + 1, // for correct menu
      name: item,
    };
  });

  return { specializationItems, specializationItemDefault };
};

const backHoverColors: string[] = ['#FCD53A', '#50C4EE', '#95D354', '#F372C7', '#9077F5', '#82DECE'];

const selectColor = (i: number) => {
  if (backHoverColors.length >= i + 1) {
    return backHoverColors[i];
  }

  const changeNum = (i + 1) % backHoverColors.length;

  return backHoverColors[changeNum];
};

export const getTopicInfo = (item: string, i: number = 1, subjectsIcons?: string, hoverColor: string = '') => {
  const itemLower = item.toLowerCase();
  const topicInfo: TopicType = {
    key: i,
    title: item,
    backgroundHover: hoverColor,
    icon: subjectsIcons,
  };

  return topicInfo;
};

export const getTopicsInfo = (
  state: RootState,
  subject: string,
  topic?: string,
  topicArea?: string,
) => {
  const subjectsItems: { [key: string]: any } = state.topic.subjects;
  const { tree, icons } = subjectsItems;

  if (isEmpty(tree) && isEmpty(icons)) {
    return [];
  }

  const getObjectByCurrentArgs = () => {
    if (topicArea && topic && subject) {
      return tree[subject][topic][topicArea];
    }
    if (topic && subject) {
      return tree[subject][topic];
    }
    if (subject) {
      return tree[subject];
    }

    return {};
  };

  const currentTopics = getObjectByCurrentArgs();

  if (isEmpty(currentTopics)) {
    return [];
  }

  const topicsItems = Object.keys(currentTopics).map((item, i) => {
    const getItemsByTopic = icons[`${subject}/${item}`];
    const topicItem = getTopicInfo(item, i, getItemsByTopic?.icon, getItemsByTopic?.['hover-color']);
    const topicAreasLength = Object.keys(tree[subject][item] || {}).length;
    const allSubTopicsLength = Object.keys(tree[subject][item] || {}).reduce((acc: number, key: string) => {
      return acc + Object.keys(tree[subject][item][key]).length;
    }, 0);
    const subTopicsLength = Object.keys(currentTopics[item] || {}).length;

    return {
      urlKey: `${subject}/${item}`,
      topicAreasLength,
      subTopicsLength: topic ? subTopicsLength : allSubTopicsLength,
      ...topicItem,
    };
  });

  console.log(topicsItems)

  return topicsItems;
};
