import styled from "styled-components/macro";
import { Radio } from "../../../Form/Form";

export const RadioList = styled.section`
display: flex;
flex-direction: column;
`;

export const RadioStyled = styled(Radio)`
& > span {
  font-size: 18px;
  line-height: 26px;
  color: #1F1F1F;
  font-weight: normal;
}
margin-bottom: 12px;
`;
