import React from 'react';
import styled from 'styled-components/macro';
import FormElements from '../../Form';
import { SUBSCRIPTION_STATUS, SUBSCRIPTION_STATUS_KEY } from './CurrentPlan';
import { PayPlanStripe, PayPlanType } from './types';
import { changeTariff } from '../../features/user/userState';
import Paypal from '../../Paypal';
import { PaypalDataType } from '../../Paypal/Paypal';
import { UserProfileBaseType } from '../../features/user/types';

const {
  Button,
} = FormElements;

interface PlanInformationProps {
  currentTariffName: string
  currentPriceId?: string
  user?: UserProfileBaseType
  onShowModalCancelSubscription: () => void
  onShowModalChangeSubscribtion: () => void
  statusSubscription: string
}

const PlanInformation = ({
  currentTariffName,
  currentPriceId,
  user,
  onShowModalChangeSubscribtion,
  onShowModalCancelSubscription,
  statusSubscription,
}: PlanInformationProps) => {
  const descriptionByStatus: Record<string, string> = {
    ACTIVE: `Next payment will be charged on April 18, 2021`,
    TIME_LEFT: 'Your subscription will expire on April 18, 2021.',
    INACTIVE: 'Subscription canceled',
  };

  const changePaypalSubscription = (data: PaypalDataType) => {

  };

  return (
    <InformationPlan>
      {user && (
        <>
          <TitlePlanWrap>
            <TitlePlan>{currentTariffName}</TitlePlan>
            <DescriptionPlan>
              {descriptionByStatus[statusSubscription]}
            </DescriptionPlan>
          </TitlePlanWrap>

          <ButtonWrapper>
            <ChangePlanButton
              onClick={onShowModalChangeSubscribtion}
            >
              {statusSubscription === SUBSCRIPTION_STATUS.ACTIVE ? 'Change plan' : 'Renew subcribtion'}
            </ChangePlanButton>
            {statusSubscription === SUBSCRIPTION_STATUS.ACTIVE && (
            <CancelSubscriptionButton
              onClick={onShowModalCancelSubscription}
            >
              Cancel subscription
            </CancelSubscriptionButton>
            )}
          </ButtonWrapper>
          {/* {((user.subscription?.system === 'paypal' || !user.subscription) && (
            <PaypalStyled
              onSubscribePaypal={changePaypalSubscription}
              selectedPlanId={'P-66110546F1612783MMA5KN2Y'}
            />
          )
          )} */}
        </>
      )}
    </InformationPlan>
  );
};

export default PlanInformation;

const InformationPlan = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const TitlePlanWrap = styled.div`
  width: 270px;
`;

const TitlePlan = styled.span`
  display: block;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  margin-bottom: 16px;
`;

const DescriptionPlan = styled.span`
  display: block;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #9C9C9C;
`;

const ButtonWrapper = styled.div`
  width: 247px;
`;

const ChangePlanButton = styled(Button)`
  width: 100%;
  height: 56px;
  background-color: #9077F5;
  border-radius: 10px;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
  margin-left: auto;
`;

const CancelSubscriptionButton = styled(ChangePlanButton)`
  background-color: #FFF;
  color: #F55F4B;
  margin-bottom: 0;
  :hover:not(:default) {
    background-color: #FFF;
  }
`;

const PaypalStyled = styled(Paypal)`
  width: auto;
  margin-left: 24px;
`;
