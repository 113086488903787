import React, {
  ReactNode, useEffect, useMemo, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import {
  useParams, useRouteMatch, useHistory, useLocation,
} from 'react-router-dom';
import { AddIcon, CheckIcon } from '../images/iconSvg';
import Sidebar, { SIDEBAR_MENU } from '../Form/FieldsEntity/Sidebar';
import ResumeTestSesion from './ResumeTestSesion';
import MenuOfSelectSpecialization from '../Form/FieldsEntity/MenuOfSelectSpecialization';
import { getAllSubjects, getSubjectsInfo } from '../features/topic/topicState';
import { TopicRouteType } from '../features/topic/types';
import { RootState } from '../app/store';
import { useCurrentChalenge } from '../ExamTest/ChalengeHooks';

import { createStartLink } from './NewTest/utils';
import { finishChallenge } from '../features/challenge/challengeState';
import { otterImages } from '../config/otterImajes';
import Footer from '../Layout/Footer/Footer';
import { getUserProfile } from '../features/user/userState';
import { oneTariff } from '../database/oneTariff';

interface DashboardPageTypes {
  children?: ReactNode
  selectedMenuItem?: string
}

interface sidebarChalengeItemType {
  key: string
  name: string
  icon?: any
  linkTo: string
}

// let sidebarItemsData: sidebarChalengeItemType[] = [];
const sidebarItems: sidebarChalengeItemType[] = [{
  key: SIDEBAR_MENU.NEW_TEST,
  name: 'New test',
  icon: <AddIcon />,
  linkTo: 'dashboard/new-test',
}, {
  key: SIDEBAR_MENU.PAST_TESTS,
  name: 'My Learning',
  icon: <CheckIcon />,
  linkTo: 'dashboard/my-learning',
}];

const DashboardPage = ({ children, selectedMenuItem }: DashboardPageTypes) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(getUserProfile);

  const isOneTariff = user.priceId && oneTariff[user.priceId];

  //debugger;

  const defaultExam: undefined | string = isOneTariff && user.defaultExam?.[0];

  const [showModal, setShowModal] = useState(false);

  const {
    headerNav, subject, topic, subTopic,
  } = useParams<TopicRouteType>();

  const currentChalenge = useCurrentChalenge();

  useEffect(() => {
    dispatch(getAllSubjects({ headerNav, subject }));
  }, []);


  useEffect(() => {
    if (currentChalenge.isActive) {
      setShowModal(true);
    }
  }, [currentChalenge]);

  useEffect(() => {
    /*
    sidebarItemsData = [];
    sidebarItems.map((item: sidebarChalengeItemType) => {
      sidebarItemsData.push({
        ...item,
        linkTo: `${item.linkTo}${subject ? `/${subject}` : ''}`
      });
    });
    */
  }, [subject]);


  const startNewChalenge = () => {
    history.push(`/challenge/${currentChalenge.challengeType}/current/1/finish`);
  };

  const resumeChalenge = () => {
    const topicId = currentChalenge.topicId || [];
    const startLink = createStartLink(currentChalenge.challengeType, topicId[0], topicId[1], topicId[2]);
    history.push(`/challenge/${currentChalenge.challengeType}/current/${currentChalenge.questions.length}`);
  };

  const { specializationItems, specializationItemDefault } = useSelector((state: RootState) => getSubjectsInfo(state, subject || ''));



  const defaultNumSubject = (defaultExam !== undefined && isOneTariff) ? specializationItems.findIndex((item) => item.name === defaultExam) + 1 : specializationItemDefault;

  const { url, path } = useRouteMatch();

  const renderOtterInSidebar = useMemo(
    () => {
      const randomNum = Math.floor((Math.random()) * Math.floor(otterImages.length));
      const randomImages = otterImages[randomNum];

      return randomImages;
    }, [url],
  );

  const setSubjectNav = (name: string) => {
    // Fix dirty hack
    if (/new-test/.exec(path)?.[0]) {
      history.push(`/dashboard/new-test/${name}`);
    } else {
      history.push(`/dashboard/my-learning/${name}`);
    }
  };

  // debugger;

  return (
    <>
      <Page>
        <ResumeTestSesion
          onCancel={() => setShowModal(false)}
          onResume={resumeChalenge}
          onStart={startNewChalenge}
          show={showModal}
        />
        <MenuOfSelectSpecializationStyled 
          disabled={defaultExam !== undefined}
          // defaultValue={defaultNumSubject}
          items={specializationItems}
          defaultValue={defaultNumSubject}
          onSelect={setSubjectNav}
        />
        <ContentSection>
          <SidebarStyled
            items={sidebarItems}
            otterBackground={renderOtterInSidebar}
            selectedMenuItem={selectedMenuItem}
          />
          {children}
        </ContentSection>
      </Page>
      <Footer />
    </>
  );
};

const MenuOfSelectSpecializationStyled = styled(MenuOfSelectSpecialization)`
  margin-bottom: 78px;
  width: fit-content;
  min-width: 830px;
  margin-left: auto;
`;

const Page = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding-top: 140px;
  padding-right: 130px;

  @media (max-width: 1400px) {
    padding-right: 50px;
  }
`;

const ContentSection = styled.div`
  display: flex;
  min-height: 100vh;
`;

const SidebarStyled = styled(Sidebar)`
  margin-right: 87px;
`;

export default DashboardPage;
