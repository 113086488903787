import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TopicCard from './TopicCard';
import SubTopicsBoard from './SubTopicsBoard';
import { TopicsType } from './type';
import ProgressPage from './ProgressPage';
import { SIDEBAR_MENU } from '../Form/FieldsEntity/Sidebar';
import { getTopicsInfo } from '../features/topic/topicState';
import { RootState } from '../app/store';
import { useLoadTopicStats } from './hooksTopic';

// const topicData = [{
//   topicId: 'Algebra',
//   average: 85,
// }, {
//   topicId: 'Geometry',
//   average: 33,
// }, {
//   topicId: 'Data',
//   average: 56,
// }, {
//   topicId: 'Ratio and proportion',
//   average: 0,
// }, {
//   topicId: 'Numbers',
//   average: 78,
// }];

const TopicsOnSubjects = () => {
  const [currentTopicNumber, setCurrentTopicNumber] = useState(1);
  const [selectedTopic, setSelectedTopic] = useState('');

  const {
    subject,
  } = useParams<{subject: string}>();
  
  useEffect(() => {
    setCurrentTopicNumber(1);
  }, [selectedTopic]);

  useEffect(() => {
    setSelectedTopic('');
  }, [subject]);

  const avgs: any = useLoadTopicStats();

  const filterList = (list: any) => list.filter((item: any) => avgs[item.name || item.title] !== undefined);

  const topicsItem = useSelector((state: RootState) => getTopicsInfo(state, subject));

  const topicsAreas = useSelector((state: RootState) => getTopicsInfo(state, subject, selectedTopic));

  const filteredTopics = filterList(topicsItem);
  const filteredAreas = filterList(topicsAreas);

  const currentAreas = topicsAreas[currentTopicNumber - 1];

  const subTopicArreas = useSelector((state: RootState) => getTopicsInfo(state, subject, selectedTopic, currentAreas?.title));
  const filteredSubTopics = filterList(subTopicArreas);

  const previousAreas = () => {
    if (currentTopicNumber === 1) {
      return setCurrentTopicNumber(topicsAreas.length);
    }

    return setCurrentTopicNumber(currentTopicNumber - 1);
  };

  const nextAreas = () => {
    if (topicsAreas.length === currentTopicNumber) {
      return setCurrentTopicNumber(1);
    }

    return setCurrentTopicNumber(currentTopicNumber + 1);
  };

  return (
    <ProgressPage selectedMenuItem={SIDEBAR_MENU.TOPICS}>
      <Wrapper>
        <Title>Topics</Title>
        <TopicCardWrapper>
          {topicsItem.map((item: any, n: number) => (
            <TopicCardStyled
              key={n}
              value={avgs[item.title] || 0}
              widthChart={107}
              heightChart={107}
              title={item.title}
              onClick={() => setSelectedTopic(item.title)}
              selectedTopic={selectedTopic === item.title}
            />
          ))}
        </TopicCardWrapper>
        {selectedTopic !== '' && (
          <SubTopicsBoard
            topicAreas={currentAreas}
            subTopics={subTopicArreas}
            onPreviousAreas={previousAreas}
            onNextAreas={nextAreas}
            currentAreas={currentTopicNumber}
            lengthAreas={topicsAreas.length}
            avgs={avgs}
          />
        )}
      </Wrapper>
    </ProgressPage>
  );
};

export default TopicsOnSubjects;

const Wrapper = styled.div`

`;

const Title = styled.h2`
  margin-left: 22px;
  margin-bottom: 23px;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
`;

const TopicCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 20px;
`;

const TopicCardStyled = styled(TopicCard)`
  width: calc(25% - 20px);
  margin: 0 10px 20px;
`;
