import {
  ChartType,
  ChartConfigType,
  ScalesSetType,
  StatType,
  DateType,
  RangeType,
  ScalesType,
  ScalesCallbacksType
} from "../types";

import { date, values } from "../scales";

import drawLinePoint from "./drawLinePoints";
import drawLineChart from './drawLineChart';

export function drawLine(
  config: ChartConfigType,
  chart: ChartType,
  onClickPoint: (d: StatType) => void,
  scalesSet: ScalesSetType,
  data: StatType[],
  dates: DateType[],
  results: RangeType[],
  onFocusPoint: (d?: StatType) => void,
  focusedPoint?: StatType
) {
  const dateScale = date(config, dates);
  const valueScale = values(config, results);


  const scales: ScalesCallbacksType = {
    dateScale,
    valueScale,
  };

  drawLineChart(config, chart, data, scales);
  drawLinePoint(config, chart, data, scales, () => {}, onFocusPoint, focusedPoint);
}
