import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import FormElements from '../Form';
import Modal from '../Form/FieldsEntity/Modal';
import Footer from '../Layout/Footer/Footer';
import Contacts from '../MainPage/Contacts';
import AddPayments from './AddPayments';

const {
  Button,
} = FormElements;

const otterPrice = require('../images/otter-price.svg');

const PricingPage = () => {
  const [showModal, setShowModal] = useState(false);

  const { push } = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Page>
        {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <AddPayments />
        </Modal>
        )}
        <Title>
          Start your
          {' '}
          <SpanTitle>free 7-day trial</SpanTitle>
          {' '}
          with Otter
        </Title>
        <Table>
          <TR>
            <TH />
            <TH>
              <TableHeadTitle>Otter One</TableHeadTitle>
              <TableHeadDescription>1 subject</TableHeadDescription>
            </TH>
            <TH>
              <TableHeadTitle>Otter Standard</TableHeadTitle>
              <TableHeadDescription>All subjects</TableHeadDescription>
            </TH>
            <TH>
              <TableHeadTitle>Otter Plus</TableHeadTitle>
              <TableHeadDescription>All subjects</TableHeadDescription>
              <TableHeadDescription>Any time live teacher support</TableHeadDescription>
            </TH>
          </TR>
          <TR>
            <TD>
              <TableSideTitle>Monthly price</TableSideTitle>
              <TableSideDescription>per month</TableSideDescription>
            </TD>
            <TD>
              <Price>
                &#163;12
                <SupText>99</SupText>
              </Price>
              <PriceDescription>per month</PriceDescription>
            </TD>
            <TD>
              <Price>
                &#163;26
                <SupText>99</SupText>
              </Price>
              <PriceDescription>per month</PriceDescription>
            </TD>
            <TD>
              <Price>
                &#163;44
                <SupText>99</SupText>
              </Price>
              <PriceDescription>per month</PriceDescription>
            </TD>
          </TR>
          {/* <TR>
          <TD>
            <TableSideTitle>Yearly price</TableSideTitle>
            <SavePrice>Save 25%</SavePrice>
          </TD>
          <TD><AbsentPrice>&#8212;</AbsentPrice></TD>
          <TD>
            <Price>
              &#163;29
              <SupText>99</SupText>
            </Price>
            <PriceDescription>per month</PriceDescription>

          </TD>
          <TD>
            <Price>
              &#163;49
              <SupText>99</SupText>
            </Price>
            <PriceDescription>per month</PriceDescription>

          </TD>
        </TR> */}
        </Table>
        <FreeTrialButton onClick={() => push(`${process.env.PUBLIC_URL}/registration/student`)}>Try 1-week free trial</FreeTrialButton>
      </Page>
      <Contacts />
      <Footer />
    </>
  );
};

export default PricingPage;

const Page = styled.div`
  box-sizing: border-box;
  padding: 160px 120px 218px 150px;
`;

const Title = styled.h1`
  margin-bottom: 100px;
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
  text-align: center;
`;

const SpanTitle = styled.span`
  color: #F87700;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 60px;
`;

const TR = styled.tr`
  :nth-child(1) td:not(:first-child) {
    border-bottom: 1px solid #C4C4C4;
    position: relative;
    ::after {
      content: '';
      display: block;
      height: 90%;
      border-left: 1px solid #C4C4C4;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    :last-child::after {
      content: none;
    }
  }
  :nth-child(2) td:first-child {
    border-top-left-radius: 10px;
    border-bottom: 1px solid #FFF;
  }
  :last-child td:not(:first-child) {
    position: relative;
    ::after {
      content: '';
      display: block;
      height: 90%;
      border-left: 1px solid #C4C4C4;
      position: absolute;
      top: 0;
      right: 0;
    }
    :last-child::after {
      content: none;
    }
  }
  :last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
`;

const TH = styled.th`
  height: 160px;
  padding: 18px;
  color: #FFF;
  text-align: center;
  vertical-align: top;
  :first-child {
    border: none;
    position: relative;
    ::before {
      content: '';
      width: 200px;
      height: 200px;
      display: block;
      background: url(${otterPrice}) no-repeat center;
      background-size: cover;
      position: absolute;
      bottom: -47px;
      left: 30px;
    }
  }
  :nth-child(2) {
    border-right: 1px solid #FFF;
    border-top-left-radius: 10px;
  }
  :nth-child(3) {
    border-right: 1px solid #FFF;
  }
  :last-child {
    border-top-right-radius: 10px;
  }
  :not(:first-child) {
    background-color: #9077F5;
  }
`;

const TD = styled.td`
  border: 1px solid black;
  height: 188px;
  width: 292px;
  text-align: center;
  :first-child {
    width: 293px;
  }
  :first-child {
    border: none;
    background-color: #9077F5;
    vertical-align: center;
  }
  :not(:first-child) {
    background-color: #FFF;
    border: none;
  }
  :last-child {
    border-right: none;
  }
`;

const TableHeadTitle = styled.span`
  display: block;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
`;

const TableHeadDescription = styled.span`
  display: block;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
`;

const TableSideTitle = styled.span`
  display: block;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: #FFF;
  margin-bottom: 5px;
`;

const TableSideDescription = styled.span`
  color: #FFF;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

const SavePrice = styled.span`
  width: fit-content;
  display: inline-block;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  box-sizing: border-box;
  padding: 4px 16px;
  border-radius: 20px;
  background-color: #FFF;
  margin-top: 5px;
`;

const PriceDescription = styled(TableSideDescription)`
  display: block;
  color: inherit;
`;

const AbsentPrice = styled.span`
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
`;

const Price = styled.span`
  display: inline-block;
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
`;

const SupText = styled.sup`
  font-size: 30px;
`;

const FreeTrialButton = styled(Button)`
  max-width: 876px;
  margin-left: auto;
  height: 79px;
  font-size: 32px;
  border-radius: 10px;
  :hover:not(:disabled) {
    background-color: #FF912C;
  }
`;
