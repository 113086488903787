import React from 'react';

import {
  Route,
  Switch,
} from 'react-router-dom';

import {
  Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


import Layout from './Layout';
import LoginPage from './User/LoginPage';
import RegistrationPage from './User/RegistrationPage';
import ForgottenPasswordPage from './User/ForgottenPasswordPage';
import LetterSent from './User/LetterSent';
import ProfileSubscription from './ProfilePage/Subscription/ProfileSubscription';
import PricingPage from './PricingPage/PricingPage';
import VerifyEmailPage from './User/VerifyEmailPage';
import ConfirmEmailPage from './User/ConfirmEmailPage';
import NewTest from './DashboardPage/NewTest/NewTest';
import PastTestsBody from './DashboardPage/PastTests/PastTestsBody';
import SubtopicItemsList from './DashboardPage/NewTest/SubtopicItemsList';
import SubjectTopicsList from './DashboardPage/NewTest/SubjectTopicsList';
import ExamStartPage from './ExamTest';
import Chalenge from './ExamTest/Chalenge';
import FinishChalenge from './ExamTest/FinishChalenge';
import ExamResultInfo from './ExamTest/ExamResultInfo';
import CorrectAnswersExamQuestions from './ExamTest/CorrectAnswersExamQuestions';
import Acl from './Acl';
import PersonalInformation from './ProfilePage/PersonalInformation/PersonalInformation';
import AcademicInformation from './ProfilePage/AcademicInformation/AcademicInformation';
import ProgressGraphBoard from './ProgressPage/ProgressGraphBoard';
import TopicsOnSubjects from './ProgressPage/TopicsOnSubjects';
import MainPage from './MainPage';
import FormProfileInformation from './SignUpSteps/FormProfileInformation';
import FormSubscribe from './SignUpSteps/FormSubscribe';
import FinishStepPage from './SignUpSteps/FinishStepPage';
import TermsOfServise from './Landing/TermsOfServise';
import Page404 from './Landing/Page404';
import PrivacyPolicy from './Landing/PrivacyPolicy';
import AboutUs from './About/AboutUs';
import HowToUseOtter from './About/HowToUseOtter';
import FaqPage from './About/FaqPage';
import ElevenPlusPage from './About/ElevenPlusPage';
import TeacherGroups from './TeacherDashboard/TeacherGroupInDashboard/TeacherGroups';
import TeacherSetUp from './TeacherCreation/TeacherSetUp';
import WelcomeTeacher from './TeacherCreation/WelcomeTeacher';
import TeacherStudents from './TeacherDashboard/TeacherStudentsInDashboard/TeacherStudents';
import TeacherGroupPage from './TeacherDashboard/TeacherGroupInDashboard/TeacherGroupPage';
import JoinPage from './User/Join/JoinPage';
import TeacherGroupRoasterPage from './TeacherDashboard/TeacherGroupInDashboard/Roaster/TeacherGroupRoaster';
import AssignmentCreatePage from './TeacherDashboard/TeacherGroupInDashboard/Assignment/AssignmentCreatePage';

type TParams = { id: number };

const stripePromise = loadStripe(
  'pk_test_51HNfFpCS0ftqW8lkBS4GbXjZRNe5YTJWNk7zgXbeg4356kdt2aW8NCBtf04Vk1tjuJ0itUjqm7An2XW1VT28ypJu00twh4nDhG',
);

export default function Routes() {

  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact>
        <MainPage />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/local/:lang`}>
        <MainPage />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/about-us`}>
        <AboutUs />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/how-to-use`}>
        <HowToUseOtter />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/eleven-plus`}>
        <ElevenPlusPage />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/faq`}>
        <FaqPage />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/:headerNav/edit/personal`}>
        <Acl>
          <PersonalInformation />
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/:headerNav/edit/academic`}>
        <Acl>
          <AcademicInformation />
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/pricing`}>
        <Layout>
          <PricingPage />
        </Layout>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/:headerNav/subscription`}>
        <Acl>
          <Elements stripe={stripePromise}>
            <ProfileSubscription />
          </Elements>
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/:headerNav/new-test/:subject/:topic/:topicAreas?/:subTopic?`}>
        <Acl>
          <NewTest>
            <SubtopicItemsList />
          </NewTest>
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/:headerNav/new-test/:subject?`}>
        <Acl>
          <NewTest>
            <SubjectTopicsList />
          </NewTest>
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/:headerNav/my-learning/:subject?`}>
        <Acl>
          <PastTestsBody />
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/:headerNav/overall/`}>
        <Acl>
          <ProgressGraphBoard />
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/:headerNav/subjects/:subject`}>
        <Acl>
          <ProgressGraphBoard />
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/:headerNav/topics/:subject`}>
        <Acl>
          <TopicsOnSubjects />
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/login`}>
        <LoginPage />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/registration/:userType`}>
        <RegistrationPage />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/password-recovery`}>
        <ForgottenPasswordPage />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/letter-sent`}>
        <LetterSent />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/challenge/start/:challengeType/:subject/:topic?/:topicAreas?/:subtopic?`}>
        <Acl>
          <ExamStartPage />
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/challenge/:challengeType/current/:currentQuestionNum/:finish?/:challengeId?`}>
        <Acl>
          <Chalenge green />
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/challenge/finish/:challengeId`}>
        <Acl>
          <FinishChalenge />
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/chalenge/detailed-result/:challengeType/:challengeId`}>
        <Acl>
          <ExamResultInfo />
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/chalenge/correct-answers/:challengeType/:challengeId/:currentQuestionNum`}>
        <Acl>
          <CorrectAnswersExamQuestions />
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/sign-up-steps/:step/profile-information`}>
        <Acl>
          <FormProfileInformation />
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/sign-up-steps/:step/subscribe`}>
        <Acl>
          <Elements stripe={stripePromise}>
            <FormSubscribe />
          </Elements>
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/sign-up-steps/:step/finish-step`}>
        <Acl>
          <FinishStepPage />
        </Acl>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/set-up-teacher`}>
        <TeacherSetUp />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/user/verify`}>
        <VerifyEmailPage />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/user/confirm/:userId/:secureCode`}>
        <ConfirmEmailPage />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/terms-of-servise`}>
        <TermsOfServise />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/privacy-policy`}>
        <PrivacyPolicy />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/teacher/:headerNav/my-groups`}>
        <TeacherGroups />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/teacher/:headerNav/my-students`}>
        <TeacherStudents />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/teacher/group/:groupId/assignment/create/:step`}>
        <AssignmentCreatePage />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/teacher/group/:groupId`}>
        <TeacherGroupPage />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/teacher/roaster/:groupId`}>
        <TeacherGroupRoasterPage />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/teacher/join`}>
        <WelcomeTeacher />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/user/join`}>
        <JoinPage />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/*`}>
        <Page404 />
      </Route>
    </Switch>
  );
}
