import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from './Header/Header';
import { getToken } from '../api';
import NotificationLargeScreen from '../Form/FieldsEntity/NotificationLargeScreen';

interface LayoutProp {
  children: ReactNode
}


export default function Layout({ children }: LayoutProp) {
  const [showNotification, setShowNotification] = React.useState<boolean>(true);
  const { lang } = useParams<{lang: string}>();

  const { i18n } = useTranslation();

  useEffect(() => {
    if (lang) {
      localStorage.setItem('lang', lang);
      window.location.href = `${process.env.PUBLIC_URL}/`;
    }
  }, [lang]);

  const selectedLang = localStorage.getItem('lang');

  useEffect(() => {
    if (selectedLang) {
      i18n.changeLanguage(selectedLang);
    }
  }, [selectedLang]);

  const token = useSelector(getToken);

  useEffect(() => {
    if (token) {
      setShowNotification(true);
    }
  }, [token]);

  return (
    <Page>
      {showNotification && (
        <NotificationLargeScreen onClose={() => setShowNotification(false)} />
      )}
      <Header />
      <Content>
        {children}
      </Content>
    </Page>
  );
}

const Page = styled.div`
  min-height: 100vh;
  position: relative;
  min-width: 375px;
`;

const Content = styled.main`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  /* background-color: #F6F8F9; */
  min-height: calc(100vh - 90px);
`;
