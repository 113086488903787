import React from 'react';
import styled from 'styled-components/macro';

interface SumarryStatisticsBoardProsp {
  standardAgeScore: number
  questionsAttempted: number
  questionsNumber?: number
  score: number
  questionsAnsweredCorrectly: number
  elapsedTime: number
  fullTime: number
  timePerQuestion: string
}

const SummaryStatisticsBoard = ({
  standardAgeScore,
  questionsAttempted,
  questionsNumber,
  questionsAnsweredCorrectly,
  score,
  elapsedTime,
  fullTime,
  timePerQuestion,
}: SumarryStatisticsBoardProsp) => {
  const fullTimeMinutes = (fullTime / 1000) / 60;

  const elapsedTimeMinutes = Math.floor((elapsedTime / 1000) / 60);

  const elapsedTimeSeconds = Math.floor((elapsedTime / 1000) % 60);

  const statisticsItems = [
    {
      name: 'Standard Age Score (SAS)',
      value: standardAgeScore,
    },
    {
      name: 'Questions Attempted',
      value: `${questionsAttempted} (out of ${questionsNumber})`,
    },
    {
      name: 'Questions answered correctly',
      value: `${questionsAnsweredCorrectly} (${score}%)`,
    },
    {
      name: 'Time Taken',
      value: `${elapsedTimeMinutes}m ${elapsedTimeSeconds}s (out of ${fullTimeMinutes}m 00s)`,
    },
    {
      name: 'Average Time per Question',
      value: timePerQuestion,
    },
  ];

  return (

    <Board>
      {statisticsItems.map((item) => (
        <Item key={item.name}>
          <ItemName>
            {item.name}
          </ItemName>
          <ItemValue>
            {item.value}
          </ItemValue>
        </Item>
      ))}
    </Board>
  );
};

export default SummaryStatisticsBoard;

const Board = styled.ul`
  padding: 30px;
  border-radius: 20px;
  background-color: #EEEAFF;
  list-style: none;
  margin-bottom: 80px;
`;

const Item = styled.li`
  padding: 11px 22px;
  display: flex;
  background-color: #FFF;
  border-radius: 10px;
  justify-content: space-between;
  
  :not(:last-child) {
    margin-bottom: 14px;
  }
`;

const ItemName = styled.span`
  display: block;
  font-size: 20px;
  line-height: 26px;
  width: calc(50% - 24px);
  margin-right: 24px;
`;

const ItemValue = styled.span`
  display: block;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
`;
