import React from 'react';
import styled from 'styled-components/macro';
import { ChallengeType } from '../features/challenge/types';
import { evaluationResult } from './FinishChalenge';
import ExamQuestionList from './ExamQuestionList';
import {
  ClockIcon,
  CheckIcon,
} from '../images/iconSvg';

interface StandartResultInfoProps {
  currentChallenge: ChallengeType
  currentQuestionNum: number
  title?: string
  challengeType: string
}

export function calcCompleatedTime(compleatedTime: string, challengeStartTime: string) {
  if (compleatedTime) {
    const a: any = new Number(new Date(compleatedTime));
    const b: any = new Number(new Date(challengeStartTime));
    return `${Math.floor((a - b) / 1000 / 60)}`;
  }
  return '0';
}

const StandartResultInfo = ({
  currentChallenge,
  currentQuestionNum,
  title,
  challengeType,
}: StandartResultInfoProps) => {

  const examResult = {
    score: currentChallenge.rate,
    presetTime: 30,
    elapsedTime: calcCompleatedTime(currentChallenge.completedTime, currentChallenge.challengeStartTime),
    extraTime: false,
    evaluation: 'excellent',
  };

  return (
    <Wrapper>
      <ExamQuestionListStyled
        currentQuestionNum={20}
        showAnswerMode
        finishTest
        questions={currentChallenge.questions}
        numOfQuestion={currentQuestionNum}
        onChangeQuestion={() => 0}
      />
      <Title>{title}</Title>
      <Description>
        <TestType>{challengeType === 'exam' ? 'Exam test' : 'Practice test'}</TestType>
        <SubjectTest>
          Subject:
          {' '}
          {currentChallenge.topicId?.[0]}
        </SubjectTest>
        {challengeType === 'test' && (
        <Topic>
          Topic:
          {' '}
          {currentChallenge.topicId?.[1]}
        </Topic>
        )}
      </Description>
      <ExamScore>
        <CheckIconStyle fillColor={evaluationResult(currentChallenge.rate)} />
        {challengeType}
        {' '}
        results:
        {' '}
        {examResult.score}
        %
      </ExamScore>
      <ElapsedTime>
        <ClockIconStyled fillColor={
              challengeType === 'test' ? '#F87700' : examResult.extraTime ? 'bad' : 'excellent'
            }
        />
        Test completion time:
        {' '}
        {examResult.elapsedTime}
        {' '}
        minutes
        {examResult.extraTime && (
        <AdditionalTime>
          &#160;(5 minutes additional time)
        </AdditionalTime>
        )}
      </ElapsedTime>
    </Wrapper>
  );
};

export default StandartResultInfo;

const Wrapper = styled.div<{showBackground?: boolean}>`
  max-width: 1005px;
  width: 100vw;
  box-sizing: border-box;
  padding: 50px 48px 84px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 40px;
  line-height: 42px;
  margin-bottom: 32px;
  padding-left: 12px;
`;

const Description = styled.div`
  display: flex;
  box-sizing: border-box;
  padding-left: 12px;
  padding-bottom: 8px;
  border-bottom: 2px solid #E0E0E0;
  margin-bottom: 50px;
`;

const TestType = styled.span`
  display: block;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #9C9C9C;
  margin-right: 50px;
`;

const SubjectTest = styled(TestType)`

`;

const Topic = styled(SubjectTest)`
  margin-right: 0;
`;

const ExamScore = styled.span`
  display: block;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 12px;
  margin-bottom: 20px;
`;

const ElapsedTime = styled(ExamScore)`
  margin-bottom: 80px;
  font-weight: normal;
  font-size: 25px;
  line-height: 34px;
`;

const AdditionalTime = styled.span`
  font-weight: bold;
  color: #F55F4B;
`;

const ClockIconStyled = styled(ClockIcon)<{fillColor: string}>`
  margin-right: 20px;
  font-size: 34px;
  & svg {
    fill: ${(props) => props.fillColor};
  }  
`;

const CheckIconStyle = styled(CheckIcon)<{fillColor: string}>`
  margin-right: 20px;
  font-size: 34px;
  & svg {
    fill: ${(props) => props.fillColor};
  }
`;

const ExamQuestionListStyled = styled(ExamQuestionList)`
  margin-bottom: 60px;
`;
