import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { checkCode, getCurrentCode, joinGroup } from '../../features/invite-code/inviteState';
import { getUserProfile, loadProfile, userProfile } from '../../features/user/userState';
import GroupCode, { Stages } from './GroupCode';


export default function GroupCodeContainer() {

  const [stage, setStage] = useState<Stages>(Stages.ENTER_CODE);
  const dispatch = useDispatch();
  const currentCode = useSelector(getCurrentCode);
  const currentUser = useSelector(getUserProfile);

  const onSubmitCode = (code: string) => {
    dispatch(checkCode({ code: code.toUpperCase() }));
    checkCode({ code });
  };

  useEffect(() => {
    dispatch(loadProfile());
  }, []);

  const history = useHistory();

  const onSignIn = () => {
    history.push('/login');
  };

  const onSignUp = () => {
    history.push('/registration/student');
  };

  useEffect(() => {
    debugger;
    if (currentUser?._id && currentCode.code) {
      dispatch(joinGroup({ code: currentCode.code }));
      history.push('/dashboard/new-test');
    } else if (currentCode.code) {
      setStage(Stages.LOGIN_OR_SIGNUP);
    } else {
      setStage(Stages.ENTER_CODE);
    }
  }, [currentCode]);

  return (
    <GroupCode onSubmitCode={onSubmitCode} stage={stage} onSignIn={onSignIn} onSignUp={onSignUp} />
  );
}
