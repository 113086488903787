import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';
import Elements from '../Form';

const {
  Button,
} = Elements;

interface ComponentProps {
  onClickDone: () => void
  onClickCancel: () => void
  description: string
  title: string
}

const ModalConfirmRemove = ({
  onClickDone,
  onClickCancel,
  title,
  description,
}: ComponentProps) => {

  return (
    <Wrapper>
      <Header>
        <Title>{title}</Title>
      </Header>
      <Content>
        <Text>
          {description}
        </Text>
        <Controls>
          <ButtonDone onClick={onClickDone}>Yes</ButtonDone>
          <ButtonCancel onClick={onClickCancel}>Cancel</ButtonCancel>
        </Controls>
      </Content>
    </Wrapper>
  );
};

export default ModalConfirmRemove;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 628px;
`;

const Header = styled.div`
  padding: 19px 25px 17px 36px;
  border-bottom: 2px solid #E0E0E0;
  width: 100%;
  text-align: left;
`;

const Title = styled.h1`
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  margin: 0;
`;

const Content = styled.div`
  padding: 18px 32px 26px 36px;
`;

const Text = styled.div`
  max-width: 506px;
  font-size: 16px;
  line-height: 22px; 
  color: #1F1F1F;
`;

const Controls = styled.div`
  margin-top: 29px;
  display: flex;
  justify-content: flex-end;
`;

// const CopyButton = styled.button`
//   background: url(${copyImage}) no-repeat center;
//   background-color: transparent;
//   width: 26px;
//   height: 26px;
//   border: none;
//   cursor: pointer;
//   margin-left: 12px;
// `;

const SubDescription = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: #9C9C9C;
  margin-bottom: 32px;
`;

const ButtonDone = styled(Button)`
  background-color: #9077F5;
  border-radius: 10px;
  width: 92px;
  height: 39px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  padding: 16px 48px;
  margin-right: 10px;
`;

const ButtonCancel = styled(ButtonDone)`
  border-radius: 10px;
  border: 2px solid #9C9C9C;
  color: #9C9C9C;
  background-color: #FFFFFF;
  :hover:not(:disabled) {
    background-color: #FFFFFF;
  }
`;
