import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import Sidebar, { SIDEBAR_MENU } from '../Form/FieldsEntity/Sidebar';
import { EditIcon, PayCardIcon } from '../images/iconSvg';
import Layout from '../Layout';

interface ProfilePageProps {
  children?: ReactNode
  selectedMenuItem: string
}

const sidebarItems = [{
  key: SIDEBAR_MENU.EDIT_PROFILE,
  name: 'Edit profile',
  icon: <EditIcon />,
  linkTo: 'profile/edit/personal',
}, {
  key: SIDEBAR_MENU.SUBSCRIPTION,
  name: 'Subscription',
  icon: <PayCardIcon />,
  linkTo: 'profile/subscription',
}];

const ProfilePage = ({ children, selectedMenuItem }: ProfilePageProps) => {

  return (
    <Layout>
      <Page>
        <Sidebar items={sidebarItems} selectedMenuItem={selectedMenuItem} />
        {children}
      </Page>
    </Layout>
  );
};

const Page = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding-top: 184px;
`;

export default ProfilePage;
