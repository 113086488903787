import React from 'react';
import styled from 'styled-components/macro';

const navigationData: string[] = [
  'Background',
  'An overview of the ISEB 11+',
  'What subjects are covered?',
  'How is the ISEB 11+ scored and what is SAS and CAT?',
  'How to prepare?',
  'Understanding the different boards (CEM, GL, ISEB etc.)',
  'Which schools use the ISEB 11+?',
  'What is the procedure after 11+ has been taken?',
  // 'What happens on the day of the exam?',
  'Is exam technique important?',
  'How does Otter help?',
  // 'How can I choose the best school for my child?',
  'School-Specific Assessments',
  'What is the London Consortium?',
  'Does Otter prepare for Grammar schools?',
  'Understanding the GL and CEM Grammar School 11+',
  'What is the UKISET?',
];

const SidebarNavigation = () => {
  return (
    <Saidbar>
      <Wrapper>
        <Title>Contents</Title>
        <NavigationsList>
          {navigationData.map((link, n: number) => (
            <NavigationItem key={link}>
              <NavigationLink href={`#${link}`}>{n + 1}. {link}</NavigationLink>
            </NavigationItem>
          ))}
        </NavigationsList>
      </Wrapper>
      <IsHackBlock />
    </Saidbar>
  );
};

export default SidebarNavigation;

const Saidbar = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #F6F8F9;
  margin-right: 10px;
  padding-bottom: 32px;
`;

const Wrapper = styled.div`
  padding: 49px 27px 50px 74px;
  width: 372px;
  flex-shrink: 0;
  flex-grow: 0;
  position: sticky;
  top: 80px;
  overflow-y: scroll;
  height: calc(100vh - 91px);

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(248, 119, 0, 0.3);
    border-radius: 10px;
    margin-right: 18px;
    margin: 18px;
  }

  ::-webkit-scrollbar-thumb {
    background: #F87700;
    width: 15px;
    height: 33px;
    border-radius: 10px;
  }

  @media (max-width: 1024px) {
    padding-left: 24px;
  }
`;

const Title = styled.h3`
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  margin-left: 13px;
  margin-bottom: 27px;
`;

const NavigationsList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const NavigationItem = styled.li`
  
`;

const NavigationLink = styled.a`
  display: block;
  padding: 10px 3px 10px 13px;
  color: #9077F5;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  :hover {
    color: #F87700;
  }
`;

const IsHackBlock = styled.div`
  flex-grow: 1;
`;
