import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import styled from 'styled-components/macro';
import Topic from '../../Form/FieldsEntity/TopicForDashboard';
import StartButton from '../../Form/FieldsEntity/StartButton';
// import topicsItem from './topicsItems';
import { RootState } from '../../app/store';
import { getSubjectName, getTopicsInfo } from '../../features/topic/topicState';
import { TopicRouteType } from '../../features/topic/types';
import UseLocalization from '../../Localization/UseLocalization';
import { subjectsStyles } from '../../config/subjectsStyles';


interface TopicsList {

}

const description = (topicAreas: number = 1, subTopics: number) => {
  const text = `${topicAreas} topic areas / ${subTopics} sub-topics`;
  return text;
};

const TopicsList = ({ }: TopicsList) => {
  const { subject } = useParams<TopicRouteType>();
  const { push } = useHistory();
  // const currentSubjectName = getSubjectName(subject);
  const currentSubject = subject || 'Maths';

  // TOD Fux maths
  const topicsItem = useSelector((state: RootState) => getTopicsInfo(state, currentSubject));

  const currentSubjectStyles = subjectsStyles[currentSubject];

  // TODO Fix maths
  return (
    <>
      <MainTopic icon={currentSubjectStyles.icon} backgroundColor={currentSubjectStyles.background.color}>
        <TextWrapper>
          <TitleTopic>
            <UseLocalization translateKey="Practice exam">
              Mock Exam
            </UseLocalization>
          </TitleTopic>
          <DescriptionTopic>
            All
            {' '}
            {currentSubject}
            {' '}
            topics
          </DescriptionTopic>
        </TextWrapper>
        <StartButton typeStart="exam" iconSize="35px" onClick={() => push(`${process.env.PUBLIC_URL}/challenge/start/exam/${currentSubject}`)}>
          <UseLocalization translateKey="action.startExam">
            Start exam
          </UseLocalization>
        </StartButton>
      </MainTopic>
      <TestsTitle>
        <UseLocalization translateKey="Practice tests">
          Practice tests
        </UseLocalization>
      </TestsTitle>
      <TestsSubTitle>
        {`${currentSubject} topics`}
      </TestsSubTitle>
      <Topics backgroundImg={currentSubjectStyles.background.img}>
        {topicsItem.map((item: any) => (
          <StyledTopic
            key={item.key}
            icon={item.icon}
            backgroundHover={item.backgroundHover}
            onClick={() => { push(`${process.env.PUBLIC_URL}/dashboard/new-test/${item.urlKey}`); }}
            shadowColor={currentSubjectStyles.shadowColor}
          >
            <TextWrapper>
              <TopicName>{item.title}</TopicName>
              <DescriptionTopic>
                {description(item.topicAreasLength, item.subTopicsLength)}
              </DescriptionTopic>
            </TextWrapper>
            <StartButtonStyled onClick={(e) => { e.stopPropagation(); push(`${process.env.PUBLIC_URL}/challenge/start/test/${currentSubject}/${item.title}`); }}>
              <UseLocalization translateKey="action.startTest">
                Start
              </UseLocalization>
            </StartButtonStyled>
          </StyledTopic>
        ))}
      </Topics>
    </>
  );
};

export default TopicsList;

const MainTopic = styled(Topic)<{backgroundColor: string}>`
  max-width: 750px; 
  margin-bottom: 97px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: #FFF;
  cursor: inherit;
  transition: background-color .2s ease-out;
  :hover {
    color: #FFF;
  }
`;

const TestsTitle = styled.h3`
  max-width: 750px;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-transform: uppercase;
  margin-bottom: 39px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  ::before {
    content: '';
    display: inline-block;
    flex-grow: 1;
    box-sizing: border-box;
    border-top: 2px solid #E0E0E0;
    margin-right: 26px;
  }
  ::after {
    content: '';
    display: inline-block;
    flex-grow: 1;
    box-sizing: border-box;
    border-top: 2px solid #E0E0E0;
    margin-left: 26px;
  }
`;

const TextWrapper = styled.div`
  max-width: 380px;
  margin: 0 20px;
  flex-shrink: 0;
  margin-right: auto;
`;

const TitleTopic = styled.span`
  display: block;
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
`;

const TopicName = styled(TitleTopic)`
  font-size: 28px;
  line-height: 34px;
`;

const DescriptionTopic = styled.span`
  display: block;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  max-width: 275px;
`;

// const ContinueExam = styled.a`
//   width: 100%;
//   min-height: 120px;
//   margin-bottom: 34px;
//   background-color: #FFF;
//   box-sizing: border-box;
//   padding: 0 44px;
//   border: 5px solid #9077F5;
//   border-radius: 20px;
//   transition: .2s ease-in-out;
//   display: flex;
//   align-items: center;
//   font-size: 28px;
//   line-height: 0;
//   font-weight: bold;
//   color: inherit;
//   cursor: pointer;
//   :hover {
//     color: inherit;
//     background-color: #EEEAFF;
//   }
// `;

// const AttentionIcon = styled.div`
//   margin-right: 53px;
//   width: 70px;
//   height: 70px;
//   & svg {
//     fill: #9077F5;
//   }
// `;

const TestsSubTitle = styled.h4`
  max-width: 750px;
  width: 100%;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 17px;
  color: #9C9C9C;
  text-align: left;
`;

const StyledTopic = styled(Topic)<{shadowColor: string}>`
  max-width: 750px;
  box-shadow: 0px 2px 8px ${({ shadowColor }) => shadowColor};
  :not(:last-child) {
    margin-bottom: 30px;
  }
`;

const StartButtonStyled = styled(StartButton)`
  padding: 18px 20px;
`;

const Topics = styled.div<{ backgroundImg: string}>`
${({ backgroundImg }) => `
  width: 100%;
  padding: 40px;
  border-radius: 20px;
  background: url(${backgroundImg}) no-repeat;
  background-size: auto;
`}`;
