import { message } from 'antd';
import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components/macro';
import { request } from '../api';
import Ellements from '../Form';

const {
  Input, Button,
} = Ellements;

interface ModalContactUsProps {
  onClose: () => void
}

const sendMessage = (values: {name: string, email: string, message: string}, onHideModal: () => void) => {
  request({
    method: 'POST',
    url: 'feedback',
    data: {
      message: `Name: ${values.name}, email: ${values.email}, message: ${values.message}`,
    },
  }, [200])
  .then(() => {
    message.success('Message sended');
    onHideModal();
  })
  .catch(() => message.error('Message not sended'));
};

const ModalContactUs = ({
  onClose,
}: ModalContactUsProps) => {
  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        message: '',
      }}
      onSubmit={(values) => {
        sendMessage(values, onClose);
      }}
    >
      {({
        handleChange,
        handleSubmit,
        setFieldValue,
        values,
        errors,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Title>Contact Us</Title>
          <InputStyled
            type="text"
            name="name"
            placeholder="Your Name"
            onChange={handleChange}
            required
          />
          <InputStyled
            type="email"
            name="email"
            placeholder="Email Address"
            onChange={handleChange}
            required
          />
          <TextArea
            name="message"
            placeholder="Your message"
            onChange={handleChange}
            required
          />
          <ButtonStyled type="submit">Send</ButtonStyled>
        </Form>

      )}
    </Formik>
  );
};

export default ModalContactUs;

const Form = styled.form`
  display: block;
  padding: 48px 30px 36px;
  box-sizing: border-box;
  max-width: 420px;
`;

const Title = styled.h1`
  font-size: 28px;
  line-height: 34px;
  font-weight: bold;
  text-align: center;
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 188px;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  padding: 16px;

  ::placeholder {
    color: #9C9C9C;
  }
`;

const ButtonStyled = styled(Button)`
  margin-top: 28px;
`;

const InputStyled = styled(Input)`
  margin-bottom: 14px;
`;
