export interface AvatarsType {
  avatarId: string
  avatarImg: string
}

export const avatars: AvatarsType[] = [
  {
    avatarId: 'tiger',
    avatarImg: require('../images/Avatars/tiger.svg'),
  },
  {
    avatarId: 'fox',
    avatarImg: require('../images/Avatars/fox.svg'),
  },
  {
    avatarId: 'penguin',
    avatarImg: require('../images/Avatars/penguin.svg'),
  },
  {
    avatarId: 'koala',
    avatarImg: require('../images/Avatars/koala.svg'),
  },
  {
    avatarId: 'lion',
    avatarImg: require('../images/Avatars/lion.svg'),
  },
  {
    avatarId: 'bear',
    avatarImg: require('../images/Avatars/bear.svg'),
  },
  {
    avatarId: 'monkey',
    avatarImg: require('../images/Avatars/monkey.svg'),
  },
  {
    avatarId: 'elephant',
    avatarImg: require('../images/Avatars/elephant.svg'),
  },
  {
    avatarId: 'badger',
    avatarImg: require('../images/Avatars/badger.svg'),
  },
  {
    avatarId: 'owl',
    avatarImg: require('../images/Avatars/owl.svg'),
  },
  {
    avatarId: 'alpacas',
    avatarImg: require('../images/Avatars/alpacas.svg'),
  },
  {
    avatarId: 'ara',
    avatarImg: require('../images/Avatars/ara.svg'),
  },
  {
    avatarId: 'beaver',
    avatarImg: require('../images/Avatars/beaver.svg'),
  },
  {
    avatarId: 'bull',
    avatarImg: require('../images/Avatars/bull.svg'),
  },
  {
    avatarId: 'camel',
    avatarImg: require('../images/Avatars/camel.svg'),
  },
  {
    avatarId: 'cat',
    avatarImg: require('../images/Avatars/cat.svg'),
  },
  {
    avatarId: 'chicken',
    avatarImg: require('../images/Avatars/chicken.svg'),
  },
  {
    avatarId: 'cow',
    avatarImg: require('../images/Avatars/cow.svg'),
  },
  {
    avatarId: 'cwak',
    avatarImg: require('../images/Avatars/cwak.svg'),
  },
  {
    avatarId: 'dak',
    avatarImg: require('../images/Avatars/dak.svg'),
  },
  {
    avatarId: 'donkey',
    avatarImg: require('../images/Avatars/donkey.svg'),
  },
  {
    avatarId: 'drake',
    avatarImg: require('../images/Avatars/drake.svg'),
  },
  {
    avatarId: 'enot',
    avatarImg: require('../images/Avatars/enot.svg'),
  },
  {
    avatarId: 'fawn',
    avatarImg: require('../images/Avatars/fawn.svg'),
  },
  {
    avatarId: 'ferret',
    avatarImg: require('../images/Avatars/ferret.svg'),
  },
  {
    avatarId: 'fox2',
    avatarImg: require('../images/Avatars/fox2.svg'),
  },
  {
    avatarId: 'gektor',
    avatarImg: require('../images/Avatars/gektor.svg'),
  },
  {
    avatarId: 'giraffe',
    avatarImg: require('../images/Avatars/giraffe.svg'),
  },
  {
    avatarId: 'goat',
    avatarImg: require('../images/Avatars/goat.svg'),
  },
  {
    avatarId: 'hedgehog',
    avatarImg: require('../images/Avatars/hedgehog.svg'),
  },
  {
    avatarId: 'hippopo',
    avatarImg: require('../images/Avatars/hippopo.svg'),
  },
  {
    avatarId: 'horse',
    avatarImg: require('../images/Avatars/horse.svg'),
  },
  {
    avatarId: 'kutenok',
    avatarImg: require('../images/Avatars/kutenok.svg'),
  },
  {
    avatarId: 'lamb',
    avatarImg: require('../images/Avatars/lamb.svg'),
  },
  {
    avatarId: 'magik',
    avatarImg: require('../images/Avatars/magik.svg'),
  },
  {
    avatarId: 'mangost',
    avatarImg: require('../images/Avatars/mangost.svg'),
  },
  {
    avatarId: 'mous',
    avatarImg: require('../images/Avatars/mous.svg'),
  },
  {
    avatarId: 'otter1',
    avatarImg: require('../images/Avatars/otter1.svg'),
  },
  {
    avatarId: 'otter2',
    avatarImg: require('../images/Avatars/otter2.svg'),
  },
  {
    avatarId: 'otter3',
    avatarImg: require('../images/Avatars/otter3.svg'),
  },
  {
    avatarId: 'otter4',
    avatarImg: require('../images/Avatars/otter4.svg'),
  },
  {
    avatarId: 'otter5',
    avatarImg: require('../images/Avatars/otter5.svg'),
  },
  {
    avatarId: 'otter6',
    avatarImg: require('../images/Avatars/otter6.svg'),
  },
  {
    avatarId: 'otter7',
    avatarImg: require('../images/Avatars/otter7.svg'),
  },
  {
    avatarId: 'panda',
    avatarImg: require('../images/Avatars/panda.svg'),
  },
  {
    avatarId: 'pig',
    avatarImg: require('../images/Avatars/pig.svg'),
  },
  {
    avatarId: 'rabbit',
    avatarImg: require('../images/Avatars/rabbit.svg'),
  },
  {
    avatarId: 'raccoon',
    avatarImg: require('../images/Avatars/raccoon.svg'),
  },
  {
    avatarId: 'rino',
    avatarImg: require('../images/Avatars/rino.svg'),
  },
  {
    avatarId: 'sharpey',
    avatarImg: require('../images/Avatars/sharpey.svg'),
  },
  {
    avatarId: 'walrus',
    avatarImg: require('../images/Avatars/walrus.svg'),
  },
  {
    avatarId: 'zebra',
    avatarImg: require('../images/Avatars/zebra.svg'),
  },
];
