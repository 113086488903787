import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { request } from '../../api';
import { AppThunk, RootState } from '../../app/store';
import { createAxiosConfig } from '../../project-types/common/axios';
import { HTTP_METHODS } from '../../project-types/common/http-types';
import { ApiCheckGroupInvite, ApiJoinGroup } from '../../project-types/group-invites/api-types';


import { GroupInvite } from '../../project-types/group-invites/types';
import { loadProfile } from '../user/userState';


type stateType = {
  code: string | null
}

type setCurrentCodeType = {
  payload: stateType,
}

const initialValue: stateType = {
  code: null,
};

const groupInviteSlice = createSlice({
  name: 'group-invite',
  initialState: initialValue,
  reducers: {
    setCurrentCode: (state, action: setCurrentCodeType) => {
      return { ...state, ...action.payload };
    },
  },
});

export default groupInviteSlice;

export const checkCode = (values: { code: GroupInvite['code'] }): AppThunk => (dispatch) => {
  return request(createAxiosConfig<ApiCheckGroupInvite>({
    url: ['/group', '/invites/', values.code],
    method: HTTP_METHODS.GET,
  }))
    .then(() => {
      dispatch(groupInviteSlice.actions.setCurrentCode({ code: values.code }));
    })
    .catch((e) => {
      message.error('Wrong code');
    });
};


export const joinGroup = (values: { code: GroupInvite['code'] }): AppThunk => (dispatch) => {
  return request(createAxiosConfig<ApiJoinGroup>({
    url: ['/group', '/invites'],
    data: {
      code: values.code,
    },
    method: HTTP_METHODS.POST,
  }))
    .then(() => {
      dispatch(loadProfile());
    })
    .catch((e) => {
      message.error('Wrong code');
      dispatch(groupInviteSlice.actions.setCurrentCode({ code: null }));
    });
};

// export const loadTeacherGroups = (): AppThunk => (dispatch) => {
//   request(createAxiosConfig<ApiGroupList>({
//     url: ['/group'],
//     method: HTTP_METHODS.GET,
//   })).then((responce: {data: ApiGroupList['successResponse']}) => {
//     dispatch(setTeacherGroups(responce.data.groupsList));
//   }).catch(console.error);
// };

export const getCurrentCode = (state: RootState) => state.groupInvite;
