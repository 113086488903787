import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface UseLocalizationProps {
  children: ReactNode
  translateKey: string
}

const UseLocalization = ({
  children,
  translateKey,
}: UseLocalizationProps) => {
  const { t } = useTranslation();

  const defaultText = typeof children === 'string' ? children : undefined;

  return (
    <>{t(translateKey, defaultText)}</>
  );
};

export default UseLocalization;
