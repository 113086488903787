import React, {
  useRef,
  useEffect,
  useLayoutEffect,
  useCallback,
  useState,
} from "react";
import { select, selectAll } from "d3-selection";
import { axisBottom, axisLeft } from "d3-axis";
import { transition as d3Transition } from "d3-transition";
import { color } from "d3-color";

import { ChartType, ChartConfigType } from "./types";

import "./styles.css";

import { arc as createArc, pie } from "d3-shape";

select.prototype.transition = d3Transition;

interface ChartProps {
  width: number;
  height: number;
  value: number;
}

const createConfig = (elWidth: number, elHeight: number) => {
  const margin = { top: 0, right: 0, bottom: 0, left: 0 };
  const width = elWidth - margin.left - margin.right;
  const height = elHeight - margin.top - margin.bottom;

  const ChartConfig: ChartConfigType = {
    size: {
      width,
      height,
    },
    margins: margin,
  };

  return ChartConfig;
};

function useChartConfig(elWidth: number, elHeight: number) {
  const [config, setConfig] = useState<ChartConfigType>(
    createConfig(elWidth, elHeight)
  );

  // const configRef = useRef<ChartConfigType>(createConfig(elWidth, elHeight));

  return config;
}

const colorsConfig: { [key: string]: string } = {
  50: "FC7E6D",
  70: "FEAC60",
  100: "9077F5",
};

const Chart = ({ width, height, value }: ChartProps) => {
  const ChartConfig = useChartConfig(width, height);

  const svgRef = useRef<SVGSVGElement | null>(null);
  const chartRef = useRef<ChartType | null>();

  useLayoutEffect(() => {
    const {
      size: { width, height },
      margins,
    } = ChartConfig;

    const svg = select(svgRef.current)
      .attr("width", width + margins.left + margins.right)
      .attr("height", height + margins.top + margins.bottom);

    const chart = svg
      .append("g")
      .attr("class", "chart")
      .attr("transform", "translate(" + margins.left + "," + margins.top + ")")
      .append("g")
      .attr("class", "pie")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    chartRef.current = chart;

    console.log(svgRef.current);
  }, [ChartConfig]);

  useLayoutEffect(() => {
    const {
      size: { width, height },
    } = ChartConfig;

    if (chartRef.current) {
      const chart = chartRef.current;

      if (chart) {
        const data = [100 - value, value];
        const outerR = width / 2;

        const arc = createArc()
          .innerRadius(outerR - 10)
          .outerRadius(outerR)
          .cornerRadius(0);

        const arcs = pie().sort((d) => {
          return d === value ? -1 : 1;
        })(data);

        const velueColor = Object.keys(colorsConfig).reduceRight((color, key: string) => {
          return (value < Number(key)) ? colorsConfig[key] : color
        }, '');

        const colors = ["F2F2F2", velueColor];

        const arcsUpdate = chart
          .selectAll<SVGPathElement, unknown>(".pie-patch")
          .data(arcs);

        arcsUpdate
          .enter()
          .append("path")
          .attr("class", "pie-patch")
          .merge(arcsUpdate)
          .style("fill", (d: { value: number }, n: number) => {
            return `#${colors[n]}`;
          })
          .attr("d", (d: any) => {
            return `${arc(d)}`;
          });

        

        const textUpdate = chart
          .selectAll<SVGTextElement, unknown>(".pie-value")
          .data([data]);

        textUpdate
          .enter()
          .append("text")
          .attr("class", "pie-value")
          .merge(textUpdate)
          .attr("y", "6px")

          .text(() => `${value}%`);
      }
    }
  }, [ChartConfig, value]);

  return <svg ref={svgRef}></svg>;
};

export default Chart;
