import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import Elements from '../../Form';
import LabelForInput from '../../Form/FieldsEntity/LabelForInput';
import { SchoolType } from '../../features/user/types';
import { createSchool, editSchool, useGetSchool } from '../hooks/schoolsFeatures';

const {
  Input, Button,
} = Elements;

interface ErrorsType {
  name?: string
  rate?: string
}

const SchoolEdit = () => {
  const { action } = useParams<{action: string}>();
  const defaultSchool = { _id: '', name: '', rate: 0 };

  const isNewSchool = action === 'new-school';

  const currentSchool = useGetSchool(action);

  const [initialValues, setInitialValues] = useState(defaultSchool);

  useEffect(() => {
    if (!isNewSchool) {
      setInitialValues(currentSchool);
    }
  });

  return (
    <>
      {(isNewSchool || initialValues._id) && (
        <Formik
          initialValues={initialValues}
          validate={(values: SchoolType) => {
            const errors: ErrorsType = {};
            if (!values.name) {
              errors.name = 'Required';
            }
            if (!values.rate) {
              errors.rate = 'Required';
            }

            return errors;
          }}
          onSubmit={
            (values) => {
              if (isNewSchool) {
                createSchool(values);
              } else {
                editSchool(values);
              }
            }
          }
        >
          {({
            values,
            errors,
            handleSubmit,
            handleReset,
            handleChange,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Title>{isNewSchool ? 'Add school' : 'Edit school'}</Title>
              <Label name="School name">
                <Input placeholder="Enter school name" name="name" value={values.name} onChange={handleChange} required />
              </Label>
              <Label name="School rate">
                <Input placeholder="Enter school rate" type="number" name="rate" max={100} min={0} value={values.rate} onChange={handleChange} required />
              </Label>
              <ButtonWrapper>
                <CancelButton type="reset" onClick={() => window.history.back()}>Cancel</CancelButton>
                <SaveButton type="submit" disabled={!values.name || !values.rate}>{isNewSchool ? 'Add' : 'Save'}</SaveButton>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default SchoolEdit;

const Form = styled.form``;

const Title = styled.h2`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 12px;
`;

const Label = styled(LabelForInput)`
  margin-bottom: 24px;
  max-width: 320px;
`;

const ButtonWrapper = styled.div`
  width: fit-content;
  display: flex;
  margin-right: auto;
  margin-top: 60px;
`;

const SaveButton = styled(Button)`
  min-width: 128px;
  width: auto;
  height: auto;
  padding: 13px 25px;
  border: 3px solid transparent;
  color: #FFF;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  background-color: #9077F5;
  margin-left: 20px;
`;

const CancelButton = styled(SaveButton)`
  margin-left: 0;
  background-color: #FFF;
  border-color: #9C9C9C;
  color: #9C9C9C;
  :hover:not(:disabled) {
    background-color: #FFF;
    border-color: #F87700;
    color: #F87700;
  }
`;
