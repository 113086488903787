import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import SchoolsTable from './SchoolsTable';
import { request } from '../../api';
import { SchoolType } from '../../features/user/types';

const SchoolsList = () => {
  const [schoolsList, setSchoolsList] = useState<SchoolType[] | undefined>([]);
  const [schoolsCount, setSchoolsCount] = useState(0);

  const getSchoolsList = (start: number, offset: number) => {
    request(
      {
        method: 'GET',
        url: `admin/school/school-list/${start}/${offset}`,
      }, [200],
    )
      .then(({ data }) => {
        setSchoolsList(data.schools);
        setSchoolsCount(data.count);
        console.log(data);
      });
  };

  useEffect(() => {
    getSchoolsList(0, 10);
  }, []);

  const deleteSchool = (_id: string) => {
    request(
      {
        method: 'DELETE',
        url: `admin/school/${_id}`,
      },
      [200],
    )
      .then(() => {
        message.success('School deleted');
        getSchoolsList(0, 10);
      });
  };

  const getNextOrPreviousSchoolsList = (tablePage: number, pageSize: number | undefined) => {
    const offset = pageSize || 10;
    const start = (tablePage - 1) * offset;

    console.log(offset, start);

    getSchoolsList(start, offset);
  };

  return (
    <SchoolsTable
      schoolsList={schoolsList}
      deleteSchool={deleteSchool}
      schoolsCount={schoolsCount}
      updateSchoolsList={getNextOrPreviousSchoolsList}
    />
  );
};

export default SchoolsList;
