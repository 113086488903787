import React from 'react';
import styled from 'styled-components/macro';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { PurpleButton } from '../Features/Buttons';

import './antdModalStyles.css';

const { confirm } = Modal;
export interface showConfirmProps {
  onAgree: () => void
  onCancel: () => void
  description: string
  title: string
}

const warningModal = ({
  onAgree,
  onCancel,
  description,
  title,
}: showConfirmProps) => {
  confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content: description,
    okText: 'Delete',
    okType: 'danger',
    className: 'antd-confirm-warning-modal',
    onOk() {
      onAgree();
    },
    onCancel() {
      onCancel();
    },
  });
};

export default warningModal;
