export const ROUTES = [
  {
    link: `${process.env.PUBLIC_URL}/users`,
    title: 'Users',
  },
  {
    link: `${process.env.PUBLIC_URL}/schools`,
    title: 'Schools',
  },
  {
    link: `${process.env.PUBLIC_URL}/admin`,
    title: 'Admin',
  },
];
