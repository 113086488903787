import React from 'react';
import styled from 'styled-components/macro';
import { UserProfileType } from '../../../features/user/types';
import { GroupFields, GroupOutType } from '../../../project-types/groups/types';
import { UserPublicType } from '../../../project-types/users/types';
import AssigmentFilterItem, { FilterType } from './AssigmentFilterItem';
import StudentsTable from './StudentsTable';

interface GroupRoasterBodyProps {
  group: GroupOutType
  onAddStudent: () => void
  onDelete: (users: UserPublicType[]) => void
}

const GroupRoasterBody = ({ group, onAddStudent, onDelete }: GroupRoasterBodyProps) => {
  return (
    <Body>
      <AddStudent onClick={onAddStudent}>+ Add students</AddStudent>
      {/* <div>Teacher group</div> */}
      <StudentsTable students={group.students} onDelete={onDelete} />
    </Body>
  );
};

export default GroupRoasterBody;

const AddStudent = styled.div`
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  color: #9077F5;
  margin-bottom: 12px;
`;

const Body = styled.div`
`;
