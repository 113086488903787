import React, { useState, useCallback, useEffect } from 'react';
import { Table, Tabs } from 'antd';
import { ColumnProps } from 'antd/es/table';
import styled from 'styled-components';
import { Button, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import UserProfileEdit from './UserProfileEdit';
import PastChalenges from './PastChalenges';
import GraphBoard from './GraphBoard';
import TopicProgress from './TopicProgress';
import { request } from '../../api';
import { SchoolType, UserProfileBaseType, UserProfileType } from '../../features/user/types';
import ChangeUserTariff from './ChangeUserTariff';
import SendMail, { MailValuesType } from './SendMail';
import SuperVisorCode from './SuperVisorCode';
import { getUserProfile } from '../../features/user/userState';
import UserNote from './UserNote';
import TeacherComment from './TeacherComment';

const { TabPane } = Tabs;

// const schoolsList = [
//   {
//     _id: '1',
//     name: "St Paul's Girls' School",
//     rate: 90,
//   },
//   {
//     _id: '2',
//     name: 'Hampton high',
//     rate: 50,
//   },
//   {
//     _id: '3',
//     name: 'Caterham High School',
//     rate: 30,
//   },
// ];

export const sendMail = (values: MailValuesType) => {
  request(
    {
      method: 'POST',
      url: 'admin/send-email',
      data: values,
    }, [200],
  )

    .then(() => message.info('Mail sended'))
    .catch(() => message.error('Mail not sended'));
};

const UserEdit = () => {
  const { id } = useParams<{id: string}>();

  const [user, setUser] = useState<UserProfileType | undefined>();
  const [schoolsList, setSchoolsList] = useState<SchoolType[]>([]);
  const [schoolsCount, setSchoolsCount] = useState(10);

  const getUser = () => {
    request(
      {
        method: 'GET',
        url: `admin/user/${id}`,
        data: {
        },
      },
      [200],
    ).then(({ data }) => {
      const userData: UserProfileType = data.user;
      setUser(userData);
    
    }).catch(e => {
    if (e.response.status === 400) {
      message.error('Access denied')
    }
  })
  };

  useEffect(() => {
    getUser();
  }, [id, setUser]);

  const updateUser = (userData: UserProfileBaseType) => {
    request(
      {
        method: 'PATCH',
        url: 'admin/edit-user',
        data: {
          ...userData,
          userId: id,
        },
      },
      [200],
    ).then(({ data }) => {
      message.info('User is updated');
    })
    .catch(e => {
      if(e.response.data) {
        const { data } = e.response;
        const err = Object.keys(data).reduce((acc: string, fieldName: string) => {
          return `${acc} ${fieldName}: ${data[fieldName].join(',')};`;
        }, '');
        message.error(err);
      } else {
        message.error('save error');
      }
    });
  };

  const getSchoolsList = (start: number, offset: number) => {
    request(
      {
        method: 'GET',
        url: `admin/school/school-list/${start}/${offset}`,
      }, [200],
    )
      .then(({ data }) => {
        setSchoolsList(data.schools);
        setSchoolsCount(data.count);
        console.log(data);
      });
  };

  useEffect(() => {
    getSchoolsList(0, schoolsCount);
  }, [schoolsCount]);

  const userProfile: UserProfileBaseType = useSelector(getUserProfile);

  const canGiveSupervisorCode = userProfile.role && ['CEO', 'SUPER_ADMIN'].includes(userProfile.role);

  return (
    <Tabs defaultActiveKey="1">
      {user?._id && (
        <TabPane tab="Edit Profile" key="1">
          <UserProfileEdit user={user} userId={id} schoolsList={schoolsList} onSubmit={updateUser} />
        </TabPane>
      )}
      {user?.role === 'USER' && (
        <>
          <TabPane tab="Past chalenges" key="2">
            <PastChalenges id={id} />
          </TabPane>
          <TabPane tab="Progess" key="3">
            <Tabs defaultActiveKey="31">
              <TabPane tab="Graph" key="31">
                <GraphBoard schoolsList={schoolsList} />
              </TabPane>
              <TabPane tab="Topic" key="32">
                <TopicProgress />
              </TabPane>
            </Tabs>
          </TabPane>
          <TabPane tab="Teacher comment" key="4">
            <TeacherComment
              onUpdateUser={updateUser}
              value={user.teacherComment}
            />
          </TabPane>
          <TabPane tab="Send mail" key="5">
            <SendMail />
          </TabPane>
          <TabPane tab="Tariff" key="6">
            <ChangeUserTariff userId={id} currentPriceId={user?.priceId} getUser={getUser} />
          </TabPane>
        </>
      )}
      {user?._id && (
        <TabPane tab="Note" key="7">
          <UserNote user={user} onUpdate={updateUser} />
        </TabPane>
      )}
      {(user?.role === 'ADMIN' && canGiveSupervisorCode) && (
        <TabPane tab="Supervisor code" key="8">
          <SuperVisorCode userId={id} />
        </TabPane>
      )}
    </Tabs>
  );
};

export default UserEdit;