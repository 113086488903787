/* eslint-disable no-unused-vars */
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';


import game from '../features/game/gameState';
import user from '../features/user/userState';
import challenge from '../features/challenge/challengeState';
import topic from '../features/topic/topicState';
import teacherSlice from '../features/teacher/teacherState';
import groupInviteSlice from '../features/invite-code/inviteState';

export const store = configureStore({
  reducer: {
    game,
    user,
    challenge,
    topic,
    teacher: teacherSlice.reducer,
    groupInvite: groupInviteSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
