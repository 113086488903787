import React, { useState } from 'react';
import styled from 'styled-components/macro';
import FormElements from '../../Form';
import Accordion from '../../Form/FieldsEntity/Accordion';
import { listTopicsTypes } from './PastPracticeTests';

const {
  Button,
} = FormElements;

interface listPasedTestsTypes {
  id: string
  key: number
  topicName: string
  date: string
  elapsedTime: number
  result: number
}

interface PracticeTestCardProps {
  topicItems: listTopicsTypes
  onShowDetailResult: (id: string) => void
}

const PracticeTestCard = ({
  topicItems,
  onShowDetailResult,
}: PracticeTestCardProps) => {
  const [numberOfResultsShown, setNumberOfResultsShown] = useState<number>(3);

  const itemsResults = topicItems.topics.slice(0, numberOfResultsShown);

  return (
    <Accordion title={<Title>{topicItems.name}</Title>}>
      <>
        <Table>
          <thead>
            <TR>
              <TH>Test</TH>
              <TH>Completion time</TH>
              <TH>Results</TH>
            </TR>
          </thead>
          <tbody>
            {itemsResults.map((item: listPasedTestsTypes) => (
              <TR key={item.key} onClick={() => onShowDetailResult(item.id)}>
                <TD>
                  <TopicName>{item.topicName}</TopicName>
                  <DatePassage>{new Date(item.date).toLocaleDateString()}</DatePassage>
                </TD>
                <TD>
                  <ElapsedTime>
                    {item.elapsedTime}
                    {' '}
                    minutes
                  </ElapsedTime>
                </TD>
                <TD>
                  <TestResult>
                    {item.result}
                    {' '}
                    %
                  </TestResult>
                </TD>
              </TR>
            ))}
          </tbody>
        </Table>
        <ButtonWrapper>
          <ShowMoreButton
            disabled={numberOfResultsShown === topicItems.topics.length}
            onClick={() => setNumberOfResultsShown(numberOfResultsShown + 3)}
          >
            Show more
          </ShowMoreButton>
          <HideButton
            disabled={numberOfResultsShown <= 3}
            onClick={() => setNumberOfResultsShown(3)}
          >
            Hide
          </HideButton>
        </ButtonWrapper>
      </>
    </Accordion>
  );
};

export default PracticeTestCard;

const Title = styled.h3`
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 0;
`;

const Table = styled.table`
  border-collapse: inherit;
  border-spacing: 0 40px;
  width: 100%;
  margin: 60px 0 40px;
`;

const TR = styled.tr`
  cursor: pointer;
`;

const TD = styled.td`
  vertical-align: top;
  padding-bottom: 8px;
  border-bottom: 1px solid #E0E0E0;
  :nth-child(2) {
    text-align: center;
  }
  :last-child {
    text-align: right;
  }
`;

const TH = styled.th`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  :first-child {
    width: 55%;
    text-align: left;
  }
  :last-child {
    text-align: right;
    width: 20%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  width: fit-content;
`;

const ShowMoreButton = styled(Button)`
  width: auto;
  height: auto;
  padding: 15px 40px;
  font-size: 22px;
  line-height: 26px;
  :hover:not(:disabled) {
    background-color: #FF912C;
  }
`;

const HideButton = styled(ShowMoreButton)`
  margin-left: 15px;
  padding: 12px 50px;
  background-color: #FFF;
  :not(:disabled) {
    border: 3px solid #F87700;
    color: #F87700;
  }
  :hover:not(:disabled) {
    color: #FFF;
    border-color: transparent;
  }
`;

const TopicName = styled.span`
  display: block;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
`;

const DatePassage = styled.span`
  display: block;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;

const ElapsedTime = styled(TopicName)`
  font-weight: normal;
  font-size: 20px;
`;

const TestResult = styled(TopicName)``;
