import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import FormElements from '../Form';
import Page from './PageForExam';
import { useCurrentChalenge } from './ChalengeHooks';
import colorStyle from '../colorStyle';
import { flushChanlenge } from '../features/challenge/challengeState';
import StandartResultInfo from './StandartResultInfo';
import SummaryResultExam from './SummaryResultExam';
import Layout from '../Layout';
import Footer from '../Layout/Footer/Footer';

const {
  LinkButton,
} = FormElements;

const ExamResultInfoContent = () => {
  const { challengeId, challengeType } = useParams<{challengeId: string, challengeType: keyof typeof colorStyle}>();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(flushChanlenge());
    };
  }, []);

  const currentChallenge = useCurrentChalenge(challengeId);

  // questionNumber
  const currentQuestionNum = currentChallenge.questionsNumber || 20;
  // const currentQuestionNum = 20;

  /* useSelector(selectCurrentChallenge);

  useEffect(() => {
    if(!currentChallenge.topicId) {
      dispatch(getChallengeById(challengeId))
    }
  }, [currentChallenge]) */

  const history = useHistory();


  const goToDashBorad = () => {
    dispatch(flushChanlenge());
    history.push(`${process.env.PUBLIC_URL}/dashboard/new-test`);
  };

  const titleOfResultInfo = currentChallenge.topicId?.[2]
                            || currentChallenge.topicId?.[1]
                            || currentChallenge.topicId?.[0];

  return (
    <ThemeProvider theme={colorStyle[challengeType].theme}>
      <Page hideLogo>
        {challengeType === 'exam' ? (
          <SummaryResultExam
            title={titleOfResultInfo}
            currentChallenge={currentChallenge}
          />
        ) : (
          <StandartResultInfo
            title={titleOfResultInfo}
            challengeType={challengeType}
            currentChallenge={currentChallenge}
            currentQuestionNum={currentQuestionNum}
          />
        )}
        <ButtonsWrapper challengeType={challengeType}>
          <LinkToDashboard
            background
            to="#"
            onClick={goToDashBorad}
          >
            Go to dashboard
          </LinkToDashboard>
          <LinkViewQuestions
            background
            to={`${process.env.PUBLIC_URL}/chalenge/correct-answers/${challengeType}/${challengeId}/1`}
          >
            View questions
          </LinkViewQuestions>
        </ButtonsWrapper>
      </Page>
    </ThemeProvider>
  );
};

const ExamResultInfo = () => {
  const { challengeType } = useParams<{challengeType: string}>();

  if (challengeType === 'exam') {
    return (
      <Layout>
        <ExamResultInfoContent />
        <Footer />
      </Layout>
    );
  };

  return <ExamResultInfoContent />;
};

export default ExamResultInfo;

const ButtonsWrapper = styled.div<{challengeType?: string}>`
  display: flex;
  height: 74px;
  margin: 0 24px 84px 60px;
  width: fit-content;
  ${({ challengeType }) => challengeType === 'exam' && 'margin-left: auto;'}
`;

const LinkToDashboard = styled(LinkButton)`
  padding: 20px 48px;
  margin-right: 14px;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.color};
  :hover {
    background-color: ${({ theme }) => theme.hoverColor};
  }
`;

const LinkViewQuestions = styled(LinkToDashboard)`
  background-color: #FFF;
  color: ${({ theme }) => theme.color};
  border: 3px solid ${({ theme }) => theme.color};
  :hover {
    border-color: ${({ theme }) => theme.hoverColor};
  }
`;
