import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteStudentFromAllGroup, getTeacherStudents, loadTeacherStudents } from '../../features/teacher/teacherState';
import { UserProfileType } from '../../features/user/types';
import { SIDEBAR_MENU } from '../../Form/FieldsEntity/Sidebar';
import { onCopyValueInBuffer } from '../../hooks/copyValue';
import { UserPublicType } from '../../project-types/users/types';
import ModalInPortal from '../../utils/ModalPortal';
import ModalConfirmRemove from '../ModalConfirmRemove';
import ModalContentForShareIviteCode, { LinkInModalShareCode } from '../ModalContentForShareIviteCode';
import TeacherDashboard from '../TeacherDashboard';
import TeacherStudentsLayout from './TeacherStudentsLayout';

const confModalShareCode = {
  title: 'Invite your students',
  description: <>
    Have your students visit
    {' '}
    <LinkInModalShareCode href={`${process.env.PUBLIC_URL}/join`} target="_blank" rel="noreferrer">www.ottereducation.com/join</LinkInModalShareCode>
    {' '}
    and enter the code
  </>,
  subDescription: 'Before inviting students, please note that Otter Education assumes you have received parent permission (or meet an exception from parent consent requirements) for any students you add to your study group.',
};

const TeacherStudents = () => {
  const {
    ModalRender,
    onClose,
    onShow,
  } = ModalInPortal();

  const RemovStudentModal = ModalInPortal();

  const onCopyCode = () => {
    onCopyValueInBuffer('Invite code');
  };

  // const studentsLength = 0;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadTeacherStudents());
  }, []);

  const teacherStudents = useSelector(getTeacherStudents);
  const [studentIdForRemove, setStudentIdForRemove] = useState<UserPublicType['_id'] | null>(null);

  const removeStudent = () => {
    if (studentIdForRemove) {
      dispatch(deleteStudentFromAllGroup(studentIdForRemove));
      RemovStudentModal.onClose();
    }
  };

  return (
    <TeacherDashboard sidebarMenuSelected={SIDEBAR_MENU.MY_STUDENTS}>
      <>
        {ModalRender && (
          <ModalRender>
            <ModalContentForShareIviteCode
              onClickDone={onClose}
              onCopyCode={onCopyCode}
              confModal={confModalShareCode}
            />
          </ModalRender>
        )}
        {RemovStudentModal.ModalRender && (
          <RemovStudentModal.ModalRender>
            <ModalConfirmRemove
              title="Delete student"
              description={`You are about to delete the selected student. Please note that
              the student data cannot be restored once deleted.
              Are you sure you want to proceed`}
              onClickDone={removeStudent}
              onClickCancel={RemovStudentModal.onClose}
            />
          </RemovStudentModal.ModalRender>
        )}
        <TeacherStudentsLayout
          students={teacherStudents}
          onAddNewStudents={onShow}
          studentsLength={teacherStudents.length}
          onRemoveStudents={(id: UserPublicType['_id']) => {
            RemovStudentModal.onShow();
            setStudentIdForRemove(id);
          }}
        />
      </>
    </TeacherDashboard>
  );
};

export default TeacherStudents;
