import PasswordValidator from 'password-validator';

const schema = new PasswordValidator();

schema
  .is().min(6)
  .is().max(100)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .not()
  .spaces();

interface errors {
  digits: string
  min: string
  max: string
  uppercase: string
  lowercase: string
}

type K = keyof errors;

type E = errors[K]

const errorsMessage: {[x: string]: E} = {
  digits: 'Min 2 digits',
  min: 'Min length 6',
  max: 'Max length 100',
  uppercase: 'Uppercase',
  lowercase: 'Lowercase',
};

const createErrorsList = (passwordErrors: string[]) => {
  return passwordErrors.map((error: string) => {
    return errorsMessage[error];
  });
};

export const passwordVerification = (password: string) => {
  const isValid = schema.validate(password, { list: true });

  return createErrorsList(isValid);
};
