import { createSlice } from '@reduxjs/toolkit';

import { combineReducers } from 'redux';
import { AxiosResponse } from 'axios';
import { message } from 'antd';
import { ChallengeType } from '../challenge/types';

import { AppThunk, RootState } from '../../app/store';
import { request, setBearerToken, removeToken } from '../../api';

import { LogInType, UserProfileType, RecoveryPasswordType } from './types';

import creteForm from '../../utils/createForm';
import { ApiGetProfile } from '../../project-types/users/api-types';
import { joinGroup } from '../invite-code/inviteState';

const initialProfileState: UserProfileType = {
  _id: null,
};

export const userProfile = createSlice({
  name: 'UserProfile',
  initialState: initialProfileState,
  reducers: {
    setCurrentProfile: (state, action: { payload: UserProfileType }) => ({ ...action.payload }),
  },
});

const reducerForm = creteForm('SignUpForm22');

const profileForm = creteForm('ProfileFormPage');


export default combineReducers({
  profile: userProfile.reducer,
  signUpForm: reducerForm.reducer,
  profileForm: profileForm.reducer,
});

export const { setCurrentProfile } = userProfile.actions;
export const signUpActions = reducerForm.actions;
export const profileFormActions = profileForm.actions;


export const updateProfile = (profile: UserProfileType): AppThunk => (dispatch) => {
  request({
    method: 'PATCH',
    url: 'user',
    data: profile,
  }, [200]).then((response) => {
    dispatch(setCurrentProfile(response.data));
    dispatch(profileForm.actions.flushErrors());
    message.info('Profile updated');
    if (profile.email) {
      message.success(`We've sent you a link to ${profile.email} to confirm the email change`);
    }
  })
    .catch((error) => {
      dispatch(profileForm.actions.setFieldsErrors(error.response.data));
      console.log(error.data);
    });
};

export const recoveryPassword = (values: RecoveryPasswordType): AppThunk => (dispatch) => {
  request({
    method: 'POST',
    url: 'restore-pass/',
    data: values,
  }, [200]).then(() => {
    window.location.href = `${process.env.PUBLIC_URL}/letter-sent`;
  }).catch(() => {
    message.error('Unregistered email addres');
  });
};


export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_ADMIN = 'LOGIN_ADMIN';

const loginUrls: { [key: string]: string } = {
  [LOGIN_USER]: 'auth',
  [LOGIN_ADMIN]: 'admin/auth',
};

// export const PROFILE_URLS = {
//   USER: 'user',
//   ADMIN: 'admin/current',
// };

// export const loadProfile = (profileUrl = PROFILE_URLS.USER): AppThunk => (dispatch) => {
//   request({
//     method: 'GET',
//     url: profileUrl,
//   }, [200]).then((e) => {
//     if (e.data) {
//       // const userData = profileUrl === 'user' ? e.data : e.data.user;

//       dispatch(setCurrentProfile(e.data.user));
//       if (e.data.superpower) {
//         message.info('You have superpower');
//       }
//     }
//   })
//     .catch((error) => {
//       if (error?.response?.status !== 403) {
//         message.error(error?.response?.data);
//       }
//     });
// };

export const PROFILE_URLS = {
  USER: 'user',
  ADMIN: 'admin/current',
};

export const loadProfile = (profileUrl = PROFILE_URLS.USER): AppThunk => (dispatch) => {
  request({
    method: 'GET',
    url: profileUrl,
  }, [200]).then((e) => {
    if (e.data) {
      const userData = profileUrl === 'user' ? e.data : e.data.user;
      dispatch(setCurrentProfile(e.data.user));
      if (e.data.superpower) {
        message.info('You have superpower');
      }
    }
  })
    .catch((error) => {
      if (error?.response?.status !== 403) {
        message.error(error?.response?.data);
      }
    });
};

export const logIn = (
  loginData: LogInType,
  loginType: string = LOGIN_USER,
): AppThunk => (dispatch, state) => {
  const url = loginUrls[`${loginType}`];

  request({
    method: 'POST',
    url,
    data: {
      ...loginData,
    },
  }, [200, 401]).then((e) => {
    if (e.response && e.response.status === 401) {
      message.error(e.response.data);
      return;
    }

    if (e.data.token) {
      setBearerToken(e.data.token);
      const inviteCode = state().groupInvite.code;
      if (inviteCode) {
        dispatch(joinGroup({ code: inviteCode }));
      } else {
        dispatch(loadProfile());
      }
    }
  })
    .catch((error) => {
      message.error(error.response?.data);
    });
};

export const logOut = (): AppThunk => (dispatch) => {
  dispatch(setCurrentProfile(initialProfileState));
  removeToken();
  window.location.href = `${process.env.PUBLIC_URL}/login`;
};


export const signUp = (userData: any, linkToUserVerify: () => void): AppThunk => (dispatch, state) => {
  const inviteCode = state().groupInvite.code;
  request({
    method: 'POST',
    url: 'user',
    data: {
      ...userData,
      code: inviteCode ? inviteCode : undefined,
    },
  }, [200, 401]).then((response) => {
    if (response) {
      linkToUserVerify();

      // setBearerToken(response.data.token);
      // dispatch(setCurrentProfile({ _id: response.data.token }));
      // console.log('response-', response.data);
      // dispatch(loadQuestions());
    }
  })
    .catch((error) => {
      dispatch(signUpActions.setFieldsErrors(error.response.data));
    });
};

export const addSubscription = (paymentMethodId: string | undefined | null, priceId: string, system: string, redirect: boolean = true): AppThunk => (dispatch) => {
  request(
    {
      method: 'POST',
      url: 'user-subscription',
      data: system === 'paypal' ? {
        system,
        priceId: paymentMethodId,
        subscriptionId: priceId,
      } : {
        system,
        paymentMethodId,
        priceId,
      },
    }, [200],
  )
    .then(() => {
      message.success('Subscription added');
      dispatch(loadProfile());
      if (redirect) {
        window.location.href = `${process.env.PUBLIC_URL}/sign-up-steps/3/finish-step`;
      }
    })
    .catch(() => message.error('Payment declined'));
};

export const changeTariff = (priceId: string, system: string, paymentMethodId?: string, ppSubscribeId?: string): AppThunk => (dispatch) => {
  request(
    {
      method: 'POST',
      url: 'change-subscription',
      data: {
        priceId,
        paymentMethodId,
        ppSubscribeId,
        system,
      },
    }, [200],
  )
    .then(() => {
      message.success('Tariff changed');
      dispatch(loadProfile());
    })
    .catch(() => message.error('Tariff not changed'));
};

export const unsubscribeUser = (feedbackMessage: string): AppThunk => (dispatch) => {
  request({
    method: 'POST',
    url: 'unsubscribe',
    data: {
      message: feedbackMessage,
    },
  }, [200])
    .then(({ data }) => {
      message.success(data.message);
      dispatch(loadProfile());
    })
    .catch(() => message.error('Unsubscribe error'));
};

export const getProfileFieldErrors = (state: RootState) => state.user.profileForm.errors;
export const getUserProfile = (state: RootState) => state.user.profile;
export const getSignUpFormFields = (state: RootState) => state.user.signUpForm;
// export const selecActiveQustion = (state: RootState) => state.questions.gameState;
