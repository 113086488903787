import EmailValidator from 'email-validator';
import { ErrorsType } from '../utils/createForm';


export const emailValidate = (value: any) => {
  const errors: ErrorsType = {};

  if (!value.email) {
    errors.email = ['Required'];
  } else if (
    !EmailValidator.validate(value.email)
  ) {
    errors.email = ['Invalid email address'];
  }
  return errors;
};
