import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import FormElements from '../../Form';

const {
  Radio,
} = FormElements;

interface ChangeGenderProps {
  onChangeGender: (gender: string) => void
  value?: string
  className?: string
}

const ChangeGender = ({
  onChangeGender,
  value = '',
  className,
}: ChangeGenderProps) => {

  return (
    <RadioWrapper className={className}>
      <RadioStyled name="gender" checked={value === 'male'} onChange={() => onChangeGender('male')}>Male</RadioStyled>
      <RadioStyled name="gender" checked={value === 'female'} onChange={() => onChangeGender('female')}>Female</RadioStyled>
    </RadioWrapper>
  );
};

export default ChangeGender;


const RadioWrapper = styled.div`
  display: flex;
  margin-left: 40px;
  box-sizing: border-box;
  padding-top: 32px;
`;

export const RadioStyled = styled(Radio)`
  :not(:last-child) {
    margin-right: 20px;
  }

  & > span {
    font-size: 16px;
    font-weight: normal;
    :before {
      width: 25px;
      height: 25px;
    }
  }

  & > input:checked + span::before {
    background-color: #9077F5;
  }
`;
