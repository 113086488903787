import React from 'react';
import GroupCodeContainer from './GroupCodeContainer';
import './join-page.css';

const otterImage = require('../../images/otter-green.svg');
const logo = require('../../images/logo.svg');


export default function JoinPage() {
  return (
      <div>
        <div className="joinPage__container">
          <a href="#" className="joinPage__logo">
            <img src={logo} alt="logo" />
          </a>
          <h1 className="joinPage__title">
            Join your study group on&nbsp;
            <span className="accent">Otter</span>
          </h1>
          <div className="joinPage__content">
            <img src={otterImage} alt="Otter Iamge" className="joinPage__image" />
            <GroupCodeContainer />
          </div>
        </div>
      </div>
  )
};
