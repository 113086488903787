import React from 'react';
import styled from 'styled-components/macro';
import FormElements from '../Form';
import TopicCard from './TopicCard';
import ProgressBar from '../Form/FieldsEntity/ProgressBar';
import { SubTopicsTypes, TopicAreasTypes } from './type';
import { useLoadTopicStats } from './hooksTopic';

const {
  ArrowButton,
} = FormElements;

interface SubTopicsBoardProps {
  onClick?: () => void
  topicAreas: any
  subTopics: any
  onPreviousAreas?: () => void
  onNextAreas?: () => void
  currentAreas?: number
  lengthAreas?: number
  avgs?: any
}

const SubTopicsBoard = ({
  onClick,
  topicAreas,
  subTopics,
  onPreviousAreas,
  onNextAreas,
  currentAreas,
  lengthAreas,
  avgs,
}: SubTopicsBoardProps) => {

  return (
    <Board>
      <TitleWrapper>
        <Title>Subtopics</Title>
        <ScrollSelectSubtopic>
          <ArrowButtonLeft onClick={onPreviousAreas} />
          <NumberSubtopic>{currentAreas} / {lengthAreas}</NumberSubtopic>
          <ArrowButtonRight onClick={onNextAreas} />
        </ScrollSelectSubtopic>
      </TitleWrapper>
      <SubTopicContent>
        <SubTopicCardStyled value={avgs[topicAreas?.name] || 0} widthChart={147} heightChart={147} title={topicAreas?.name} />
        <ProgressWrapper>
          {subTopics.map((item: any, n: number) => (
            <ProgressBar key={n} valueProgress={avgs[item.name] || 0} topic={item?.name} />
          ))}
        </ProgressWrapper>
      </SubTopicContent>
    </Board>
  );
};

export default SubTopicsBoard;

const Board = styled.div`
  background-color: #FFF;
  border-radius: 12px;
  box-shadow: 0px 2px 7px #F0F2F3;
  box-sizing: border-box;
  padding: 40px 40px;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  color: #C4C4C4;
  text-transform: uppercase;
  margin-right: 20px;
  margin-bottom: 0;
`;

const ScrollSelectSubtopic = styled.div`
  display: flex;
`;

const ArrowButtonRight = styled(ArrowButton)`
  background-color: transparent;
  width: auto;
  height: auto;
  :hover:not(:disabled) {
    background-color: transparent;
  }
  :active {
    box-shadow: 0px 0px 5px 0px #E0E0E0;
  }
`;

const ArrowButtonLeft = styled(ArrowButtonRight)`
  transform: rotate(180deg);
`;

const NumberSubtopic = styled.span`
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  box-sizing: border-box;
  padding: 0 15px;
`;

const SubTopicContent = styled.div`
  display: flex;
`;

const SubTopicCardStyled = styled(TopicCard)`
  box-shadow: none;
  padding: 0;
  min-height: auto;
  cursor: default;
  margin-right: 90px;
  justify-content: flex-start;
  border: none;
  & h6 {
    font-size: 22px;
    line-height: 29px;
  }
  :hover {
    border-color: transparent;
  }
  & > div {
    width: 147px;
    height: 147px;
  }
`;

const ProgressWrapper = styled.div`
  width: 100%;
`;
