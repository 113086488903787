import { scaleLinear } from "d3-scale";
import { select } from "d3-selection";

import "./styles.css";

const chart = select("#mock__chart").append('g')
    .attr('transform', 'translate(10, 10)')

const values = [
  {
    title: "Data",
    value: 100
  },
  {
    title: "Numbers",
    value: 30
  },
  {
    title: `Ratio and Proportion`,
    value: 100
  },
  {
    title: "Mesurement",
    value: 20
  }
];

type ValuesType = typeof values;


export const CONFIG = {
  CHART_WIDTH: 890,
  BAR_HEIGHT: 30,
  BAR_MARGIN: 7,
  TEXT_WIDTH: 180,
  TEXT_BAR_MARGIN: 14,
  GRID_OUTER: 15,
  GRID_TEXT_MARGIN: 14
};

export type ConfigType = typeof CONFIG;

export type ChartType = typeof chart;

const percentsScale = scaleLinear().domain([0, 100]).range([0, CONFIG.CHART_WIDTH - CONFIG.TEXT_WIDTH]);

export function drawVerticalLines(chart: ChartType, lines: number[], numberOfBars: number, CFG: ConfigType) {
  const vertical = chart.selectAll<SVGGElement, number[]>(".mock__vertical-grid").data(lines);
  const verticalEnter = vertical.enter().append("g").attr("class", "mock__vertical-grid");

  verticalEnter.append('path').attr('class', 'mock__vertical-grid__path');
  verticalEnter.append('text').attr('class', 'mock__vertical-grid__text').attr('dominant-baseline', 'central');

  const verticalUpdate = verticalEnter.merge(vertical);

  verticalUpdate.attr("transform", (d, n) => {
    const startX = CFG.TEXT_WIDTH + percentsScale(d)//CFG.TEXT_WIDTH + (d * 10);
    const startY = (CFG.BAR_HEIGHT + CFG.BAR_MARGIN * 2) * numberOfBars + CFG.GRID_OUTER;
    return `translate(${startX},  ${startY})`;
  });

  verticalUpdate.select('.mock__vertical-grid__text')
    .attr('dy', CFG.GRID_TEXT_MARGIN)
    .text(d => {
      if (d === 0) {
        return '%';
      }
      return `${d}`;
    })

  verticalUpdate.select('.mock__vertical-grid__path')
    .attr('class', d => `mock__vertical-grid__path  mock__vertical-grid__path_${d}`)
    .attr('stroke-dasharray', d => d === 50 ? 'none' : '2 2')
    .attr('d', (d, n) => {
      const endY = -1 * (CFG.BAR_HEIGHT + CFG.BAR_MARGIN * 2) * numberOfBars - CFG.GRID_TEXT_MARGIN;
      return `M 0, 0 L 0, ${endY}`
    })

  vertical.exit().remove();
}


export function drawChart(chart: ChartType, values: ValuesType, CFG: ConfigType) {
  const node = chart.selectAll<SVGGElement, number[]>("g.node").data(values);
  const nodeEnter = node.enter().append("g").attr("class", "node");

  nodeEnter.append("rect").attr("class", "mock__bar");
  nodeEnter.append("text").attr("class", "mock__text").attr('dominant-baseline', 'central')
  nodeEnter.append("path").attr("class", 'mock__line mock__top-line');
  nodeEnter.append("path").attr("class", 'mock__line mock__bottom-line');

  console.log("--2-");
  const nodeUpdate = nodeEnter.merge(node);

  nodeUpdate.attr("transform", (d, n) => {
    const yTranslate = (CFG.BAR_HEIGHT + CFG.BAR_MARGIN * 2) * n;
    return `translate(0,  ${yTranslate})`;
  });

  nodeUpdate
    .select(".mock__bar")
    .attr("width", (d) => percentsScale(d.value))
    .attr("height", CFG.BAR_HEIGHT)
    .attr("y", CFG.BAR_MARGIN)
    .attr("x", CFG.TEXT_WIDTH);

  nodeUpdate
    .select(".mock__text")
    .attr("dx", CFG.TEXT_WIDTH - CFG.TEXT_BAR_MARGIN)
    .attr("dy", CFG.BAR_HEIGHT / 2 + CFG.BAR_MARGIN)
    .html((d) => {
      return `<tspan>${d.title}`
    });

  nodeUpdate
    .select(".mock__top-line")
    .attr("d", `M${CFG.TEXT_WIDTH}, 0 L ${percentsScale(100) + CFG.TEXT_WIDTH}, 0`)


  nodeUpdate
    .select(".mock__bottom-line")
    .attr("d", `M${CFG.TEXT_WIDTH}, ${CFG.BAR_HEIGHT + CFG.BAR_MARGIN * 2} L ${percentsScale(100) + CFG.TEXT_WIDTH}, ${CFG.BAR_HEIGHT + CFG.BAR_MARGIN * 2}`)

  node.exit().remove();
}

