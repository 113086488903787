import React, { ReactNode, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { getToken } from '../api';

interface AclProps {
  children: ReactNode
}

const Acl = ({ children }: AclProps) => {
  const { push } = useHistory();
  useEffect(() => {
    if (!getToken()) {
      push(`${process.env.PUBLIC_URL}/login`);
    }
  }, [getToken]);

  return <div>
    {children}
  </div>;
};

export default Acl;