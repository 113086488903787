import React, { ReactChild } from 'react';
import styled from 'styled-components/macro';
import Sidebar, { sidebarItemType, SIDEBAR_MENU } from '../Form/FieldsEntity/Sidebar';
import { AvatarIcon, GroupIcon } from '../images/iconSvg';
import Layout from '../Layout';

interface TeacherDashboardProps {
  children: ReactChild
  sidebarMenuSelected: string
  hideSideBar: boolean
}

const sidebarTeacherDashbordMenuConf: sidebarItemType[] = [
  {
    key: SIDEBAR_MENU.MY_GROUPS,
    name: 'My groups',
    icon: <GroupIcon />,
    linkTo: 'teacher/dashboard/my-groups',
  },
  {
    key: SIDEBAR_MENU.MY_STUDENTS,
    name: 'My students',
    icon: <AvatarIcon />,
    linkTo: 'teacher/dashboard/my-students',
  },
];

const TeacherDashboard = ({
  children,
  sidebarMenuSelected,
  hideSideBar
}: TeacherDashboardProps) => {
  return (
    <Layout>
      <Content>
        {!hideSideBar && (
          <SidebarStyled
            items={sidebarTeacherDashbordMenuConf}
            selectedMenuItem={sidebarMenuSelected}
          />
        )}
        {children}
      </Content>
    </Layout>
  );
};

TeacherDashboard.defaultProps = {
  hideSideBar: false,
};

export default TeacherDashboard;

const Content = styled.div`
  display: flex;
  padding-top: 184px;
  margin-right: 150px;

  @media(max-width: 1400px) {
    margin-right: 50px;
  }
`;

const SidebarStyled = styled(Sidebar)`
  margin-right: 97px;
`;
