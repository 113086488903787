import { useEffect } from 'react';

interface UseKeyboardActionProps {
  enterKeyPressed?: () => void
  escKeyPressed?: () => void
}

const useKeyboardAction = ({
  enterKeyPressed,
  escKeyPressed,
}: UseKeyboardActionProps) => {
  const viewKeyPress = (e: KeyboardEvent) => {
    const { code } = e;
    if (code === 'Enter' && enterKeyPressed) enterKeyPressed();
    if (code === 'Esc' && escKeyPressed) escKeyPressed();
  };

  useEffect(() => {
    document.addEventListener('keydown', viewKeyPress);
    return () => {
      document.removeEventListener('keydown', viewKeyPress);
    };
  });
};

useKeyboardAction.defaultProps = {
  enterKeyPressed: () => {},
  escKeyPressed: () => {},
};

export default useKeyboardAction;
