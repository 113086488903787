import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import Layout from '../Layout';
import Footer from '../Layout/Footer/Footer';
import OtterTeam from './OtterTeam';

const otterBackgroundBlue = require('../images/otter-background-blue.svg');
const quoteBack = require('../images/about-us-quotes-back.svg');
const otterSwimLeft = require('../images/otter-swim-left.svg');

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Wrapper>

        <SectionWrapper>
          <ImageOtterBackBlue src={otterBackgroundBlue} alt="otter" />
          <SectionHello>
            <Title>
              Hello from everyone
              <br />
              at Otter!
            </Title>
            <Description>
              A very warm welcome to parents and teachers! Here at Otter we are
              passionate about learning and technology. We have developed a unique
              dynamic AI system born out of the need for a trustworthy and realistic
              platform to prepare kids for the new adaptive online 11+ and the
              accelerating trend towards online exams.
            </Description>
            <Description>
              Based on input from schools, teachers, tutors and parents,
              we have created the most accessible, adaptive, sensibly priced and easy to use platform
              available. Our questions are written by teachers and tutorshighly experienced in 11+
              preparation and simulate the real exam questions as closely as possible.
            </Description>
          </SectionHello>
        </SectionWrapper>

        <SectionWrapper>
          <Section>
            <Title>
              The future of
              <br />
              learning is here
            </Title>
            <Description>
              Since the online 11+ assessment was introduced a few years ago,
              education professionals have been acutely aware of the lack of a
              robust online practice tool. In recent times we have seen a rapid
              shift to online assessment and we now know that online education is
              here to stay. We founded Otter to help schools and parents harness
              the enormous potential of adaptive learning technology and make
              effective learning and exam preparation accessible and affordable.
            </Description>
          </Section>
          <QuoteSection>
            <QuoteTitle>Our Vision</QuoteTitle>
            <QuoteDescription>
              At Otter our goal is to couple
              dynamic AI technology with
              <br />
              robust learning materials to create
              <br />
              a trustworthy, highly effective and
              <br />
              accessible online education
              <br />
              platform for parents
              <br />
              and schools.
            </QuoteDescription>
          </QuoteSection>
        </SectionWrapper>

        <TeamTitle>Greetings from the Otter team!</TeamTitle>
        <OtterTeamStyled />

        <SectionWrapper>
          <Section>
            <Title>
              Big Ambitions
            </Title>
            <Description>
              At Otter we are always looking for new opportunities and believe that
              our technology can radically transform all areas of education and assessment,
              far beyond 11+. If you would like to work or partner with us please don’t
              hesitate to get in touch.
            </Description>
          </Section>
          <ImageOtterSwimLeft src={otterSwimLeft} alt="otter" />
        </SectionWrapper>

      </Wrapper>
      <Footer />
    </Layout>
  );
};

export default AboutUs;

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 152px 0px 36px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1120px) {
    padding-top: 32px;
  }
`;

const Title = styled.h2`
  max-width: 488px;
  width: 100%;
  font-size: 40px;
  line-height: 50px;
  font-weight: bold;
  color: #F87700;
  margin-bottom: 30px;

  @media (max-width: 1120px) {
    text-align: center;
    max-width: 100%;
  }
`;

const Description = styled.p`
  max-width: 488px;
  width: 100%;
  font-size: 18px;
  line-height: 30px;

  @media (max-width: 1120px) {
    max-width: 100%;
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media (max-width: 1120px) {
    margin-top: 24px;
  }
`;

const SectionHello = styled(Section)`
  align-items: flex-end;
`;

const SectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1160px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 24px;
  margin: 0 auto 90px;

  @media (max-width: 1120px) {
    flex-direction: column;
  }
`;

const ImageOtterBackBlue = styled.img`
  @media (max-width: 1120px) {
    width: 70%;
  }
`;

const ImageOtterSwimLeft = styled.img`
  @media (max-width: 1120px) {
    width: 70%;
  }
`;

const QuoteSection = styled(Section)`
`;

const QuoteTitle = styled(Title)`
  text-align: center;
  margin: 0 71px 32px 51px;
  width: auto;
`;

const QuoteDescription = styled.p`
  font-size: 24px;
  line-height: 41px;
  font-weight: bold;
  text-align: center;
  max-width: 577px;
  background: url(${quoteBack}) no-repeat;
  background-size: contain;
  box-sizing: border-box;
  padding: 52px 71px 30px 51px;
  min-height: 377px;
`;

const TeamTitle = styled.h2`
  margin-bottom: 58px;
  font-weight: bold;
  font-size: 40px;
  lineheight: 50px;
  text-align: center;
`;

const OtterTeamStyled = styled(OtterTeam)`
  margin-bottom: 90px;
`;
