import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { combineReducers } from 'redux';
import axios, { AxiosResponse } from 'axios';
import { message } from 'antd';

import { AppThunk, RootState } from '../../app/store';
import { request, setBearerToken } from '../../api';

import { ChallengeType, QuestionType, ReportType } from './types';


export const initialChallengeState: ChallengeType = {
  topicId: null,
  isActive: false,
  questions: [],
  challengeType: 'exam',
  rate: 0,
  challengeStartTime: 'Thu Oct 15 2020 10:13:09 GMT+0300',
  challengeFinishTime: 'Thu Oct 15 2020 10:53:09 GMT+0300',
  completedTime: 'Thu Oct 15 2020 10:53:09 GMT+0300',
  timeIsEnded: false,
  extended: false
};


export const challenge = createSlice({
  name: 'Challenge',
  initialState: initialChallengeState,
  reducers: {
    setCurrentChallenge: (state, action: { payload: ChallengeType }) => ({ ...action.payload }),
    flushChanlenge: () => ({ ...initialChallengeState }),
    // setCurrentQuestion: (state, action: { payload: CurrentQuestionType }) => ({ ...action.payload }),
  },
});

export default combineReducers({
  current: challenge.reducer,
});

export const { setCurrentChallenge, flushChanlenge } = challenge.actions;


export const selectCurrentChallenge = (state: RootState) => state.challenge.current;

export const getCurrentChallenge = (): AppThunk => (dispatch) => {
  request({
    method: 'GET',
    url: 'challenge/state',
  }, [200, 404]).then((response) => {
    if (response?.data) {
      dispatch(setCurrentChallenge(response.data.activeChallenge));
      // console.log('response-', response.data);
      // dispatch(loadQuestions());
    }
  })
    .catch(error => {
    message.error('Network error');
  });
};

export const answerQuestion = (questionId: string, answer: string): AppThunk => (dispatch) => {
  request({
    method: 'POST',
    url: `challenge/question/${questionId}`,
    data: {
      answer
    },
  }, [200]).then((response) => {
    if (response) {
      // TODO FIX UPDATE FULL STATE
      dispatch(getCurrentChallenge());
      // console.log('response-', response.data);
      // dispatch(loadQuestions());
    }
  })
  .catch(error => {
    message.error('Error');
  });
};

interface ThemeId {
  subject: string
  topic: string
  subtopic: string
};

export const startChallenge = (topicId: any, type: string): AppThunk => (dispatch) => {
  request({
    method: 'POST',
    url: 'challenge',
    data: {
      topicId: Object.values(topicId).filter(part => part),
      type
    },
  }, [200]).then((response) => {
    if (response) {
      console.log(response.data)
      dispatch(setCurrentChallenge(response.data.challengeSession));
      // console.log('response-', response.data);
      // dispatch(loadQuestions());
    }
  })
  .catch(error => {
    message.error('Error');
  });
};

export const finishChallenge = (challengeId: string, challengeType: string): AppThunk => (dispatch) => {
  request({
    method: 'POST',
    url: 'challenge/finish',
    data: {
    },
  }, [200]).then((response) => {
    if (response) {
      dispatch(getCurrentChallenge());
      setTimeout(() => 
        window.location.href = `${process.env.PUBLIC_URL}/chalenge/detailed-result/${challengeType}/${challengeId}`,
        3000,
      )
      // console.log('response-', response.data);
      // dispatch(loadQuestions());
    }
  })
  .catch(error => {
    message.error('Error');
  });
};

 //http://{{host}}:{{port}}/api/challenge/question


export const addQuestion = (): AppThunk => (dispatch) => {
  request({
    method: 'POST',
    url: 'challenge/question',
    data: {
    },
  }, [200]).then((response) => {
    if (response) {
      dispatch(getCurrentChallenge());
      // console.log('response-', response.data);
      // dispatch(loadQuestions());
    }
  })
  .catch(error => {
    message.error('Error');
  });
};

// TODO tmp solution
export const skipQuestion = (questionId: string): AppThunk => (dispatch) => {
  request({
    method: 'POST',
    url: `challenge/question/${questionId}`,
    data: {
      answer: ''
    },
  }, [200]).then((response) => {
    if (response) {
      dispatch(getCurrentChallenge());
      // console.log('response-', response.data);
      // dispatch(loadQuestions());
    }
  })
  .catch(error => {
    message.error('Error');
  });
};



export const getChallengeById = (chalengeId: string): AppThunk => (dispatch) => {
  request({
    method: 'GET',
    url: `challenge/state/${chalengeId}`,
    data: {
    },
  }, [200]).then((response) => {
    if (response) {
      dispatch(setCurrentChallenge(response.data.challenge));
      // console.log('response-', response.data);
      // dispatch(loadQuestions());
    }
  })
  .catch(error => {
    message.error('Error');
  });
};


export const extendChalenge = (): AppThunk => (dispatch) => {
  request({
    method: 'POST',
    url: `challenge/extend`,
    data: {
      answer: ''
    },
  }, [200]).then((response) => {
    if (response) {
      dispatch(getCurrentChallenge());
      // console.log('response-', response.data);
      // dispatch(loadQuestions());
    }
  })
  .catch(error => {
    message.error('Error');
  });
};

export const reportChallenge = (report: ReportType): AppThunk => (dispatch) => {
  const formData = new FormData();
  formData.append('image', report.file);
  formData.append('data', JSON.stringify(report));
  request({
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: 'report',
    data: formData,
  }, [200]).then((response) => {
    console.log(response);
  })
  .catch(() => {
  
  });
};

export const getUserProfile = (state: RootState) => state.user.profile;
// export const selecActiveQustion = (state: RootState) => state.questions.gameState;