import styled from 'styled-components/macro';
import FormElements from '../../Form';

const {
  Button,
} = FormElements;

export const PurpleButton = styled(Button)`
  min-width: 128px;
  width: auto;
  height: auto;
  padding: 13px 25px;
  border: 3px solid transparent;
  color: #FFF;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  background-color: #9077F5;
  margin-left: 20px;
`;

export const WhiteButton = styled(PurpleButton)`
  margin-left: 0;
  background-color: #FFF;
  border-color: #9C9C9C;
  color: #9C9C9C;
  :hover:not(:disabled) {
    background-color: #FFF;
    border-color: #F87700;
    color: #F87700;
  }
`;
