import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import Logo from '../Form/FieldsEntity/Logo';

interface FormWrapProps {
  children: ReactNode
};

const CardContainer = ({ children }: FormWrapProps) => {
  return (
    <FullFrame>
      <LogoStyled />
      <Card>
        {children}
      </Card>
    </FullFrame>
  );
};

const FullFrame = styled.div`
  display: flex;
  min-height: 100vh;
  max-width: 1440px;
  min-width: 470px;
  margin: 0 auto;
  width: 100%;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  box-sizing: border-box;
  padding: 22px 74px;
`;

const LogoStyled = styled(Logo)`
  align-self: flex-start;
`;

const Card = styled.div`
  width: 420px;
  background-color: #FFF;
  box-shadow: 0px 2px 8px #F0F2F3;
  border-radius: 10px;
  margin: auto 0;
  overflow: hidden;
`;

export default CardContainer;
