import React from "react";
import styled from "styled-components/macro";
import { DropdownIcon } from "../images/iconSvg";
import { Checkbox } from '../Form/Form';
import { UserPublicType } from "../project-types/users/types";

interface SelectDropdownInputProps {
  listItems: Array<UserPublicType>;
  selectedListItems: Array<any>;
  onListItemClickHandler: (student: UserPublicType) => void;
  onSelectAllClickHandler: () => void;
  isOpen: boolean;
  onBlockClickHandler: () => void;
}

const SelectDropdownInput: React.FC<SelectDropdownInputProps> = (
  {
    listItems,
    selectedListItems,
    onListItemClickHandler,
    onSelectAllClickHandler,
    isOpen,
    onBlockClickHandler,
  }
) => {
  const selectedIds = selectedListItems.map(item => item._id);

  const getLabel = (listItemsLength: number, selectedListItemsLength: number) => {
    if (listItemsLength === selectedListItemsLength) {
      return 'All students';
    }
    if (selectedListItemsLength === 1) {
      return '1 student';
    }
    if (!selectedListItemsLength) {
      return 'No one selected';
    }
    return `${selectedListItemsLength} students`;
  };

  return (
    <Wrapper>
      <InputBlock
        isOpen={isOpen}
        onClick={onBlockClickHandler}
      >
        {getLabel(listItems.length, selectedListItems.length)}
        <DropdownIcon />
      </InputBlock>
      {isOpen && (
        <DropdownContent>
          <DropdownItem>
            <Checkbox checked={listItems.length === selectedListItems.length} onChange={onSelectAllClickHandler} />
            Select all
          </DropdownItem>
          {listItems.map(student => (
            <DropdownItem key={student._id}>
              <Checkbox checked={selectedIds.includes(student._id)} onChange={() => onListItemClickHandler(student)} />
              {student.fullName}
            </DropdownItem>
          ))}
        </DropdownContent>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const InputBlock = styled.div<{ isOpen: boolean }>`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: 370px;
  padding: 17px 15px;
  border-radius: 10px;
  font-size: 16px;
  color: #1f1f1f;
  border: ${(props) =>
    props.isOpen ? "2px solid #9077f5" : "2px solid #e0e0e0"};

    svg {
      transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "")};
    }
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 63px;
  width: 370px;
  border-radius: 0 0 4px 4px;
  background: #fff;
  box-shadow: 0 2px 8px #dedede;
  left: 0;
  z-index: 10;
  max-height: 210px;
  overflow-y: scroll;
`;

const DropdownItem = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px 23px;
`;

export default SelectDropdownInput;
