import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Modal from '../../Form/FieldsEntity/Modal';
import FormElements from '../../Form';

const STAGES = {
  CONFIRM: 'CONFIRM',
  CANCEL: 'CANCEL',
  NOTIFICATION: 'NOTIFICATION',
};

const listOfReasons = [
  'I am not satisfied with the quality of the material',
  'The material is not relevant for my child',
  'I’ve had technical problems using the platform',
  'The subscription is too expensive',
  'Other',
];

const {
  Button, Radio,
} = FormElements;

interface ModalCancelSubscriptionProps {
  showModal: boolean
  onClose: () => void
  onCancelSubscribe: (reason: string) => void
}

const ModalCancelSubscription = ({
  showModal,
  onClose,
  onCancelSubscribe,
}: ModalCancelSubscriptionProps) => {
  const [selectedReason, setSelectedReason] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const [currentStage, setCurrentStage] = useState(STAGES.CONFIRM);

  const selectReason = (reason: string) => {
    setTextareaValue('');
    setSelectedReason(reason);
  };

  const closeModal = () => {
    setCurrentStage(STAGES.CONFIRM);
    onClose();
  };

  const cancelSubscribe = () => {
    const sendingReason = textareaValue === '' ? selectedReason : textareaValue;
    setCurrentStage(STAGES.NOTIFICATION);
    onCancelSubscribe(sendingReason);
  };


  if (!showModal) return null;

  if (currentStage === STAGES.CANCEL) {
    return (
      <Modal onClose={closeModal}>
        <Container>
          <TitleCancelSubscription>Subscription has been canceled.</TitleCancelSubscription>
          <Description>Your monthly subscription will expire on March 31, 2021.</Description>
          <FeedbackWrapper>
            <FeedbackDescription>
              <b>
                Before you go, would you mind letting us know why you canceled?
              </b>
              {' '}
              Your feedback will help us make Otter better.
            </FeedbackDescription>
            {listOfReasons.map((reason: string, n: number) => (
              <RadioStyled key={n} name="reason" checked={reason === selectedReason} onChange={() => selectReason(reason)}>
                <FeedbackItem>
                  {reason}
                </FeedbackItem>
              </RadioStyled>
            ))}
            {selectedReason === 'Other' && (
              <TextArea placeholder="Please specify" onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setTextareaValue(e.currentTarget.value)} />
            )}
          </FeedbackWrapper>
          <ButtonWrapper>
            <SkipButton onClick={closeModal}>Skip</SkipButton>
            <CancelSubscriptionButton
              disabled={selectedReason === '' || (selectedReason === 'Other' && textareaValue === '')}
              onClick={cancelSubscribe}
            >
              Submit
            </CancelSubscriptionButton>
          </ButtonWrapper>
        </Container>
      </Modal>
    );
  }

  if (currentStage === STAGES.CONFIRM) {
    return (
      <Modal onClose={onClose}>
        <Container>
          <Title>We’re sorry to see you go!</Title>
          <DescriptionOfCancel>
            If you would like to proceed with canceling your
            subscription, please select “Cancel Subscription” below.
          </DescriptionOfCancel>
          <ButtonWrapper>
            <SkipButton onClick={closeModal}>Undo</SkipButton>
            <CancelSubscriptionButton onClick={() => setCurrentStage(STAGES.CANCEL)}>Cancel Subscription</CancelSubscriptionButton>
          </ButtonWrapper>
        </Container>
      </Modal>
    );
  }

  if (currentStage === STAGES.NOTIFICATION) {
    setTimeout(
      () => closeModal(),
      3000,
    );

    return (
      <Modal unclosed onClose={() => {}}>
        <Container>
          <TitleNotification>
            Thank you so much for your feedback.
            <br />
            We really appreciate it!
          </TitleNotification>
        </Container>
      </Modal>
    );
  }

  return null;
};

export default ModalCancelSubscription;

const Container = styled.div`
  box-sizing: border-box;
  padding: 50px 70px 44px;
  background-color: #FFF;
  max-width: 740px;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  width: fit-content;
  display: flex;
  margin-left: auto;
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 25px;
  line-height: 26px;
  margin-bottom: 30px;
`;

const TitleCancelSubscription = styled(Title)`
  margin-bottom: 12px;
`;

const Description = styled.span`
  display: block;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 40px;
`;

const DescriptionOfCancel = styled(Description)`
  margin-bottom: 60px;
`;

const FeedbackWrapper = styled.div`
  padding: 36px;
  border-radius: 10px;
  background-color: #EEEAFF;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const FeedbackDescription = styled(Description)`
  margin-bottom: 20px;
`;

const FeedbackItem = styled(Description)`
  margin-bottom: 0;
`;

const CancelSubscriptionButton = styled(Button)`
  min-width: 128px;
  width: auto;
  height: auto;
  padding: 13px 25px;
  border: 3px solid transparent;
  color: #FFF;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-left: 20px;
  background-color: #9077F5;
`;

const SkipButton = styled(CancelSubscriptionButton)`
  margin-left: 0;
  background-color: #FFF;
  border-color: #9C9C9C;
  color: #9C9C9C;
  :hover:not(:disabled) {
    background-color: #FFF;
    border-color: #F87700;
    color: #F87700;
  }
`;

const RadioStyled = styled(Radio)`
  :not(:last-child) {
    margin-bottom: 24px;
  }

  & span::before {
    width: 16px;
    height: 16px;
    background-color: #FFF;
    border-color: #1F1F1F;
  }

  & > input:checked + span::before {
    background-color: #9077F5;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 132px;
  border-radius: 10px;
  border: 1px solid #9C9C9C;
  box-sizing: border-box;
  padding: 15px;
  font-size: 16px;
  :placeholdre {
    color: #9C9C9C;
  }

  :focus {
    outline: none;
    border: 1px solid #9077F5;
  }
`;

const TitleNotification = styled(Title)`
  text-align: center;
  margin-bottom: 0;
`;
