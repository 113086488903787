import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {
  Formik,
  // Form,
} from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import ProfileEditContainer from '../ProfileEditContainer';
import { EditIcon } from '../../images/iconSvg';
import ModalChangeAvatar from './ModalChangeAvatar';
import DateOfBirth from './ChangeDate';
import FirstAndLastNameInput from './FirstAndLastNameInput';
import ChangePassword from './ChangePassword';
import FormElements from '../../Form';
import ChangeGender from './ChangeGender';
import { ErrorsType } from '../../utils/createForm';
import { getProfileFieldErrors, getUserProfile, updateProfile } from '../../features/user/userState';
import { UserProfileType } from '../../features/user/types';
import ProfilePage from '../ProfilePage';
import { SIDEBAR_MENU } from '../../Form/FieldsEntity/Sidebar';
import ChangeEmail from './ChangeEmail';
import { InputError } from '../../Form/FieldsEntity/FormInput';
import { passwordVerification } from '../../hooks/passwordValidator';
import { avatars } from '../../database/avatars';

const { Button } = FormElements;

// const defaultAvatar = require('../../images/defaultAvatar.svg');

interface InitialProfileInformationType {
  name: string;
  dateOfBirth: string;
  email: string;
  password: string;
  gender: string;
  avatar: string;
}

export const profileValidator = (values: UserProfileType) => {
  const errors: ErrorsType = {};
  if (!values.fullName) {
    errors.fullName = ['Full name is required'];
  }

  if (!values.dateOfBirth) {
    errors.dateOfBirth = ['Date of birth is required'];
  }

  // if (!values.email) {
  //   errors.email = ['Email is required'];
  // }

  if (!values.avatar) {
    errors.avatar = ['Avatar is required'];
  }

  if (values.password) {
    if (values.password === undefined || passwordVerification(values.password).length > 0 || !values.confirmPassword) {
      errors.password = passwordVerification(values.password);
    }
  }

  return errors;
};

export const removeNoChangedValue = (
  currentValues: UserProfileType,
  changedValues: UserProfileType,
) => {
  return Object.keys(changedValues).reduce((acc, item) => {
    if (item === 'password' && changedValues.password === '') return acc;

    if (currentValues[item] !== changedValues[item]) {
      return { ...acc, [item]: changedValues[item] };
    }

    return acc;
  }, {});
};

const PersonalInformation = () => {
  const [showModalChangeAvatar, setShowModalChangeAvatar] = useState(false);

  const initialValues: UserProfileType = useSelector(getUserProfile);

  const errorsEmail = useSelector(getProfileFieldErrors);

  const dispatch = useDispatch();

  return (
    <ProfilePage selectedMenuItem={SIDEBAR_MENU.EDIT_PROFILE}>
      <ProfileEditContainer current="personal">
        {initialValues._id && (
        <Formik
          initialValues={{
            avatar: initialValues.avatar,
            fullName: initialValues.fullName,
            dateOfBirth: initialValues.dateOfBirth,
            email: initialValues.email,
          }}
          validate={profileValidator}
          onSubmit={(values) => {
            const valuesHasChanges = removeNoChangedValue(initialValues, values);
            dispatch(updateProfile(valuesHasChanges));
          }}
        >
          {({
            values,
            errors,
            handleSubmit,
            setFieldValue,
            handleReset,
          }) => (
            <form onSubmit={handleSubmit} onReset={handleReset}>
              <ModalChangeAvatar
                showModal={showModalChangeAvatar}
                onCancel={() => setShowModalChangeAvatar(false)}
                onChangeAvatar={(avatarId: string) => setFieldValue('avatar', avatarId)}
                value={values.avatar}
              />

              <AvatarWrapper>
                <Avatar src={avatars.find(
                  (item: any) => values.avatar === item.avatarId,
                )?.avatarImg}
                />
                <ChangeAvatarButton
                  onClick={() => setShowModalChangeAvatar(true)}
                />
              </AvatarWrapper>

              <FirstAndLastNameInput
                nameValue={values.fullName}
                onChangeFullName={(name: string) => setFieldValue('fullName', name)}
                errors={errors.fullName}
              />

              <Title>Date of Birth</Title>
              <DateAndGenderWrapper>
                <DateOfBirth
                  dateValue={values.dateOfBirth}
                  onChange={(date) => {
                    setFieldValue('dateOfBirth', date);
                  }}
                  errors={errors.dateOfBirth}
                />
                {/* <ChangeGender
                  onChangeGender={(gender: string) => {
                    setFieldValue('gender', gender);
                  }}
                  value={values.gender}
                /> */}
              </DateAndGenderWrapper>

              <Title>Email</Title>
              <ChangeEmail
                email={values.email}
                onChangeEmail={(email: string) => setFieldValue('email', email)}
                errors={errorsEmail.email}
              />

              <ChangePassword
                onChangePassword={(password: string) => {
                  setFieldValue('password', password);
                }}
                onConfirm={(confirm) => setFieldValue('confirmPassword', confirm)}
              />

              <ButtonWrapper>
                <CancelButton type="reset">Cancel</CancelButton>
                <SaveButton type="submit">Save changes</SaveButton>
              </ButtonWrapper>
            </form>
          )}
        </Formik>
        )}
      </ProfileEditContainer>
    </ProfilePage>
  );
};

export default PersonalInformation;

const AvatarWrapper = styled.div`
  position: relative;
  width: 140px;
  height: 140px;
  margin-bottom: 47px;
`;

const ChangeAvatarButton = styled(EditIcon)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: -3px;
  right: 17px;
  cursor: pointer;
  & svg {
    fill: #9077f5;
  }
`;

const Avatar = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;
`;

const ButtonWrapper = styled.div`
  width: fit-content;
  display: flex;
  margin-left: auto;
  margin-top: 60px;
`;

export const SaveButton = styled(Button)`
  min-width: 128px;
  width: auto;
  height: auto;
  padding: 13px 25px;
  border: 3px solid transparent;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  background-color: #9077f5;
  margin-left: 20px;
`;

export const CancelButton = styled(SaveButton)`
  margin-left: 0;
  background-color: #fff;
  border-color: #9c9c9c;
  color: #9c9c9c;
  :hover:not(:disabled) {
    background-color: #fff;
    border-color: #f87700;
    color: #f87700;
  }
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
`;

const DateAndGenderWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
`;
