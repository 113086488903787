import React from 'react';
import styled from 'styled-components/macro';
import { AddIcon, EditIconCircle, GoBackIcon } from '../images/iconSvg';

interface ComponentProps {
  onAdd: () => void
  onEdit?: () => void
  addButtonName?: string
  addButtonComponent?: React.ReactNode
  title: string,
  showGoBack?: boolean
  editEntityName?: string
  noteText?: string,
  onBack?: () => void
}

const HeaderForTeacherDashboard = ({
  title,
  addButtonName,
  addButtonComponent,
  onAdd,
  showGoBack,
  editEntityName,
  onEdit,
  noteText,
  onBack,
}: ComponentProps) => {
  return (
    <Header>
      {showGoBack && (
        <GoBack>
          <GoBackContent onClick={onBack}>
            <GoBackIconStyled />
            <>Go back</>
          </GoBackContent>
        </GoBack>
      )}
      <Title>{title}</Title>
      {addButtonName && (
        <Button onClick={onAdd}>
          <AddIconStyled />
          {addButtonName}
        </Button>
      )}
      {addButtonComponent && (
        <>
          {addButtonComponent}
        </>
      )}
      {editEntityName && (
        <EditEntityWrap>
          <>
            <EditEntity href="#" onClick={onEdit}>
              {editEntityName}
              <EditIconStyled />
            </EditEntity>
            <EditEntityNote>
              {noteText}
            </EditEntityNote>
          </>
        </EditEntityWrap>
      )}
    </Header>
  );
};

HeaderForTeacherDashboard.defaultProps = {
  showGoBack: false,
};

export default HeaderForTeacherDashboard;

const Header = styled.header`
  border-bottom: 2px solid #E0E0E0;
  padding-bottom: 18px;
  margin-bottom: 56px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 32px;
  line-height: 26px;
  margin: 0;
  margin-right: 32px;
`;

const GoBack = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #C4C4C4;
  width: 100%;
  padding-bottom: 33px;
  display: flex;
  &:hover {
    color: #C4C4C4;
  }
`;

const GoBackContent = styled.div`
  display: flex;
  cursor: pointer;
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: #9077F5;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
`;

const AddIconStyled = styled(AddIcon)`
  & svg {
    fill: #9077F5;
  }

  font-size: 30px;
  margin-right: 13px;
`;

const GoBackIconStyled = styled(GoBackIcon)`
  & svg {
    fill: #C4C4C4;
  }

  font-size: 15px;
  margin-right: 7px;
`;

const EditEntity = styled.a`
  margin-top: 10px;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  width: 100%;
  color: #1F1F1F;
  display: flex;
  &:hover {
    color: #C4C4C4;
  }
`;

const EditIconStyled = styled(EditIconCircle)`
  margin-left: 8px;
  font-size: 26px;
  font-size: 26px;
  & svg {
    fill: #C4C4C4;
  }
`;

const EditEntityWrap = styled.div`
  dispalay: flex;
  width: 100%;
  display: flex;
  align-items: center;
`;


const EditEntityNote = styled.div`
  font-size: 18px;
  line-height: 26px;
  color: #1F1F1F;
  width: 50%;
  flex-wrap: nowrap;
  text-align: right;
`;