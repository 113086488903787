import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import Modal from '../../Form/FieldsEntity/Modal';
import UseLocalization from '../../Localization/UseLocalization';
import ModalContactUs from '../../MainPage/ModalContactUs';

const otterLogo = require('../../images/logoWhiteText.svg');
const facebookIcon = require('../../images/facebook-icon.svg');
const twitterIcon = require('../../images/twitter-icon.svg');
const whatsappIcon = require('../../images/whatsapp-icon.svg');
const instagranIcon = require('../../images/instagram-icon.svg');

const renderSocialIcon = () => (
  <>
    <SocialLink href="https://www.facebook.com/Otter-Education-141467427775723" target="_blank" rel="noreferrer"><SocialIcon src={facebookIcon} /></SocialLink>
    <SocialLink href="https://twitter.com/OtterEducation" target="_blank" rel="noreferrer"><SocialIcon src={twitterIcon} /></SocialLink>
    <SocialLink href="https://www.instagram.com/ottereducation/" target="_blank" rel="noreferrer"><SocialIcon src={instagranIcon} /></SocialLink>
    <SocialLink href="tel:+442035190942"><SocialIcon src={whatsappIcon} /></SocialLink>
  </>
);

interface FooterProps {
  className?: string
}

const Footer = ({
  className,
}: FooterProps) => {
  const [showModalContact, setShowModalContact] = useState(false)
  const { url } = useRouteMatch();

  const dontShowIn: Record<string, string> = {
    '/': '/',
    '/pricing': '/pricing',
  };

  return (
    <FooterWrap className={className}>
      {showModalContact && (
        <Modal onClose={() => setShowModalContact(false)}>
          <ModalContactUs
            onClose={() => setShowModalContact(false)}
          />
        </Modal>
      )}
      <MenuSection>
        <LogoWrapper>
          <LogoLink to="/" />
          <Description>
            {/* <UseLocalization translateKey="otter.description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, pulvinar aliquet bibendum ultrices nulla id.
          </UseLocalization> */}
          </Description>
        </LogoWrapper>
        {!dontShowIn[url] && (
          <SocialIconMobile>
            {renderSocialIcon()}
          </SocialIconMobile>
        )}
        <MenuWrapper>
          <OtterNavList>
            <MenuTitle>Otter</MenuTitle>
            <MenuItem>
              <LinkInToMenu to={`${process.env.PUBLIC_URL}/about-us`}>
                <UseLocalization translateKey="navigation.about">
                  About
                </UseLocalization>
              </LinkInToMenu>
            </MenuItem>
            <MenuItem>
              <LinkInToMenu to={`${process.env.PUBLIC_URL}/how-to-use`}>
                <UseLocalization translateKey="navigation.about">
                  Why Choose Otter?
                </UseLocalization>
              </LinkInToMenu>
            </MenuItem>
            <MenuItem>
              <LinkInToMenu to={`${process.env.PUBLIC_URL}/eleven-plus`}>
                <UseLocalization translateKey="navigation.about">
                  Understanding the 11+
                </UseLocalization>
              </LinkInToMenu>
            </MenuItem>
            <MenuItem>
              <LinkInToMenu to={`${process.env.PUBLIC_URL}/faq`}>
                <UseLocalization translateKey="navigation.about">
                  FAQ
                </UseLocalization>
              </LinkInToMenu>
            </MenuItem>
            <MenuItem>
              <LinkInToMenu to={`${process.env.PUBLIC_URL}/pricing`}>
                <UseLocalization translateKey="navigation.pricing">
                  Pricing
                </UseLocalization>
              </LinkInToMenu>
            </MenuItem>
            <MenuItem>
              <LinkInToMenu to={`${process.env.PUBLIC_URL}/privacy-policy`} target="_blank" rel="noreferrer">
                <UseLocalization translateKey="navigation.termsOfService">
                  Terms & Privacy
                </UseLocalization>
              </LinkInToMenu>
            </MenuItem>
          </OtterNavList>
          <ConnectNavList>
            <MenuTitle>Connect</MenuTitle>
            <MenuItem>
              <LinkInToMenu to="#" onClick={() => setShowModalContact(true)}>
                <UseLocalization translateKey="navigation.contact">
                  Contact
                </UseLocalization>
              </LinkInToMenu>
            </MenuItem>
            <MenuItem>
              <LinkInToMenu to={`${process.env.PUBLIC_URL}/registration/student`}>
                <UseLocalization translateKey="action.signUp">
                  Sign Up
                </UseLocalization>
              </LinkInToMenu>
            </MenuItem>
            <MenuItem>
              <LinkInToMenu to={`${process.env.PUBLIC_URL}/login`}>
                <UseLocalization translateKey="action.logIn">
                  Log In
                </UseLocalization>
              </LinkInToMenu>
            </MenuItem>
          </ConnectNavList>
        </MenuWrapper>
      </MenuSection>
      <CopyrightSection>
        <DescriptionCopyright>
          <UseLocalization translateKey="copyright">
          &#169; Otter Education Ltd. All rights reserved.
          </UseLocalization>
        </DescriptionCopyright>
        {!dontShowIn[url] && (
          <SocialLinkBox>
            {renderSocialIcon()}
          </SocialLinkBox>
        )}
      </CopyrightSection>
    </FooterWrap>
  );
};

export default Footer;

const FooterWrap = styled.footer`
  max-width: 1440px;
  width: 100%;
  background-color: #4C4C4C;
`;

const MenuSection = styled.div`
  padding: 74px 150px 47px;
  display: flex;

  @media (max-width: 1279px) {
    padding-left: 44px;
    padding-right: 44px;
  }

  @media (max-width: 1000px) {
    padding: 45px;
  }

  @media (max-width: 840px) {
    flex-direction: column;
  }
`;

const LogoWrapper = styled.div`
  margin-right: 192px;

  @media (max-width: 1279px) {
    margin-right: auto;
  }

  @media (max-width: 1000px) {
    margin-right: 50px;
  }

  @media (max-width: 600px) {
    margin-right: 0;
  }
`;

const Description = styled.p`
  width: 363px;
  font-size: 20px;
  line-height: 26px;
  color: #FFF;

  @media (max-width: 900px) {
    // font-size: 14px;
    // line-height: 17px;
    width: 288px;
  }

  @media (max-width: 840px) {
    text-align: left;
    width: 100%;
    margin-bottom: 40px;
  }

  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 17px;
  }

  @media (max-width: 400px) {
    text-align: center;
  }
`;

const LogoLink = styled(Link)`
  display: block;
  width: 292px;
  height: 96px;
  background: url(${otterLogo}) no-repeat center;
  margin-bottom: 34px;

  @media (max-width: 900px) {
    width: 180px;
    height: 60px;
    background-size: contain;
  }

  @media (max-width: 400px) {
    margin: 0 auto 34px;
  }
`;

const MenuWrapper = styled.div`
  paddingtop: 26px;
  display: flex;
  max-width: 470px;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 800px) {
    justify-content: flex-start;
  }

  @media (max-width: 400px) {
    justify-content: center;
    max-width: 100%;
  }
`;

const OtterNavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  @media (max-width: 800px) {
    margin-right: 60px;
  }

  @media (max-width: 600px) {
    :last-child {
      margin-right: 0;
    }
  }
`;

const ConnectNavList = styled(OtterNavList)`

`;

const MenuTitle = styled.h6`
  font-weight: bold;
  font-size: 40px;
  line-height: 43px;
  margin-bottom: 36px;
  color: #FFF;

  @media (max-width: 800px) {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 24px;
  }
`;

const MenuItem = styled.li`
  :not(:last-of-type) {
    margin-bottom: 22px;
  }

  @media (max-width: 1000px) {
    margin-bottom: 14px;
  }
`;

const LinkInToMenu = styled(Link)`
  display: block;
  color: #FFF;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  :hover {
    color: #FFF;
  }

  @media (max-width: 800px) {
    font-size: 12px;
    line-height: 15px;
  }
`;

const CopyrightSection = styled.div`
  box-sizing: border-box;
  padding: 26px 150px 27px 150px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1279px) {
    padding-left: 44px;
    padding-right: 44px;
  }

  @media (max-width: 800px) {
    // flex-direction: column;
    // align-items: flex-start;
    padding: 22px 16px;
  }
`;

const DescriptionCopyright = styled(Description)`
  width: auto;
  font-weight: bold;
  margin: 0;

  @media (max-width: 800px) {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 0;
  }
`;

const SocialLinkBox = styled.div`
  display: flex;

  @media (max-width: 600px) {
    display: none;
  }
`;

const SocialLink = styled.a`
  width: 56px;
  height: 56px;
  border-radius: 24px;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  :not(:last-of-type) {
    margin-right: 27px;
  }

  @media (max-width: 800px) {
    width: 42px;
    height: 42px;
  }
`;

const SocialIcon = styled.img`
  @media (max-width: 800px) {
    width: 24px;
    height: 24px;
  }
`;

const SocialIconMobile = styled.div`
  display: none;
  margin: 0 auto;
  margin-bottom: 34px;
  @media (max-width: 600px) {
    display: flex;
  }
`;
