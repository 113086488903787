import React from "react";
import styled from "styled-components/macro";
import { ArrowIcon } from '../images/iconSvg';

interface StagingComponentProps {
  stages: Array<string>;
  currentPageIndex: string;
}

export default function StagingComponent({ stages, currentPageIndex }: StagingComponentProps) {
  return (
    <Container>
      {stages.map((stage, index) => <Stage isCurrent={index <= Number(currentPageIndex) - 1}>{stage} <ArrowIcon /></Stage>)}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Stage = styled.span<{ isCurrent: boolean; }>`
  font-size: 20px;
  color: ${(props) => props.isCurrent ? '#9077f5' : '#c4c4c4'};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  gap: 8px;

    svg {
      fill: ${(props) => props.isCurrent ? '#9077f5' : '#c4c4c4'};
    }
`;
