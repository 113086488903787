import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { SchoolType } from '../../features/user/types';
import RadioSelectPeriod from '../../ProgressPage/RadioSelectPeriod';
import ScrollSelectSchool, { schoolItemsTypes } from '../../ProgressPage/ScrollSelectSchool';
import LineChart from '../../Chart/LineChart/LineChart';
import { monthView } from '../../Chart/LineChart/dateView';

interface GraphBoardProps {
  schoolsList: SchoolType[]
}

const data = [
  {
    id: 'asder',
    rate: 100,
    avg: 90,
    date: 13,
    endTime: new Date("2020-11-13T00:00:00.000Z"),
  },
];

const GraphBoard = ({
  schoolsList,
}: GraphBoardProps) => {
  const [school, setSchool] = useState(schoolsList[0]);
  const [params, setParams] = useState({ period: 'week', topic: 'Maths' });

  return (
    <Board>
      <ControlPanel>
        <ScrollSelectSchool
          targetSchools={schoolsList}
          onChange={(currentSchool: schoolItemsTypes) => setSchool(currentSchool)}
        />
        <RadioSelectPeriod
          value={params.period}
          onSelectPeriod={(name: string) => setParams({ ...params, period: name })}
        />
      </ControlPanel>
      {data.length > 0 && (
        <LineChart
          target={school}
          dateView={monthView}
          onClickPoint={() => {}}
          width={700}
          height={300}
          data={data}
        />
      )}
    </Board>
  );
};

export default GraphBoard;

const Board = styled.div`
  background-color: #fff;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 35px 35px 25px;
  box-shadow: 0px 1.625px 6.5px #f0f2f3;
  max-width: 1280px;
  width: 100%;
`;

const ControlPanel = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
`;

