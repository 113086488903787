import React, { useRef, useEffect, useLayoutEffect, useCallback, useState } from "react";
import { select } from "d3-selection";
import { axisBottom, axisLeft } from "d3-axis";
import { transition as d3Transition } from "d3-transition";
// import { timeF } from 'd3-time';

import * as Scales from "./scales";

import "./styles.css";

import {
  ChartConfigType,
  AxisSetType,
  ScalesSetType,
  ChartType,
  StatType,
  DateType,
  TargetSchoolType
} from "./types";
import { drawLine } from "./draw/drawLine";
import { drawAxis } from "./drawAxis";
import { drawTargetLine } from './draw/drawTarget';
import { StatOptions } from "fs";

select.prototype.transition = d3Transition;

interface ChartProps {
  width: number,
  height: number,
  onClickPoint: (d: StatType) => void
  data: any;
  dateView: (d: DateType) => string;
  target: TargetSchoolType
}

const createConfig = (elWidth: number, elHeight: number) => {
  const margin = { top: 50, right: 40, bottom: 50, left: 40 };
  const width = elWidth - margin.left - margin.right;
  const height = elHeight - margin.top - margin.bottom;

  const ChartConfig: ChartConfigType = {
    size: {
      width,
      height,
    },
    margins: margin,
  };

  return ChartConfig;
}

function useChartConfig(elWidth: number, elHeight: number) {
  const [config, setConfig] = useState<ChartConfigType>(createConfig(elWidth, elHeight));

  // const configRef = useRef<ChartConfigType>(createConfig(elWidth, elHeight));

  return config;
}

const Chart = ({ width, height, dateView, onClickPoint, data: rawData, target }: ChartProps) => {
  const ChartConfig = useChartConfig(width, height);

  const svgRef = useRef<SVGSVGElement | null>(null);
  const chartRef = useRef<ChartType | null>();


  const data = rawData.map((data: string)  => {
    return data;
  })

  useLayoutEffect(() => {
    const {
      size: { width, height },
      margins,
    } = ChartConfig;

    const svg = select(svgRef.current)
      .attr("width", width + margins.left + margins.right)
      .attr("height", height + margins.top + margins.bottom);

    const chart = svg
      .append("g")
      .attr("class", "chart")
      .attr("transform", "translate(" + margins.left + "," + margins.top + ")");

    chart.append("g").attr("class", "axis x-axis");
    chart.append("g").attr("class", "axis y-axis");

    chartRef.current = chart;

    console.log(svgRef.current);
  }, [ChartConfig]);


  const [focusesPoint, setFocusedPoint] = useState<StatType | undefined>();

  const onChnageFocusedPoint = (d?: StatType) => {
    if (d !== focusesPoint) {
      setFocusedPoint(d);
    }
  }

  useLayoutEffect(() => {
     const {
      size: { height },
    } = ChartConfig;


    const dates = data.map((item: any) => item.endTime);
    const results = data.map((item: any) => item.result);

    if (chartRef.current) {
      const chart = chartRef.current;

      const scalesSet: ScalesSetType = {
        x: Scales.date(ChartConfig, dates),
        y: Scales.values(ChartConfig, results),
      };

      const axisSet: AxisSetType = {
        x: axisBottom(scalesSet.x)
          .ticks(4)
          // .tickFormat(format("%m/%d"))
          .tickFormat((d: any) => dateView(d))
          .tickSize(-1 * height)
          ,
        y: axisLeft(scalesSet.y)
          .ticks(5)
          .tickFormat((d: any) => `${d}%`),
      };

      if (chart) {
        drawAxis(ChartConfig, chart, axisSet, focusesPoint);
        drawLine(ChartConfig, chart, onClickPoint, scalesSet, data, dates, results, onChnageFocusedPoint, focusesPoint);
        drawTargetLine(ChartConfig, chart, dates, results, target);
      }
    }
  }, [ChartConfig, data, dateView, target, focusesPoint]);

  return (
    <svg ref={svgRef}>
      <defs>
        <filter
          id="filter"
          x="-20%"
          y="-20%"
          width="140%"
          height="140%"
          filterUnits="objectBoundingBox"
          primitiveUnits="userSpaceOnUse"
          colorInterpolationFilters="linearRGB"
        >
          <feDropShadow
            stdDeviation="5 5"
            in="SourceGraphic"
            dx="5"
            dy="5"
            floodColor="#000000"
            floodOpacity="0.5"
            x="0%"
            y="0%"
            width="100%"
            height="100%"
            result="dropShadow"
          />
        </filter>
        <linearGradient
          id="gradient"
          x1="0"
          y1="0"
          x2="0"
          y2="100%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9077F5" />
          <stop offset="0.01042" stop-color="#AA95FF" />
          <stop offset="0.731655" stop-color="#FCF4F3" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Chart;
