import { useState, useEffect } from 'react';
import { message } from 'antd';
import { request } from '../../api';
import { PayPlanType } from '../../ProfilePage/Subscription/types';

export const useGetPriceList = () => {
  const [priceList, setPriceList] = useState<PayPlanType>({ stripe: [], paypal: [] });

  useEffect(() => {
    request(
      {
        method: 'GET',
        url: '/subscriptions',
      },
      [200],
    )
      .then((response) => {
        const { stripe, paypal } = response.data;
        setPriceList({
          stripe: stripe.reverse().map((tariff: any) => ({ ...tariff, unit_amount: tariff.unit_amount / 100, unit_amount_decimal: tariff.unit_amount_decimal / 100 })),
          paypal: paypal.map((tariff: any) => ({ ...tariff, unit_amount: tariff.unit_amount / 100, unit_amount_decimal: tariff.unit_amount_decimal / 100 })),
        });
      })
      .catch(() => message.error('Error on load tariff\'s'));
  }, [setPriceList]);

  return priceList;
};
