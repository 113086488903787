import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import Layout from '../../Layout';
import Footer from '../../Layout/Footer/Footer';
import ElevenPlusContent from './ElevenPlusContent';
import SidebarNavigation from './SidebarNavigation';

const imageToHeader = require('../../images/elevenPlusMainImage.svg');

const ElevenPlusPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Header>
        <Title>
          Understanding the
          {' '}
          <SpanInTitle>11+</SpanInTitle>
        </Title>
        <ImageWrapper>
          <ImageInHeader src={imageToHeader} width="390" height="409" />
        </ImageWrapper>
      </Header>
      <Content>
        <SidebarNavigation />
        <ElevenPlusContent />
      </Content>
      <Footer />
    </Layout>
  );
};

export default ElevenPlusPage;

const Header = styled.div`
  width: 100%;
  min-height: 321px;
  padding: 90px 20px 80px;
  background-color: #9077F5;
  display: flex;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 56px;
  line-height: 78px;
  font-weight: bold;
  color: #FFF;
  max-width: 525px;
  margin-right: 30px;
  margin-bottom: 0;
`;

const SpanInTitle = styled.span`
  font-size: 64px;
  line-height: 80px;
  color: #FB8C26;
`;

const ImageWrapper = styled.div`
  max-width: 390px;
  width: 100%;
  height: auto;
  position: relative;
`;

const ImageInHeader = styled.img`
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: contain;
`;

const Content = styled.div`
  width: 100%;
  background-color: #F2F2F2;
  display: flex;
`;
