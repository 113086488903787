import React, { useState } from 'react';

import styled from 'styled-components/macro';
import Elements from '../../Form';
import LabelForInput from '../../Form/FieldsEntity/LabelForInput';
import useKeyboardAction from '../../hooks/keyboardAction';
import { GroupFields, GroupOutType } from '../../project-types/groups/types';
import { TitleH4, TitleH5 } from '../../styledElements/styledTextElements';

const {
  Button,
  Input,
} = Elements;

interface ComponentProps {
  copyCode: (code: string) => void
  group: GroupOutType,
  onNext: (groupName: string) => void
  onClose: () => void,
  updateGroup: (groupFields: Pick<GroupFields, 'name'>) => void
}

const ModalContentFromEditGroup = ({
  group,
  onNext,
  onClose,
  copyCode,
  updateGroup,
}: ComponentProps) => {
  const [groupName, setGroupName] = useState(group.name);
  const [groupCode, setGroupCode] = useState(group.inviteCode);

  const [isSaved, setIsSaved] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const saveName = () => {
    updateGroup({ name: groupName });
    setIsSaved(true);
  };

  const onСopyCode = () => {
    if (group.inviteCode) {
      copyCode(group.inviteCode);
      setIsCopied(true);
    }
  };

  const maxLength = 35;
  const groupNameLength = groupName.length;

  const disabledButton = groupNameLength <= 1;

  const groupNameViewLength = `${groupNameLength} / ${maxLength}`;

  const onAddGroupName = () => {
    if (!disabledButton) {
      onNext(groupName);
    }
  };

  useKeyboardAction({
    enterKeyPressed: onAddGroupName,
    escKeyPressed: onClose,
  });

  return (
    <Wrapper>
      <Title>Edit group</Title>
      <InputTitle>Group Name</InputTitle>
      <Label name="Group Name">
        <InputWrapper>
          <Input
            type="text"
            value={groupName}
            onChange={(e) => {
              setGroupName(e.target.value);
              setIsSaved(false);
            }}
            maxLength={maxLength}
            placeholder="Enter group name"
          />
          {isSaved ? (
            <SaveLink stage={SaveLinkStage.SAVED} onClick={saveName}>Saved</SaveLink>
          ) : (
            <SaveLink stage={SaveLinkStage.START} onClick={saveName}>Save</SaveLink>
          )}
        </InputWrapper>
      </Label>
      <Label name="Group Code">

        <InputWrapper>
          <Input
            type="text"
            disabled
            onChange={(e) => setGroupName(e.target.value)}
            value={groupCode}
            maxLength={maxLength}
            placeholder="Enter group name"
          />
          {isCopied ? (
            <SaveLink stage={SaveLinkStage.SAVED} onClick={onСopyCode}>Copied</SaveLink>
          ) : (
            <SaveLink stage={SaveLinkStage.START} onClick={onСopyCode}>Copy</SaveLink>
          )}
        </InputWrapper>
      </Label>
      <ButtonsWrap>
        <CancelButton
          onClick={onClose}
        >
          Cancel
        </CancelButton>
        <NextButton
          onClick={onAddGroupName}
        >
          Done
        </NextButton>
      </ButtonsWrap>
    </Wrapper>
  );
};

export default ModalContentFromEditGroup;

const Wrapper = styled.div`
  padding: 42px;
  max-width: 612px;
  width: 100vw;
`;

const Title = styled(TitleH4)`
  margin-bottom: 74px;
  text-align: center;
`;

const InputTitle = styled(TitleH5)`
  margin-bottom: 19px;
`;

const Label = styled(LabelForInput)`
  margin-bottom: 49px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

enum SaveLinkStage {
  START = '#C4C4C4',
  SAVED = '#95D354',
}
const SaveLink = styled.a<{ stage: SaveLinkStage }>`
  font-size: 18px;
  line-height: 22px;
  color: ${(props) => props.stage};
  margin-left: 31px;
  font-weight: bold;
  min-width: 67px;
  text-align: right;
  display: inline-block;
`;

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  height: 56px;
`;

const CancelButton = styled(Button)`
  max-width: 204px;
  height: 56px;
  padding: 17px 60px;
  background-color: #9077F5;
  border-radius: 10px;
  background: #FFFFFF;
  color: #9C9C9C;
  border: 2px solid #9C9C9C;
  &:hover:not(:disabled) {
    background: #FFFFFF;
  }
`;


const NextButton = styled(Button)`
  max-width: 147px;
  height: 56px;
  padding: 17px 60px;
  background-color: #9077F5;
  border-radius: 10px;
`;
