const colorStyle = {
  exam: {
    theme: {
      color: '#9077F5',
      hoverColor: '#AA95FF',
    },
  },
  test: {
    theme: {
      color: '#F87700',
      hoverColor: '#FF912C',
    },
  },
};

export default colorStyle;
