import React from 'react';
import styled from 'styled-components/macro';
import CardContainer from './CardContainer';

const letterImage = require('../images/letter.svg');

const VerifyEmailPage = () => (
  <CardContainer>
    <Wrapper>
      <Title>Please verify your email</Title>
      <LetterImage />
      <Description>
        You’re almost ready to get started with Otter! We have sent an email with a confirmation link to your email address.
      </Description>
      <Description>
        Just
        {' '}
        <Bold>click on the link in that email</Bold>
        {' '}
        to complete your sign-up.
      </Description>
      <Description>
        If you don’t see it, you may need to check your
        {' '}
        <Bold>spam folder.</Bold>
      </Description>
    </Wrapper>
  </CardContainer>
);

export default VerifyEmailPage;

const Title = styled.h1`
  margin-bottom: 28px;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 16px;
  text-align: center;
  max-width: 320px;
`;

const LetterImage = styled.div`
  width: 110px;
  height: 90px;
  background: url(${letterImage}) no-repeat;
  background-size: cover;
  margin-bottom: 36px;
`;

const Wrapper = styled.form`
  box-sizing: border-box;
  padding: 60px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Bold = styled.span`
  font-weight: bold;
`;
