import React, { useEffect } from 'react';
import { Form, Input, Button, Checkbox, Card } from 'antd';
import { Store } from 'antd/lib/form/interface';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { getUserProfile, logIn, LOGIN_ADMIN } from '../features/user/userState';
import { FormWrap, FormItem } from './Form';


const Game = () => {
  const userState = useSelector(getUserProfile);
  const dispatch = useDispatch();

  function onFinish(values: Store) {
    const { email, password, code } = values;
    dispatch(logIn({ email, password, code }, LOGIN_ADMIN));
  };

  function onFinishFailed(errorInfo: Store) {
  };


  useEffect(() => {
    if (userState._id) {
      window.location.href = `${process.env.PUBLIC_URL}/users`;
    }
  }, [userState]);

  /*
  const onRespone = (answer: AnswerType) => {
    dispatch(answerQuestion(answer));
  };
  */

  return (
    <FormWrap title="Авторизация">
      <FormStyled
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <FormItem
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Email is required' }]}
        >
          <Input />
        </FormItem>

        <FormItem
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Password is required' }]}
        >
          <Input.Password />
        </FormItem>

        <FormItem
          label="2fa key"
          name="code"
          rules={[{ required: true, message: '2fa key is required' }]}
        >
          <Input/>
        </FormItem>

        <FormItem>
          <Button type="primary" htmlType="submit">
            Войти
          </Button>
        </FormItem>
      </FormStyled>
    </FormWrap>
  );
};


const FormStyled = styled(Form)`
`;

export default Game;

/*
<FormItem name="remember" valuePropName="checked">
  <Checkbox>Remember me</Checkbox>
</FormItem>
*/
