import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

const welcomeOtter = require('../../images/welcomeOtter.svg');

interface ComponentProps {
  title: string
  linkToNext: string
  description: string
  className?: string
}

const ContentForWelcomePage = ({
  title,
  linkToNext,
  description,
  className,
}: ComponentProps) => {
  return (
    <Container className={className}>
      <Title>{title}</Title>
      <OtterImg src={welcomeOtter} />
      <Description>{description}</Description>
      <LinkStyled to={linkToNext}>Start learning</LinkStyled>
    </Container>
  );
};

ContentForWelcomePage.defaultProps = {
  title: 'Welcome to Otter!',
};

export default ContentForWelcomePage;

const Container = styled.div`
  width: 100%;
  padding: 54px 102px 90px;
  background-color: #FFF;
  box-shadow: 0px 2px 8px #F0F2F3;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 18px;
`;

const OtterImg = styled.img`
  width: 458px;
  height: 396px;
  margin-bottom: 57px;
`;

const Description = styled.span`
  display: block;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 58px;
`;

const LinkStyled = styled(Link)`
  min-width: 264px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background-color: #9077F5;
  margin: 0 auto;
  color: #FFF;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  :hover {
    color: #FFF;
    background-color: #AA95FF;
  }
`;
