import { Tabs } from 'antd';
import React from 'react';
import styled from 'styled-components/macro';
import CreateAdmin from './CreateAdmin';

const { TabPane } = Tabs;

const AdminEdit = () => {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane key="1" tab="Create admin">
        <CreateAdmin />
      </TabPane>
    </Tabs>
  );
};

export default AdminEdit;
