import React, { ReactElement } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { RouteType } from '../features/topic/types';
import Radio from '../Form/FieldsEntity/Radio';
import FormWrap from './CardContainer';
import StudentSignUp from './StudentSignUp';
import TeacherSignUp from './TeacherSignUp';
import UserMenu from './UserMenu';

const RegistrationPage = () => {
  const { userType } = useParams<RouteType>();
  const { push } = useHistory();

  const redirectByChangeUserType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    push(`${process.env.PUBLIC_URL}/registration/${value}`);
  };

  const formByUserType: Record<string, ReactElement> = {
    student: <StudentSignUp />,
    teacher: <TeacherSignUp />,
  };

  return (
    <FormWrap>
      <UserMenu createNewUser />
      <RadioWrapper>
        <RadioStyled
          name="type"
          value="student"
          checked={userType === 'student'}
          backgroundRadio="#F87700"
          onChange={redirectByChangeUserType}
        >
          Student
        </RadioStyled>
        <RadioStyled
          name="type"
          value="teacher"
          checked={userType === 'teacher'}
          backgroundRadio="#F87700"
          onChange={redirectByChangeUserType}
        >
          Teacher
        </RadioStyled>
      </RadioWrapper>
      {userType && formByUserType[userType]}
    </FormWrap>
  );
};

export default RegistrationPage;

const RadioWrapper = styled.div`
  display: flex;
  padding: 39px 30px 0 43px;
  box-sizing: border-box;
`;

const RadioStyled = styled(Radio)`
  margin-right: 25px;
`;
