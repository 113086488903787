import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import Admin from './Admin';
import { store } from './app/store';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18n from './Localization/i18next';


if (process.env.REACT_APP_BUILD_TARGET === 'admin') {
  console.log('admin!')
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Admin />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
} else if (process.env.REACT_APP_BUILD_TARGET === 'ssr') {

} else {
  ReactDOM.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <App />
        </Provider>
      </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
