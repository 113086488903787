import styled from 'styled-components/macro';

export const TitleH1 = styled.h1`
  font-size: 40px;
  line-height: 43px;
`;

export const TitleH2 = styled.h2`
  font-size: 32px;
  line-height: 44px;
`;

export const TitleH3 = styled.h3`
  font-size: 28px;
  line-height: 34px;
`;

export const TitleH4 = styled.h4`
  font-size: 22px;
  line-height: 26px;
`;

export const TitleH5 = styled.h5`
  font-size: 18px;
  line-height: 22px;
`;
