import React, {useEffect} from 'react';
import { useSelector } from 'react-redux'
import { getUserProfile } from './features/user/userState';
import { selectCurrentChallenge } from './features/challenge/challengeState';
import { premiumTariff } from './database/premiumTariff'
import styled from 'styled-components';

const otterLiveTutor = require('./images/tutor-live.svg');

async function resume() {
  const timeout = () => new Promise(res => setTimeout(res, 1000));

  while (!window.chaport || !window.Chatra) {
    await timeout();
  }
}

export default function Chats() {
  const [showTeacherChat, setShowTeacherChat] = React.useState<boolean>(false);

  const user = useSelector(getUserProfile);
  const currentChallenge = useSelector(selectCurrentChallenge);

  useEffect(() => {
    resume().then(() => {
      if (window.Chatra && window.chaport) {
        if (user._id) {
          const userPriceId: string = user.priceId || '';
          window.chaport.q('stopSession');
          if (window.Chatra.show) {
            window.Chatra.show();
          }
          if (premiumTariff[userPriceId] && !currentChallenge.isActive) {
            setShowTeacherChat(true);
          } else setShowTeacherChat(false);
        } else {
          setShowTeacherChat(false);
          if (!currentChallenge.isActive) {
            window.chaport.q('startSession');
          } else {
            window.chaport.q('stopSession');
          }
        }
      }
    });
  }, [user, currentChallenge]);

  return (
    <div>
      {showTeacherChat && (
      <ChatButton onClick={
        () => { if (window.Chatra) window.Chatra.openChat(true); }} />
      )}
    </div>
  );
}

//  <button onclick="Chatra('closeChat', true)">Open chat window</button>

const ChatButton = styled.button`
    background: url(${otterLiveTutor}) no-repeat right 0px bottom 0px;
    border: none;
    color: white;
    padding: 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    border-radius: 50%;
    z-index:1000;
    position:fixed; bottom: 0; right:0;`;
