import React from 'react';
import {
  BrowserRouter,
} from 'react-router-dom';
import Routes from './routes';
import Chats from './Chats';
import { CreateRoutes } from './route/renderRoute';
import { teacherRouteConfig } from './route/teacherRouteConfig';
import { defaultRouteConfig } from './route/defaultRouteConfig';
import { useSelector } from 'react-redux';
import { getUserProfile } from './features/user/userState';


export default function App() {
  const profile = useSelector(getUserProfile);

  return (
    <BrowserRouter>
      {/* {profile.userType === 'teacher' ? (
        <CreateRoutes routeConfig={[...defaultRouteConfig, ...teacherRouteConfig]} />
      ) : ( */}
      <Routes />
      {/* )} */}
      <Chats />
    </BrowserRouter>
  );
}
