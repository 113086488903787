import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import ProfileEditContainer from '../ProfileEditContainer';
import InputSelect, { SchoolsListTypes } from './InputSelect';
import LabelForInput from '../../Form/FieldsEntity/LabelForInput';
import ChangeDate from '../PersonalInformation/ChangeDate';
import TargetSchoolsList from './TargetSchoolsList';
import FormElements from '../../Form';
import ProfilePage from '../ProfilePage';
import { SIDEBAR_MENU } from '../../Form/FieldsEntity/Sidebar';
import { getUserProfile, updateProfile } from '../../features/user/userState';
import { useLoadSchools } from './academicHooks';
import { SchoolType, UserProfileOnInitType, UserProfileType } from '../../features/user/types';
import { ErrorsType } from '../../utils/createForm';
import { InputError } from '../../Form/FieldsEntity/FormInput';

const {
  Button, Radio,
} = FormElements;

const labelCurrentSchoolInfo = 'Please enter student’s current school.';

const AcademicInformation = () => {
  const [disabledChangeDate, setDisabledChangeDate] = useState(false);

  const dispatch = useDispatch();

  const initialValues: UserProfileType = useSelector(getUserProfile);

  return (
    <ProfilePage selectedMenuItem={SIDEBAR_MENU.EDIT_PROFILE}>
      <ProfileEditContainer current="academic">
        {initialValues._id && (

        <Formik
          initialValues={{
            // currentSchool: initialValues.currentSchool,
            nextExamDate: initialValues.nextExamDate,
            // targetSchools: initialValues.targetSchools,
          }}
          validate={
            (values: UserProfileOnInitType) => {
              const errors: ErrorsType = {};

              // if (!values.currentSchool) {
              //   errors.currentSchool = ['Current school is required'];
              // }

              if (!values.targetSchools) {
              }

              return { ...errors };
            }
          }
          onSubmit={
            (values, { setSubmitting }) => {
              dispatch(updateProfile(values));
            }
          }
        >
          {({
            values,
            handleSubmit,
            handleReset,
            setFieldValue,
            errors,
          }) => (
            <form onSubmit={handleSubmit} onReset={handleReset}>
              <CurrentSchoolLabel name="Current school" labelInfo={labelCurrentSchoolInfo}>
                <InputSelectStyled
                  name="currentSchool"
                  targetSchool={initialValues.currentSchool?.name}
                  onChangeSchool={(target: string) => setFieldValue('currentSchool', target)}
                  error={
                    !!(errors?.currentSchool && errors.currentSchool.length > 0)
                  }
                />
                <InputError errors={errors.currentSchool} />
              </CurrentSchoolLabel>

              <Title>Approximate Exam Date</Title>
              <ChangeDateWrapper>
                {!disabledChangeDate && (
                <ChangeDate
                  dateValue={values.nextExamDate}
                  onChange={(date: string) => setFieldValue('nextExamDate', date)}
                  errors={errors.nextExamDate}
                />
                )}
                <RadioStyled
                  onClick={() => setDisabledChangeDate(!disabledChangeDate)}
                  checked={disabledChangeDate}
                  onChange={() => setFieldValue('examDate', disabledChangeDate)}
                >
                  Don’t know/not applicable
                </RadioStyled>
              </ChangeDateWrapper>

              <TargetSchoolsList
                schoolsList={[]}
                targetSchoolsList={initialValues.targetSchools || []}
                onChangeTargetSchools={(targetSchools: string[]) => setFieldValue('targetSchools', targetSchools)}
                errors={errors.targetSchools}
              />
              <ButtonWrapper>
                <CancelButton type="reset">Cancel</CancelButton>
                <SaveButton type="submit" onClick={() => false}>Save changes</SaveButton>
              </ButtonWrapper>
            </form>
          )}
        </Formik>
        )}
      </ProfileEditContainer>
    </ProfilePage>
  );
};

export default AcademicInformation;

const InputSelectStyled = styled(InputSelect)`
  max-width: 570px;
`;

const ButtonWrapper = styled.div`
  width: fit-content;
  display: flex;
  margin-left: auto;
  margin-top: 60px;
`;

const SaveButton = styled(Button)`
  min-width: 128px;
  width: auto;
  height: auto;
  padding: 13px 25px;
  border: 3px solid transparent;
  color: #FFF;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  background-color: #9077F5;
  margin-left: 20px;
`;

const CancelButton = styled(SaveButton)`
  margin-left: 0;
  background-color: #FFF;
  border-color: #9C9C9C;
  color: #9C9C9C;
  :hover:not(:disabled) {
    background-color: #FFF;
    border-color: #F87700;
    color: #F87700;
  }
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
`;

const CurrentSchoolLabel = styled(LabelForInput)`
  margin-bottom: 40px;
`;

const ChangeDateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const RadioStyled = styled(Radio)`
  margin-left: 26px;
  align-self: baseline;
  margin-top: 34px;
  :not(:last-child) {
    margin-right: 20px;
  }

  & > span {
    font-size: 16px;
    font-weight: normal;
    :before {
      width: 25px;
      height: 25px;
    }
  }

  & > input:checked + span::before {
    background-color: #9077F5;
  }
`;
