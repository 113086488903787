import React, { KeyboardEvent } from "react";
import styled from "styled-components/macro";
import { Input } from "../../../Form/Form";
import { Slider } from "antd";

interface AssignmentSettingsBlockProps {
  onChangeNumberOfQuestionsHandler: (num: string) => void;
  onChangeCompexityLevelHandler: (values: [number, number]) => void;
  numberOfQuestions: string;
  complexityLevel: [number, number];
}

const invalidChars = ["-", "+", "e", ".", ","];

export default function AssignmentSettingsBlock({
  onChangeNumberOfQuestionsHandler,
  onChangeCompexityLevelHandler,
  numberOfQuestions,
  complexityLevel,
}: AssignmentSettingsBlockProps) {
  const inputOnChangeHandler = (value: string, setValue: (num: string) => void) => {
    setValue(value);
    if (value.length > 2) {
      setValue(value.slice(1, 3));
    }
    if (+value > 50) {
      setValue('50');
    }
  };
  const blockInvalidChars = (e: KeyboardEvent<HTMLInputElement>) => {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Container>
      <NumberOfQuestionsBlock>
        <StyledSpan>Number of questions</StyledSpan>
        <StyledInput
          value={numberOfQuestions}
          type="number"
          min={1}
          max={50}
          onKeyDown={blockInvalidChars}
          onChange={(e) => inputOnChangeHandler(e.target.value, onChangeNumberOfQuestionsHandler)}
        />
      </NumberOfQuestionsBlock>
      <ComplexityLevelBlock>
        <TextContent>
          <StyledSpan>Complexity level</StyledSpan>
          <BoldSpan>{`${complexityLevel[0]} - ${complexityLevel[1]}`}</BoldSpan>
        </TextContent>
        <Slider
          range={true}
          max={6}
          min={1}
          defaultValue={complexityLevel}
          onChange={(e) => onChangeCompexityLevelHandler(e as [number, number])}
        />
      </ComplexityLevelBlock>
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #eeeaff;
  /* margin: 15px; */
  margin-left: 15px;
  max-width: 220px;
  max-height: 150px;
`;

const NumberOfQuestionsBlock = styled.div`
  display: flex;
  gap: 12px;
`;

const StyledInput = styled(Input)`
  padding: 10px;
  max-width: 75px;
  &:focus {
    outline: 2px solid #9077f5;
  }
`;

const StyledSpan = styled.span``;

const BoldSpan = styled(StyledSpan)`
  font-weight: bold;
`;

const ComplexityLevelBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TextContent = styled.div`
  display: flex;
  justify-content: space-between;
`;
