import React from 'react';
import styled from 'styled-components/macro';
import {
  useHistory,
} from 'react-router-dom';
import fill from 'lodash/fill';
import FormElements from '../Form';
import Notification from '../Form/FieldsEntity/Notification';
import { QuestionType } from '../features/challenge/types';

const {
  Button,
} = FormElements;

interface FinishTestTypes {
  onContinue?: () => void
  numOfQuestion: number
  questionsList: QuestionType[]
  challengeId: string
  challengeType: string
  finisChalenge: () => void
}

const lastQuestions = (items: QuestionType[]) => {
  return items.find((item) => item.userAnswer === undefined);
};

const FinishTest = ({
  finisChalenge,
  onContinue,
  questionsList,
  numOfQuestion,
  challengeId,
  challengeType
}: FinishTestTypes) => {
  const { push } = useHistory();

  const empyQuestions = fill(Array(numOfQuestion - questionsList.length), {});

  const textOnFinishButton = challengeType === 'exam' ? 'Finish exam' : 'End session';

  return (
    <Wrapper>
      {lastQuestions(questionsList) && (
        <NotificationStyled>
          <AlertText>
            You haven’t answered questions
          {' '}
          </AlertText>
          {questionsList.map((item: any, n: number) => {
            if (item.userAnswer === undefined && challengeType === 'exam') {
              return (
                <AlertQuestionNumber key={n}>{n + 1}</AlertQuestionNumber>
              );
            }
          })}
          {empyQuestions.map((_, n) => (
            <AlertQuestionNumber key={n}>{n + 1 + questionsList.length}</AlertQuestionNumber>
          ))}
        </NotificationStyled>
      )}
      <Title>Do you want to finish the {challengeType === 'exam' ? 'exam' : 'session'}?</Title>
      <Description>
        Finish the {challengeType === 'exam' ? 'exam' : 'session'} or go back to the questions.
      </Description>
      <ButtonsWrapper>
        <FinishButton onClick={finisChalenge}>{textOnFinishButton}</FinishButton>
      <ContinueButton onClick={onContinue}>Continue {challengeType}</ContinueButton>
      </ButtonsWrapper>
    </Wrapper >
  );
};

export default FinishTest;

const Wrapper = styled.div``;

const Title = styled.h1`
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 14px;
`;

const Description = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 70px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const FinishButton = styled(Button)`
  width: auto;
  background-color: ${({ theme }) => theme.color};
  margin-right: 28px;
  height: 74px;
  box-sizing: border-box;
  padding: 20px 68px;
  
  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.hoverColor};
  }
`;

const ContinueButton = styled(FinishButton)`
  border: 3px solid ${({ theme }) => theme.color};
  background-color: #FFF;
  color: ${({ theme }) => theme.color};
  :hover {
    color: #FFF;
    border-color: transparent;
  }
`;

const NotificationStyled = styled(Notification)`
  background-color: ${({ theme }) => theme.color};
`;

const AlertText = styled.span`
  display: inline;
`;

const AlertQuestionNumber = styled.span`
  display: inline;
  margin-left: 5px;
  :not(:last-child)::after {
    content: ',';
    display: inline;
  }
`;
