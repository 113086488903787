import { fill } from 'lodash';
import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import Modal from '../Form/FieldsEntity/Modal';

const LoadIndikator = () => {
  const emptyArray = fill(Array(3), 0);

  return (
    <IndicationContainer>
      {emptyArray.map((_, n) => (
        <ItemIndicator />
      ))}
    </IndicationContainer>
  );
};

const ModalSubmittingAnswers = () => {
  return (
    <Modal unclosed onClose={() => {}}>
      <Wrapper>
        <Description>
          Submitting answers.
          <br />
          Otter is marking!
        </Description>
        <LoadIndikator />
      </Wrapper>
    </Modal>
  );
};

export default ModalSubmittingAnswers;

const Wrapper = styled.div`
  padding: 88px 90px 82px;
  max-width: 740px;
  width: 100vw;
`;

const Description = styled.p`
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  margin-bottom: 38px;
  text-align: center;
  width: 100%;
`;

const IndicationContainer = styled.div`
  display: flex;
  margin: 0 auto;
  width: fit-content;
`;

const jumping = keyframes`
  from {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(-10px);
  }

  50% {
    transform: translateY(0px);
  }

  75% {
    transform: translateY(0px);
  }

  to {
    transform: translateY(0px);
  }
`;

const ItemIndicator = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #95D354;
  animation-name: ${jumping};
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  :nth-child(1) {
    animation-delay: .5s;
  }

  :nth-child(2) {
    animation-delay: 1s;
  }

  :nth-child(3) {
    animation-delay: 1.5s;
  }

  :not(:last-child) {
    margin-right: 22px;
  }
`;
