import React, { useState } from "react";
import styled from "styled-components/macro";
import { DatePicker, TimePicker } from "antd";
import { AssignmentFields } from "../../../project-types/assignments/types";
import { TitleH2, TitleH5 } from "../../../styledElements/styledTextElements";
import Elements from '../../../Form';
import moment from "moment";
import { ApiChangeAssignment } from "../../../project-types/assignments/api-types";

const { Input, Button } = Elements;

interface AssignCompleteProps {
  onAssignClickHandler: (formData: ApiChangeAssignment['data']) => void;
  assignment: AssignmentFields;
}

export default function AssignComplete({
  onAssignClickHandler,
  assignment
}: AssignCompleteProps) {
  const [newName, setNewName] = useState(assignment.name);
  const [newDate, setNewDate] = useState(new Date(assignment.dueDate));

  const onAssignClick = () => {
    const formData = { name: newName, dueDate: newDate.toString() };
    onAssignClickHandler(formData);
  };

  const onChangeDate = (date: Date) => {
    const changedDate = new Date(newDate).setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    setNewDate(new Date(changedDate));
  };

  const onChangeTime = (date: Date) => {
    const changedTime = new Date(newDate).setHours(date.getHours(), date.getMinutes(), date.getSeconds());
    setNewDate(new Date(changedTime));
  };

  return (
    <Wrapper>
      <TitleH2>{`Assign ${assignment.type.toLowerCase()}`}</TitleH2>
      <ChangeNameBlock>
        <ChangeNameLabel>
          <StyledSpan>Assignment name</StyledSpan>
          <StyledSpan>{`${newName.length} / 40`}</StyledSpan>
        </ChangeNameLabel>
        <Input value={newName} onChange={(e) => setNewName(e.target.value)} maxLength={40} />
      </ChangeNameBlock>
      <ChangeDateContainer>
        <ChangeDateBlock>
          <StyledTitleH5>Due date</StyledTitleH5>
          <StyledDatePicker value={moment(newDate, 'MMMM DD')} onChange={(date) => date && onChangeDate(date.toDate())} format="MMMM DD" />
        </ChangeDateBlock>
        <ChangeDateBlock>
          <StyledTitleH5>Due time</StyledTitleH5>
          <StyledTimePicker value={moment(newDate, 'h:mm A')} onChange={(date) => date && onChangeTime(date.toDate())} format="h:mm A" />
        </ChangeDateBlock>
      </ChangeDateContainer>
      <ButtonBlock>
        <AssignButton onClick={onAssignClick}>Assign</AssignButton>
      </ButtonBlock>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  max-width: 914px;
`;

const ChangeNameBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 370px;
`;

const ChangeNameLabel = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledSpan = styled.span`
  font-size: 14px;
  line-height: 18px;
  color: #1f1f1f;
`;

const StyledDatePicker = styled(DatePicker)`
  border-radius: 10px;
  padding: 17px 23px;
`;

const StyledTimePicker = styled(TimePicker)`
  border-radius: 10px;
  padding: 17px 23px;
`;

const ChangeDateContainer = styled.div`
  display: flex;
  gap: 35px;
`;

const ChangeDateBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

const StyledTitleH5 = styled(TitleH5)`
  white-space: nowrap;
`;

const ButtonBlock = styled.div`
  padding-top: 20px;
  border-top: 2px solid #e0e0e0;
`;

const AssignButton = styled(Button)`
  background-color: #9077f5;
  width: auto;
  font-size: 22px;
  line-height: 26px;
  padding: 15px 60px;
`;