import { select } from 'd3-selection';
import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components/macro';

import { drawVerticalLines, drawChart, CONFIG, ChartType } from './utils';




interface TopicBoardResultType {
  topicScore: Array<{ title: string, value: number }>
}

export default function TopicBoardResult({ topicScore }: TopicBoardResultType) {
  const ref = useRef<SVGSVGElement | null>(null);

  const height = (CONFIG.BAR_HEIGHT + CONFIG.BAR_MARGIN * 2) * topicScore.length + CONFIG.GRID_OUTER + 10 + CONFIG.GRID_TEXT_MARGIN + CONFIG.GRID_OUTER;

  useLayoutEffect(() => {
    if (ref && topicScore.length > 0) {
      const chart = select(ref.current).append('g').attr('transform', 'translate(10, 10)')
      drawVerticalLines((chart as any), [0, 10, 20, 30, 40, 50, 60, 70, 80], topicScore.length, CONFIG);
      drawChart((chart as any), topicScore, CONFIG);
    }
  }, [topicScore])

  return (<div>
    <StyledSvg ref={ref} style={{ height: `${height}px` }}></StyledSvg>
  </div>);
}


const StyledSvg = styled.svg`
  width: 100%;
  opacity: 0.99;
  margin-bottom: 40px;
`;