import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

interface NoteModalProps {
  onCreateNote: (note: string) => void
  onCancel: () => void
  visible?: boolean
}

const NoteModal = ({
  onCreateNote,
  onCancel,
  visible,
}: NoteModalProps) => {
  const [note, setNote] = useState<string>('');

  return (
    <Modal
      title="Create note"
      onOk={() => onCreateNote(note)}
      onCancel={onCancel}
      visible={visible}
    >
      <TextArea value={note} onChange={(e) => setNote(e.target.value)} style={{minHeight: 400}} />
    </Modal>
  );
};

export default NoteModal;
