import React from "react";
import styled from "styled-components/macro";
import { Checkbox } from "../../../Form/Form";
import { EditIconCircle } from "../../../images/iconSvg";
import { AssignmentFields } from "../../../project-types/assignments/types";
import moment from "moment";

const checkMark = require("../../../images/check-mark.svg");

interface AssigmentTableProps {
  assignments: AssignmentFields[];
  onEditClickHandler: (assignment: AssignmentFields) => void;
  onViewReportClickHandler: (assignment: AssignmentFields) => void;
  isDeleteActive: boolean;
  onDeleteClickHandler: () => void;
  onSelectAssignments: (id: string) => void;
  onSelectAllAssignments: () => void;
  selectedAssignments: Array<string>;
}

export default function AssigmentsTable({
  assignments,
  onEditClickHandler,
  onViewReportClickHandler,
  isDeleteActive,
  onDeleteClickHandler,
  onSelectAssignments,
  onSelectAllAssignments,
  selectedAssignments,
}: AssigmentTableProps) {
  return (
    <TableStyled>
      <TableHeadStyled>
        <tr>
          <ThStyled scope="col">Assignment name</ThStyled>
          <ThStyled scope="col">Due date</ThStyled>
          <ThStyled scope="col">completion</ThStyled>
          <ThStyledDelete onClick={onDeleteClickHandler} isActive={isDeleteActive} scope="col">
            Delеte
          </ThStyledDelete>
          <ThStyled scope="col">
            <CheckboxStyled onChange={onSelectAllAssignments} checked={selectedAssignments.length === assignments.length} />
          </ThStyled>
        </tr>
      </TableHeadStyled>
      <tbody>
        {assignments.map((assignment) => (
          <tr key={assignment._id}>
            <TdStyled>
              <AssigmentNodeTitle>{assignment.name}</AssigmentNodeTitle>
              <AssigmentNodeType>{assignment.type}</AssigmentNodeType>
            </TdStyled>
            <TdStyled>
              {moment(new Date(assignment.dueDate)).format("MMM Do, hh:mm A")}
            </TdStyled>
            <TdStyled>
              <ViewReportLink
                onClick={() => onViewReportClickHandler(assignment)}
              >
                View report
              </ViewReportLink>
            </TdStyled>
            <TdStyled>
              <ViewReportLink onClick={() => onEditClickHandler(assignment)}>
                <EditIconStyled />
                Edit
              </ViewReportLink>
            </TdStyled>
            <TdStyled>
              <CheckboxStyled onChange={() => onSelectAssignments(assignment._id)} checked={selectedAssignments.includes(assignment._id)} />
            </TdStyled>
          </tr>
        ))}
      </tbody>
    </TableStyled>
  );
}

const TableStyled = styled.table`
  width: 100%;
`;

const TableHeadStyled = styled.thead`
  background: #eeeaff;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
`;

const ThStyled = styled.th`
  padding: 14px 0px 10px 21px;
`;

const ThStyledDelete = styled(ThStyled)<{ isActive: boolean }>`
  color: ${(props) => (props.isActive ? "#1F1F1F" : "#C4C4C4")};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const TdStyled = styled.th`
  padding: 14px 0px 10px 21px;
`;

const AssigmentNodeTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`;

const AssigmentNodeType = styled.div`
  font-size: 12px;
  line-height: 22px;
`;

const ViewReportLink = styled.a`
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-transform: capitalize;
  color: #9077f5;
`;

const EditIconStyled = styled(EditIconCircle)`
  & svg {
    fill: #9077f5;
  }

  display: inline;
  font-size: 18px;
  margin-right: 7px;
`;

const CheckboxStyled = styled(Checkbox)`
  & > span::before  {
    background: none;
  }

  & > input:checked + span::before {
    background: url(${checkMark}) no-repeat center; 
    background-color: #F87700;
  }
`;
