import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { UserProfileType } from '../../features/user/types';
import { AvatarsType } from '../../database/avatars';
import { PayPlanStripe, PayPlanType } from '../../ProfilePage/Subscription/types';

interface ChildStatisticsLayoutProps {
  user?: UserProfileType
  userAvatar?: AvatarsType
  currentTariffName: string
  userAge?: string | number
  examsNumber: number
  testsNumber: number
  examDate: string
}

const ChildStatisticsLayout = ({
  user,
  userAvatar,
  currentTariffName,
  userAge,
  examsNumber,
  testsNumber,
  examDate,
}: ChildStatisticsLayoutProps) => (
  <Wrapper>
    <LeftInfo>
      <UserInfoWrapper>
        <UserAvatar src={userAvatar?.avatarImg} />
        <UserInfo>
          <UserName title={user?.fullName}>{user?.fullName}</UserName>
          <UserAge>Age: {userAge || 'Not specified'}</UserAge>
          <UserPlanLink to={`${process.env.PUBLIC_URL}/profile/subscription`}>
            Plan:
            {' '}
            {currentTariffName}
          </UserPlanLink>
        </UserInfo>
      </UserInfoWrapper>
      <ChallengesInfo>
        <ChallengesTitle>Completed:</ChallengesTitle>
        <Challenges>
          <Challenge>
            <ChallengeNumber>{examsNumber}</ChallengeNumber>
            <ChallengeName>{examsNumber <= 1 ? 'mock exam' : 'mock exams'}</ChallengeName>
          </Challenge>
          <Challenge>
            <ChallengeNumberOrange>{testsNumber}</ChallengeNumberOrange>
            <ChallengeName>{testsNumber <= 1 ? 'practice test' : 'practice tests'}</ChallengeName>
          </Challenge>
        </Challenges>
      </ChallengesInfo>
    </LeftInfo>
    <RightInfo>
      <SchoolsAndExamDateInfo>
        <CurrentSchool>
          <SchoolTitle>Current school:</SchoolTitle>
          <ItemName>{user?.currentSchool?.name || 'Not selected'}</ItemName>
        </CurrentSchool>
        <TargetSchools>
          <TargetSchoolsTitle>Target schools:</TargetSchoolsTitle>
          <TargetSchoolsList>
            {user?.targetSchools && user?.targetSchools.length > 0
              ? user?.targetSchools?.map((item) => (
                <SchoolTarget key={item._id}>
                  <ItemName>{item.name}</ItemName>
                </SchoolTarget>
              )) : (
                <ItemName>
                  Not selected
                </ItemName>
              )}
          </TargetSchoolsList>
        </TargetSchools>
        <ExamDate>
          <ExamDateName>Exam date:</ExamDateName>
          <ItemName>{examDate}</ItemName>
        </ExamDate>
      </SchoolsAndExamDateInfo>
    </RightInfo>
  </Wrapper>
);

export default ChildStatisticsLayout;

const Wrapper = styled.div`
  margin-top: 48px;
  padding: 16px;
  background-color: #EEEAFF;
  display: flex;
  justify-content: center;
  min-height: 190px;
`;

const LeftInfo = styled.div`
  border-right: 2px solid #FFF;
  display: flex;
  align-items: center;
`;

const RightInfo = styled.div`

`;

const UserInfoWrapper = styled.div`
  display: flex;
  margin-right: 46px;
`;

const UserAvatar = styled.img`
  width: 143px;
  height: 143px;
  margin-right: 24px;
`;

const UserInfo = styled.div`
  width: 220px;
  overflow: hidden;
  align-self: center;
`;

const UserName = styled.span`
  font-weight: bold;
  font-size: 29px;
  line-height: 35px;
  display: block;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  margin-bottom: 17px;
  position: relative;

  ::after {
    content: '';
    display: block;
    height: 100%;
    width: 30px;  
    background: linear-gradient(90deg,rgba(238, 234, 255, 0) 0%,#EEEAFF 100%);
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const UserAge = styled.span`
  font-size: 20px;
  line-height: 26px;
  display: block;
  margin-bottom: 4px;
`;

const UserPlanLink = styled(Link)`
  font-size: 20px;
  line-height: 26px;
`;

const ChallengesInfo = styled.div`
  align-self: center;
  margin-right: 46px;
`;

const ChallengesTitle = styled.span`
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  margin-bottom: 8px;
  margin-left: 12px;
  display: block;
`;

const Challenges = styled.ul`
  padding: 20px 26px;
  border-radius: 10px;
  background-color: #FFF;
`;

const Challenge = styled.li`
  display: flex;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 11px;
  }
`;

const ChallengeNumber = styled.span`
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #9077F5;
  margin-right: 26px;
`;

const ChallengeNumberOrange = styled(ChallengeNumber)`
  color: #F87700;
`;

const ChallengeName = styled.span`
  font-size: 20px;
  line-height: 26px;
`;

const SchoolsAndExamDateInfo = styled.div`
  margin-left: 47px;
`;

const CurrentSchool = styled.div`
  margin-bottom: 4px;
`;

const TargetSchools = styled.div`
  margin-bottom: 11px;
`;

const TargetSchoolsList = styled.ul`
  padding: 0;
  list-style: none;
`;

const SchoolTarget = styled.li`
  margin-bottom:  4px;
`;

const SchoolTitle = styled.span`
  display: block;
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  color: #9077F5;
`;

const TargetSchoolsTitle = styled(SchoolTitle)`
  color: #F87700;
`;

const ItemName = styled.span`
  font-size: 16px;
  line-height: 22px;
`;

const ExamDate = styled.div`

`;

const ExamDateName = styled(SchoolTitle)`
  display: inline;
  color: #1F1F1F;
  margin-right: 10px;
`;
