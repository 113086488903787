import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SIDEBAR_MENU } from '../../Form/FieldsEntity/Sidebar';
import ModalInPortal from '../../utils/ModalPortal';
import TeacherDashboard from '../TeacherDashboard';
import GroupBar, { ControlsMode } from './GroupBar';
import ModalContentFromAddGroup from './ModalContentForAddGroup';
import TeacherGroupLayout from './TeacherGroupsLayout';
import { GroupFields } from '../../project-types/groups/types';
import { createTeacherGroup, getTeacherGroups, loadTeacherGroups, deleteTeacherGroup } from '../../features/teacher/teacherState';


const TeacherGroups = () => {
  const {
    ModalRender,
    onShow,
    onClose,
  } = ModalInPortal();

  const { push } = useHistory();
  const dispatch = useDispatch();

  const teacherGroups = useSelector(getTeacherGroups);

  useEffect(() => {
    dispatch(loadTeacherGroups());
  }, []);

  const addGroupNameAndRedirect = (groupName: string) => {
    dispatch(createTeacherGroup({ name: groupName }));
    onClose();
  };

  const onDeleteGroup = (id: GroupFields['_id']) => {
    // eslint-disable-next-line dot-notation
    dispatch(deleteTeacherGroup(id));
  };

  const onEditGroup = (id: GroupFields['_id']) => {
    push(`${process.env.PUBLIC_URL}/teacher/roaster/${id}`);
  };

  const onOpenGroup = (id: GroupFields['_id']) => {
    push(`${process.env.PUBLIC_URL}/teacher/group/${id}`);
  };

  return (
    <TeacherDashboard
      sidebarMenuSelected={SIDEBAR_MENU.MY_GROUPS}
    >
      <>
        {ModalRender && (
          <ModalRender>
            <ModalContentFromAddGroup
              onNext={addGroupNameAndRedirect}
              onClose={onClose}
            />
          </ModalRender>
        )}
        <TeacherGroupLayout
          onAddNewGroup={onShow}
        >
          {teacherGroups && teacherGroups.map((group: GroupFields) => (
            <GroupBar
              controlsMode={ControlsMode.EDIT_AND_DELETE}
              key={group.owner}
              id={group._id}
              name={group.name}
              noteText={group.students.length === 1 ? `${group.students.length} student` : `${group.students.length} students`}
              onDelete={onDeleteGroup}
              onEdit={onEditGroup}
              onRedirect={onOpenGroup}
              activeAssigments={0}
            />
          ))}
        </TeacherGroupLayout>
      </>
    </TeacherDashboard>
  );
};

export default TeacherGroups;
