import { message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { request } from '../api';
import { loadProfile } from '../features/user/userState';
import Ellements from '../Form';

const {
  Radio,
} = Ellements;

const subjects = [
  'Maths',
  'English',
  'Verbal reasoning',
  'Non-verbal reasoning',
];

interface SelectSubjectBoardProps {
  onSelectSubject?: (subject: string) => void
}

const SelectSubjectBoard = ({
  onSelectSubject,
}: SelectSubjectBoardProps) => {
  const [selectedSubject, setSelectedSubject] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedSubject === '') return;

    request({
      method: 'POST',
      url: 'default-exam',
      data: {
        topicId: selectedSubject,
      },
    }, [200])
      .then(() => {
        if (onSelectSubject) {
          onSelectSubject(selectedSubject);
        }
        message.success('Subject selected');
        dispatch(loadProfile());
      })
      .catch(() => message.error('Something went wrong'));
  }, [selectedSubject]);

  return (
    <Wrapper>
      <DescriptionSection>
        Select the subject you are interested in, for which you will take exams and tests
      </DescriptionSection>
      {subjects.map((subject: string) => (
        <RadioStyled
          key={subject}
          name="subject"
          // defaultChecked={selectedSubject === subject}
          onChange={() => setSelectedSubject(subject)}
          required
        >
          <SubjectName>{subject}</SubjectName>
        </RadioStyled>
      ))}
    </Wrapper>
  );
};

export default SelectSubjectBoard;

const Wrapper = styled.div`
  
`;

const SubjectName = styled.span`
  font-size: 32px;
  line-height: 52px;
  font-weight: bold;
`;

const RadioStyled = styled(Radio)`
  margin-right: 24px;
`;

const DescriptionSection = styled.p`
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 52px;
  align-self: flex-start;
`;
