import { useEffect, useState } from 'react';
import { SchoolType } from '../../features/user/types';
import { request } from '../../api';
import { message } from 'antd';

export const createSchool = (values: SchoolType) => {
  request(
    {
      method: 'POST',
      url: 'admin/school',
      data: values,
    }, [200],
  )
    .then(() => {
      message.success('School created');
      window.location.href = `${process.env.PUBLIC_URL}/schools`;
    });
};

export const editSchool = (values: SchoolType) => {
  request(
    {
      method: 'PATCH',
      url: `admin/school/${values._id}`,
      data: values,
    },
    [200],
  )
    .then(() => {
      message.info('School updated');
      window.location.href = `${process.env.PUBLIC_URL}/schools`;
    });
};

export const useGetSchool = (_id: string) => {
  const [school, setSchool] = useState<SchoolType>({_id: '', name: '', rate: 0});

  useEffect(() => {
    request(
      {
        method: 'GET',
        url: `admin/school/${_id}`,
      },
      [200],
    )
      .then(({ data }) => setSchool(data));
  }, [setSchool]);

  return school;
};
