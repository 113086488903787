import React, { ReactElement, useEffect } from 'react';
import styled from 'styled-components/macro';
import { FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import LabelForInput from '../Form/FieldsEntity/LabelForInput';
import Radio from '../Form/FieldsEntity/Radio';
import { Input } from '../Form/Form';
import InputSelect from '../ProfilePage/AcademicInformation/InputSelect';
import Ellements from '../Form';
import Page from './PageWrapperForTeacherSetUp';
import { TEACHER_CONF_TYPE } from './TeacherSetUp';
import { InputError } from '../Form/FieldsEntity/FormInput';

import { TYPE_TEACHER } from '../project-types/teacher/types';
import { ApiCreateTeacher } from '../project-types/teacher/api-types';

import { getSignUpFormFields, signUpActions } from '../features/user/userState';

const {
  Button,
} = Ellements;

const labelInfo = 'To select your school, please start typing the school name';

type FormikPropsByCurrentValues = FormikProps<TEACHER_CONF_TYPE['initialValue']>
type SignUpErrorsType = ApiCreateTeacher['failResponse']['errors']

interface TeacherSetUpBodyProps {
  selectedConf: TEACHER_CONF_TYPE
  onSelectConf: (conf: TEACHER_CONF_TYPE) => void
  setUpConf: TEACHER_CONF_TYPE[]
  formikProps: FormikPropsByCurrentValues
}

const TeacherSchoolSetUp = ({
  setFieldValue,
  handleChange,
  values,
  errors,
}: FormikPropsByCurrentValues,
  signUpErrors: SignUpErrorsType,
) => {
  return (
    <>
      <LabelSelectSchool name="School" labelInfo={labelInfo}>
        <InputSelectStyled
          onChangeSchool={(target) => setFieldValue('school', target)}
          // targetSchool={values.school}
          error={errors.school ? errors.school.length > 0 : false}
        />
        <InputError errors={errors.school} />
      </LabelSelectSchool>

      <SubTitle>
        Enter your professional email adress
      </SubTitle>
      <LabelForInput name="Email adress">
        <InputStyled
          name="email"
          type="email"
          placeholder="Email@schoolname.com"
          onChange={handleChange}
          value={values.email}
          required
          error={errors.email ? errors.email.length > 0 : false}
        />
        <InputError errors={errors.email || signUpErrors.user} />
      </LabelForInput>
    </>
  );
};

const AgencySchoolSetUp = ({
  handleChange,
  values,
  errors,
}: FormikPropsByCurrentValues,
  signUpErrors: SignUpErrorsType,
) => {
  return (
    <>
      <LabelSelectSchool name="Agency">
        <InputStyled
          name="agency"
          placeholder="Agency name"
          onChange={handleChange}
          value={values.agency}
          required
          error={errors.agency ? errors.agency.length > 0 : false}
        />
        <InputError errors={errors.agency} />
      </LabelSelectSchool>

      <SubTitle>
        Enter your professional email adress
      </SubTitle>
      <LabelForInput name="Email adress">
        <InputStyled
          name="email"
          type="email"
          placeholder="Email@schoolname.com"
          onChange={handleChange}
          value={values.email}
          required
          error={errors.email ? errors.email.length > 0 : false}
        />
        <InputError errors={errors.email || signUpErrors?.user} />
      </LabelForInput>
    </>
  );
};

const PrivateTutorSetUp = ({
  handleChange,
  errors,
  values,
}: FormikPropsByCurrentValues,
  signUpErrors: SignUpErrorsType,
) => {
  return (
    <>
      <SubTitle>
        Enter your email adress
      </SubTitle>
      <LabelForInput name="Email adress">
        <InputStyled
          name="email"
          type="email"
          placeholder="Email@schoolname.com"
          onChange={handleChange}
          value={values.email}
          required
          error={errors.email ? errors.email.length > 0 : false}
        />
        <InputError errors={errors.email || signUpErrors.user} />
      </LabelForInput>
    </>
  );
};

const TeacherSetUpBody = ({
  selectedConf,
  onSelectConf,
  setUpConf,
  formikProps,
}: TeacherSetUpBodyProps) => {
  const {
    values,
  } = formikProps;

  const signUpErrors = useSelector(getSignUpFormFields).errors;

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(signUpActions.flushErrors());
    };
  }, [selectedConf]);

  const elementsByTeacherType: Record<string, ReactElement> = {
    [TYPE_TEACHER.SCHOOL_TEACHER]: TeacherSchoolSetUp(formikProps, signUpErrors),
    [TYPE_TEACHER.AGENCY_TEACHER]: AgencySchoolSetUp(formikProps, signUpErrors),
    [TYPE_TEACHER.PRIVATE_TUTOR]: PrivateTutorSetUp(formikProps, signUpErrors),
  };

  const isEmptyValues: Record<string, boolean> = {
    [TYPE_TEACHER.SCHOOL_TEACHER]: !values.school || !values.email,
    [TYPE_TEACHER.AGENCY_TEACHER]: !values.agency || !values.email,
    [TYPE_TEACHER.PRIVATE_TUTOR]: !values.email,
  };

  return (
    <Page>
      <Form onSubmit={formikProps.handleSubmit}>
        <Title>To get started, please set up your account</Title>
        <SubTitle>I am...</SubTitle>
        <RadioWrapper>
          {setUpConf.map((teacher) => (
            <RadioStyled
              hoverBorderColor="#F87700"
              backgroundRadio="#F87700"
              key={teacher.type}
              name="teacher_type"
              checked={selectedConf.type === teacher.type}
              onChange={() => onSelectConf(teacher)}
            >
              <TeacherType>{teacher.name}</TeacherType>
            </RadioStyled>
          ))}
        </RadioWrapper>

        {elementsByTeacherType[selectedConf.type]}

        <SaveButton type="submit" disabled={!formikProps.isValid || isEmptyValues[selectedConf.type]}>
          Create an account
        </SaveButton>
      </Form>
    </Page>
  );
};

export default TeacherSetUpBody;

const Form = styled.form`
  max-width: 1005px;
  padding: 54px 88px;
  border-radius: 10px;
  background-color: #FFF;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 32px;
  line-height: 44px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 58px;
`;

const SubTitle = styled.h2`
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 24px;
`;

const RadioWrapper = styled.div`
  display: flex;
  margin-bottom: 66px;
`;

const TeacherType = styled.span`
  font-size: 18px;
  line-height: 26px;
  font-weight: normal;
`;

const RadioStyled = styled(Radio)`
  :not(:last-child) {
    margin-right: 54px;
  }
`;

const InputSelectStyled = styled(InputSelect)`
  max-width: 610px;
`;

const LabelSelectSchool = styled(LabelForInput)`
  margin-bottom: 60px;
`;

const InputStyled = styled(Input)`
  max-width: 550px;
`;

const SaveButton = styled(Button)`
  min-width: 128px;
  width: auto;
  height: auto;
  padding: 17px 63px;
  border: 3px solid transparent;
  color: #fff;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  background-color: #9077f5;
  margin-left: auto;
  margin-top: 67px;
`;
