import React, { useState } from 'react';

import styled from 'styled-components/macro';
import Elements from '../../Form';
import LabelForInput from '../../Form/FieldsEntity/LabelForInput';
import useKeyboardAction from '../../hooks/keyboardAction';
import { TitleH4, TitleH5 } from '../../styledElements/styledTextElements';

const {
  Button,
  Input,
} = Elements;

interface ComponentProps {
  onNext: (groupName: string) => void
  onClose: () => void
}

const ModalContentFromAddGroup = ({
  onNext,
  onClose,
}: ComponentProps) => {
  const [groupName, setGroupName] = useState('');

  const maxLength = 35;
  const groupNameLength = groupName.length;

  const disabledButton = groupNameLength <= 1;

  const groupNameViewLength = `${groupNameLength} / ${maxLength}`;

  const onAddGroupName = () => {
    if (!disabledButton) {
      onNext(groupName);
    }
  };

  useKeyboardAction({
    enterKeyPressed: onAddGroupName,
    escKeyPressed: onClose,
  });

  return (
    <Wrapper>
      <Title>Create New Group</Title>
      <InputTitle>Name your group</InputTitle>
      <Label name="Group Name" leftTitle={groupNameViewLength}>
        <Input
          type="text"
          onChange={(e) => setGroupName(e.target.value)}
          maxLength={maxLength}
          placeholder="Enter group name"
        />
      </Label>
      <NextButton
        onClick={onAddGroupName}
        disabled={disabledButton}
      >
        Next
      </NextButton>
    </Wrapper>
  );
};

export default ModalContentFromAddGroup;

const Wrapper = styled.div`
  padding: 42px;
  max-width: 472px;
  width: 100vw;
`;

const Title = styled(TitleH4)`
  margin-bottom: 74px;
  text-align: center;
`;

const InputTitle = styled(TitleH5)`
  margin-bottom: 19px;
`;

const Label = styled(LabelForInput)`
  margin-bottom: 49px;
  max-width: 350px;
`;

const NextButton = styled(Button)`
  width: fit-content;
  padding: 17px 60px;
  margin: 0 auto;
  background-color: #9077F5;
`;
