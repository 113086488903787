import React, { useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components/macro";
import { useHistory, useParams } from "react-router-dom";
import ItemNumber, {
  QUESTION_NUMBER_STATUSES,
} from "../Form/FieldsEntity/ItemNumber";
import Page from "./PageForExam";
import ChalengeBody from "./ChalengeBody";
import FormElements from "../Form";
import {
  useChangeQuestion,
  useCurrentChalenge,
  useCurrentQuestion,
} from "./ChalengeHooks";
import ExamQuestionList from "./ExamQuestionList";
import colorStyle from "../colorStyle";
import Timer from "../Form/FieldsEntity/Timer";

import NotificationOfTheCorrectAnswer from "./NotificationOfTheCorrectAnswer";

const { LinkButton } = FormElements;

interface CorrectAnswersExamQuestionsProps {}

const CorrectAnswersExamQuestions = ({}: CorrectAnswersExamQuestionsProps) => {
  const {
    challengeId,
    currentQuestionNum: currentQuestionNumRaw,
    challengeType,
  } = useParams<{
    challengeId: string;
    currentQuestionNum: string;
    challengeType: keyof typeof colorStyle;
  }>();
  const currentQuestionNum = Number(currentQuestionNumRaw);

  const currentChallenge = useCurrentChalenge(challengeId);
  const onChangeQuestion = useChangeQuestion(
    (questionNum) =>
      `${process.env.PUBLIC_URL}/chalenge/correct-answers/${challengeType}/${challengeId}/${questionNum}`
  );

  // questionNumber
  const numbOfQuestions = currentChallenge.questionsNumber || 20;
  // const numbOfQuestions = 20;

  const currentQuestion = useCurrentQuestion(
    currentChallenge,
    currentQuestionNum
  );

  const goToPrevQuestion = () => {
    if (currentQuestionNum !== 0) {
      onChangeQuestion(currentQuestionNum - 1);
    }
  };

  const history = useHistory();

  const goToNextQuestion = () => {
    if (currentChallenge.questions.length !== currentQuestionNum) {
      return onChangeQuestion(currentQuestionNum + 1);
    }
    if (challengeType === "test" && currentChallenge.isActive) {
      const nextQuestion = currentQuestionNum + 1;
      history.push(
        `${process.env.PUBLIC_URL}/challenge/${challengeType}/current/${nextQuestion}`
      );
    }
  };

  const [showResultModal, setShowResultModal] = useState(false);

  useEffect(() => {
    if (
      currentChallenge.challengeType === "test" &&
      currentChallenge.isActive
    ) {
      setShowResultModal(true);
      setTimeout(() => {
        setShowResultModal(false);
      }, 1200);
    }
  }, [currentChallenge, setShowResultModal]);

  // `${process.env.PUBLIC_URL}/challenge/finish/${currentChallenge._id}`

  const Back = currentChallenge.isActive ? (
    <BackToResults
      background
      to={`${process.env.PUBLIC_URL}/challenge/${challengeType}/current/${currentQuestionNum}/finish`}
    >
      Finish challenge
    </BackToResults>
  ) : (
    <BackToResults
      background
      to={`${process.env.PUBLIC_URL}/chalenge/detailed-result/${challengeType}/${challengeId}`}
    >
      Go to result
    </BackToResults>
  );

  const isSubject = currentChallenge.topicId?.[0];

  return (
    <ThemeProvider theme={colorStyle[challengeType].theme}>
      <Page isSubject={isSubject}>
        {currentQuestion?.userAnswer && <NotificationOfTheCorrectAnswer
          right={currentQuestion?.right}
          showModal={showResultModal}
        />}
        <Wrapper>
          <QuestionProgress>
            <ExamQuestionList
              numOfQuestion={numbOfQuestions}
              questions={currentChallenge.questions}
              onChangeQuestion={onChangeQuestion}
              currentQuestionNum={currentQuestionNum}
              showAnswerMode
            />
            <Timer chalenge={currentChallenge} onTimeOut={() => {}} />
            {Back}
          </QuestionProgress>
          <ChalengeBody
            currentChalenge={currentChallenge}
            onAnswer={() => {}}
            onSkip={() => {}}
            currentQuestion={currentQuestion}
            currentQuestionNum={currentQuestionNum}
            showAnswer
            onNextQuestion={() => goToNextQuestion()}
            onPreviousQuestion={() => goToPrevQuestion()}
            selectedLastQuestion={
              20 <= currentQuestionNum
            }
          />
        </Wrapper>
      </Page>
    </ThemeProvider>
  );
};

export default CorrectAnswersExamQuestions;

const Wrapper = styled.div`
  max-width: 1005px;
  width: 100vw;
  box-sizing: border-box;
  padding: 50px 60px 84px;
`;

const QuestionProgress = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 120px;
`;

const BackToResults = styled(LinkButton)`
  align-self: baseline;
  padding: 12px 28px;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color};
  :hover {
    background-color: ${({ theme }) => theme.hoverColor};
  }
`;
