import React, { ComponentType, FC } from 'react';
import styled from "styled-components/macro";
import { AddIcon, CheckIcon, SandwitchActiveIcon } from '../../../images/iconSvg';

export enum FilterType {
  ADD_NEW,
  ACTIVE,
  PAST
}

interface FilterDisplayConfig {
  title: string,
  icon: React.ReactNode,
  color: string
}

const AddIconStyled = styled(AddIcon)`
  & svg {
    fill: #9077F5;
  }

  font-size: 30px;
  margin-right: 13px;
`;

const CheckIconStyled = styled(CheckIcon)`
& svg {
  fill: #95D354;
}

font-size: 30px;
margin-right: 13px;
`;

const SandwitchActiveIconStyled = styled(SandwitchActiveIcon)`
& svg {
  fill: #F87700;
}

font-size: 30px;
margin-right: 13px;
`;

const DispalyConfig: { [key in FilterType]: FilterDisplayConfig } = {
  [FilterType.ADD_NEW]: {
    title: 'Add new',
    icon: <AddIconStyled />,
    color: '#EEEAFF',
  },
  [FilterType.ACTIVE]: {
    title: 'Active',
    icon: <CheckIconStyled />,
    color: '#FFD9B5',
  },
  [FilterType.PAST]: {
    title: 'Past',
    icon: <SandwitchActiveIconStyled />,
    color: '#DEF2C9',
  },
};

type AssigmentFilterItemProps = {
  type: FilterType,
  number?: number,
  onClick: () => void
  isSelect?: boolean;
}


export default function AssigmentFilterItem({ onClick, type, number, isSelect = false }: AssigmentFilterItemProps) {
  const displayConfig = DispalyConfig[type];
  const numberString = number ? `(${number})` : '';
  return (
    <RectStyled hoverColor={displayConfig.color} onClick={onClick} isSelect={isSelect}>
      {displayConfig.icon}
      <TitleStyled>{`${displayConfig.title}`}</TitleStyled>
      <NumberStyled>{numberString}</NumberStyled>
    </RectStyled>
  );
}

const RectStyled = styled.div<{ hoverColor: string, isSelect: boolean }>`
  cursor: pointer;
  width: 215px;
  height: 81px;
  display: flex;
  background-color: ${(props) => props.isSelect ? props.hoverColor : "#FFFFFF"};
  &:hover {
    background-color: ${(props) => props.hoverColor};
  }
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-right: 20px;
  padding: 23px 29px;
`;

const TitleStyled = styled.span`
  font-weight: bold;
  font-size: 20px;
`;

const NumberStyled = styled.span`
  font-size: 20px;
  margin-left: 7px;
`;