import React from 'react';
import styled from 'styled-components/macro';
import { schoolsPretested } from '../../config/schoolsPretested';

const ScrollingBar = () => {
  return (
    <Wrapper>
      <List>
        {schoolsPretested.map((school) => (
          <ListItem>{school}</ListItem>
        ))}
      </List>
    </Wrapper>
  );
};

export default ScrollingBar;

const Wrapper = styled.div`
  max-width: 878px;
  width: 100%;
  height: 388px;
  min-height: 388px;
  padding: 17px 35px 17px 0;
  border-radius: 10px;
  overflow-y: scroll;
  background-color: #FFF;
  border-right: 18px solid #FFF;

  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(248, 119, 0, 0.3);
    border-radius: 10px;
    margin-right: 18px;
    margin: 18px;
  }

  ::-webkit-scrollbar-thumb {
    background: #F87700;
    width: 15px;
    height: 33px;
    border-radius: 10px;
  }
`;

const List = styled.ul`
  width: 100%;
  height: 100%;
  list-style: none;
  padding-left: 0;
  background-color: #FFF;
  /* overflow: hidden; */
  border-radius: 10px;
`;

const ListItem = styled.li`
  padding: 13px 30px;
  font-size: 16px;
  line-height: 22px;

  :nth-child(even) {
    background-color: #F7EDE3;
  };
`;
