import React from 'react';
import styled from 'styled-components/macro';
import { UserPublicType } from '../../project-types/users/types';
import HeaderForTeacherDashboard from '../HeaderForTeacherDashboard';
import GroupBar, { ControlsMode } from '../TeacherGroupInDashboard/GroupBar';

interface ComponentProps {
  onAddNewStudents: () => void
  onRemoveStudents: (id: UserPublicType['_id']) => void,
  studentsLength: number,
  students: UserPublicType[]
}

const TeacherStudentsLayout = ({
  studentsLength,
  onAddNewStudents,
  onRemoveStudents,
  students,
}: ComponentProps) => {
  const titleInHeader = `My students (${studentsLength})`;


  return (
    <Wrapper>
      <HeaderForTeacherDashboard
        onAdd={onAddNewStudents}
        title={titleInHeader}
      />
      {students.length < 1 && (
        <Description>
          You didn’t add any students yet
        </Description>
      )}

      {students && students.map((student: UserPublicType) => (
        <GroupBar
          controlsMode={ControlsMode.ONLY_TRASH}
          key={student._id}
          id={student._id}
          name={student.fullName}
          noteText={student.email}
          onDelete={onRemoveStudents}
          onEdit={() => {}}
          onRedirect={() => {}}
          activeAssigments={0}
        />
      ))}
    </Wrapper>
  );
};

export default TeacherStudentsLayout;

const Wrapper = styled.div`
  width: 100%;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 22px;
`;
