import React from 'react';
import styled from 'styled-components/macro';
import FormElements from '../../Form';

const {
  Button,
} = FormElements;

const visaIcon = require('../../images/visa.svg');

interface PaymentInformationType {
  onShowModalCancelSubscription: () => void
}

const PaymentInformation = ({ onShowModalCancelSubscription }: PaymentInformationType) => {
  return (
    <Wrapper>
      <Title>Payment method</Title>
      <Box>
        <CardInfoWrapper>
          <Icon src={visaIcon} alt="payment-icon" />
          <CardNumber>****4242</CardNumber>
        </CardInfoWrapper>
        
        <ChangeCardButton onClick={onShowModalCancelSubscription}>Change card</ChangeCardButton>
      </Box>
    </Wrapper>
  );
};

export default PaymentInformation;

const Wrapper = styled.div``;

const Title = styled.h3`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 14px;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CardInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  margin-right: 14px;
`;

const CardNumber = styled.span`
  display: block;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
`;

const RemoveSpan = styled.span`
  display: block;
  fon-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #9077F5;
  margin: 0 20px;
  cursor: pointer;
`;

const ChangeCardButton = styled(Button)`
  width: auto;
  height: 56px;
  background-color: #9077F5;
  border-radius: 10px;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
  background-color: #FFF;
  color: inherit;
  margin-bottom: 0;
  :hover:not(:default) {
    background-color: #FFF;
  }
`;
