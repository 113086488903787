import React from 'react';
import styled from 'styled-components/macro';
import { QuestionType } from '../features/challenge/types';
import Radio from '../Form/FieldsEntity/Radio';
import ShowLatex from './ShowLates';

interface TypeQuestionProps {
  typeQuestion: string;
  itemsQuestion?: any;
  currentQuestionItems: QuestionType;
  correctAnswer?: string;
  userAnswer?: string;
}

const RenderMarkCorrectAnswer = (answer?: boolean) => (
  <MarkOfSelectedOption color={answer ? '#95D354' : '#F55F4B'}>
    {answer ? 'Correct answer is' : 'Incorrect'}
  </MarkOfSelectedOption>
);

const ShowingAnswerQuestion = ({
  typeQuestion,
  itemsQuestion,
  currentQuestionItems,
  userAnswer,
  correctAnswer,
}: TypeQuestionProps) => {
  // TODO то же самое что и TypeOfQuestion

  // TODO Блок с ответом и solution дублируется
  switch (typeQuestion) {
    case 'multiple':
      let correctAnswerText: any;
      const correctAnswer: string = currentQuestionItems.correctAnswer || '0';

      if (currentQuestionItems?.answers && typeof correctAnswer === 'string') {
        const answers = currentQuestionItems.answers || [];
        const n = parseInt(correctAnswer, 10);
        correctAnswerText = answers[n];
      }


      return (
        <>
          <AnswerChoice>
            {itemsQuestion
              && itemsQuestion.map((item: string, n: number) => (
                <RadioWrapper>
                  <RadioStyled
                    key={item}
                    backgroundRadio={
                      correctAnswer === `${n}` ? '#95D354' : '#F55F4B'
                    }
                    checked={
                      userAnswer === `${n}` || correctAnswer === `${n}`
                    }
                    disabled
                  >
                    <ShowLatex>{item}</ShowLatex>
                  </RadioStyled>
                  {/* {item.selectedAnswer && RenderMarkCorrectAnswer(item.correctAnswer)} */}
                </RadioWrapper>
              ))}
          </AnswerChoice>

          {!currentQuestionItems.right && currentQuestionItems.userAnswer !== undefined && (
            <>
              <InputWrapper>
                {RenderMarkCorrectAnswer(true)}
                <SpanAnswer>
                  <ShowLatex>{correctAnswerText}</ShowLatex>
                </SpanAnswer>
              </InputWrapper>
              {!currentQuestionItems.right && currentQuestionItems.userAnswer !== undefined && (
              <CorrectAnswerWrap>
                {/* <CorrectAnswerTitle>Correct answer</CorrectAnswerTitle> */}
                {currentQuestionItems.explanation && currentQuestionItems.explanation !== '' && (
                  <SolutionBox>
                    <SolutionTitle>Solution</SolutionTitle>
                    <SolutionDescription>
                      <ShowLatex>{currentQuestionItems.explanation}</ShowLatex>
                    </SolutionDescription>
                  </SolutionBox>
                )}
              </CorrectAnswerWrap>
              )}
            </>
          )}
        </>
      );

    case 'input':
      return (
        <>
          {currentQuestionItems.userAnswer && (
            <InputWrapper>
              {RenderMarkCorrectAnswer(currentQuestionItems.right)}
              <AnswerInput value={currentQuestionItems.userAnswer} disabled />
            </InputWrapper>
          )}
          {!currentQuestionItems.right && currentQuestionItems.userAnswer !== undefined && (
            <>
              <InputWrapper>
                {RenderMarkCorrectAnswer(true)}
                <SpanAnswer>{currentQuestionItems.correctAnswer}</SpanAnswer>
              </InputWrapper>
              {!currentQuestionItems.right && (
              <CorrectAnswerWrap>
                {/* <CorrectAnswerTitle>Correct answer</CorrectAnswerTitle> */}
                {currentQuestionItems.explanation && currentQuestionItems.explanation !== '' && (
                <SolutionBox>
                  <SolutionTitle>Solution</SolutionTitle>
                  <SolutionDescription>
                    <ShowLatex>{currentQuestionItems.explanation}</ShowLatex>
                  </SolutionDescription>
                </SolutionBox>
                )}
              </CorrectAnswerWrap>
              )}
            </>
          )}
        </>
      );
    case 'input-eng':
      return (
        <>
          {currentQuestionItems.userAnswer && (
            <InputWrapper>
              {RenderMarkCorrectAnswer(currentQuestionItems.right)}
              <AnswerInput value={currentQuestionItems.userAnswer} disabled />
            </InputWrapper>
          )}
          {!currentQuestionItems.right && currentQuestionItems.userAnswer !== undefined && (
            <>
              <InputWrapper>
                {RenderMarkCorrectAnswer(true)}
                <SpanAnswer>{currentQuestionItems.correctAnswer}</SpanAnswer>
              </InputWrapper>
              {!currentQuestionItems.right && (
              <CorrectAnswerWrap>
                {/* <CorrectAnswerTitle>Correct answer</CorrectAnswerTitle> */}
                {currentQuestionItems.explanation && currentQuestionItems.explanation !== '' && (
                <SolutionBox>
                  <SolutionTitle>Solution</SolutionTitle>
                  <SolutionDescription>
                    <ShowLatex>{currentQuestionItems.explanation}</ShowLatex>
                  </SolutionDescription>
                </SolutionBox>
                )}
              </CorrectAnswerWrap>
              )}
            </>
          )}
        </>
      );
    case 'select-image': {
      return (
        <PictureOptions>
          {itemsQuestion.map((item: any) => (
            <RadioImgWrap>
              {item.selectedAnswer
                && RenderMarkCorrectAnswer(item.correctAnswer)}
              <RadioImg
                key={item.key}
                hoverBorderColor={item.correctAnswer ? '#95D354' : '#F55F4B'}
                hideIndicator
                checked={item.selectedAnswer || item.correctAnswer}
                disabled
              >
                <OptionImg src={item.image} />
              </RadioImg>
            </RadioImgWrap>
          ))}
        </PictureOptions>
      );
    }
    default:
      return null;
  }
};

export default ShowingAnswerQuestion;

const AnswerChoice = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnswerInput = styled.input`
  height: 56px;
  max-width: 562px;
  width: 100%;
  background: #ffffff;
  /* Typography / Secondary 2 */
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 22px;
  margin-top: 8px;
  ::placeholder {
    color: #9c9c9c;
  }
`;

const RadioStyled = styled(Radio)`
  display: flex;
  align-items: center;
  margin-right: 50px;
  & span {
    font-size: 16px;
    font-weight: normal;
  }
`;

const RadioImgWrap = styled.div`
  align-self: flex-end;
`;

const RadioImg = styled(Radio)`
  margin-right: 26px;
  & span {
    overflow: hidden;
    margin: 0;
    margin-top: 20px;
  }
`;

const PictureOptions = styled.div`
  display: flex;
  min-height: 90px;
  // max-width: 500px;
  flex-wrap: wrap;
`;

const OptionImg = styled.img`
  width: 86px;
  height: 86px;
  flex-shrink: 0;
`;

const MarkOfSelectedOption = styled.span<{ color?: string }>`
  display: block;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: ${(props) => props.color};
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  :not(:last-child) {
    margin-bottom: 26px;
  }
  margin-bottom: 27px;
`;

const InputWrapper = styled.div`
  margin-bottom: 27px;
`;

const CorrectAnswerWrap = styled.div``;

const CorrectAnswerTitle = styled.span`
  display: block;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: #95d354;
  margin: 50px 0 17px;
`;

const SolutionBox = styled.div`
  box-sizing: border-box;
  padding: 38px 40px 30px;
  border-radius: 16px;
  background-color: #eeeaff;
`;

const SolutionTitle = styled.h2`
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 20px;
`;

const SolutionDescription = styled.p`
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 50px;
`;

const SpanAnswer = styled.span`
  display: block;
  font-weight: 500;
  font-size: 22px;
  box-sizing: border-box;
  padding: 15px;
`;
