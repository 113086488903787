import React, {
  useState, useEffect, ReactText,
} from 'react';
import {
  Table, Button, PageHeader, Input, message,
} from 'antd';
import { ColumnProps } from 'antd/es/table';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { UserProfileType } from '../../features/user/types';
import Filter, { FilterValuesType } from './Filter';

import { request } from '../../api';
import { PurpleButton } from '../Features/Buttons';
import { createMailList, createNoteFromUser } from './hooks';
import warningModal from './WarningModal';
import { getUserProfile } from '../../features/user/userState';
import NoteModal from './NoteModal';

function UsersTable() {
  const [users, setUsers] = useState<UserProfileType[]>([]);
  const [filters, setFilters] = useState<FilterValuesType>({});
  const [usersCount, setUsersCount] = useState<number>(0);
  // const [startItem, setStartItem] = useState(0);
  // const [offsetItems, setOffsetItems] = useState(10);
  const [startAndOffset, setStartAndOffset] = useState({ start: 0, offset: 10 });
  const [selectedUsers, setSelectedUsers] = useState<ReactText[]>([]);
  const [mailListName, setMailListName] = useState<string>('');
  const [showNoteModal, setShowNoteModal] = useState(false);

  const getUsers = () => {
    request(
      {
        method: 'POST',
        url: 'admin/user-list',
        data: {
          start: startAndOffset.start,
          offset: startAndOffset.offset,
          ...filters,
        },
      },
      [200],
    ).then(({ data }) => {
      const StatItemsList: UserProfileType[] = data.users;
      setUsersCount(data.count);
      setUsers(StatItemsList);
    });
  };

  useEffect(() => {
    getUsers();
  }, [setUsers, filters, startAndOffset]);

  const deleteSelectedUsers = (usersId: ReactText[]) => {
    request(
      {
        method: 'POST',
        url: 'admin/delete-users',
        data: {
          userIds: usersId,
        },
      }, [200]
    )
      .then(({ data }) => {
        message.info(data.message);
        getUsers();
        setSelectedUsers([]);
      })
      .catch(e => {
        message.error('Delete error')
      });
  };

  const changeStartAndOffsetItems = (start: number, offset: number | undefined) => {
    const changeOffset = offset || 10;
    const changeStart = (start - 1) * changeOffset;

    setStartAndOffset({ start: changeStart, offset: changeOffset });
  };

  const columns: ColumnProps<UserProfileType>[] = [
    {
      title: 'id',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Full name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Controls',
      dataIndex: 'c',
      key: 'c',
      render: (_, user) => (
        <div>
          <Button href={`${process.env.PUBLIC_URL}/users/${user._id}`}>edit</Button>
        </div>
      ),
    },
  ];


  useEffect(() => {
    // TODO Load users
    // dispatch(loadScores());
  }, []);

  // TODO select users list
  const dataSource: UserProfileType[] = users; // useSelector(selectScores);

  const onFilter = (filterData: FilterValuesType) => {
    setFilters(filterData);
  };

  const mailList = () => {
    createMailList(selectedUsers, mailListName);
  };

  const userProfile = useSelector(getUserProfile);

  const canDeleteFourUsers = userProfile.role && ['ADMIN', 'SUPER_ADMIN'].includes(userProfile.role);

  const selectUserIdInTable = (selected: ReactText[]) => {
    setSelectedUsers([...selected]);
  };

  const canDeleteSelectedUsers = () => {
    if (canDeleteFourUsers && selectedUsers.length > 4) {
      return message.error('You cannot delete more than four users');
    }

    warningModal(
      {
        title: 'Warning',
        description: 'Do you agree to delete the selected accounts?',
        onAgree: () => deleteSelectedUsers(selectedUsers),
        onCancel: () => console.log('No'),
      },
    );
  };

  return (
    <>
      <NoteModal
        onCancel={() => setShowNoteModal(false)}
        onCreateNote={(note: string) => {
          setShowNoteModal(false);
          createNoteFromUser(selectedUsers, note)
        }}
        visible={showNoteModal}
      />
      <QuestionsTableWrap>
        <Header
          className="site-page-header"
          title="Users"
        />
        <Filter onFilter={onFilter} />
        <CreateMailWrappers>
          <Input placeholder="Enter mail list name" style={{ maxWidth: 320 }} value={mailListName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMailListName(e.target.value)} />
          <MailListButton disabled={selectedUsers.length <= 0 || mailListName === ''} onClick={mailList}>Create mail list</MailListButton>
          <CreateNoteButton
            disabled={selectedUsers.length <= 0}
            onClick={() => setShowNoteModal(true)}
          >
            Create note
          </CreateNoteButton>
          <DeleteUsersButton
            disabled={selectedUsers.length <= 0}
            onClick={canDeleteSelectedUsers}
          >
            Delete selected users
          </DeleteUsersButton>
        </CreateMailWrappers>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            total: usersCount,
            onChange: (page, pageSize) => changeStartAndOffsetItems(page, pageSize),
            onShowSizeChange: (current, size) => changeStartAndOffsetItems(1, size),
          }}
          rowKey="_id"
          rowSelection={
            {
              type: 'checkbox',
              selectedRowKeys: selectedUsers,
              onChange: selectUserIdInTable,
            }
          }
        />
      </QuestionsTableWrap>
    </>
  );
}

const Header = styled(PageHeader)`
  padding-left: 0;
`;

const QuestionsTableWrap = styled.div`
`;

const CreateMailWrappers = styled.div`
  // height: 64px;
  position: sticky;
  top: 64px;
  z-index: 998;
  background-color: #f0f2f5;
  border-top: 1px solid #D9D9D9;
  box-sizing: border-box;
  padding: 24px 0;
  display: flex;
`;

const MailListButton = styled(PurpleButton)`
  padding: 8px 16px;
  margin-left: 12px;
`;

const CreateNoteButton = styled(MailListButton)`
  margin-left: 48px;
`;

const DeleteUsersButton = styled(MailListButton)`
  margin-left: 48px;
  background-color: #F14444;
  :hover:not(:disabled) {
    background-color: #FC3535;
  }
`;

export default UsersTable;
