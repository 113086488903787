import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Table, Button } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useDispatch } from 'react-redux';
import { PageHeader } from 'antd';
import { SchoolType } from '../../features/user/types';

interface SchoolsTableProps {
  schoolsList?: SchoolType[]
  deleteSchool: (_id: string) => void
  schoolsCount: number
  updateSchoolsList: (tablePage: number, pageSize: number | undefined) => void
}

const SchoolsTable = ({
  schoolsList,
  deleteSchool,
  schoolsCount,
  updateSchoolsList,
}: SchoolsTableProps) => {

  const columns: ColumnProps<SchoolType>[] = [
    {
      title: 'id',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'School name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: 'Controls',
      dataIndex: 'controls',
      key: 'controls',
      render: (_, school) => (
        <div key={school._id}>
          <Button href={`${process.env.PUBLIC_URL}/schools/${school._id}`} style={{ marginRight: 16 }}>Edit</Button>
          <Button type="primary" onClick={() => deleteSchool(school._id)} danger>Delete</Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Header
        className="site-page-header"
        title="Schools"
        extra={
          [
            <Button key="1" type="primary" href={`${process.env.PUBLIC_URL}/schools/new-school`}>Add new school</Button>,
          ]
        }
      />
      <Table
        dataSource={schoolsList}
        columns={columns}
        pagination={{
          defaultPageSize: 10,
          total: schoolsCount,
          onChange: (page, pageSize) => updateSchoolsList(page, pageSize),
          onShowSizeChange: (current, size) => updateSchoolsList(1, size),
        }}
      />
    </>
  );
};

export default SchoolsTable;

const Header = styled(PageHeader)`
  padding-left: 0;
  padding-right: 0;
`;
