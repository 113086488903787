import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface TypeProps {
  children?: ReactNode | string
  className?: string
  name?: string
  disabled?: boolean
  checked?: boolean
  hideIndicator?: boolean
  backgroundRadio?: string
  hoverBorderColor?: string
  onClick?: () => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: string | number
}

const Radio = ({
  className,
  children,
  hideIndicator,
  backgroundRadio,
  hoverBorderColor,
  ...more
}: TypeProps) => {
  return (
    <Label className={className} backgroundRadio={backgroundRadio} borderColor={hoverBorderColor}>
      <Input type="radio" {...more} />
      <Span hideCircle={hideIndicator}>
        {children}
      </Span>
    </Label>
  );
};

export default Radio;

const Input = styled.input`
  display: none;
`;

const Span = styled.span<{hideCircle?: boolean}>`
  display: inline-flex;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  box-sizing: border-box;
  border: ${props => props.hideCircle ? '2px solid #9C9C9C' : 'none'};
  border-radius: ${props => props.hideCircle ? '8px' : 'none'};
  ::before {
    content: ${props => props.hideCircle ? 'none' : '""'};
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #C4C4C4;
    /* background-color: #FFF; */
    margin-right: 15px;
    flex-shrink: 0;
    /* align-self: baseline; */
  }
`;

const Label = styled.label<{backgroundRadio?: string, borderColor?: string}>`
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  min-height: 20px;
  & ${Input}:checked + ${Span}::before {
    background-color: ${props => props.backgroundRadio};
    border: none;
  }
  & ${Input}:checked + ${Span} {
    border-color: ${({ theme }) => theme.color};
  }
`;
