import React from 'react';
import styled from 'styled-components/macro';

const checkboxPeriodItems = [{
  key: 1,
  name: 'week',
}, {
  key: 2,
  name: 'month',
}, {
  key: 3,
  name: 'year',
}];

interface RadioSelectPeriodProps {
  onSelectPeriod: (name: string) => void,
  value: string
}

const RadioSelectPeriod = ({ value, onSelectPeriod }: RadioSelectPeriodProps) => {
  return (
    <SelectPeriodWrapper>
      {checkboxPeriodItems.map((item: {key: number, name: string}) => (
        <LabelCheckbox key={item.key}>
          <Checkbox type="radio" checked={item.name === value} name="select-period" onChange={() => onSelectPeriod(item.name)} />
          <PeriodSpan>{item.name}</PeriodSpan>
        </LabelCheckbox>
      ))}
    </SelectPeriodWrapper>
  );
};

export default RadioSelectPeriod;

const SelectPeriodWrapper = styled.div`
  display: flex;
`;

const Checkbox = styled.input`
  display: none;
`;

const PeriodSpan = styled.span`
  display: block;
  box-sizing: border-box;
   text-transform: capitalize;
  padding: 8px 12px;
  background-color: #EEEAFF;
  border-radius: 8px;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  cursor: pointer;
  :hover {
    background-color: #AA95FF;
    color: #FFF;
  }
`;

const LabelCheckbox = styled.label`
  ${Checkbox}:checked + ${PeriodSpan} {
    background-color: #9077F5;
    color: #FFF;
  }
  :not(:last-child) {
    margin-right: 10px;
  }
`;
