import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { UserProfileType } from '../../../features/user/types';
import {
  ExitIcon,
  EditIcon,
  SupportIcon,
} from '../../../images/iconSvg';

const defaultTeacherAvatar = require('../../../images/Avatars/defaultTeacher.svg');

interface TeacherFrameProps {
  onLogOut: () => void
  userProfile: UserProfileType
}

const TeacherFrame = ({
  onLogOut,
  userProfile,
}: TeacherFrameProps) => {

  return (
    <User tabIndex={0}>
      <UserInfoWrapper>
        <UserAvatar src={defaultTeacherAvatar} />
        <UserInfo>
          <UserName>{userProfile.fullName}</UserName>
          <UserSchool>Name of School</UserSchool>
        </UserInfo>
      </UserInfoWrapper>
      <ProfileMenu>
        <ProfileMenuLinks>
          <LinkInMenu to={`${process.env.PUBLIC_URL}/teacher/profile`}>
            <EditIconStyle />
            Edit Profile
          </LinkInMenu>
          <LinkInMenu to={`${process.env.PUBLIC_URL}/teacher/support`}>
            <SupportIconStyled />
            Support / Instructions
          </LinkInMenu>
          <LinkInMenu to="#" onClick={onLogOut}>
            <ExitIconStyled />
            Log Out
          </LinkInMenu>
        </ProfileMenuLinks>
      </ProfileMenu>
    </User>
  );
};

export default TeacherFrame;

const UserAvatar = styled.img`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  margin-right: 25px;

  :focus {
    outline: none;
  }
`;

const UserInfo = styled.div`
  overflow: hidden;
  width: 100%;
`;

const UserName = styled.span`
  display: block;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #1F1F1F;
  overflow: hidden;
  white-space: nowrap;
  position: relative;

  ::after {
    content: '';
    display: block;
    height: 100%;
    width: 30px;  
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const UserSchool = styled.span`
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  ::first-letter {
    text-transform: uppercase;
  }
`;

const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileMenu = styled.div`
  width: 310px;
  border-radius: 15px;
  position: absolute;
  top: 100%;
  right: -30px;
  background-color: #FFF;
  box-shadow: 0px 2px 8px #F0F2F3;
  visibility: hidden;
  transform: translateY(-10px);
  opacity: 0;
  transition: .3s ease-out;
  transition-property: opacity, transform;
`;

const User = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  :focus {
    outline: none;
  }

  :focus-within ${ProfileMenu} {
    visibility: visible;
    transform: translateY(0px);
    opacity: 1;
  }

  @media (max-width: 686px) {
    display: none;
  }
`;

const ProfileMenuLinks = styled.div`
  padding: 12px;
`;

const LinkInMenu = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 4px;
  padding-left: 26px;
  font-size: 18px;
  line-height: 21px;
  transition: background-color .3s ease-in-out;
  color: inherit;

  :hover {
    background-color: #EEEAFF;
    color: inherit;
  }

  :not(:last-child) {
    margin-bottom: 6px;
  }
`;

const EditIconStyle = styled(EditIcon)`
  width: 19px;
  margin-right: 15px;
`;

const ExitIconStyled = styled(ExitIcon)`
  width: 19px;
  margin-right: 15px;
`;

const SupportIconStyled = styled(SupportIcon)`
  width: 19px;
  margin-right: 15px;
`;
