import React, { useState } from "react";
import styled from "styled-components/macro";
import Radio from "../Form/FieldsEntity/Radio";
import ShowLatex from "./ShowLates";

interface TypeQuestionProps {
  typeQuestion: string;
  itemsQuestion?: any;
  onChangeAnswer: (answer: string) => void;
  answer: string;
  isAnswered: boolean;
}

const TypeOfQuestion = ({
  answer,
  isAnswered,
  typeQuestion,
  itemsQuestion,
  onChangeAnswer,
}: TypeQuestionProps) => {
  switch (typeQuestion) {
    case "multiple":
      return (
        <AnswerChoice>
          {itemsQuestion &&
            itemsQuestion.map((item: string, key: number) => (
              <RadioStyled
                name="answer-choice"
                key={item}
                onClick={() => onChangeAnswer(`${key}`)}
                backgroundRadio="#9077F5"
                checked={answer === `${key}`}
                disabled={isAnswered}
              >
                <ShowLatex>{item}</ShowLatex>
              </RadioStyled>
            ))}
        </AnswerChoice>
      );
    case "input":
      return (
        <AnswerInput
          disabled={isAnswered}
          value={answer}
          placeholder="Please enter an answer"
          onChange={(e: any) => onChangeAnswer(e.target.value)}
        />
      );
    case "input-eng":
      return (
        <AnswerInput
          disabled={isAnswered}
          value={answer}
          placeholder="Please enter an answer"
          onChange={(e: any) => onChangeAnswer(e.target.value)}
        />
      );
    case "select-image":
      return (
        <PictureOptions>
          {itemsQuestion.map((item: any, n: number) => (
            <RadioImg
              key={item.key}
              name="image-answer"
              onClick={() => onChangeAnswer(item.key)}
              hoverBorderColor="#9077F5"
              hideIndicator
            >
              <OptionImg src={item.image} />
            </RadioImg>
          ))}
        </PictureOptions>
      );
    default:
      return null;
  }
};

export default TypeOfQuestion;

const AnswerChoice = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnswerInput = styled.input`
  height: 56px;
  max-width: 562px;
  width: 100%;
  background: #ffffff;
  /* Typography / Secondary 2 */
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 22px;
  ::placeholder {
    color: #9c9c9c;
  }
`;

const RadioStyled = styled(Radio)`
  & span {
    font-size: 16px;
    font-weight: normal;

    :hover {
      ::before {
        border-color: #9077f5;
      }
    }
  }
  :not(:last-child) {
    margin-bottom: 26px;
  }
`;

const RadioImg = styled(Radio)`
  :not(:last-child) {
    margin-right: 25px;
  }
  & span {
    overflow: hidden;
    margin: 0;
    :hover {
      border-color: #9077f5;
    }
  }
`;

const QuestionImg = styled.img`
  object-fill: cover;
  max-width: 560px;
  height: auto;
  margin-top: 17px;
`;

const PictureOptions = styled.div`
  display: flex;
  min-height: 90px;
  // max-width: 500px;
  flex-wrap: wrap;
`;

const OptionImg = styled.img`
  width: 86px;
  height: 86px;
  flex-shrink: 0;
`;
